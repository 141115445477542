import {
    AttributeEditor,
    AttributeEditorProps,
    FormField,
    Input,
    SpaceBetween,
    TextContent,
    Textarea,
    Toggle
} from "@amzn/awsui-components-react";
import { DocumentContent, DocumentGroup } from "@amzn/ask-legal-domain";
import * as React from "react";
import { Builder } from "builder-pattern";
import { UIModel } from "../../../model/ui-model";
import { PageDraftModel } from "../../../model/page-draft-model";

export const DocumentContentEdit = (props: {
    state: UIModel.State<DocumentContent>;
}) => {
    const content = (props.state.value as DocumentContent);
    const def = [
        Builder<AttributeEditorProps.FieldDefinition<DocumentGroup>>()
            .label("Name")
            .control((item: DocumentGroup, index: number) => (
                <FormField
                    errorText={UIModel.FieldValidation.assertNotNullString(item.groupTitle.trim())}
                >
                    <Input
                        value={item.groupTitle}
                        placeholder="Enter document group title..."
                        onChange={(e: { detail: { value: string }; }) => {
                            props.state.setValue(
                                DocumentContent.updateGroup(
                                    content,
                                    content.fileGroups[index],
                                    e.detail.value,
                                    item.groupDescription
                                )
                            );
                        }}
                    />
                </FormField>
            ))
            .build(),
        Builder<AttributeEditorProps.FieldDefinition<DocumentGroup>>()
            .label("Description")
            .control((item: DocumentGroup, index: number) => (
                <FormField
                    errorText={
                        UIModel.FieldValidation.assertWithinCharLimit(
                            PageDraftModel.DOC_DESCRIPTION_CHAR_LIMIT, item.groupDescription, true
                        )
                    }
                >
                    <Textarea
                        value={item.groupDescription}
                        placeholder="Enter document group description..."
                        onChange={(e: { detail: { value: string }; }) => {
                            props.state.setValue(
                                DocumentContent.updateGroup(
                                    content,
                                    content.fileGroups[index],
                                    item.groupTitle,
                                    e.detail.value
                                )
                            );
                        }}
                    />
                </FormField>
            ))
            .build()
    ];

    const addEmptyGroup = () => {
        props.state.setValue(
            DocumentContent.addGroup(content, "", "")
        );
    };

    const removeGroup = (index: number) => {
        props.state.setValue(
            DocumentContent.removeGroup(
                content,
                content.fileGroups[index].groupTitle
            )
        );
    };

    return (
        <SpaceBetween size="m">
            <FormField
                label="Documents Ordering Preference"
                description="When enabled, the documents will be sorted by filename in an ascending order. When disabled, you can change the order manually."
            >
                <Toggle
                    checked={!(props.state.value as DocumentContent)?.manualSort}
                    onChange={(e: { detail: { checked: boolean }; }) => props.state.setValue(
                        DocumentContent.updateMeta(props.state.value as DocumentContent, {
                            manualSort: !e.detail.checked
                        })
                    )}
                >
                    <TextContent>
                        Auto-Order By Filename <small><em>(Enabled by default)</em></small>
                    </TextContent>
                </Toggle>
            </FormField>
            <AttributeEditor
                definition={def}
                onAddButtonClick={addEmptyGroup}
                onRemoveButtonClick={(e) => removeGroup(e.detail.itemIndex)}
                isItemRemovable={(group) => !group.files || group.files.length === 0}
                items={!!content?.fileGroups ? content.fileGroups : []}
                addButtonText={"Add new Document Group"}
                removeButtonText={"Remove"}
                empty="No additional Document Groups added."
            />
        </SpaceBetween>
    );
};
import {
  EntityFactory,
  IRecommendation,
  RecommendationRule,
  Role,
  Tenure,
} from "@amzn/ask-legal-domain";
import {
  SpaceBetween,
  RadioGroup,
  ColumnLayout,
  TextContent,
  Checkbox,
  StatusIndicator,
  Popover,
  Box,
  Input,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../../hooks/api-hook";
import { RecommendationRuleModel } from "../../../model/recommendation-rule-model";
import { AppContext } from "../../../setup/context";
import { EntitySelection } from "../../common/EntitySelection";
import { GeoSelect } from "../../common/GeoSelect";
import { UIField } from "../../common/UIField";
import { RolePopover } from "../../role/RoleInfoLink";

export const RuleFieldsEditing = (props: {
  instanceId: string;
  state: RecommendationRuleModel.EditState;
}) => {
  const context = React.useContext(AppContext);
  const [skipTenure, setSkipTenure] = React.useState<boolean>(
    Tenure.toDays(props.state.tenure.value) > 0 ? false : true
  );
  return (
    <ColumnLayout columns={1} borders="horizontal">
      <ColumnLayout columns={2}>
        <UIField.StateValueField
          name="Rule Name"
          state={props.state.name}
          constraintText={`Maximum ${RecommendationRuleModel.NAME_CHAR_LIMIT} characters`}
          editing={true}
        />
        <UIField.CustomField
          name="Rule Type"
          child={
            <RadioGroup
              onChange={(e) =>
                props.state.ruleType.setValue(
                  e.detail.value as RecommendationRule.Type
                )
              }
              value={props.state.ruleType.value}
              items={[
                {
                  value: RecommendationRule.Type.ALL,
                  label: "All",
                  description: "All rules must match",
                },
                {
                  value: RecommendationRule.Type.ANY,
                  label: "Any",
                  description: "At least one rule can match",
                },
              ]}
            />
          }
        />
      </ColumnLayout>
      <div>
        <ColumnLayout columns={2} borders="vertical">
          <SpaceBetween direction="vertical" size="l">
            <Checkbox
              onChange={({ detail }) => {
                setSkipTenure(detail.checked);
                props.state.tenure.setValue(Tenure.create(0, 0));
              }}
              checked={skipTenure}
            >
              Skip tenure check in this rule
            </Checkbox>
            {!skipTenure && (
              <React.Fragment>
                <UIField.CustomField
                  name="Tenure in years"
                  child={
                    <Input
                      type="number"
                      placeholder="Number of years"
                      value={props.state.tenure.value.years}
                      onChange={(e: { detail: { value: string; }; }) => {
                          const num = parseInt(e.detail.value);
                          if (!isNaN(num) && num >= 0) {
                              props.state.tenure.setValue(
                                Tenure.update(
                                  props.state.tenure.value,
                                  num,
                                  props.state.tenure.value.months,
                                )
                              );
                          }
                      }}
                    />
                }/>
                <UIField.CustomField
                  name="Tenure in months"
                  child={
                    <Input
                      type="number"
                      placeholder="Number of months"
                      value={props.state.tenure.value.months}
                      onChange={(e: { detail: { value: string; }; }) => {
                          const num = parseInt(e.detail.value);
                          if (!isNaN(num) && num >= 0) {
                              props.state.tenure.setValue(
                                Tenure.update(
                                  props.state.tenure.value,
                                  props.state.tenure.value.years,
                                  num
                                )
                              );
                          }
                      }}
                    />
                }/>
                <div>Total Tenure {props.state.tenure.value.years} year(s) and {props.state.tenure.value.months} month(s)</div>
                <UIField.CustomField
                  name="Tenure Operation"
                  child={
                    <RadioGroup
                      onChange={({ detail }) =>
                        props.state.tenureOperator.setValue(
                          detail.value as RecommendationRule.Operator
                        )
                      }
                      value={props.state.tenureOperator.value}
                      items={[
                        {
                          value: RecommendationRule.Operator.Equal,
                          label: "Equal",
                        },
                        {
                          value: RecommendationRule.Operator.LessThan,
                          label: "Less than",
                        },
                        {
                          value: RecommendationRule.Operator.LessThanInclusive,
                          label: "Less than or equal to",
                        },
                        {
                          value: RecommendationRule.Operator.GreaterThan,
                          label: "Greater than",
                        },
                        {
                          value:
                            RecommendationRule.Operator.GreaterThanInclusive,
                          label: "Greater than or equal to",
                        },
                      ]}
                    />
                  }
                />
              </React.Fragment>
            )}
          </SpaceBetween>
          <SpaceBetween direction="vertical" size="l">
            <UIField.CustomField
              name="Roles"
              child={
                <EntitySelection.MultiWithPagination
                  api={context.getRoleAPI().loadByInstance}
                  partitionKey={props.instanceId}
                  selected={props.state.roles.value}
                  onSelectionChange={(entities: Role.Data[]) =>
                    props.state.roles.setValue(
                      entities.map((e) => EntityFactory.toEntityRef(e))
                    )
                  }
                />
              }
            />
            <UIField.CustomField
              name="Geos"
              child={
                <GeoSelect.Multi
                  selected={props.state.geoCodes.value}
                  onSelectionChange={props.state.geoCodes.setValue}
                />
              }
            />
          </SpaceBetween>
        </ColumnLayout>
      </div>
    </ColumnLayout>
  );
};

export const RuleFieldsView = (props: { rule: RecommendationRule.Data }) => {
  return (
    <ColumnLayout columns={2} variant="text-grid">
      <SpaceBetween size="m">
        <UIField.LabelField
          label="Rule Type"
          children={props.rule.ruleType.toUpperCase()}
        />
        <UIField.LabelField
          label="Roles"
          children={
            !!props.rule.roles ? (
              <SpaceBetween size="xxs" direction="vertical">
                {props.rule.roles.map((r) => (
                  <RolePopover id={r} variant="text" />
                ))}
              </SpaceBetween>
            ) : (
              "-"
            )
          }
        />
      </SpaceBetween>
      <SpaceBetween size="m">
        <UIField.LabelField
          label="Tenure"
          children={Tenure.toDays(props.rule.tenure.value) > 0 ? `${props.rule.tenure.value.years}y - ${props.rule.tenure.value.months}m` : "-"}
        />
        <UIField.LabelField
          label="Tenure Operation"
          children={Tenure.toDays(props.rule.tenure.value) > 0 ? `${props.rule.tenure.operator}` : "-"}
        />
        <UIField.LabelField
          label="Geos"
          children={
            !!props.rule.geoCodes
              ? props.rule.geoCodes.map((g) => (
                  <TextContent>
                    <strong>{g.value.value} </strong>[{g.value.type}]
                  </TextContent>
                ))
              : "-"
          }
        />
      </SpaceBetween>
    </ColumnLayout>
  );
};

export const RuleDeleteContent = (props: {
  ruleId: string;
  instanceId: string;
  setCanDelete: (exists: boolean) => void;
}) => {
  const context = React.useContext(AppContext);

  const searchRecRunner = useAPI(context.getRecommendationAPI().searchRule);

  React.useEffect(() => {
    searchRecRunner.submitRun(
      IRecommendation.SearchRuleInput.create({
        instanceId: props.instanceId,
        ruleId: props.ruleId,
      })
    );
  }, []);

  React.useEffect(() => {
    if (
      searchRecRunner.status === "Succeeded" &&
      searchRecRunner.data.output.length === 0
    ) {
      props.setCanDelete(true);
    }
  }, [searchRecRunner.status]);

  return (
    <SpaceBetween size="m">
      {searchRecRunner.status === "New" && (
        <StatusIndicator type="pending">
          Starting check on recommendations
        </StatusIndicator>
      )}
      {searchRecRunner.status === "Running" && (
        <StatusIndicator type="loading">
          Searching for dependent recommendations
        </StatusIndicator>
      )}
      {searchRecRunner.status === "Succeeded" &&
        searchRecRunner.data.output.length === 0 && (
          <StatusIndicator type="success">
            Dependency checks complete
          </StatusIndicator>
        )}
      {searchRecRunner.status === "Succeeded" &&
        searchRecRunner.data.output.length !== 0 && (
          <StatusIndicator type="warning">
            One or more recommendations are dependent on this role.{" "}
            <Popover
              dismissAriaLabel="Close"
              fixedWidth
              size="small"
              triggerType="text"
              content={
                <UIField.LabelField
                  label="Recommendation(s)"
                  children={
                    <ul>
                      {searchRecRunner.data.output.map((v) => (
                        <li>{v.title}</li>
                      ))}
                    </ul>
                  }
                />
              }
            >
              View Details
            </Popover>
          </StatusIndicator>
        )}
      {searchRecRunner.status === "Error" && (
        <StatusIndicator type="error">
          Error checking recommendations. Reason:{" "}
          {searchRecRunner.data.err.message}
        </StatusIndicator>
      )}
      <Box textAlign="center">
        {searchRecRunner.status === "Succeeded" &&
          searchRecRunner.data.output.length === 0 && (
            <TextContent>
              Click on{" "}
              <em>
                <strong>submit</strong>
              </em>{" "}
              to delete selected rule.
            </TextContent>
          )}
        {searchRecRunner.status === "Succeeded" &&
          searchRecRunner.data.output.length !== 0 && (
            <TextContent>
              Please remove all dependencies to delete this rule.
            </TextContent>
          )}
      </Box>
    </SpaceBetween>
  );
};

import {
    EntityFactory,
    EntityRef,
    EntityType,
    IRole,
    Role,
    Team
} from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import { Builder } from "builder-pattern";

export namespace RoleModel {
    export const NAME_CHAR_LIMIT = 50;

    export class RoleState {
        name: UIModel.State<string>;
        members: UIModel.State<Team[]>;
        instanceRef: EntityRef;
        init: (entity?: Role.Data) => void;
        reset: () => void;

        public static toCreateInput(state: RoleState) {
            return IRole.CreateRoleInput.create({
                instanceRef: state.instanceRef,
                name: state.name.value,
                members: state.members.value
            });
        }

        public static toUpdateInput(state: RoleState, roleRef: EntityRef) {
            return IRole.UpdateRoleInput.create({
                roleRef: roleRef,
                instanceRef: state.instanceRef,
                newName: state.name.value,
                newMembers: state.members.value
            });
        }

        static use (props: {
            instanceId: string;
        }): RoleState {
            const name = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: NAME_CHAR_LIMIT
            });
            const members = UIModel.State.useRequiredArray<Team>({ initialValue: [] });
            const instanceRef = EntityFactory.fromEntityAttributes(props.instanceId, EntityType.Instance);

            const init = (entity?: Role.Data) => {
                if (entity) {
                    name.setValue(entity.name);
                    members.setValue(entity.members);
                } else {
                    name.setValue("");
                    members.setValue([]);
                }
            };

            const reset = () => {
                name.setValue(null);
                members.setValue(null);
            };

            return Builder<RoleState>()
                .name(name)
                .members(members)
                .instanceRef(instanceRef)
                .init(init)
                .reset(reset)
                .build();
        }
    }
}
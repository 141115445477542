import {
    APIOutput,
    AppError,
    EntityRef,
    EntityType,
    ErrorCode,
    Page} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "./ErrorFlashbar";
import { UIField } from "./UIField";
import { PageModel } from "../../model/page-model";
import { UIConstants } from "../../utils/common-utils";

export const DeprecationModal = (props: {
    entityRef: EntityRef;
    /**
     * To indicate the entity is already deprecated, this modal will be used for activation
     */
    deprecated: boolean;
    /**
     * Supports only Page entity Types
     */
    forwardingUrl?: string;
    onUpdated: (entity: any) => void;
    onCancel: () => void;
}) => {
    const context = React.useContext(AppContext);
    const [error, setError] = React.useState<AppError>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const commonState = PageModel.DeprecatePageState.use({
        entityRef: props.entityRef,
        forwardingUrl: props.forwardingUrl
    });

    const deprecate = async () => {
        if (props.entityRef.type !== EntityType.Page) {
            setError(
                AppError.create(`Unable to deprecate entity type: ${props.entityRef.type}`, 400)
            );
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const deprecationOutput = await context.getPageAPI().deprecate(
                PageModel.DeprecatePageState.toInput(commonState)
            );
            const output = APIOutput.fromRaw<Page.Data>(deprecationOutput.data);
            if (output.isErr()) {
                setError(output.err);
            } else {
                props.onUpdated(output.data);
            }
        } catch (e) {
            let code = 500;
            let message = UIConstants.ERROR_MESSAGE;
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                message = e.response.data.message;
            }
            if (!!e.response && !!e.response.status && e.response.status > 0) {
                code = e.response.status;
            }
            setError(AppError.create(message, code as ErrorCode));
        } finally {
            setLoading(false);
        }
    };

    const activate = async () => {
        if (props.entityRef.type !== EntityType.Page) {
            setError(AppError.create(`Unable to activate entity type: ${props.entityRef.type}`, 400));
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const activateOutput = await context.getPageAPI().activate(props.entityRef.id);
            const output = APIOutput.fromRaw<Page.Data>(activateOutput.data);
            if (output.isErr()) {
                setError(output.err);
            } else {
                props.onUpdated(output.data);
            }
        } catch (e) {
            let code = 500;
            let message = UIConstants.ERROR_MESSAGE;
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                message = e.response.data.message;
            }
            if (!!e.response && !!e.response.status && e.response.status > 0) {
                code = e.response.status;
            }
            setError(AppError.create(message, code as ErrorCode));
        } finally {
            setLoading(false);
        }
    };

    const onSubmit = () => {
        if (!props.deprecated) {
            deprecate();
        } else if (props.deprecated) {
            activate();
        }
    };

    const deprecationModalContent = (
        <SpaceBetween direction="vertical" size="s">
            {props.entityRef.type === EntityType.Page && !props.deprecated &&
                <UIField.StateValueField
                    name="Specify forwarding URL (optional)"
                    state={commonState.forwardingUrl}
                    editing={true}
                />
            }
            {props.entityRef.type === EntityType.Page && !!props.deprecated && props.forwardingUrl &&
                <UIField.ValueField
                    name="Forwarding URL (will be removed once activated)"
                    value={props.forwardingUrl}
                />
            }
            <p>Are you sure you want to {props.deprecated ? "activate" : "deprecate"} this {props.entityRef.type.toLowerCase()}?</p>
        </SpaceBetween>
    );

    return (
        <Modal
            visible={true}
            size="medium"
            header={`${props.deprecated ? "Activate" : "Deprecate"} ${props.entityRef.type}`}
            footer={
                <Box float="right">
                    <Button variant="link" loading={loading} onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        loading={loading}
                        onClick={onSubmit}
                        disabled={!!commonState.forwardingUrl.errorText}
                    >
                        Confirm
                    </Button>
                </Box>
            }
            onDismiss={props.onCancel}>
            {error && <ErrorFlashbar error={error} />}
            {deprecationModalContent}
        </Modal>
    );
};
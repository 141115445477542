import { IUserActivity } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface UserActivityAPI {
    record(input: IUserActivity.RecordInput): Promise<APIResponse<{}>>;
}

export class UserActivityAPIImpl implements UserActivityAPI {
    constructor (private context: AppContextValue) {}

    async record(input: IUserActivity.RecordInput): Promise<APIResponse<{}>> {
        const response: APIResponse<{}> = await RestAPI.post(
            "custom-domain",
            "/user-activity/record",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
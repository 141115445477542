import { AppError } from "@amzn/ask-legal-domain";
import { Flashbar, FlashbarProps } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIConstants } from "../../utils/common-utils";

export const ErrorFlashbar = (props: { error: AppError, action?: JSX.Element }) => {
    const [items, setItems] = React.useState<FlashbarProps.MessageDefinition[]>([
        {
            header: `${UIConstants.ERROR_MESSAGE}`,
            type: "error",
            action: props.action,
            content: `${props.error.code}: ${props.error.message}`,
            dismissible: false,
            dismissLabel: "Dismiss message",
            onDismiss: () => setItems([])
        }
    ]);
    return <Flashbar items={items}/>;
};
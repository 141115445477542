import {
    DecisionTreeContent,
    DecisionTreeNode,
    RichTextContent
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { UIField } from "../../common/UIField";
import { StrongRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export const DecisionTreeContentEdit = (props: {
    state: UIModel.State<DecisionTreeContent>;
}) => {
    return <UIField.CustomField
        name="Welcome Text"
        child={<StrongRichTextEdit
            content={!props.state.value ?
                DecisionTreeContent.create(
                    DecisionTreeNode.createRootNode({
                        nodeType: "SingleSelect",
                        containerId: ""
                    }), [], RichTextContent.create()).welcomeText.contentValue :
                props.state.value.welcomeText?.contentValue
            }
            onChange={(e) => {
                props.state.setValue(
                    DecisionTreeContent.update(props.state.value, RichTextContent.create(e))
                );
            }}
            hidePermContent={true}
        />}
    />;
};
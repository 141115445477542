import * as React from "react";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";
import { AppContext } from "../../setup/context";
import { RecommendationPolarisFactory } from "../../factory/polaris/rec-polaris-factory";
import { Box, Button, ButtonDropdown, CollectionPreferences, Flashbar, Header, Pagination, PropertyFilter, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useAPI } from "../../hooks/api-hook";
import { RecommendationModel } from "../../model/recommendation-model";
import { SimpleModal } from "../common/SimpleModal";
import { RecModalContent } from "./modal-content/RecModalContent";
import { Recommendation } from "@amzn/ask-legal-domain";

export const RecommendationTable = (props: {
    id: string, viewOnly?: boolean
}) => {
    const context = React.useContext(AppContext);
    const [modalAction, setModalAction] = React.useState<string>("");
    const [statusUpdates, setStatusUpdates] = React.useState<Array<any>>([]);

    const createRecRunner = useAPI(context.getRecommendationAPI().create);
    const updateRecRunner = useAPI(context.getRecommendationAPI().update);
    const deleteRecRunner = useAPI(context.getRecommendationAPI().delete);

    const recState = RecommendationModel.RecommendationState.use({ instanceId: props.id });

    const createRecButton = (
        <Button
            variant="primary"
            onClick={() => actionHandler("create")}
        >
            Create Recommendation
        </Button>
    );

    const collection = useLoadingWithServerSidePagination<Recommendation.Data>(
        context.getRecommendationAPI().loadAll,
        props.id,
        {
            columnDefinitions: RecommendationPolarisFactory.Table.toColumnDefinitions(),
            defaultTablePreferences: RecommendationPolarisFactory.Table.toDefaultTablePreferences(),
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ],
            selectionType: props.viewOnly ? undefined : "single"
        }, {
            filteringProperties: [
                RecommendationPolarisFactory.Table.PropertyFilter.Any,
                RecommendationPolarisFactory.Table.PropertyFilter.Title
            ]
        }
    );
    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };
    const header = (
        <Header variant="h2" counter={counter()} actions={
            <SpaceBetween direction="horizontal" size="m">
                <Button variant="icon" iconName="refresh" onClick={() => {
                    if (collection) {
                        collection.loadItems();
                    }
                }}/>
                {!props.viewOnly &&
                    <React.Fragment>
                        <ButtonDropdown
                            loading={false}
                            items={[
                                {
                                    text: "Edit",
                                    id: "update",
                                    disabled: collection.table.props.selectedItems.length !== 1
                                },
                                {
                                    text: "Delete",
                                    id: "delete",
                                    disabled: collection.table.props.selectedItems.length !== 1
                                }
                            ]}
                            onItemClick={(event: { detail: { id: string } }) => {
                                actionHandler(event.detail.id);
                            }}>
                            Actions
                        </ButtonDropdown>
                        {createRecButton}
                    </React.Fragment>
                }
            </SpaceBetween>
        }>
            Recommendations
        </Header>
    );
    const empty = (
        <Box textAlign="center" color="inherit">
            <b>
                No Recommendations found
            </b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No Recommendations found in this Instance
            </Box>
            {!props.viewOnly && createRecButton}
        </Box>
    );

    const dismiss = () => {
        recState.reset();
        setModalAction("");
    };

    const actionHandler = (action: string) => {
        switch (action) {
            case "create":
                recState.init();
                setModalAction("create");
                break;
            case "update":
                recState.init(collection.table.props.selectedItems[0]);
                setModalAction("update");
                break;
            case "delete":
                setModalAction("delete");
                break;
            default: break;
        }
    };

    React.useEffect(() => {
        if (createRecRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Recommendation: ${createRecRunner.data.output.title} was successfully created.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (createRecRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to create recommendation. Reason: ${createRecRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [createRecRunner.status]);

    React.useEffect(() => {
        if (updateRecRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Recommendation: ${updateRecRunner.data.output.title} was successfully updated.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (updateRecRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to update recommendation. Reason: ${updateRecRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [updateRecRunner.status]);

    React.useEffect(() => {
        if (deleteRecRunner.status === "Succeeded") {
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Recommendation: ${deleteRecRunner.data.output.title} was successfully deleted.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (deleteRecRunner.status === "Running") {
            setStatusUpdates([{
                type: "success",
                content: `Deleting selected recommendation.`,
                loading: true
            }]);
        } else if (deleteRecRunner.status === "Error") {
            setStatusUpdates([{
                type: "error",
                content: `Failed to delete recommendation. Reason: ${deleteRecRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [deleteRecRunner.status]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <Flashbar
                    items={statusUpdates}
                />
                {collection.table.error && <ErrorFlashbar error={collection.table.error}/>}
                {modalAction === "create" &&
                    <SimpleModal
                        child={<RecModalContent state={recState} isCreate={true}/>}
                        header={"Create New Recommendation"}
                        loading={createRecRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            createRecRunner.submitRun(
                                RecommendationModel.RecommendationState.toCreateInput(recState)
                            );
                        }}
                        disabled={
                            !!recState.title.errorText ||
                            !!recState.description.errorText ||
                            !!recState.url.errorText ||
                            !!recState.rules.errorText ||
                            !!recState.description.errorText
                        }
                        size="max"
                    />
                }
                {modalAction === "update" &&
                    <SimpleModal
                        child={<RecModalContent state={recState} isCreate={false}/>}
                        header={"Update Recommendation"}
                        loading={updateRecRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            updateRecRunner.submitRun(
                                RecommendationModel.RecommendationState.toUpdateInput(
                                    recState,
                                    collection.table.props.selectedItems[0]
                                )
                            );
                        }}
                        disabled={
                            !!recState.title.errorText ||
                            !!recState.description.errorText ||
                            !!recState.url.errorText ||
                            !!recState.rules.errorText
                        }
                        size="large"
                    />
                }
                {modalAction === "delete" &&
                    <SimpleModal
                        child={<div>Click on <em><strong>submit</strong></em> to confirm deletion of selected recommendation.</div>}
                        header="Delete Recommendation"
                        loading={false}
                        onCancel={dismiss}
                        onSave={() => {
                            deleteRecRunner.submitRun(collection.table.props.selectedItems[0].id);
                            setModalAction("");
                        }}
                        disableLeavingAlert
                    />
                }
                <Table
                    {...collection.table.props}
                    empty={empty}
                    header={header}
                    preferences={
                        <CollectionPreferences {...collection.tablePreference.props}/>
                    }
                    pagination={
                        <Pagination {...collection.tablePagination.props}/>
                    }
                    filter={
                        <PropertyFilter {...collection.tableFiltering.props} />
                    }
                />
            </SpaceBetween>
        </React.Fragment>
    );
};
import { SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UserProfileInfo } from "../../components/user/UserProfileInfo";
import { UserFavoritesDetail } from "../../components/user/UserFavoriteDetail";
import { Identity } from "@amzn/ask-legal-domain";

export const UserProfileHome = (props: {
    user: Identity;
    instanceId: string;
}) => {
    return <>
        <SpaceBetween direction="vertical" size="xl">
            <UserProfileInfo userId={props.user}/>
            <UserFavoritesDetail instanceId={props.instanceId}/>
        </SpaceBetween>
    </>;
};
import {
    CustomListContent,
    CustomListObject
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid,
    Header,
    Input,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { Board, BoardItem, BoardProps } from "@amzn/awsui-board-components";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { boardI18nStrings, boardItemI18nStrings } from "../../../i18n/boardI18nStrings";

export const CustomListContentEdit = (props: {
    state: UIModel.State<CustomListContent>;
}) => {
    const renderBoardItem = (item: BoardProps.Item<CustomListObject>, actions: any) => (
        <BoardItem
            header={<Header>{item.id}.&nbsp;{item.data.key}</Header>}
            i18nStrings={boardItemI18nStrings}
            settings={
                <Button
                    ariaLabel="Remove item"
                    variant="normal"
                    iconName="remove"
                    onClick={() => actions.removeItem()}
                >
                    Remove
                </Button>
            }
        >
            <ColumnLayout columns={2}>
                <FormField
                    label="Title"
                    errorText={UIModel.FieldValidation.assertNotNullString(item.data.key.trim())}
                >
                    <Input
                        value={item.data.key}
                        placeholder="Enter title..."
                        onChange={(e: { detail: { value: string; }; }) => {
                            props.state.setValue(
                                CustomListContent.replaceItem(
                                    props.state.value,
                                    Number(item.id),
                                    CustomListObject.create(e.detail.value, item.data.value)
                                )
                            );
                        }}
                    />
                </FormField>
                <FormField
                    label="URL"
                    errorText={
                        (UIModel.FieldValidation.isValidURL(item.data.value) || UIModel.FieldValidation.isValidMailto(item.data.value)) ?
                            null : "URL is not valid"
                    }>
                    <Input
                        value={item.data.value}
                        placeholder="Enter content URL..."
                        inputMode="url"
                        type="url"
                        onChange={(e: { detail: { value: string; }; }) => {
                            props.state.setValue(
                                CustomListContent.replaceItem(
                                    props.state.value,
                                    Number(item.id),
                                    CustomListObject.create(item.data.key, e.detail.value)
                                )
                            );
                        }}
                    />
                </FormField>
            </ColumnLayout>
        </BoardItem>
    );

    const getBoardItem = (content: CustomListObject, id: string): BoardProps.Item<CustomListObject> => {
        return {
            id: id,
            data: content,
            rowSpan: 1,
            columnSpan: 12
        };
    };

    return <>
        <hr/>
        <Grid
            gridDefinition={[
                { colspan: 10 }
            ]}
        >
            <SpaceBetween size="m" direction="vertical">
                <Board<CustomListObject>
                    renderItem={renderBoardItem}
                    onItemsChange={event =>
                        props.state.setValue(
                            CustomListContent.create(
                                event.detail.items.map(item => item.data)
                            )
                        )
                    }
                    items={!props.state.value ? [] : props.state.value.contentValue.map((val, idx) => getBoardItem(val, idx.toString()))}
                    i18nStrings={boardI18nStrings}
                    empty={
                        <Box textAlign="center" color="inherit">
                            <SpaceBetween size="xxs">
                                <div>
                                    <Box variant="strong" color="inherit">
                                        No items
                                    </Box>
                                    <Box variant="p" color="inherit">
                                        There are no items on the dashboard.
                                    </Box>
                                </div>
                                <Button
                                    iconName="add-plus"
                                    onClick={() => {
                                        props.state.setValue(CustomListContent.create([CustomListObject.create("", "")]));
                                    }}
                                >
                                    Add an item
                                </Button>
                            </SpaceBetween>
                        </Box>
                    }
                />
                {props.state.value?.contentValue.length > 0 && <Box textAlign="center" color="inherit">
                    <Button
                        iconName="add-plus"
                        onClick={() => {
                            props.state.setValue(
                                CustomListContent.create(
                                    [
                                        ...props.state.value.contentValue,
                                        CustomListObject.create("", "")
                                    ]
                                )
                            );
                        }}
                    >
                        Add item
                    </Button>
                </Box>}
            </SpaceBetween>
        </Grid>
    </>;
};
import { Identity, Team } from "@amzn/ask-legal-domain";
import { TokenGroupProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";

export namespace EntityPermissionPolarisFactory {
    export namespace TokenGroup {
        export function toTokens(teams: Team[]) {
            const tokens = teams.map((t) =>
                Builder<TokenGroupProps.Item>()
                    .label(t.teamName)
                    .description(t.teamId)
                    .build()
            );
            return tokens;
        }

        export function toUserTokens(users: Identity[]) {
            return users.map((u) =>
                Builder<TokenGroupProps.Item>()
                    .label(u.id)
                    .labelTag(u.name)
                    .description(u.email)
                    .build()
            );
        }
    }
}
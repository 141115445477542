import * as React from "react";
import {
  AdvancedList,
  FieldDefinition,
  FieldDefinitionDataType,
  ReservedFieldKey,
} from "@amzn/altar-sds-client";
import {
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Header,
  SpaceBetween,
  Table,
} from "@amzn/awsui-components-react";
import {
  ColumnAddEditModal,
  ColumnAddEditModalProps,
} from "./ColumnAddEditModal";
import {
  AdvancedListContent,
  FieldConfiguration,
  UpdateAdvancedListPayload,
} from "@amzn/ask-legal-domain";
import { UIModel } from "../../../../../../model/ui-model";
import { AdvancedListPolarisFactory } from "../../../../../../factory/polaris/advanced-list-polaris-factory";
import { Builder } from "builder-pattern";
import {
  DeprecateFieldDefinitonModal,
  DeprecateFieldDefinitonModalProps,
} from "../../../DeprecateFieldDefinitonModal";
import { AdvancedListModel } from "../../../../../../model/advanced-list-model";
import { AdvancedListFactory } from "../../../../../../factory/advanced-list-factory";

export const Columns = (props: {
  state: UIModel.State<AdvancedListContent>;
  advancedList: AdvancedList;
  onReorder: () => void;
}) => {
  const [fieldAddEditModalProps, setFieldAddEditModalProps] =
    React.useState<ColumnAddEditModalProps>();

  const [deprecateFieldModalProps, setDeprecateFieldModalProps] =
    React.useState<DeprecateFieldDefinitonModalProps>();

  const buttonDropdownItems: ButtonDropdownProps.ItemOrGroup[] = [
    {
      text: "Add column by type",
      items: [
        {
          id: FieldDefinitionDataType.choice,
          text: "Choice",
        },
        {
          id: FieldDefinitionDataType.date,
          text: "Date",
        },
        {
          id: FieldDefinitionDataType.hyperlink,
          text: "Hyperlink",
        },
        {
          id: FieldDefinitionDataType.number,
          text: "Number",
        },
        {
          id: FieldDefinitionDataType.IdentityRef,
          text: "Person",
        },
        {
          id: FieldDefinitionDataType.string,
          text: "Text",
        },
        {
          id: FieldDefinitionDataType.boolean,
          text: "Yes/No (Checkbox)",
        },
        {
          id: FieldDefinitionDataType.multiIdentityRef,
          text: "Multi Person",
        },
        {
          id: FieldDefinitionDataType.sequence,
          text: "Sequence"
        }
      ],
    }, {
      text: "Add system column",
      items: [
        {
          id: ReservedFieldKey.CreatedDate,
          text: AdvancedListFactory.RESERVED_FIELDS[ReservedFieldKey.CreatedDate].displayName,
        },
        {
          id: ReservedFieldKey.CreatedBy,
          text: AdvancedListFactory.RESERVED_FIELDS[ReservedFieldKey.CreatedBy].displayName,
        },
        {
          id: ReservedFieldKey.UpdatedDate,
          text: AdvancedListFactory.RESERVED_FIELDS[ReservedFieldKey.UpdatedDate].displayName,
        },
        {
          id: ReservedFieldKey.UpdatedBy,
          text: AdvancedListFactory.RESERVED_FIELDS[ReservedFieldKey.UpdatedBy].displayName,
        },
      ],
    }
  ];

  const onAddNewSystemColumnAction = (fieldKey: string) => {
    let fieldDefinition: FieldDefinition;
    switch (fieldKey) {
      case ReservedFieldKey.CreatedDate:
      case ReservedFieldKey.CreatedBy:
      case ReservedFieldKey.UpdatedDate:
      case ReservedFieldKey.UpdatedBy:
        fieldDefinition = AdvancedListFactory.RESERVED_FIELDS[fieldKey];
        break;
      default:
        console.warn("Invalid system field key");
    }
    if (!fieldDefinition) return;
    setFieldAddEditModalProps({
      forAdd: true,
      type: fieldDefinition.dataType as FieldDefinitionDataType,
      state: props.state,
      fieldDef: fieldDefinition,
    });
  };

  const onAddNewColumnAction = (dataType: FieldDefinitionDataType) => {
    setFieldAddEditModalProps({
      forAdd: true,
      type: dataType,
      state: props.state,
    });
  };

  const onUpdateColumnAction = (fieldDefinition: FieldDefinition) => {
    setFieldAddEditModalProps({
      state: props.state,
      fieldDef: fieldDefinition,
      fieldConfiguration:
        props.state.value.fieldConfigurations?.[fieldDefinition.fieldKey],
    });
  };

  const addFieldInAdvancedListContent = (params: {
    fieldDefintion: FieldDefinition;
    fieldVisibility: boolean;
    fieldConfiguration?: FieldConfiguration.Item;
  }) => {
    const advancedListContent = AdvancedListContent.addField({
      content: props.state.value,
      fieldDefinition: params.fieldDefintion,
      fieldConfiguration: params.fieldConfiguration,
      fieldVisibility: params.fieldVisibility,
    });
    props.state.setValue(advancedListContent);
  };

  const updateFieldInAdvancedListContent = (
    fieldDef: FieldDefinition,
    fieldVisibility?: boolean,
    fieldConfiguration?: FieldConfiguration.Item
  ) => {
    const updatedData = AdvancedListContent.updateField({
      content: props.state.value,
      fieldDefinition: fieldDef,
      fieldConfiguration: fieldConfiguration,
      fieldVisibility: fieldVisibility,
    });

    props.state.setValue(updatedData);
  };

  const deleteFieldDefinition = (fieldDef: FieldDefinition) => {
    const content: AdvancedListContent = props.state.value;

    const foundUpdatePayload =
      content.updateAdvancedListPayload.addFieldDefinitionsList?.find(
        (def) => def.fieldKey === fieldDef?.fieldKey
      );
    if (!!foundUpdatePayload) {
      const updatedAdvancedListContent =
        AdvancedListContent.deleteFieldFromUpdatePayload({
          content: content,
          fieldDefinition: fieldDef,
        });

      props.state.setValue(updatedAdvancedListContent);
    } else {
      // otherwise update field deprecated status
      updateFieldInAdvancedListContent(fieldDef);
    }
  };

  const onShowOnLivePageChange = (fieldKey: string, checked: boolean) => {
    const data = props.state.value;

    const updatedVisibleFields = new Set(data.visibleFields);

    if (checked) {
      updatedVisibleFields.add(fieldKey);
    } else {
      updatedVisibleFields.delete(fieldKey);
    }

    const updatedData = Builder<AdvancedListContent>(data)
      .visibleFields(Array.from(updatedVisibleFields))
      .build();

    props.state.setValue(updatedData);
  };

  const setFieldRequired = (fieldKey: string, isRequired: boolean) => {
    const fieldDef = [
      ...(props.state?.value?.updateAdvancedListPayload
        ?.addFieldDefinitionsList ?? []),
      ...(props.advancedList?.fieldDefinitions ?? []),
    ].find((def) => def.fieldKey! === fieldKey);

    const updatedData: UpdateAdvancedListPayload = {
      ...props.state?.value?.updateAdvancedListPayload,
      addFieldDefinitionsList: [
        ...(props.state?.value?.updateAdvancedListPayload?.addFieldDefinitionsList?.filter(
          (def) => def.fieldKey! !== fieldKey
        ) ?? []),
        {
          ...fieldDef!,
          required: isRequired,
        },
      ],
    };

    props.state.setValue(
      Builder<AdvancedListContent>(props.state.value)
        .updateAdvancedListPayload(updatedData)
        .build()
    );
  };

  const onActionItemClicked = (fieldDef: FieldDefinition, type: string) => {
    switch (type) {
      case "edit":
        onUpdateColumnAction(fieldDef);
        break;
      case "delete":
        setDeprecateFieldModalProps({
          fieldDef: fieldDef,
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {fieldAddEditModalProps && (
        <ColumnAddEditModal
          {...fieldAddEditModalProps}
          onCreated={(value) => {
            addFieldInAdvancedListContent({
              fieldDefintion: value.fieldDef,
              fieldVisibility: value.fieldVisibility,
              fieldConfiguration: value.fieldConfiguration,
            });
            setFieldAddEditModalProps(undefined);
          }}
          onUpdated={(value) => {
            updateFieldInAdvancedListContent(
              value.fieldDef,
              value.fieldVisibility,
              value.fieldConfiguration
            );
            setFieldAddEditModalProps(undefined);
          }}
          onCancel={() => {
            setFieldAddEditModalProps(undefined);
          }}
        />
      )}
      {deprecateFieldModalProps && (
        <DeprecateFieldDefinitonModal
          {...deprecateFieldModalProps}
          onDeprecated={(fieldDef: FieldDefinition) => {
            deleteFieldDefinition(fieldDef);
            setDeprecateFieldModalProps(undefined);
          }}
          onCancel={() => {
            setDeprecateFieldModalProps(undefined);
          }}
        />
      )}
      <SpaceBetween direction="vertical" size="xxxs">
        <Header variant="h2">Columns</Header>
        <Table
          header={
            <Header
              variant="h3"
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button onClick={() => props.onReorder()}>Reorder Columns</Button>
                  <ButtonDropdown
                    variant="primary"
                    items={buttonDropdownItems}
                    onItemClick={({ detail }) => {
                      if (AdvancedListFactory.isReservedField(detail.id)) {
                        onAddNewSystemColumnAction(detail.id as string);
                      } else {
                        onAddNewColumnAction(detail.id as FieldDefinitionDataType);
                      }
                    }}
                  >
                    Add Column
                  </ButtonDropdown>
                </SpaceBetween>
              }
            >
              Manage Columns
            </Header>
          }
          items={[
            ...AdvancedListPolarisFactory.Table.addOrUpdateFieldDefinitions(
              props.state.value?.updateAdvancedListPayload.reorderFieldDefinitionSequence ?
                // order fields based on pending draft field order change
                AdvancedListModel.sortAdvancedListFieldDefinitions(
                  props.advancedList?.fieldDefinitions,
                  props.state.value?.updateAdvancedListPayload?.reorderFieldDefinitionSequence
                ) || [] :
                // default field order
                props.advancedList?.fieldDefinitions || [],
              props.state?.value?.updateAdvancedListPayload
                ?.addFieldDefinitionsList ?? []
            ),
          ].filter((def) => !def.deprecated)}
          columnDefinitions={AdvancedListPolarisFactory.Table.buildManageColumnDefinitions(
            {
              visibileFields: props.state?.value?.visibleFields,
              onShowOnLivePageChange: onShowOnLivePageChange,
              onRequiredStatusChange: setFieldRequired,
              onActionItemClicked: onActionItemClicked,
            }
          )}
          stickyColumns={{ first: 0, last: 1 }}
        />
      </SpaceBetween>
    </>
  );
};

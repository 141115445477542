import * as React from "react";
import { Handle, Position, Node as _Node } from "reactflow";

export namespace CustomNode {
    export const Type = "decisionTreeNode";

    export interface Data {
        overviewText: string;
        isLeaf: boolean;
        isRoot: boolean;
    }

    export type Node = _Node<Data>;

    export const Comp = (props: { data: Data }) => {
        const defaultStyling: React.CSSProperties = {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "40px"
        };
        const focusedStyling: React.CSSProperties = {
            maxHeight: "100%",
            fontWeight: "bold"
        };
        const [textStyling, setTextStyling] = React.useState<React.CSSProperties>(defaultStyling);

        return (
            <>
                {!props.data.isRoot && <Handle type="target" position={Position.Top} draggable={false}/> }
                <div
                    onMouseEnter={() => setTextStyling(focusedStyling)}
                    onMouseLeave={() => setTextStyling(defaultStyling)}
                    style={{...textStyling, lineHeight: "20px"}}
                >
                    {props.data.overviewText}
                </div>
                <Handle type="source" position={Position.Bottom} draggable={false}/>
            </>
        );
    };

    export const List = { [Type]: CustomNode.Comp };
}

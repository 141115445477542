import { SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { NavigationModel } from "../../../model/navigation-model";
import { UIField } from "../../common/UIField";

export const AddNavItemContent = (props: {
    state: NavigationModel.AddNavItemState;
}) => {
    const getBreadCrumbPost = (label: string) => <em>{label}&nbsp;&nbsp;<b>&gt;</b>&nbsp;&nbsp;</em>;

    return (
        <React.Fragment>
            <SpaceBetween direction="vertical" size="m">
                <span>
                    {getBreadCrumbPost(props.state.parentTab.value.name)}
                    {props.state.parentGroup.value && getBreadCrumbPost(props.state.parentGroup.value.name)}
                    {props.state.parentLink.value && getBreadCrumbPost(props.state.parentLink.value.name)}
                </span>
                {!props.state.parentLink.value && <SpaceBetween direction="horizontal" size="xxs">
                    <Toggle
                        onChange={({ detail }) => props.state.isLink.setValue(detail.checked)}
                        checked={props.state.isLink.value}>Link
                    </Toggle>
                    &nbsp;<small><em>(Toggle off to set Text only)</em></small>
                </SpaceBetween>}
                <UIField.StateValueField
                    name="Name"
                    state={props.state.nameField}
                    editing={true}
                />
                {props.state.isLink.value &&
                    <UIField.StateValueField
                    name="URL"
                    state={props.state.urlField}
                    editing={true}
                />}
            </SpaceBetween>
        </React.Fragment>
    );
};
import { flatMap } from "lodash";

export namespace CoreUtils {
    type PrimitiveTypes = string | number;

    type ArrayKeys<K extends PrimitiveTypes, U> =
        | `${K}`
        | `${K}[]`
        | `${K}.${NestedKeyOf<U>}`
        | `${K}[].${NestedKeyOf<U>}`;

    type ObjectKeys<K extends PrimitiveTypes, V> =
        | `${K}`
        | `${K}.${NestedKeyOf<V>}`;

    export type NestedKeyOf<T> = {
        [K in keyof T & PrimitiveTypes]
            : T[K] extends (infer U)[]
                ? ArrayKeys<K, U>
                : T[K] extends object
                    ? ObjectKeys<K, T[K]>
                    : `${K}`;
    }[keyof T & PrimitiveTypes];

    // Helper function to get values from a nested path, including arrays
    export function getNestedValues(obj: any, path: string): string[] {
        const parts = path.split(".");
        let current: any = obj;
        for (const part of parts) {
            if (part.endsWith("[]")) {
                // Handle array
                const arrayProp = part.slice(0, -2);
                if (Array.isArray(current[arrayProp])) {
                    current = flatMap(current[arrayProp], item => getNestedValues(
                        item,
                        parts.slice(parts.indexOf(part) + 1).join("."))
                    );
                } else {
                    return [];
                }
                break;
            } else {
                current = current[part];
                if (current === undefined) return [];
            }
        }
        return Array.isArray(current) ? current.map(String) : [String(current)];
    }
}
import { EntityRef, IContainer, Input, Container, IDecisionTree, DecisionTreeContent } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface DecisionTreeNodeAPI {
    update(input: IDecisionTree.UpdateDraftInput): Promise<APIResponse<DecisionTreeContent>>;
    get(input: IDecisionTree.GetNodeInput): Promise<APIResponse<IDecisionTree.GetNodeOutput>>;
}

export class DecisionTreeNodeAPIImpl implements DecisionTreeNodeAPI {
    constructor (private context: AppContextValue) {}


    async update(input: IDecisionTree.UpdateDraftInput): Promise<APIResponse<DecisionTreeContent>> {
        const response: APIResponse<DecisionTreeContent> = await RestAPI.post(
            "custom-domain",
            "/decision-tree-node/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async get (id: IDecisionTree.GetNodeInput): Promise<APIResponse<IDecisionTree.GetNodeOutput>> {
        const response: APIResponse<IDecisionTree.GetNodeOutput> = await RestAPI.post(
            "custom-domain",
            "/decision-tree-node/get",
            toDefaultRequestPayload(id)
        );
        return response;
    }
}

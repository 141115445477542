import { InstanceFactory } from "@amzn/ask-legal-domain";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useInstanceState } from "../components/instance/instance-store";

const useRoutedParamsWithContext = <T extends Record<string, string>>() => {
  const params = useParams<T>();
  const { setInstanceId } = useInstanceState();

  const _instanceId = InstanceFactory.fromEntityId(params?.instanceId || params?.pageLibraryId || params?.pageId || params?.containerId);
  useEffect(() => {
    if (_instanceId) {
      setInstanceId(_instanceId);
    }
  }, [_instanceId]);

  return params;
};

export default useRoutedParamsWithContext;
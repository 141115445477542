import { IRecommendation, InstanceFactory, PageLibraryFactory } from "@amzn/ask-legal-domain";
import { Grid, Icon, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import "../../styles/util-styles.scss";
import "../../styles/home-page/instance-home-widgets.scss";
import "../../styles/home-page/responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { LegalOnlyBadge } from "../common/LegalOnlyTag";

export const RecommendationWidget = (props: { instanceId: string }) => {
    const context = React.useContext(AppContext);
    const [pageSize, setPageSize] = React.useState<number>(3);
    const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0);
    const [recommendationResult, setRecommendationResult] = React.useState<IRecommendation.RecommendOutput>();

    const recommendRunner = useAPI(context.getRecommendationAPI().recommend);

    const fetchRecommendations = () => {
        recommendRunner.submitRun(IRecommendation.RecommendInput.create(
            props.instanceId,
            {
                pageSize: pageSize,
                currentPageIndex: currentPageIndex
            }
        ));
    };

    React.useEffect(() => {
        // listen to window size to change pageSize accordingly to best fit the page
        // Not yet implemented
    });

    React.useEffect(() => {
        fetchRecommendations();
    }, [props.instanceId, pageSize, currentPageIndex]);

    React.useEffect(() => {
        if (recommendRunner.status === "Succeeded") {
            setRecommendationResult(recommendRunner.data.output);
        }
    }, [recommendRunner.status]);

    const renderRecommendationCards = () => {
        const cardProps: RecommendationCardProps[] = [];
        for (let index = 0; index < pageSize; index++) {
            if (
                recommendRunner.status === "Succeeded" &&
                !!recommendationResult &&
                index < recommendationResult.results.length
            ) {
                // loading has finished, recommendation exist
                cardProps.push({
                    id: recommendationResult.results[index].id,
                    description: recommendationResult.results[index].description,
                    title: recommendationResult.results[index].title,
                    url: recommendationResult.results[index].url,
                    loading: false,
                });
            } else if (recommendRunner.status === "Succeeded" && !!recommendationResult) {
                // loading has finished, but no more recommendations to render
                cardProps.push({
                    id: "",
                    description: "No Recommendations to render",
                    title: "No Recommendations to render",
                    url: "",
                    loading: true,
                    isEmpty: true
                });
            } else {
                // loading in progress
                cardProps.push({
                    id: "",
                    description: "Loading...",
                    title: "Loading...",
                    url: "",
                    loading: true,
                });
            }
        }
        return (
            <React.Fragment>
                <Grid className="homepage-responsive" gridDefinition={[
                    { colspan: { default: 12, s: 4 } },
                    { colspan: { default: 12, s: 4 } },
                    { colspan: { default: 12, s: 4 } },
                ]}>
                    {cardProps.map(cardProp => <RecommendationCard {...cardProp} />)}
                </Grid>
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <div className="recommendation-widget-main">
                <div className="recommendation-widget-title">
                    Recommended&nbsp;&nbsp;&nbsp;for&nbsp;&nbsp;&nbsp;You
                </div>
                {recommendRunner.status === "Running" &&
                    <div className="vertical-center recommendation-empty">
                        <Spinner variant="inverted" size="large" />
                    </div>
                }
                {recommendRunner.status === "Succeeded" && recommendRunner.data.output.count > 0 &&
                    <React.Fragment>
                        <div className="card-deck" style={{ zIndex: 300 }}>
                            <div
                                className={"vertical-center " +
                                    (currentPageIndex > 0 ?
                                        "other-widget-navigate-icon" :
                                        "other-widget-navigate-icon-disabled"
                                    )}
                                onClick={() => {
                                    if (currentPageIndex > 0) {
                                        setCurrentPageIndex(currentPageIndex - 1);
                                    }
                                }}>
                                <Icon
                                    name="angle-left-double"
                                    size="big"
                                />
                            </div>
                            {renderRecommendationCards()}
                            <div
                                className={
                                    "vertical-center " +
                                    (
                                        (!!recommendationResult && (currentPageIndex + 1) * pageSize < recommendationResult.count) ?
                                            "other-widget-navigate-icon" : "other-widget-navigate-icon-disabled"
                                    )}
                                onClick={() => {
                                    if (!!recommendationResult && (currentPageIndex + 1) * pageSize < recommendationResult.count) {
                                        setCurrentPageIndex(currentPageIndex + 1);
                                    }
                                }}>
                                <Icon
                                    name="angle-right-double"
                                    size="big"
                                />
                            </div>
                        </div>
                    </React.Fragment>}
            </div>
        </React.Fragment>
    );
};

interface RecommendationCardProps {
    id: string;
    title: string;
    description: string;
    url: string;
    loading: boolean;
    isEmpty?: boolean;
}

const RecommendationCard = (props: RecommendationCardProps) => {
    const context = React.useContext(AppContext);
    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );
    const pageLinkRegex = /\/page\/([0-9a-zA-z\-]+)\/live/g;

    const getPageLibraryId = (url: string) => {
        const regex = new RegExp(pageLinkRegex);
        const matches = url.matchAll(regex);

        for (const match of matches) {
            return PageLibraryFactory.fromEntityId(match[1]);
        }
        return "";
    };

    const recordViewHistory = () => {
        let entityId = InstanceFactory.fromEntityId(props.id);
        if (!!props.url.match(pageLinkRegex)) {
            entityId = props.url.match(pageLinkRegex)[0].split("/")[2];
        }
        recordViewHistoryRunner.submitRun({
            type: "Recommendation",
            entityId: entityId,
            url: props.url,
            recommendationId: props.id
        });
    };

    if (!!props.isEmpty) {
        return (<div className="card recommendation-card-empty">
            <div className="card-body recommendation-card-body vertical-center">
                Empty Content
            </div>
        </div>);
    }

    if (!!props.loading) {
        return <div className="card recommendation-card">
            <div className="card-body recommendation-card-body vertical-center">
                <Spinner />
            </div>
        </div>;
    }

    return (
        <div
            onClick={() => {
                if (!props.loading) {
                    window.open(props.url, "_blank");
                    recordViewHistory();
                }
            }}
            className="card recommendation-card">
            <div className="card-body recommendation-card-body">
                <h5 className="card-title text-center">
                    {props.title}
                    {pageLinkRegex.test(props.url) && <LegalOnlyBadge pageLibraryId={getPageLibraryId(props.url)} />}
                </h5>
                <p className="card-text">{props.description}</p>
            </div>
        </div>
    );
};
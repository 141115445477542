import { Questionnaire, QuestionnaireGroup } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { QuestionnaireModel } from "./questionnaire-model";
import { UIModel } from "./ui-model";

export namespace QuestionnaireGroupModel {
  export namespace UI {
    export type Actions =
      | "AddQuestionGroup"
      | "UpdateQuestionGroup"
      | "DeleteQuestionGroup"
      | "MoveQuestionGroupUp"
      | "MoveQuestionGroupDown";
    export enum MoveAction {
      MOVE_UP = "move_up",
      MOVE_DOWN = "move_down",
    }

    export namespace Update {
      export class UpdateQuestionnaireGroupState {
        questionnaireState: QuestionnaireGroup.QuestionGroup[];
        isDirty: boolean;
        addOrUpdateQuestionGroup: (questionGroup: QuestionnaireGroup.QuestionGroup) => void;
        deleteQuestionGroup: (questionGroup: QuestionnaireGroup.QuestionGroup) => void;
        moveQuestionGroup: (
          questionGroup: QuestionnaireGroup.QuestionGroup,
          action: string
        ) => void;
        reset: () => void;

        static use(props: { template?: QuestionnaireGroup.Data }) {
          const template = UIModel.State.use<QuestionnaireGroup.Data>({
            initialValue: props.template,
          });
          const [isDirty, setDirty] = useState<boolean>(false);
          const questionnaireState = UIModel.State.use<QuestionnaireGroup.Data>({
            initialValue: props.template,
          });

          useEffect(() => {
            init(props.template);
          }, []);

          const reset = () => {
            questionnaireState.setValue(template.value);
            setDirty(false);
          };

          const init = (template?: QuestionnaireGroup.Data) => {
            if (!template) return;
            questionnaireState.setValue(template);
            setDirty(false);
          };

          const addOrUpdateQuestionGroup = (question: QuestionnaireGroup.QuestionGroup) => {
            const newQuestionnaire = QuestionnaireGroup.Data.addOrUpdateQuestionGroup(
              questionnaireState.value,
              question
            );
            questionnaireState.setValue(newQuestionnaire);
            setDirty(true);
          };

          const deleteQuestionGroup = (questionGroup: QuestionnaireGroup.QuestionGroup) => {
            const newQuestionnaire = QuestionnaireGroup.Data.removeQuestionGroup(
              questionnaireState.value,
              questionGroup.id
            );
            questionnaireState.setValue(newQuestionnaire);
            setDirty(true);
          };

          const moveQuestionGroup = (
            questionGroup: QuestionnaireGroup.QuestionGroup,
            action: string
          ) => {
            const existingQuestionGroups = [...questionnaireState.value.questionGroups];

            // find existing
            const findIndex = existingQuestionGroups.findIndex(
              (q) => q.id === questionGroup.id
            );

            if (existingQuestionGroups.length > 1 && findIndex !== -1) {
              if (action === QuestionnaireModel.UI.MoveAction.MOVE_UP) {
                if (findIndex > 0) {
                  let temp = existingQuestionGroups[findIndex];
                  existingQuestionGroups[findIndex] =
                  existingQuestionGroups[findIndex - 1];
                  existingQuestionGroups[findIndex - 1] = temp;
                }
              } else if (action === QuestionnaireModel.UI.MoveAction.MOVE_DOWN) {
                if (findIndex < existingQuestionGroups.length - 1) {
                  let temp = existingQuestionGroups[findIndex];
                  existingQuestionGroups[findIndex] =
                  existingQuestionGroups[findIndex + 1];
                  existingQuestionGroups[findIndex + 1] = temp;
                }
              }
            }

            const newQuestions = QuestionnaireGroup.Data.create({
              questionGroups: existingQuestionGroups,
            });
            questionnaireState.setValue(newQuestions);
            setDirty(true);
          };

          return Builder<UpdateQuestionnaireGroupState>(
            new UpdateQuestionnaireGroupState()
          )
            .reset(reset)
            .isDirty(isDirty)
            .addOrUpdateQuestionGroup(addOrUpdateQuestionGroup)
            .deleteQuestionGroup(deleteQuestionGroup)
            .moveQuestionGroup(moveQuestionGroup)
            .questionnaireState(questionnaireState.value.questionGroups)
            .build();
        }
      }

      export class EditState<T> {
        active: boolean;
        result: T;
        activate: () => void;
        deactivate: () => void;
        save: () => void;
        reset: () => void;

        constructor() {
          const [active, setActive] = useState<boolean>(false);
          const activate = () => {
            setActive(true);
          };
          const deactivate = () => {
            setActive(false);
          };

          return Builder<EditState<any>>()
            .activate(activate)
            .deactivate(deactivate)
            .active(active)
            .build();
        }
      }

      export class QuestionGroupInputItem {
        id: string;
        groupName: string;
        questions: Questionnaire.Question[];

        static defaultValue() {
          return Builder<QuestionGroupInputItem>()
            .id("")
            .groupName("")
            .questions([])
            .build();
        }

        static fromQuestion(questionGroup: QuestionnaireGroup.QuestionGroup) {
          if (!questionGroup) {
            return QuestionGroupInputItem.defaultValue();
          }
          return Builder<QuestionGroupInputItem>()
            .id(questionGroup.id)
            .groupName(questionGroup.groupName)
            .questions(questionGroup.questions)
            .build();
        }
      }

      export class AddQuestionGroupState extends EditState<QuestionnaireGroup.QuestionGroup> {
        readonly actionName: string = "Add Question Group";
        groupField: UIModel.State<string>;
        questionsField: UIModel.State<Questionnaire.Question[]>;

        static use() {
          const [result, setResult] = useState<QuestionnaireGroup.QuestionGroup>();
          const groupField = UIModel.State.useRequired<string>({
            initialValue: "",
          });
          const questionsField = UIModel.State.use<Questionnaire.Question[]>({
            initialValue: [],
          });

          const reset = (): void => {
            setResult(null);
            groupField.setValue("");
            questionsField.setValue([]);
          };

          const save = () => {
            if (!groupField.value) return;
            const newResult = QuestionnaireGroup.QuestionGroup.create({
              groupName: groupField.value,
              questions: questionsField.value,
            });
            setResult(newResult);
          };

          return Builder<AddQuestionGroupState>(new AddQuestionGroupState())
            .result(result)
            .groupField(groupField)
            .questionsField(questionsField)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class EditQuestionGroupState extends EditState<QuestionnaireGroup.QuestionGroup> {
        readonly actionName: string = "Edit Question Group";
        editQuestionInputState: UIModel.State<QuestionGroupInputItem>;
        activeQuestionGroupState: UIModel.State<QuestionnaireGroup.QuestionGroup>;

        static use() {
          const [result, setResult] = useState<QuestionnaireGroup.QuestionGroup>();
          const editQuestionInputState = UIModel.State.use<QuestionGroupInputItem>({
            initialValue: QuestionGroupInputItem.defaultValue(),
          });
          const activeQuestionGroupState = UIModel.State.use<QuestionnaireGroup.QuestionGroup>(
            {
              initialValue: null,
            }
          );

          useEffect(() => {
            editQuestionInputState.setValue(
              QuestionGroupInputItem.fromQuestion(activeQuestionGroupState.value)
            );
          }, [activeQuestionGroupState.value]);

          const reset = (): void => {
            setResult(null);
            editQuestionInputState.setValue(QuestionGroupInputItem.defaultValue());
            activeQuestionGroupState.setValue(null);
          };

          const save = () => {
            if (!editQuestionInputState.value) return;
            const newResult = QuestionnaireGroup.QuestionGroup.update({
              existingQuestion: activeQuestionGroupState.value,
              groupName: editQuestionInputState.value.groupName,
              questions: editQuestionInputState.value.questions
            });
            setResult(newResult);
          };

          return Builder<EditQuestionGroupState>(new EditQuestionGroupState())
            .result(result)
            .activeQuestionGroupState(activeQuestionGroupState)
            .editQuestionInputState(editQuestionInputState)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class DeleteQuestionGroupState extends EditState<QuestionnaireGroup.QuestionGroup> {
        readonly actionName: string = "Delete Question Group";
        activeQuestionGroupState: UIModel.State<QuestionnaireGroup.QuestionGroup>;

        static use() {
          const [result, setResult] = useState<QuestionnaireGroup.QuestionGroup>();
          const activeQuestionGroupState = UIModel.State.use<QuestionnaireGroup.QuestionGroup>(
            {
              initialValue: null,
            }
          );

          const reset = (): void => {
            setResult(null);
          };

          const save = () => {
            if (!activeQuestionGroupState.value) return;
            setResult(activeQuestionGroupState.value);
          };

          return Builder<DeleteQuestionGroupState>(new DeleteQuestionGroupState())
            .result(result)
            .activeQuestionGroupState(activeQuestionGroupState)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class MoveQuestionState extends EditState<QuestionnaireGroup.QuestionGroup> {
        readonly actionName: string = "Move Question";
        activeQuestionGroupState: UIModel.State<QuestionnaireGroup.QuestionGroup>;
        activeQuestionActionState: UIModel.State<string>;

        static use() {
          const activeQuestionGroupState = UIModel.State.use<QuestionnaireGroup.QuestionGroup>(
            {
              initialValue: null,
            }
          );
          const activeQuestionActionState = UIModel.State.use<string>({
            initialValue: null,
          });

          return Builder<MoveQuestionState>(new MoveQuestionState())
            .activeQuestionGroupState(activeQuestionGroupState)
            .activeQuestionActionState(activeQuestionActionState)
            .build();
        }
      }
    }
  }
}
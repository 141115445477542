import * as React from "react";
import { Input, SpaceBetween } from "@amzn/awsui-components-react";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { SimpleModal } from "../../common/SimpleModal";

export interface DeprecateFieldDefinitonModalProps {
    fieldDef: FieldDefinition;
    onDeprecated?: (fieldDef: FieldDefinition) => void;
    onCancel?: () => void;
}

export const DeprecateFieldDefinitonModal = (
    props: DeprecateFieldDefinitonModalProps
) => {
    const [value, setValue] = React.useState<string>("");

    const onConfirm = () => {
        props.onDeprecated?.({
            ...props.fieldDef,
            deprecated: true,
            required: false
        });
    };
    return (
        <SimpleModal
            child={<SpaceBetween size="m">
                <div>Are you sure you want to delete this column?</div>
                <div><strong>Note: Deleting will result in this column being removed from the Live version of this page after publishing it</strong></div>
                <div>Please type <strong><em>delete</em></strong> to confirm deletion:</div>
                <Input
                    placeholder={"delete"}
                    value={value}
                    onChange={({ detail }) => {
                        setValue(detail.value);
                    }}
                />
            </SpaceBetween>}
            header={`Delete Column - ${props.fieldDef.displayName}`}
            onCancel={props.onCancel}
            onSave={onConfirm}
            size="large"
            disableLeavingAlert={true}
            loading={false}
            disabled={value !== "delete"}
        />
    );
};
import * as React from "react";
import { useHistory } from "react-router-dom";
import { PreferenceSelectionModal } from "../../components/instance/PreferenceSelectionModal";
import { AppContext } from "../../setup/context";

export const ApplicationDefaultHome = () => {
    const context = React.useContext(AppContext);
    const history = useHistory();
    const [ displayInstanceSelectionModal, setDisplayInstanceSelectionModal ] = React.useState<boolean>(false);

    React.useEffect(() => {
        const redirect = async () => {
            const identity = await context.getIdentity();
            const preference = context.getPreference();
            if (!!preference && preference.userId === identity.id && !!preference.instanceId) {
                history.push(`/instance/${preference.instanceId}/home`);
            } else {
                setDisplayInstanceSelectionModal(true);
            }
        };
        redirect();
    }, []);

    return (
        <div>
            {displayInstanceSelectionModal && <PreferenceSelectionModal/>}
        </div>
    );
};
import * as React from "react";
import { Columns } from "./columns/Columns";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { UIModel } from "../../../../../model/ui-model";
import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { AdvancedList } from "@amzn/altar-sds-client";
import { ColumnReorderModal } from "./columns/ColumnReorderModal";

export const Configuration = (props: {
  state: UIModel.State<AdvancedListContent>;
  advancedList: AdvancedList;
}) => {
  const [isReordering, setIsReordering] = React.useState(false);

  return (
    <>
      {isReordering && (
        <ColumnReorderModal
          state={props.state}
          advancedList={props.advancedList}
          onSubmit={(keys) => {
            props.state.setValue({
              ...props.state.value,
              updateAdvancedListPayload: {
                ...props.state.value.updateAdvancedListPayload,
                reorderFieldDefinitionSequence: keys
              }
            });
            setIsReordering(false);
          }}
          onCancel={() => setIsReordering(false)}
        />
      )}
      <SpaceBetween direction="vertical" size="s">
        <Columns
          state={props.state}
          advancedList={props.advancedList}
          onReorder={() => setIsReordering(true)}
        />
      </SpaceBetween>
    </>
  );
};

import { EntityFactory, EntityType, ErrorCode, INavigation, Navigation } from "@amzn/ask-legal-domain";
import { Button, StatusIndicator } from "@amzn/awsui-components-react";
import { useContext, useEffect } from "react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { NavigationDetail } from "./NavigationDetail";

export const NavigationPreload = (props: {
    instanceId: string;
}) => {
    const context = useContext(AppContext);
    const loadNavigationRunner = useAPI(
        context.getNavigationAPI().load
    );
    const createNavigationRunner = useAPI(
        context.getNavigationAPI().create
    );

    const dataState = UIModel.State.use<Navigation.Data>({});

    useEffect(() => {
        loadNavigationRunner.submitRun(
            INavigation.LoadNavigationInput.create({
                navigationId: props.instanceId
            })
        );
    }, [props.instanceId]);

    const createNavigation = () => {
        createNavigationRunner.submitRun(
            INavigation.CreateNavigationInput.create({
                instanceRef: EntityFactory.fromEntityAttributes(props.instanceId, EntityType.Instance),
                tabs: []
            })
        );
    };

    useEffect(() => {
        if (loadNavigationRunner.status === "Succeeded") {
            dataState.setValue(loadNavigationRunner.data.output);
        }
    }, [loadNavigationRunner.status]);

    useEffect(() => {
        if (createNavigationRunner.status === "Succeeded") {
            loadNavigationRunner.reload();
        }
    }, [createNavigationRunner.status]);

    return (
        <React.Fragment>
            {loadNavigationRunner.status === "Error" && (
                <ErrorFlashbar
                    error={loadNavigationRunner.data.err}
                    action={loadNavigationRunner.data.err.code === (404 as ErrorCode) ?
                        <Button
                            variant="primary"
                            loading={createNavigationRunner.status === "Running"}
                            onClick={createNavigation}>Create Navigation
                        </Button> : null
                    }
                />)
            }
            {loadNavigationRunner.status === "Running" && (
                <StatusIndicator type="loading">
                    Loading
                </StatusIndicator>
            )}
            {loadNavigationRunner.status === "Succeeded" && dataState.value && (
                <React.Fragment>
                    <NavigationDetail state={dataState}/>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};
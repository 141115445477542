import { AccordionContent, Container } from "@amzn/ask-legal-domain";
import * as React from "react";
import { BasicAccordionView } from "../basic-accordian/component";
import { ContainerDescription } from "../ContainerDescription";

export const AccordionContentView = (props: {
    content: AccordionContent;
    headerData?: Container.Margin;
    footerData?: Container.Margin;
    diffMode?: boolean;
    expandCollapseAllTrigger?: { value: string; };
}) => {
    return <React.Fragment>
        <ContainerDescription.View
            variant="Header"
            data={props.headerData}
            diffMode={props.diffMode}
        />
        <BasicAccordionView
            existingQuestions={props.content.contentValue}
            diffMode={props.diffMode}
            expandCollapseAllTrigger={props.expandCollapseAllTrigger}
        />
        <ContainerDescription.View
            variant="Footer"
            data={props.footerData}
            diffMode={props.diffMode}
        />
    </React.Fragment>;
};
import { IdentityRef as SDSIdentityRef } from "@amzn/altar-sds-client";

export namespace SDSUtils {
    export function getAmazonPersonRef(alias: string): SDSIdentityRef {
        return {
            id: alias,
            realm: "Amazon",
            type: "Person",
        };
    }
}
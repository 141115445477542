import { useAPI } from "../../hooks/api-hook";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useHistory } from "react-router-dom";
import {
    Box,
    Button,
    Cards,
    CardsProps,
    Modal,
    Pagination,
    SpaceBetween,
    TextFilter,
} from "@amzn/awsui-components-react";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { Instance } from "@amzn/ask-legal-domain";
import { useCollection } from "@amzn/awsui-collection-hooks";

export const PreferenceSelectionModal = (props: {
    onDismiss?: () => void;
}) => {
    const context = React.useContext(AppContext);
    const history = useHistory();
    const instanceLoadAllApiRunner = useAPI(
        context.getInstanceAPI().loadAll
    );

    const [instances, setInstances] = React.useState<Instance[]>([]);
    const { items, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection<Instance>(
        instances,
        {
            filtering: {
                empty: "No instances found",
                noMatch: "Empty"
            },
            pagination: { pageSize: 10 },
            sorting: {
                defaultState: {
                    sortingColumn: {
                        sortingField: "name"
                    }
                }
            },
            selection: {
                trackBy: (x: Instance) => x.id
            }
        }
    );

    const onClick = () => {
        if (collectionProps.selectedItems?.length) {
            context.setPreference(collectionProps.selectedItems[0].id);
            history.push(`/instance/${collectionProps.selectedItems[0].id}/home`);
            if (!!props.onDismiss) {
                props.onDismiss();
            }
        }
    };

    const cardDefinition: CardsProps.CardDefinition<Instance> = {
        header: item => <span id={item.id}>{item.name}</span>
    };

    const helpInfoText = <span>
        Select the PathFinder site you would like to visit. PathFinder will remember your selection on this device, you can always change your default PathFinder site by clicking on the name of the instance on the top right of the homepage.
    </span>;

    React.useEffect(() => {
        instanceLoadAllApiRunner.submitRun({});
    }, []);

    React.useEffect(() => {
        if (instanceLoadAllApiRunner.status === "Succeeded") {
            setInstances(instanceLoadAllApiRunner.data.output);
        }
    }, [instanceLoadAllApiRunner.status]);

    return (
        <Modal header="Choose a PathFinder Instance" onDismiss={() => {
            if (!!props.onDismiss) {
                props.onDismiss();
            }
        }} visible={true} footer={
            <Box float="right">
                <Button disabled={!collectionProps.selectedItems?.length} onClick={onClick} variant="primary">Go</Button>
            </Box>
          }>
            <SpaceBetween direction="vertical" size="l">
                {instanceLoadAllApiRunner.status === "Error" && <ErrorFlashbar error={instanceLoadAllApiRunner.data.err} />}
                {helpInfoText}
                <Cards
                    {...collectionProps}
                    key="instance-selection"
                    cardDefinition={cardDefinition}
                    loading={instanceLoadAllApiRunner.status === "Running"}
                    loadingText="Loading"
                    items={items}
                    selectionType="single"
                    variant="container"
                    entireCardClickable={true}
                    cardsPerRow={[
                        { cards: 1 },
                        { cards: 2, minWidth: 500 },
                        { cards: 3, minWidth: 800 }
                    ]}
                    ariaLabels={{
                        itemSelectionLabel: (selectionState, item) => item.name,
                        selectionGroupLabel: "Instance"
                    }}
                    renderAriaLive={(data: CardsProps.LiveAnnouncement) => `${data.firstIndex} out of ${data.totalItemsCount}`}
                    filter={
                        <TextFilter
                            {...filterProps}
                            filteringPlaceholder="Filter instances"
                            filteringAriaLabel="Filter instances"
                            filteringClearAriaLabel="Clear"
                            countText={filteredItemsCount}
                            disabled={instanceLoadAllApiRunner.status === "Running"}
                        />
                    }
                    pagination={
                        <Pagination
                            {...paginationProps}
                            disabled={instanceLoadAllApiRunner.status === "Running"}
                        />
                    }
                />
            </SpaceBetween>
        </Modal>
    );
};
import { EntityRef, IRecommendationRule, RecommendationRule, EntityFactory, EntityType, Geo, Tenure } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { UIModel } from "./ui-model";

export namespace RecommendationRuleModel {
    export const NAME_CHAR_LIMIT = 50;
    export class EditState {
        ruleType: UIModel.State<RecommendationRule.Type>;
        name: UIModel.State<string>;
        geoCodes: UIModel.State<Geo[]>;
        tenure: UIModel.State<Tenure>;
        tenureOperator: UIModel.State<RecommendationRule.Operator>;
        roles: UIModel.State<EntityRef[]>;
        errorText: string;
        _cache: RecommendationRule.Data;
        init: (entity?: RecommendationRule.Data) => void;
        reset: () => void;

        public static toCreateInput(params: { state: EditState, instanceId: string }) {
            const instanceRef = EntityFactory.fromEntityAttributes(params.instanceId, EntityType.Instance);
            return IRecommendationRule.CreateRecommendationRuleInput.create({
                ruleType: params.state.ruleType.value,
                geoCodes: params.state.geoCodes.value,
                tenure: {
                    value: params.state.tenure.value,
                    operator: params.state.tenureOperator.value
                },
                roles: params.state.roles.value.map(v => v.id),
                name: params.state.name.value,
                instanceRef: instanceRef,
            });
        }

        public static toUpdateInput(params: { state: EditState, ruleRef: EntityRef }) {
            return IRecommendationRule.UpdateRecommendationRuleInput.create({
                ruleRef: params.ruleRef,
                newRuleType: params.state.ruleType.value,
                newGeoCodes: params.state.geoCodes.value,
                newTenure: {
                    value: params.state.tenure.value,
                    operator: params.state.tenureOperator.value
                },
                newRoles: params.state.roles.value.map(v => v.id),
                newName: params.state.name.value
            });
        }

        public static use() {
            const ruleType = UIModel.State.use<RecommendationRule.Type>({
                initialValue: RecommendationRule.Type.ALL
            });
            const name = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: NAME_CHAR_LIMIT
            });
            const geoCodes = UIModel.State.useArray<Geo>({
                initialValue: []
            });
            const tenure = UIModel.State.use<Tenure>({
                initialValue: Tenure.create(0, 0)
            });
            const tenureOperator = UIModel.State.use<RecommendationRule.Operator>({
                initialValue: RecommendationRule.Operator.Equal
            });
            const roles = UIModel.State.useArray<EntityRef>({
                initialValue: []
            });
            const [_errorText, _setErrorText] = useState<string>("");

            const [_cache, setCache] = useState<RecommendationRule.Data>();

            const init = (entity?: RecommendationRule.Data) => {
                if (entity) {
                    roles.setValue(entity.roles.map(v => EntityFactory.fromEntityAttributes(v, EntityType.Role)));
                    tenure.setValue(entity.tenure.value);
                    geoCodes.setValue(entity.geoCodes.map(g => g.value));
                    name.setValue(entity.name);
                    tenureOperator.setValue(entity.tenure.operator);
                } else {
                    roles.setValue([]);
                    tenure.setValue(Tenure.create(0, 0));
                    geoCodes.setValue([]);
                    name.setValue("");
                    tenureOperator.setValue(RecommendationRule.Operator.Equal);
                }
                setCache(entity);
            };

            const reset = () => {
                init(_cache);
            };

            useEffect(() => {
                const errorTexts = [];
                if (name.value.length === 0) {
                    errorTexts.push("Rule name cannot be empty");
                }
                _setErrorText(errorTexts.join("\n"));
            }, [roles.value, name.value, geoCodes.value, tenure.value]);

            return Builder<EditState>()
                .name(name)
                .geoCodes(geoCodes)
                .roles(roles)
                .ruleType(ruleType)
                .tenure(tenure)
                .tenureOperator(tenureOperator)
                .errorText(_errorText)
                .init(init)
                .reset(reset)
                .build();
        }
    }
}
import { Input, IRole, Role, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface RoleAPI {
    create(input: IRole.CreateRoleInput): Promise<APIResponse<Role.Data>>;
    update(input: IRole.UpdateRoleInput): Promise<APIResponse<Role.Data>>;
    load(input: string): Promise<APIResponse<Role.Data>>;
    loadByInstance(
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Role.Data>>>;
    delete(input: string): Promise<APIResponse<Role.Data>>;
}

export class RoleAPIImpl implements RoleAPI {
    constructor (private context: AppContextValue) {}

    async create(input: IRole.CreateRoleInput): Promise<APIResponse<Role.Data>> {
        const response: APIResponse<Role.Data> = await RestAPI.post(
            "custom-domain",
            "/role/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async update(input: IRole.UpdateRoleInput): Promise<APIResponse<Role.Data>> {
        const response: APIResponse<Role.Data> = await RestAPI.post(
            "custom-domain",
            "/role/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async load(input: string): Promise<APIResponse<Role.Data>> {
        const response: APIResponse<Role.Data> = await RestAPI.post(
            "custom-domain",
            "/role/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }
    async loadByInstance (
        input: ServerSidePaginatedLoadingInput
    ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Role.Data>>> {
        const response: APIResponse<ServerSidePaginatedLoadingOutput<Role.Data>> = await RestAPI.post(
            "custom-domain",
            "/role/load-by-instance",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async delete(input: string): Promise<APIResponse<Role.Data>> {
        const response: APIResponse<Role.Data> = await RestAPI.post(
            "custom-domain",
            "/role/delete",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}

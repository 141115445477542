import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { TeamInterface, Team } from "@amzn/ask-legal-domain";
import { Select, Multiselect, SelectProps, MultiselectProps, AutosuggestProps} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";

export namespace TeamSearch {

    const API_RESULTS_LIMIT = 5;

    export const toSelectOption = (t: Team) => {
        if (!t) return;
        return Builder<SelectProps.Option>()
            .value(t.teamId)
            .label(t.teamName)
            .build();
    };

    export const fromSelectOption = (option: SelectProps.Option) => {
        if (!option) return;
        return Builder<Team>()
            .teamId(option.value)
            .teamName(option.label)
            .build();
    };

    export const toMultiSelectOptions = (t: Team[]) => {
        if (!t || t.length === 0) return [];
        return t.map(e => toSelectOption(e));
    };

    export const fromMultiSelectOptions = (t: MultiselectProps.Option[]) => {
        if (!t || t.length === 0) return [];
        return t.map(e => fromSelectOption(e));
    };

    export const Single = (props: {
        selected?: Team;
        disabled?: boolean;
        onTeamSelectChange: (selected: Team) => void;
    }) => {
        let fillOptions: Array<Team> = [];
        let populateOptions: SelectProps.Option[] = [];

        const context = React.useContext(AppContext);
        const teamApiRunner = useAPI(
            context.getTeamAPI().searchTeam
        );

        const [selectedOption, setSelectedOption] = React.useState<SelectProps.Option>(toSelectOption(props.selected));
        const [selectItems, setSelectItems] = React.useState<SelectProps.Option[]>([]);
        const [selectStatusType, setSelectStatusType] = React.useState<AutosuggestProps.StatusType>("finished");

        const onChangeHandler = async (e: any) => {
            setSelectedOption(e.detail.selectedOption);
        };

        let runnerTimeOut: any;
        const onLoadHandler = async (e: any) => {
            if (e.detail.filteringText.length < 3) return;
            clearTimeout(runnerTimeOut);
            runnerTimeOut = setTimeout(() => {
                teamApiRunner.submitRun(
                    TeamInterface.SearchTeamInput.create(
                        e.detail.filteringText,
                        API_RESULTS_LIMIT
                    )
                );
            }, 500);
        };

        React.useEffect(() => {
            if (teamApiRunner.status === "Succeeded") {
                fillOptions = teamApiRunner.data.output;
                if (!fillOptions || fillOptions.length === 0) {
                    populateOptions = [];
                } else {
                    populateOptions = fillOptions.map((item) => toSelectOption(item));
                }
                setSelectItems(populateOptions);
                setSelectStatusType("finished");
            } else if (teamApiRunner.status === "Error") {
                setSelectStatusType("error");
            } else if (teamApiRunner.status === "Running") {
                setSelectStatusType("loading");
            }
        }, [teamApiRunner.status]);

        React.useEffect(() => {
            if (!props.onTeamSelectChange) return;
            props.onTeamSelectChange(fromSelectOption(selectedOption));
        }, [selectedOption]);

        React.useEffect(() => {
            if (!props.selected) {
                setSelectedOption(null);
                setSelectItems([]);
                setSelectStatusType("finished");
            }
        }, [props.selected]);

        const selectProperties = Builder<SelectProps>()
            .statusType(selectStatusType)
            .options(selectItems)
            .filteringType("manual")
            .ariaRequired(true)
            .onChange(onChangeHandler)
            .onLoadItems(onLoadHandler)
            .placeholder("Choose a team")
            .selectedAriaLabel("Selected")
            .empty("No match found")
            .selectedOption(selectedOption)
            .errorText("Error fetching teams")
            .recoveryText("Retry")
            .disabled(props.disabled)
            .build();

        return <Select {...selectProperties}/>;
    };

    export const Multiple = (props: {
        initialSelected?: Team[];
        disabled?: boolean;
        onTeamSelectChange: (selected: Team[]) => void;
    }) => {
        const context = React.useContext(AppContext);
        const teamApiRunner = useAPI(
            context.getTeamAPI().searchTeam
        );

        const [selectedOptions, setSelectedOptions] = React.useState<MultiselectProps.Option[]>(toMultiSelectOptions(props.initialSelected));
        const [allItems, setAllItems] = React.useState<MultiselectProps.Option[]>([]);
        const [selectStatusType, setSelectStatusType] = React.useState<AutosuggestProps.StatusType>("finished");

        const onChangeHandler = async (e: any) => {
            setSelectedOptions(e.detail.selectedOptions);
        };

        let runnerTimeOut: any;
        const onLoadHandler = async (e: any) => {
            if (e.detail.filteringText.length < 3) return;
            clearTimeout(runnerTimeOut);
            runnerTimeOut = setTimeout(() => {
                teamApiRunner.submitRun(
                    TeamInterface.SearchTeamInput.create(
                        e.detail.filteringText,
                        API_RESULTS_LIMIT
                    )
                );
            }, 500);
        };

        React.useEffect(() => {
            if (teamApiRunner.status === "Succeeded") {
                let populateOptions = [];
                const fillOptions = teamApiRunner.data.output;
                if (!fillOptions || fillOptions.length === 0) {
                    populateOptions = [];
                } else {
                    populateOptions = toMultiSelectOptions(fillOptions);
                }
                setAllItems(populateOptions);
                setSelectStatusType("finished");
            } else if (teamApiRunner.status === "Error") {
                setSelectStatusType("error");
            } else if (teamApiRunner.status === "Running") {
                setSelectStatusType("loading");
            }
        }, [teamApiRunner.status]);

        React.useEffect(() => {
            if (!props.onTeamSelectChange || !selectedOptions) return;
            props.onTeamSelectChange(fromMultiSelectOptions(selectedOptions));
        }, [selectedOptions]);

        const multiSelectProperties = Builder<MultiselectProps>()
            .statusType(selectStatusType)
            .options(allItems)
            .filteringType("manual")
            .ariaRequired(true)
            .onChange(onChangeHandler)
            .onLoadItems(onLoadHandler)
            .placeholder("Choose team(s)")
            .selectedAriaLabel("Selected")
            .empty("No match found")
            .selectedOptions(selectedOptions)
            .deselectAriaLabel((e: any) => "Remove " + e.label)
            .errorText("Error fetching teams")
            .recoveryText("Retry")
            .disabled(props.disabled)
            .build();

        return <Multiselect {...multiSelectProperties}/>;
    };
}

import { InstanceFactory, PageLibraryFactory } from "@amzn/ask-legal-domain";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { PageLive } from "./PageLive";
import { PagePortal } from "./PagePortal";
import useRoutedParamsWithContext from "../../setup/useRoutedParamsWithContext";

export const pageRoutes = [
    {
        path: "/page/:pageId/admin",
        content: (props: RouteComponentProps) => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            const params = new URLSearchParams(useLocation().search);
            if (params.has("tab")) {
                const tab = params.get("tab");
                return <PagePortal id={pageId} activeTabId={tab} />;
            }
            return <PagePortal id={pageId} />;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Admin Portal", href: `#/instance/${InstanceFactory.fromEntityId(pageId)}/admin` },
                { text: "Page Library Admin Portal", href: `#/page-library/${PageLibraryFactory.fromEntityId(pageId)}/admin` },
                { text: "Page Admin Portal", href: `#/page/${pageId}/admin` }
            ]} />;
        },
        header: () => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            const instanceId = InstanceFactory.fromEntityId(pageId);
            return <SherpaHeader headerType="Other" instanceId={instanceId} />;
        },
        footer: () => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={pageId} />;
        }
    },
    {
        path: "/page/:pageId/live",
        content: (props: RouteComponentProps) => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            const params = new URLSearchParams(useLocation().search);
            if (params.has("version")) {
                const version = JSON.parse(params.get("version"));
                return <PageLive id={pageId} version={version} />;
            }
            return <PageLive id={pageId} />;
        },
        header: () => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            const instanceId = InstanceFactory.fromEntityId(pageId);
            return <SherpaHeader headerType="LivePage" instanceId={instanceId} />;
        },
        footer: () => {
            let { pageId } = useRoutedParamsWithContext<{ pageId: string }>();
            return <SherpaFooter variant="LivePage" entityId={pageId} />;
        }
    }
];
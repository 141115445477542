import { DEFAULT_CONFIG } from "./default";

export const RESTRICTED_HYPER_LINK_ONLY_CONFIG = {
    ...DEFAULT_CONFIG,
    branding: false,
    height: 500,
    paste_retain_style_properties: "all",
    paste_data_images: false,
    force_br_newlines: false,
    resize: true,
    menubar: false,
    content_style: "body { font-family: 'Amazon Ember'; font-size: 18px }",
    toolbar:
        "undo redo | fontsizeselect | link",
    table_column_resizing: "resizetable",
    entity_encoding: "raw",
    valid_elements: "div,p,strong,a[*]",
};
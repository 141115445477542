import {
    EntityFactory,
    EntityRef,
    EntityType,
    ILabel,
    Label,
    Team
} from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import { Builder } from "builder-pattern";

export namespace LabelModel {
    export const NAME_CHAR_LIMIT = 50;

    export class LabelState {
        name: UIModel.State<string>;
        members: UIModel.State<Team[]>;
        instanceRef: EntityRef;
        isDeprecated: UIModel.State<boolean>;

        init: (entity?: Label.Data) => void;
        reset: () => void;

        public static toCreateInput(state: LabelState) {
            return ILabel.CreateLabelInput.create({
                instanceRef: state.instanceRef,
                name: state.name.value
            });
        }

        public static toUpdateInput(state: LabelState, labelRef: EntityRef) {
            return ILabel.UpdateLabelInput.create({
                LabelRef: labelRef,
                instanceRef: state.instanceRef,
                newName: state.name.value,
                isDeprecated: state.isDeprecated.value
            });
        }

        static use (props: {
            instanceId: string;
        }): LabelState {
            const name = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: NAME_CHAR_LIMIT
            });

            const isDeprecated = UIModel.State.useRequired({
                initialValue: false
            });

            const instanceRef = EntityFactory.fromEntityAttributes(props.instanceId, EntityType.Instance);

            const init = (entity?: Label.Data) => {
                if (entity) {
                    name.setValue(entity.name);
                    isDeprecated.setValue(entity.deprecated);
                } else {
                    name.setValue("");
                    isDeprecated.setValue(false);
                }
            };

            const reset = () => {
                isDeprecated.setValue(null);
            };

            return Builder<LabelState>()
                .name(name)
                .isDeprecated(isDeprecated)
                .instanceRef(instanceRef)
                .init(init)
                .reset(reset)
                .build();
        }
    }
}
import { Box } from "@amzn/awsui-components-react";
import * as React from "react";
import "../../../styles/util-styles.scss";

export const BaseRedirectContent = (props: {
    children: JSX.Element;
    contentType?: string;
}) => {
    // Handle flexbox styling differently for home page
    const contentStyle: React.CSSProperties = props.contentType === "HomePage" ? {
        minHeight: "87.4vh",
    } : {};
    return <React.Fragment>
        <div
            className="sherpa-main-ui"
            style={contentStyle}
        >
            <br/>
            <br/>
            <div className="vertical-center center-text bg-lightgray rounded-border">
                <Box margin="xxl" padding="xxl" >
                    {props.children}
                </Box>
            </div>
        </div>
    </React.Fragment>;
};
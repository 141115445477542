import { EntityFactory, EntityRef, EntityType, InstanceFactory, IUserProfile } from "@amzn/ask-legal-domain";
import { Box, ButtonDropdown, ButtonDropdownProps, Header, Icon, Table, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useAPI2 } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { SimpleModal } from "../common/SimpleModal";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";
import { FavoritePolarisFactory } from "../../factory/polaris/favorite-polaris-factory";
import { UIField } from "../common/UIField";
import { UIModel } from "../../model/ui-model";

export const UserFavoritesDetail = (props: {
    instanceId: string
}) => {
    const context = React.useContext(AppContext);
    const [modalAction, setModalAction] = React.useState("");

    const loadFavoritesRunner = useAPI2(
        context.getUserProfileAPI().loadFavorites
    );
    const removeFavoriteApi = useAPI2(
        context.getUserProfileAPI().unfavor
    );

    const loadInput = IUserProfile.LoadFavoritesInput.create(
        1000, // configure the number to 1000 to fetch all favorite at once
        1,
        props.instanceId
    );

    React.useEffect(() => {
        loadFavoritesRunner.invoke(loadInput);
        table.setProps((prev) => ({
            ...prev,
            loading: true,
        }));
    }, [props.instanceId]);

    React.useEffect(() => {
        if (loadFavoritesRunner.status === "Succeeded") {
            const tableItems = loadFavoritesRunner.output.favorites.map((item, index) => ({
                ...item,
                rank: index + 1
            }));
            table.setProps((prev) => ({
                ...prev,
                items: tableItems,
                loading: false,
            }));
        }
    }, [loadFavoritesRunner.status]);

    React.useEffect(() => {
        if (removeFavoriteApi.status === "Succeeded") {
            setModalAction("");
            loadFavoritesRunner.invoke(loadInput);
        }
    }, [removeFavoriteApi.status]);

    const counter = () => {
        return `(${table.props.items.length})`;
    };

    const table = useComponnentProps<TableProps<FavoritePolarisFactory.Table.Item>>({
        columnDefinitions: [
            FavoritePolarisFactory.Table.Rank,
            FavoritePolarisFactory.Table.Title,
            FavoritePolarisFactory.Table.Description
        ],
        items: [],
        selectedItems: [],
        selectionType: "single",
        onSelectionChange: e => table.setProps((prev) => ({
            ...prev,
            selectedItems: e.detail.selectedItems
        })),
        visibleColumns: [
            FavoritePolarisFactory.Table.Rank.id,
            FavoritePolarisFactory.Table.Title.id,
            FavoritePolarisFactory.Table.Description.id
        ],
        resizableColumns: true,
        loading: false,
        loadingText: "Loading favorites...",
        empty: (<Box textAlign="center" color="text-body-secondary" padding={{ top: "xxl" }}>
            <b>No favorites found</b>
            <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
            >
                No favorites saved yet.
            </Box>
        </Box>)
    });

    const header = <Header
        variant="h2"
        counter={counter()}
        description="Your top 4 favorites will be displayed first on the home page."
        actions={
            <ButtonDropdown
                disabled={table.props.selectedItems.length === 0}
                items={
                    FavDetailButtonDropdowns.toButtonDefinitions()
                }
                onItemClick={(e: { detail: { id: string } } ) => {
                    switch (e.detail.id) {
                        case FavDetailButtonDropdowns.Remove.id:
                            setModalAction("Remove");
                            break;
                        case FavDetailButtonDropdowns.Rank.id:
                            setModalAction("ChangeRank");
                            break;
                        default:
                            break;
                    }
                }}>
                    <Icon name="menu"/>
                </ButtonDropdown>
        }>
        My Favorites
    </Header>;

    return (
        <React.Fragment>
            {removeFavoriteApi.status === "Error" && <ErrorFlashbar error={removeFavoriteApi.err}/>}
            {modalAction === "Remove" &&
                <SimpleModal
                header="Remove Favorite"
                disableLeavingAlert
                child={
                    <div>
                        Remove selected item from favorites?
                    </div>
                }
                loading={removeFavoriteApi.status === "Running"}
                onCancel={() => setModalAction(null)}
                onSave={() => removeFavoriteApi.invoke(table.props.selectedItems[0])}
            />}
            {modalAction === "ChangeRank" &&
                <ChangeRankModal
                    closeModal={() => setModalAction(null)}
                    selectedItem={table.props.selectedItems[0]}
                    maxRank={table.props.items.length}
                    onUpdated={newOrder => {
                        const reordered = table.props.items.slice().sort((a, b) => {
                            const indexA = newOrder.findIndex(i => i.id === a.id);
                            const indexB = newOrder.findIndex(i => i.id === b.id);
                            return indexA - indexB;
                        });
                        table.setProps(prev => ({
                            ...prev,
                            items: reordered.map((item, index) => ({
                                ...item,
                                rank: index + 1
                            }))
                        }));
                    }}
                />}
            <Table {...table.props} header={header}/>
        </React.Fragment>
    );
};

const ChangeRankModal = (props: {
    closeModal: () => void;
    maxRank: number;
    selectedItem: FavoritePolarisFactory.Table.Item
    onUpdated: (newOrder: EntityRef[]) => void;
}) => {
    const context = React.useContext(AppContext);
    const reorderFavoriteApi = useAPI2(
        context.getUserProfileAPI().reorderFavorites
    );
    const newRankState = UIModel.State.use<number>({
        initialValue: props.selectedItem.rank,
        validation: val => {
            if (isNaN(val)) {
                return "Rank must be a number";
            } else if (val <= 0) {
                return "Rank must be bigger than 1";
            } else if (val > props.maxRank) {
                return `Rank must be smaller than or equal to ${props.maxRank}`;
            }
        }
    });

    React.useEffect(() => {
        if (reorderFavoriteApi.status === "Succeeded") {
            props.closeModal();
            props.onUpdated(reorderFavoriteApi.output.favorites);
        }
    }, [reorderFavoriteApi.status]);

    return (
        <SimpleModal
            header="Reorder your favorite"
            disableLeavingAlert
            child={
                <div>
                    <UIField.NumberValueField name="New rank" state={newRankState} editing={true}/>
                </div>
            }
            disabled={!!newRankState.errorText}
            loading={reorderFavoriteApi.status === "Running"}
            onCancel={() => props.closeModal()}
            onSave={() => reorderFavoriteApi.invoke(
                IUserProfile.ReorderFavoriteInput.create(
                    EntityFactory.fromEntityAttributes(props.selectedItem.id, props.selectedItem.type as EntityType),
                    newRankState.value,
                    InstanceFactory.fromEntityId(props.selectedItem.id)
                )
            )}
        />);
};

namespace FavDetailButtonDropdowns {
    export const Remove = Builder<ButtonDropdownProps.Item>()
        .id("Delete")
        .text("Delete")
        .build();
    export const Rank = Builder<ButtonDropdownProps.Item>()
        .id("ChangeRank")
        .text("Change favorite rank")
        .build();
    export function toButtonDefinitions() {
        return [
            Remove,
            Rank
        ];
    }
}

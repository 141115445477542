export namespace DateTimeConstants {
    export const MONTHS: Record<number, string[]> = {
        1: ["Jan", "January"],
        2: ["Feb", "February"],
        3: ["Mar", "March"],
        4: ["Apr", "April"],
        5: ["May", "May"],
        6: ["Jun", "June"],
        7: ["Jul", "July"],
        8: ["Aug", "August"],
        9: ["Sep", "September"],
        10: ["Oct", "October"],
        11: ["Nov", "November"],
        12: ["Dec", "December"]
    };
}
import * as React from "react";
import { Button, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import { IdentityRef, IdentityType } from "@amzn/altar-sds-client";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import "../../styles/custom-token.scss";

export const IdentityToken = (props: {
    identityRef: IdentityRef;
    onRemove: (id: string) => void;
}) => {
    const context = React.useContext(AppContext);
    const [displayName, setDisplayName] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const getUserByLoginApiRunner = useAPI(
        context.getUserSearchAPI().getUserByLogin
    );

    const getTeamNameByTeamIdApiRunner = useAPI(
        context.getTeamAPI().getTeamNameByTeamId
    );

    React.useEffect(() => {
        if (getUserByLoginApiRunner.status === "Succeeded") {
            setDisplayName(getUserByLoginApiRunner.data.output?.name);
            setLoading(false);
        } else if (getUserByLoginApiRunner.status === "Error") {
            console.info("Error loading display name for ", props.identityRef.id);
            setLoading(false);
        } else if (getUserByLoginApiRunner.status === "Running") {
            setLoading(true);
        }
    }, [getUserByLoginApiRunner.status]);

    React.useEffect(() => {
        if (getTeamNameByTeamIdApiRunner.status === "Succeeded") {
            setDisplayName(getTeamNameByTeamIdApiRunner.data.output.teamName);
            setLoading(false);
        } else if (getTeamNameByTeamIdApiRunner.status === "Error") {
            console.info("Error loading display name for ", props.identityRef.id);
            setLoading(false);
        } else if (getTeamNameByTeamIdApiRunner.status === "Running") {
            setLoading(true);
        }
    }, [getTeamNameByTeamIdApiRunner.status]);

    React.useEffect(() => {
        if (props.identityRef.type === IdentityType.PERSON) {
            getUserByLoginApiRunner.submitRun({login: props.identityRef.id});
        } else if (props.identityRef.type === IdentityType.TEAM) {
            getTeamNameByTeamIdApiRunner.submitRun({teamId: props.identityRef.id});
        }
    }, [props.identityRef]);

    const displayTeam = () => {
        return !displayName ? (
            <span>{props.identityRef.id}</span>
        ) : (
            <span>{displayName}</span>
        );
    };

    const displayUser = () => {
        return !displayName ? (
            <span>{`(${props.identityRef.id})`}</span>
        ) : (
            <span>{`${displayName} (${props.identityRef.id})`}</span>
        );
    };

    return (
        <>
            <div className="polaris-token-clone blue">
                <SpaceBetween direction="horizontal" size="s">
                    {props.identityRef.type === IdentityType.TEAM && (
                        displayTeam()
                    )}
                    {props.identityRef.type === IdentityType.PERSON && (
                        displayUser()
                    )}
                    {loading && <Spinner />}
                    <Button
                        iconName="close" variant="inline-icon"
                        onClick={() => props.onRemove(props.identityRef.id)}
                    />
                </SpaceBetween>
            </div>
        </>
    );
};

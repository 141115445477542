import { KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { Box, Link, SpaceBetween, Badge } from "@amzn/awsui-components-react";
import * as React from "react";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import { UIModel } from "../../model/ui-model";
import { SmartAnswersView } from "./search-components/SmartAnswersView";
import { SmartRichTextView } from "../common/RichTextEditor/SmartRichTextView";
import { QueryResultItem }  from "@aws-sdk/client-kendra";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import "../../styles/component/layout/SearchResultPageLayout.scss";
import { getDocumentUri } from "../../utils/common-utils";

export const SearchResultPanel = (props: {
    instanceId: string;
    results: SearchInterface.SearchQueryResponse;
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
}) => {
    const context = React.useContext(AppContext);
    const submitFeedbackRunner = useAPI2(
        context.getSearchAPI().feedback
    );
    const suggestedResults = [];
    const faqResults = [];
    const linkResults = [];
    const otherResults = [];

    const submitClickFeedback = (resultId: string) => {
        submitFeedbackRunner.invoke(
            SearchInterface.SubmitFeedbackInput.create({
                instanceId: props.instanceId,
                queryId: props.results.result.QueryId,
                clickFeedbackItems: [{
                    ClickTime: new Date(),
                    ResultId: resultId
                }]
            })
        );
    };

    props.results.result.ResultItems.forEach(r => {
        if (r.Type === "ANSWER") {
            suggestedResults.push(r);
        } else if (r.Type === "QUESTION_ANSWER") {
            if (r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Custom.ContentType).Value.StringValue === "Link") {
                linkResults.push(r);
            } else {
                faqResults.push(r);
            }
        } else {
            otherResults.push(r);
        }
    });

    return (
        <SpaceBetween direction="vertical" size="l">
            { props.results.result.TotalNumberOfResults === 0 && (
                <div>No relevant resources found</div>
            )}
            { (suggestedResults.length > 0 || faqResults.length > 0 || linkResults.length > 0) && <SmartAnswersView
                instanceId={props.instanceId}
                searchQueryId={props.results.result.QueryId}
                suggestedResults={suggestedResults}
                faqResults={faqResults}
                linkResults={linkResults}
            />}
            { otherResults.length > 0 && (
                <div>
                    <Box variant="small">
                        Displaying {otherResults.length} of {
                        (props.results.result.TotalNumberOfResults - suggestedResults.length - faqResults.length) > 100 ?
                        100 : (props.results.result.TotalNumberOfResults - suggestedResults.length - faqResults.length)
                    } results
                    </Box>
                    <br/>
                    <br/>
                    <SpaceBetween direction="vertical" size="l">
                        {otherResults.map((r: QueryResultItem) => (
                            <SpaceBetween size="xxxs" direction="vertical">
                                <span onClick={() => submitClickFeedback(r.Id)}>
                                    <Link href={getDocumentUri({ resultItem: r, instanceId: props.instanceId })} target="_blank" fontSize="heading-m">
                                        <SmartRichTextView
                                            content={r.DocumentTitle.Text}
                                        />
                                    </Link>
                                </span>
                                <Box margin="n" padding="n">
                                    <span style={{ color: "grey" }}>
                                        {DateTimeFormatter.toSimpleDate(r.DocumentAttributes.find(
                                            a => a.Key === KendraConst.Attributes.Default.LastUpdatedDate
                                            )?.Value.StringValue)}
                                    </span>&ensp;{r.DocumentExcerpt.Text}
                                </Box>
                                <ResultItemFooter item={r} submitFeedbackHandler={submitClickFeedback} itemLink={getDocumentUri({ resultItem: r, instanceId: props.instanceId })} />
                            </SpaceBetween>
                        ))}
                    </SpaceBetween>
                </div>
            )}
        </SpaceBetween>
    );
};

export const ResultItemFooter = (props: {
    item: QueryResultItem;
    submitFeedbackHandler: (resultId: string) => void;
    itemLink: string;
}) => {
    const LegalOnly = props.item.DocumentAttributes.find(a =>
        a.Key === KendraConst.Attributes.Custom.LegalOnly
    )?.Value.StringValue;

    return (
        <React.Fragment>
            <Box variant="small" color="text-body-secondary" margin={{right: "m"}} padding="n">
                <span onClick={() => props.submitFeedbackHandler(props.item.Id)}>
                    <Link
                        external
                        externalIconAriaLabel="Opens in a new tab"
                        fontSize="body-s"
                        href={props.itemLink}>{`${window.location.origin}`}
                    </Link>
                </span>
            </Box>
            { (LegalOnly === "true") &&
            <Box variant="small" color="text-body-secondary" margin={{right: "m"}} padding="n">
                <Badge color="green">Legal Only</Badge>
            </Box>}
        </React.Fragment>
    );
};
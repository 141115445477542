import * as React from "react";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { PageNotFound } from "./PageNotFound";

export const redirectRoutes = [
    {
        path: "*",
        content: (props: any) => <PageNotFound />,
        header: () => <SherpaHeader headerType="Home" />,
        footer: () => <SherpaFooter variant="LivePage" />
    }
];
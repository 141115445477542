import { SearchInterface, KendraConst } from "@amzn/ask-legal-domain";
import { Checkbox, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

export const AuthorFilter = (props: {
    authors: string[];
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
}) => {
    const selectedAuthors: any = props.queryInputState.value.filterConfiguration?.find(x => x.attribute === KendraConst.Attributes.Default.Authors)?.valueMap.value;
    return (
        <ExpandableSection defaultExpanded={false} header="Authors">
            <SpaceBetween direction="vertical" size="s">
                {props.authors.map(a => (
                    <Checkbox
                        checked={selectedAuthors?.some(author => author === a) ? true : false }
                        onChange={e => {
                            if (e.detail.checked) {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Default.Authors,
                                        operation: "ContainsAny",
                                        valueMap: {
                                            value: [a],
                                            valueType: KendraConst.IndexAttributeType.STRING_LIST
                                        }
                                    })
                                );
                            } else {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.removeFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Default.Authors,
                                        operation: "ContainsAny",
                                        valueMap: {
                                            value: [a],
                                            valueType: KendraConst.IndexAttributeType.STRING_LIST
                                        }
                                    })
                                );
                            }
                        }}>{a}
                    </Checkbox>
                ))}
            </SpaceBetween>
        </ExpandableSection>
    );
};
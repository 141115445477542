import * as React from "react";
import { AppContext } from "../../setup/context";
import { Box, Button, ButtonDropdown, CollectionPreferences, Flashbar, Header, Pagination, PropertyFilter, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useLoadingCollection } from "../../hooks/polaris-hooks";
import { RolePolarisFactory } from "../../factory/polaris/role-polaris-factory";
import { RoleDeleteContent, RoleModalContent } from "./modal-content/RoleModalContent";
import { useAPI } from "../../hooks/api-hook";
import { RoleModel } from "../../model/role-model";
import { SimpleModal } from "../common/SimpleModal";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";

export const RoleTable = (props: {
    id: string
}) => {
    const context = React.useContext(AppContext);
    const [modalAction, setModalAction] = React.useState<string>("");
    const [statusUpdates, setStatusUpdates] = React.useState<Array<any>>([]);
    const [canDelete, setCanDelete] = React.useState<boolean>(false);

    const createRoleRunner = useAPI(context.getRoleAPI().create);
    const updateRoleRunner = useAPI(context.getRoleAPI().update);
    const deleteRoleRunner = useAPI(context.getRoleAPI().delete);

    const roleState = RoleModel.RoleState.use({ instanceId: props.id });

    const createRoleButton = (
        <Button
            variant="primary"
            onClick={() => actionHandler("create")}
        >
            Create Role
        </Button>
    );

    const collection = useLoadingWithServerSidePagination(
        context.getRoleAPI().loadByInstance,
        props.id,
        {
            columnDefinitions: RolePolarisFactory.Table.toColumnDefinitions(),
            defaultTablePreferences: RolePolarisFactory.Table.toDefaultTablePreferences(),
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ],
            selectionType: "single"
        }, {
            filteringProperties: [
                RolePolarisFactory.Table.PropertyFilter.Name,
                RolePolarisFactory.Table.PropertyFilter.Any
            ]
        }
    );

    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };
    const header = (
        <Header variant="h2" counter={counter()} actions={
            <SpaceBetween direction="horizontal" size="m">
                <Button variant="icon" iconName="refresh" onClick={() => {
                    if (collection) {
                        collection.loadItems();
                    }
                }}/>
                <ButtonDropdown
                    loading={false}
                    items={[
                        {
                            text: "Edit",
                            id: "update",
                            disabled: collection.table.props.selectedItems.length !== 1
                        },
                        {
                            text: "Delete",
                            id: "delete",
                            disabled: collection.table.props.selectedItems.length !== 1
                        }
                    ]}
                    onItemClick={(event: { detail: { id: string } }) => {
                        actionHandler(event.detail.id);
                    }}>Actions</ButtonDropdown>
                {createRoleButton}
            </SpaceBetween>
        }>Roles</Header>
    );
    const empty = (
    <Box textAlign="center" color="inherit">
        <b>
            No Roles found
        </b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Roles found in this Instance
        </Box>
        {createRoleButton}
    </Box>);

    const dismiss = () => {
        roleState.reset();
        setModalAction("");
    };

    const actionHandler = (action: string) => {
        switch (action) {
            case "create":
                roleState.init();
                setModalAction("create");
                break;
            case "update":
                roleState.init(collection.table.props.selectedItems[0]);
                setModalAction("update");
                break;
            case "delete":
                setCanDelete(false);
                setModalAction("delete");
                break;
            default: break;
        }
    };

    const setDependency = (exists: boolean) => {
        setCanDelete(exists);
    };

    React.useEffect(() => {
        if (createRoleRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Role: ${createRoleRunner.data.output.name} was successfully created.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (createRoleRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to create role. Reason: ${createRoleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [createRoleRunner.status]);

    React.useEffect(() => {
        if (updateRoleRunner.status === "Succeeded") {
            dismiss();
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Role: ${updateRoleRunner.data.output.name} was successfully updated.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (updateRoleRunner.status === "Error") {
            dismiss();
            setStatusUpdates([{
                type: "error",
                content: `Failed to update role. Reason: ${updateRoleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [updateRoleRunner.status]);

    React.useEffect(() => {
        if (deleteRoleRunner.status === "Succeeded") {
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Role: ${deleteRoleRunner.data.output.name} was successfully deleted.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
            setCanDelete(false);
        } else if (deleteRoleRunner.status === "Running") {
            setStatusUpdates([{
                type: "success",
                content: `Deleting selected role.`,
                loading: true
            }]);
        } else if (deleteRoleRunner.status === "Error") {
            setStatusUpdates([{
                type: "error",
                content: `Failed to delete role. Reason: ${deleteRoleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [deleteRoleRunner.status]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <Flashbar items={statusUpdates}/>
                { !!collection.table.error && <ErrorFlashbar error={collection.table.error}/>}
                {modalAction === "create" &&
                    <SimpleModal
                        child={<RoleModalContent state={roleState}/>}
                        header={"Create New Role"}
                        loading={createRoleRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            createRoleRunner.submitRun(RoleModel.RoleState.toCreateInput(roleState));
                        }}
                        disabled={
                            !!roleState.name.errorText ||
                            !!roleState.members.errorText
                        }
                    />
                }
                {modalAction === "update" &&
                    <SimpleModal
                        child={<RoleModalContent state={roleState}/>}
                        header={"Update Role"}
                        loading={updateRoleRunner.status === "Running"}
                        onCancel={dismiss}
                        onSave={() => {
                            updateRoleRunner.submitRun(RoleModel.RoleState.toUpdateInput(roleState, collection.table.props.selectedItems[0]));
                        }}
                        disabled={
                            !!roleState.name.errorText ||
                            !!roleState.members.errorText
                        }
                    />
                }
                {modalAction === "delete" && (
                    <SimpleModal
                        header="Delete Role"
                        disableLeavingAlert
                        child={
                            <RoleDeleteContent
                                instanceId={props.id}
                                roleId={collection.table.props.selectedItems[0].id}
                                setCanDelete={setDependency}
                            />
                        }
                        loading={false}
                        onCancel={dismiss}
                        onSave={() => {
                            deleteRoleRunner.submitRun(collection.table.props.selectedItems[0].id);
                            setModalAction("");
                        }}
                        disabled={!canDelete}
                    />
                )}
                <Table
                    {...collection.table.props}
                    empty={empty}
                    header={header}
                    preferences={
                        <CollectionPreferences {...collection.tablePreference.props}/>
                    }
                    pagination={
                        <Pagination {...collection.tablePagination.props}/>
                    }
                    filter={
                        <PropertyFilter {...collection.tableFiltering.props}/>
                    }
                />
            </SpaceBetween>
        </React.Fragment>
    );
};
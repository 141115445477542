import { FilterField, Recommendation } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { Badge, CollectionPreferencesProps, Link, PropertyFilterProps, TableProps } from "@amzn/awsui-components-react";
import { CommonPolarisFactory } from "./common-polaris-factory";
import * as React from "react";
import { RulePopover } from "../../components/recommendation/RuleInfoLink";

export namespace RecommendationPolarisFactory {
    export namespace Table {
        export namespace PropertyFilter {
            export const Title = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Name)
                .operators(["="])
                .propertyLabel("Title")
                .build();
                export const Any = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Any)
                .operators(["="])
                .propertyLabel("Any")
                .build();
        }

        export const InstanceRef = Builder<TableProps.ColumnDefinition<Recommendation.Data>>()
            .id("instanceRef")
            .header("Instance")
            .cell((item: Recommendation.Data) => item.instanceId)
            .build();
        export const Title = Builder<TableProps.ColumnDefinition<Recommendation.Data>>()
            .id("title")
            .header("Title")
            .cell((item: Recommendation.Data) => item.title)
            .build();
        export const Url = Builder<TableProps.ColumnDefinition<Recommendation.Data>>()
            .id("url")
            .header("Page URL")
            .cell((item: Recommendation.Data) =>
                <React.Fragment>
                    <Link href={item.url}>{item.url}</Link>
                </React.Fragment>)
            .build();
        export const Rules = Builder<TableProps.ColumnDefinition<Recommendation.Data>>()
            .id("rules")
            .header("Rules")
            .cell((item: Recommendation.Data) => item.rules.map(e =>
                    <Badge color="blue">
                        <RulePopover id={e} />
                    </Badge>
                ))
            .build();

        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                InstanceRef,
                Title,
                Url,
                Rules,
            ]);
        }
        export function toDefaultTablePreferences() {
            return Builder<CollectionPreferencesProps.Preferences<any>>()
                .pageSize(10)
                .visibleContent([
                    Title.id,
                    Url.id,
                    Rules.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id
                ])
                .build();
        }
    }
}
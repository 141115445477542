import { PageFactory } from "@amzn/ask-legal-domain";
import { Button } from "@amzn/awsui-components-react";
import * as React from "react";
import { useServerSideCollection } from "../../hooks/legal-contacts-collection-hook";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { LegalContactsPolarisFactory } from "../../factory/polaris/legal-contacts-polaris-factory";
import { ServerSideLegalContactsTable } from "./ServerSideLegalContactsTable";

export const AllLegalContactsTable = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const loadAllLegalContactsApiRunner = useAPI2(
        context.getLegalContactAPI().loadAll
    );
    const collection = useServerSideCollection(
        loadAllLegalContactsApiRunner,
        props.instanceId,
        {
            columnDefinitions: LegalContactsPolarisFactory.Table.toColumnDefinition(),
            defaultSortingColumn: LegalContactsPolarisFactory.DEFAULT_SORTING_STATE.sortingColumn,
            filteringProperties: LegalContactsPolarisFactory.Filters.PropertyFilters.toDefaultFilteringProperties(),
            selectionType: "single"
        }
    );
    const getContainerURLWithFilter = (containerId: string, legalContactUserId: string) => {
        if (!containerId) return "";
        return `#/page/${
            PageFactory.fromEntityId(containerId)
        }/live?container=${
            containerId
        }&filter=contact: ${
            legalContactUserId
        }`;
    };
    const viewOnPageButton = <Button
        disabled={collection.tableProps.props.selectedItems.length < 1}
        iconName="external"
        href={getContainerURLWithFilter(
            collection.tableProps.props.selectedItems[0]?.containerId,
            collection.tableProps.props.selectedItems[0]?.legalContactUser.id
        )}
        target="_blank"
    >
        View on Page
    </Button>;
    return <ServerSideLegalContactsTable collection={collection} headerActions={viewOnPageButton} />;
};
import { Link, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";

export namespace PageLink {
    export const Admin = (props: {
        id: string;
        externalTitle?: string;
        tab?: "overview" | "permissions" | "livePage" | "draftPage" | "versionHistory";
    }) => {
        const context = React.useContext(AppContext);
        const loadPageRunner = useAPI(
            context.getPageAPI().load
        );
        React.useEffect(() => {
            if (!props.externalTitle) {
                loadPageRunner.submitRun(
                    props.id
                );
            }
        }, [props.id]);

        return (
            <SpaceBetween size="s" direction="horizontal">
                <Link variant="secondary" href={`#/page/${props.id}/admin${ props.tab ? `?tab=${props.tab}` : "" }`}>
                    {props.externalTitle ? props.externalTitle : (
                        loadPageRunner.status === "Succeeded" ?
                        loadPageRunner.data.output.title :
                        props.id
                    )}
                </Link>
            </SpaceBetween>
        );
    };

    export const Live = (props: {
        id: string;
        text?: string;
    }) => {
        const context = React.useContext(AppContext);
        const loadPageRunner = useAPI(
            context.getPageAPI().load
        );
        React.useEffect(() => {
            if (!props.text) {
                loadPageRunner.submitRun(
                    props.id
                );
            }
        }, [props.id]);

        return (
            <Link variant="secondary" href={`#/page/${props.id}/live`}>
                {props.text ? props.text : (
                    loadPageRunner.status === "Succeeded" ? loadPageRunner.data.output.title : props.id
                )}
            </Link>
        );
    };
}

import * as React from "react";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { ApplicationDefaultHome } from "./home";
import { LabSetting } from "./lab-setting";

export const commonRoutes = [
    {
        path: "/",
        content: (props: any) => <ApplicationDefaultHome/>,
        header: () => <SherpaHeader headerType="Home"/>
    },
    {
        path: "/lab",
        content: (props: any) => <LabSetting/>,
        header: () => <SherpaHeader headerType="Home"/>
    }
];
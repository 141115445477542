import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import { Button, Input, Grid, TokenGroup, SpaceBetween, FormField, FormFieldProps, InputProps } from "@amzn/awsui-components-react";
import {
  PrimitiveDataFactory,
} from "@amzn/ask-legal-domain";
import { ChoiceOption } from "@amzn/altar-sds-client";

const INPUT_LIMIT = 200;
const DUPLICATE_VALIDATION_MESSAGE = "Value already exists, please check existing options";
const CHAR_LIMIT_VALIDATION_MESSAGE = `Exceeded character limit of ${INPUT_LIMIT}`;

export const ChoiceOptionInput = (props: {
    state: UIModel.State<ChoiceOption[]>
}) => {
    const [inputText, setInputText] = React.useState<string>("");
    const [validationMessage, setValidationMessage] = React.useState<string>("");
    const getValidationResult = (t: string) => {
        if (t.length > INPUT_LIMIT) {
            return CHAR_LIMIT_VALIDATION_MESSAGE;
        }
        if (props.state.value?.find(o => o.displayValue === t)) {
            return DUPLICATE_VALIDATION_MESSAGE;
        }
    };

    React.useEffect(() => {
        setValidationMessage(getValidationResult(inputText));
    }, [inputText]);

    const onItemAdd = () => {
        if (!validationMessage) {
            props.state.setValue([
                ...props.state.value,
                {
                    displayValue: inputText, key: PrimitiveDataFactory.id()
                }
            ]);
            setInputText("");
        }
    };

    return (
        <>
            <SpaceBetween size="m">
                <Grid
                    gridDefinition={[
                        { colspan: 10 },
                        { colspan: 2 }
                    ]}
                >
                    <FormField errorText={validationMessage}>
                        <Input
                            stretch={true}
                            value={inputText}
                            onChange={({ detail }) => setInputText(detail.value)}
                            onKeyDown={({ detail }) => {
                                if (detail.keyCode === 13) {
                                    onItemAdd();
                                }
                            }}
                        />
                    </FormField>
                    <Button
                        disabled={validationMessage}
                        variant="icon" iconName="add-plus" onClick={onItemAdd}
                    />
                </Grid>
                {props.state.value.length > 0 && <TokenGroup
                    items={props.state.value
                        .map(o => ({label: o.displayValue, value: o.key}))
                    }
                    onDismiss={(e) => {
                        props.state.setValue([
                            ...props.state.value.slice(0, e.detail.itemIndex),
                            ...props.state.value.slice(e.detail.itemIndex + 1)
                        ]);
                    }}
                />}
            </SpaceBetween>
        </>
    );
};

export const ChoiceOptionAdd = (props: {
  choiceOptions: ChoiceOption[];
  onConfirm: (choiceOptions: ChoiceOption[]) => void;
  onCancel: () => void;
}) => {
  const choiceOptionState = UIModel.State.use<ChoiceOption[]>({initialValue: []});

  React.useEffect(() => {
    choiceOptionState.setValue(props.choiceOptions);
  }, [props.choiceOptions]);

  return (
    <SpaceBetween direction="vertical" size="xs">
      <ChoiceOptionInput
        state={choiceOptionState}
      />
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          variant="primary"
          onClick={(e) => {
            props.onConfirm(choiceOptionState.value);
          }}
        >
          Confirm
        </Button>
        <Button
          onClick={(e) => {
            props.onCancel();
          }}
        >
          Cancel
        </Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};

export const ChoiceOptionsEdit = (props: {
  choiceOptions: ChoiceOption[];
  onConfirm: (choiceOptions: ChoiceOption[]) => void;
  onCancel: () => void;
}) => {
  const choiceOptionState = UIModel.State.use<ChoiceOption[]>({initialValue: []});
  const [valid, setValid] = React.useState<boolean>(true);

  const [formFieldPropsList, setFormFieldPropsList] = React.useState<
    (Pick<FormFieldProps, "label" | "errorText"> & {
      key: string;
    })[]
  >(
    props.choiceOptions.map((e, index) => ({
      label: `Option #${index + 1}`,
      key: e.key!,
    }))
  );
  const [inputChoicePropsList, setInputChoicePropsList] = React.useState<
    (Pick<InputProps, "value"> & {
      key: string;
    })[]
  >(
    props.choiceOptions.map((option) => ({
      value: option.displayValue,
      key: option.key
    }))
  );

  React.useEffect(() => {
    choiceOptionState.setValue(props.choiceOptions);
  }, [props.choiceOptions]);

  function updateInputPropsValue(key: string, value?: string) {
    setInputChoicePropsList((prev) =>
      prev.map((e) => {
        if (e.key !== key) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateFormFieldProps(
    key: string,
    value?: Pick<FormFieldProps, "label" | "errorText">
  ) {
    setFormFieldPropsList((prev) =>
      prev.map((e) => {
        if (e.key !== key) return e;
        return {
          ...e,
          ...value,
        };
      })
    );
  }

  function validate() {
    let validData = true;
    const uniqueValues: Set<string> = new Set();
    for (const inputChoiceProps of inputChoicePropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.key === inputChoiceProps.key
      );
      if (uniqueValues.has(inputChoiceProps.value)) {
        updateFormFieldProps(formFieldProps.key, {
          errorText: DUPLICATE_VALIDATION_MESSAGE,
        });
        validData = false;
        continue;
      }

      if (inputChoiceProps.value.length > INPUT_LIMIT) {
        updateFormFieldProps(formFieldProps.key, {
          errorText: CHAR_LIMIT_VALIDATION_MESSAGE,
        });
        validData = false;
        continue;
      }

      uniqueValues.add(inputChoiceProps.value);
      updateFormFieldProps(formFieldProps.key, {
        errorText: undefined,
      });
    }

    setValid(validData);
  }

  React.useEffect(() => {
    validate();
  }, [inputChoicePropsList]);

  const onConfirm = () => {
    if (!valid) {
      console.warn(`not valid data`);
      return;
    }

    const updatedChoiceOptions: ChoiceOption[] = inputChoicePropsList.map((props) => ({
      key: props.key,
      displayValue: props.value.trim()
    }));

    props.onConfirm(updatedChoiceOptions);
  };

  return (
    <>
      <SpaceBetween direction="vertical" size="xs">
        {
          formFieldPropsList.map((formFieldProps) =>
            (
              <FormField
                stretch={false}
                {...formFieldProps}
              >
                {
                  inputChoicePropsList
                  .filter((inputChoiceProps) => inputChoiceProps.key === formFieldProps.key)
                  .map((inputChoiceProps) => (
                      <Input
                        {...inputChoiceProps}
                        onChange={({ detail }) => {
                          updateInputPropsValue(inputChoiceProps.key, detail.value);
                        }}
                      />
                    )
                  )
                }
              </FormField>
            )
          )
        }
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="primary"
            disabled={!valid}
            onClick={(e) => {
              onConfirm();
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={(e) => {
              props.onCancel();
            }}
          >
            Cancel
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </>
  );
};
import * as React from "react";
import { Box, Button, CollectionPreferences, CollectionPreferencesProps, Header, Pagination, PaginationProps, PropertyFilter, PropertyFilterProps, SpaceBetween } from "@amzn/awsui-components-react";
import { RelatedTable, useTreeCollection } from "@amzn/polaris-related-table";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { APIOutput, AppError, Container, ErrorCode, FilterField, Page, PageFactory, PageLibraryFactory, ServerSidePaginatedLoadingInput, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { APIResponse } from "../../api/common";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";
import { propertyFilterI18nStrings } from "../../i18n/propertyFilteringI18nString";
import { DescriptionManagerPolarisFactory } from "../../factory/polaris/description-manager-polaris-factory";
import { Builder } from "builder-pattern";
import { UIConstants } from "../../utils/common-utils";

export const DescriptionManagerHome = (props: {
  id: string;
}) => {
  const context = React.useContext(AppContext);

  const [allItems, setAllItems] = React.useState<any[]>([]);
  const [error, setError] = React.useState<AppError>();
  const [pageLibrary, setPageLibrary] = React.useState<string>("");
  const [page, setPage] = React.useState<string>("");
  const [container, setContainer] = React.useState<string>("");
  const [count, setCount] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [selectedItems, setSelectedItems] = React.useState([]);

  const loadItems = async (api: (input: ServerSidePaginatedLoadingInput) => Promise<APIResponse<ServerSidePaginatedLoadingOutput<Page.Data>>>,
    partitionKey: string,
    currentPageIndex: number,
    pageSize: number,
    filters: PropertyFilterProps.Query
  ) => {
    try {
      setLoading(true);
      let selectedFilters = [];

      let pageNameFilter = filters.tokens.filter((entry) => { return (entry.propertyKey === DescriptionManagerPolarisFactory.Table.filterByPage.key); });
      if (pageNameFilter.length > 0) {
        const value = pageNameFilter[0].value;
        selectedFilters.push({ field: FilterField.Name, value: value, negate: false });
      }

      let pageLibraryFilter = filters.tokens.filter((entry) => { return (entry.propertyKey === DescriptionManagerPolarisFactory.Table.filterByPageLibrary.key); });
      if (pageLibraryFilter.length > 0) {
        const value = pageLibraryFilter[0].value;
        selectedFilters.push({ field: FilterField.PageLibraryId, value: props.id + "-" + value, negate: false });
      }

      let input = ServerSidePaginatedLoadingInput.create({
        partitionKey: partitionKey,
        currentPageIndex: currentPageIndex,
        pageSize: pageSize,
        filters: selectedFilters
      });
      let rawOutput;
      rawOutput = await api(input);

      // User changed pagination setting while api is loading, ignore this loading
      if (input.pagination.currentPageIndex !== currentPageIndex ||
        input.pagination.pageSize !== pageSize
      ) {
        return;
      }
      const output = APIOutput.fromRaw<ServerSidePaginatedLoadingOutput<Page.Data>>(rawOutput.data);
      if (output.isErr()) {
        setError(output.err);
      } else {
        await transformData(output.data);
        tablePagination.setProps({
          ...tablePagination.props,
          pagesCount: Math.ceil(output.data.totalCount / pageSize)
        });
        setCount(output.data.totalCount);
        return output;
      }
    } catch (e) {
      let code: ErrorCode = 500;
      let message = UIConstants.ERROR_MESSAGE;
      if (!!e.response && !!e.response.data && !!e.response.data.message) {
        message = e.response.data.message;
      }
      if (!!e.response && !!e.response.status && e.response.status > 0) {
        code = e.response.status;
      }
      setError(Builder<AppError>().code(code).message(message).build());
    } finally {
      setLoading(false);
    }
  };

  const transformData = async (input: ServerSidePaginatedLoadingOutput<Page.Data>) => {
    // function to add parentId to containers for table
    let transformedData = [];
    for (const item of input.result) {
      transformedData.push(item);
      const mainSection = item.sections.find((section) => { return (section.location === "Main"); });
      if (item.sections.length > 0 && mainSection && mainSection.containers.length > 0) {
        // Loading each section data
        const rawOutput = await context.getContainerAPI().batchLoadMetadata(mainSection.containers);
        const output = APIOutput.fromRaw<Container.Data[]>(rawOutput.data);
        if (output.isErr()) {
          setError(output.err);
        } else {
          output.data.forEach((container) => {
            transformedData.push({
              ...container,
              parentId: item.id,
            });
          });
        }
      }
    }
    setAllItems(transformedData);
  };

  const columnDefinitions = DescriptionManagerPolarisFactory.Table.toColumnDefinitions();

  const { expandNode, items, collectionProps } = useTreeCollection(allItems,
    {
      columnDefinitions,
      keyPropertyName: "id",
      parentKeyPropertyName: "parentId",
      sorting: {},
      selection: {
        trackBy: "id",
      },
      filtering: {},
    }, true
  );

  const header = (<Header
    variant="h2"
    counter={" (" + selectedItems.length + "/" + count + ")"}
    actions={
      < Box float="right" >
        <SpaceBetween direction="horizontal" size="xs">
          <Button href={pageLibrary ? "#/page-library/" + pageLibrary + "/admin" : ""}>View Page Library</Button>
          <Button href={page ? "#/page/" + page + "/admin" : ""}>View Page</Button>
          <Button href={container ? "#/page/" + page + "/live?container=" + container : ""}>View Container</Button>
        </SpaceBetween>
      </Box >
    }>
    Description Manager
  </Header >);

  const empty = (<Box textAlign="center" color="inherit">
    <b>No Pages</b>
    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
      No Pages to display
    </Box>
  </Box>);

  const tablePagination = useComponnentProps<PaginationProps>({
    pagesCount: 0,
    currentPageIndex: 1, // page index starts from 1
    onChange: (e) => {
      tablePagination.setProps((prev) => ({
        ...prev,
        currentPageIndex: e.detail.currentPageIndex
      }));
    }
  });

  const tablePreference = useComponnentProps<CollectionPreferencesProps>({
    cancelLabel: "Cancel",
    confirmLabel: "Confirm",
    title: "Table Preferences",
    preferences: { pageSize: 10, visibleContent: columnDefinitions.map((column) => { return (column.id); }) },
    pageSizePreference: {
      title: "Select page size",
      options: [
        CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
        CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
        CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
      ]
    },
    visibleContentPreference: CommonPolarisFactory.Table.VisibleContentPreference.toVisibleContentPreference(
      columnDefinitions
    ),
    onConfirm: (e) => {
      tablePreference.setProps((prev) => ({
        ...prev,
        preferences: e.detail
      }));
    }
  });

  const tableFilteringQuery = useComponnentProps<PropertyFilterProps.Query>({
    tokens: [],
    operation: "and"
  });


  const loadOptions = async (filteringProperty: PropertyFilterProps.FilteringProperty, filterText: string) => {
    let api;
    if (filteringProperty.key === DescriptionManagerPolarisFactory.Table.filterByPageLibrary.key) {
      api = context.getPageLibraryAPI().searchByName;
    } else {
      api = context.getPageAPI().searchByName;
    }

    if (!filterText) {
      filterText = "";
    }

    let input = ServerSidePaginatedLoadingInput.create({
      partitionKey: props.id,
      currentPageIndex: 1,
      pageSize: 20,
      filters: [{ field: FilterField.Name, value: filterText, negate: false, operator: "optional" }] // configure this array when we need to table filtering
    });
    let rawOutput;
    rawOutput = await api(input);

    const output = APIOutput.fromRaw<ServerSidePaginatedLoadingOutput<Page.Data>>(rawOutput.data);
    if (output.isErr()) {
      tableFiltering.setProps({
        ...tableFiltering.props,
        filteringStatusType: "error",
        filteringErrorText: "loading data failed"
      });
    } else {
      let filteringOptions = [];
      output.data.result.map((data) => {
        if (filteringProperty.key === DescriptionManagerPolarisFactory.Table.filterByPage.key) {
          filteringOptions.push({ propertyKey: filteringProperty.key, value: data.name + " : (" + data.id + ")" });
        } else {
          filteringOptions.push({ propertyKey: filteringProperty.key, value: data.name });
        }
      });
      tableFiltering.setProps({
        ...tableFiltering.props,
        filteringStatusType: "finished",
        filteringOptions: filteringOptions
      });
      return output;
    }
  };

  const tableFiltering = useComponnentProps<PropertyFilterProps>({
    query: tableFilteringQuery.props,
    onChange: e => tableFilteringQuery.setProps(prev => ({
        ...prev,
        operation: e.detail.operation,
        tokens: e.detail.tokens.map(t => ({
            ...t,
            propertyKey: t.propertyKey ? t.propertyKey : FilterField.Name
        }))
    })),
    filteringLoadingText: "loading options",
    asyncProperties: true,
    filteringOptions: [],
    i18nStrings: propertyFilterI18nStrings,
    filteringProperties: DescriptionManagerPolarisFactory.Table.toFilterOptions(),
    hideOperations: true
  });

  const resetButtons = () => {
    setPage("");
    setPageLibrary("");
    setContainer("");
  };

  React.useEffect(() => {
    tableFiltering.setProps(prev => ({
      ...prev,
      query: tableFilteringQuery.props
    }));
  }, [tableFilteringQuery.props]);

  React.useEffect(() => {
    tablePagination.setProps({
      ...tablePagination.props,
      currentPageIndex: 1,
    });
    setSelectedItems([]);
    // trigger load Items too
    loadItems(context.getPageAPI().loadAllByInstance,
      props.id,
      1,
      tablePreference.props.preferences.pageSize,
      tableFiltering.props.query
    );
  }, [tablePreference.props.preferences.pageSize, tableFiltering.props.query]);

  React.useEffect(() => {
    if (tableFiltering.props.filteringStatusType !== "loading") {
      setSelectedItems([]);
      loadItems(
        context.getPageAPI().loadAllByInstance,
        props.id,
        tablePagination.props.currentPageIndex,
        tablePreference.props.preferences.pageSize,
        tableFiltering.props.query
      );
    }
  }, [tablePagination.props.currentPageIndex]);

  React.useEffect(() => {
    if (selectedItems.length > 0) {
      setPageLibrary(PageLibraryFactory.fromEntityId(selectedItems[0].id));
      if (selectedItems[0].parentId) {
        setPage(PageFactory.fromEntityId(selectedItems[0].id));
        setContainer(selectedItems[0].id);
      } else {
        setPage(selectedItems[0].id);
        setContainer("");
      }
    } else {
      resetButtons();
    }
  }, [selectedItems]);

  return (
    <React.Fragment>
      {!!error && <ErrorFlashbar error={error} />}
      <RelatedTable
        {...collectionProps}
        expandChildren={expandNode}
        columnDefinitions={columnDefinitions}
        visibleColumns={tablePreference.props.preferences.visibleContent}
        loading={loading}
        loadingText={"Loading data"}
        items={items}
        empty={empty}
        header={header}
        resizableColumns
        selectionType="single"
        trackBy="id"
        preferences={
          <CollectionPreferences {...tablePreference.props} />
        }
        pagination={
          <Pagination {...tablePagination.props} />
        }
        filter={
          <PropertyFilter {...tableFiltering.props} />
        }
        selectedItems={selectedItems}
        onSelectionChange={
          (event) => {
            setSelectedItems(event.detail.selectedItems);
          }
        }
      />
    </React.Fragment>
  );
};
import * as React from "react";
import { UIModel } from "../../model/ui-model";
import { useAPI } from "../../hooks/api-hook";
import { Icon, Spinner } from "@amzn/awsui-components-react";
import { AppContext } from "../../setup/context";
import { EntityRef } from "@amzn/ask-legal-domain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import "../../styles/component/favor-button.scss";

export const FavorButton = (props: {
    isFavoritedState: UIModel.State<boolean>;
    targetEntityRef: EntityRef;
    size: "big" | "normal"
}) => {
    const context = React.useContext(AppContext);
    const favorRunner = useAPI(context.getUserProfileAPI().favor);
    const unfavorRunner = useAPI(context.getUserProfileAPI().unfavor);

    const favor = () => {
        favorRunner.submitRun(props.targetEntityRef);
    };

    const unfavor = () => {
        unfavorRunner.submitRun(props.targetEntityRef);
    };

    React.useEffect(() => {
        if (favorRunner.status === "Succeeded") {
            props.isFavoritedState.setValue(true);
        }
    }, [favorRunner.status]);

    React.useEffect(() => {
        if (unfavorRunner.status === "Succeeded") {
            props.isFavoritedState.setValue(false);
        }
    }, [unfavorRunner.status]);

    return (
        <span className="favor-button-span">
            {(favorRunner.status === "Running" || unfavorRunner.status === "Running") && <Spinner variant={props.size === "big" ? "normal" : "inverted"} size={props.size} />}
            {favorRunner.status !== "Running" && unfavorRunner.status !== "Running" && (
                <span onClick={props.isFavoritedState.value ? unfavor : favor}>
                    {props.isFavoritedState.value ?
                        <span title="UnFavorite" style={{ color: "darkorange" }}>
                            <FontAwesomeIcon icon={solidHeart} size={props.size === "big" ? "2x" : "1x"} />
                        </span> :
                        <span title="Favorite" style={{ color: props.size === "big" ? "black" : "white" }}>
                            <FontAwesomeIcon icon={props.size === "big" ? regularHeart : solidHeart} size={props.size === "big" ? "2x" : "1x"} />
                        </span>}
                </span>
            )}
        </span>
    );
};
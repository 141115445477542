import { PageDraft } from "@amzn/ask-legal-domain";
import {
    Alert,
    Button,
    ColumnLayout,
    ExpandableSection,
    Header,
    Popover,
    SpaceBetween,
    TextContent
} from "@amzn/awsui-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { PageDraftModel } from "../../model/page-draft-model";
import { PageModel } from "../../model/page-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { IdentityLink } from "../common/IdentityLink";
import { UIField } from "../common/UIField";
import { PageLink } from "../page/PageLink";
import {
    getQueryParameterMap,
    setURLByQueryParameters
} from "../../utils/common-utils";
import "../../styles/util-styles.scss";

export const PageDraftGeneralInfo = (props: {
    state: UIModel.State<PageDraft.Data>;
}) => {
    const context = React.useContext(AppContext);

    const inPageInfoEditModeFromURL = (): boolean => {
        const urlParams = getQueryParameterMap();
        return urlParams.get("mode") === "editPageInfo";
    };

    const [expanded, setExpanded] = React.useState<boolean>(inPageInfoEditModeFromURL());
    const [editing, setEditing] = React.useState<boolean>(inPageInfoEditModeFromURL());
    const updateDraftMetaState = PageDraftModel.UpdateDraftMetaState.use({
        draft: props.state.value
    });
    const updateDraftMetaRunner = useAPI(
        context.getPageDraftAPI().updateMeta
    );

    const updateMeta = () => {
        updateDraftMetaRunner.submitRun(
            PageDraftModel.UpdateDraftMetaState.toInput(
                updateDraftMetaState
            )
        );
    };

    const removePageInfoEditModeFromURL = () => {
        const urlSearchParams = getQueryParameterMap();
        urlSearchParams.delete("mode");
        setURLByQueryParameters(urlSearchParams);
    };

    React.useEffect(() => {
        updateDraftMetaState.setDraft(props.state.value);
    }, [
        props.state.value.id,
        props.state.value.version
    ]);

    React.useEffect(() => {
        if (updateDraftMetaRunner.status === "Succeeded") {
            props.state.setValue(updateDraftMetaRunner.data.output);
            setEditing(false);
            removePageInfoEditModeFromURL();
        }
        if (updateDraftMetaRunner.status === "Error") {
            setEditing(false);
        }
    }, [updateDraftMetaRunner.status]);

    const getPageDraftMetadata = () => {
        return (
            <ColumnLayout columns={4} variant="text-grid">
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Base Page"}>
                        <PageLink.Live id={props.state.value.pageId} />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Current Version"}>
                        {props.state.value.version}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Draft Status"}>
                        {props.state.value.status}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Created By"}>
                        <IdentityLink
                            id={props.state.value.createdBy.id}
                            name={props.state.value.createdBy.name}
                        />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Creation Date"}>
                        {DateTimeFormatter.formatDate(props.state.value.creationDate)}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Last Modified By"}>
                        <IdentityLink
                            id={props.state.value.lastModifiedBy.id}
                            name={props.state.value.lastModifiedBy.name}
                        />
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Last Modified Date"}>
                        {DateTimeFormatter.formatDate(props.state.value.lastModifiedDate)}
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Last Modified Comment"}>
                        {props.state.value.lastModifiedCommit}
                    </UIField.LabelField>
                </SpaceBetween>
                <SpaceBetween size="s">
                    <UIField.LabelField
                        label={"Draft Reviewed By"}>
                        {props.state.value.reviewedBy ?
                            <IdentityLink
                                id={props.state.value.reviewedBy.id}
                                name={props.state.value.reviewedBy.name}
                            /> : null
                        }
                    </UIField.LabelField>
                    <UIField.LabelField
                        label={"Draft Contributors"}>
                        {props.state.value.contributors?.map(c =>
                            <IdentityLink
                                id={c.id}
                                name={c.name}
                            />
                        )}
                    </UIField.LabelField>
                </SpaceBetween>
            </ColumnLayout>
        );
    };

    const getActionButtons = () => {
        if (props.state.value.status !== "DRAFT") {
            return null;
        }
        return (
            <div>
                {editing && (
                    <SpaceBetween direction="horizontal" size="s">
                        <Button
                            variant="secondary"
                            loading={updateDraftMetaRunner.status === "Running"}
                            onClick={() => {
                                updateDraftMetaState.reset();
                                setEditing(false);
                                removePageInfoEditModeFromURL();
                            }}>Cancel
                        </Button>
                        <Button
                            variant="primary"
                            disabled={
                                updateDraftMetaState.newPageTitleField.errorText ||
                                updateDraftMetaState.newPageDescription.errorText
                            }
                            loading={updateDraftMetaRunner.status === "Running"}
                            onClick={updateMeta}>Save
                        </Button>
                    </SpaceBetween>
                )}
                {expanded && !editing && (
                    <Button
                        variant="normal"
                        loading={updateDraftMetaRunner.status === "Running"}
                        onClick={() => {
                            setEditing(true);
                            setExpanded(true);
                        }}>Edit Page Info
                    </Button>
                )}
            </div>
        );
    };

    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);

    React.useEffect(() => {
        if (!updateDraftMetaState.newPageDescription.value || (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length === PageModel.DESCRIPTION_CHAR_LIMIT)) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length >= 0) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters ( ${PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length} remaining )`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - updateDraftMetaState.newPageDescription.value.length < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                        Maximum {PageModel.DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color: "#d13212" }}>{updateDraftMetaState.newPageDescription.value.length - PageModel.DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
            );
        }
    }, [updateDraftMetaState.newPageDescription.value]);

    return (
        <ExpandableSection
            variant="container"
            expanded={expanded}
            className="custom-container"
            onChange={(e) => setExpanded(e.detail.expanded)}
            headerActions={<span>{getActionButtons()}</span>}
            headerInfo={
                <span>
                    <Popover
                        position="bottom"
                        size="large"
                        triggerType="custom"
                        header={"Page Draft Metadata"}
                        content={getPageDraftMetadata()}
                    >
                        <span title="info" style={{ color: "white", cursor: "pointer" }}>
                            <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                        </span>
                    </Popover>
                </span>
            }
            headerText={<span className="dark-header-p-2">General Info</span>}
        >
            {updateDraftMetaRunner.status === "Error" && <ErrorFlashbar error={updateDraftMetaRunner.data.err} />}
            <SpaceBetween size="s">
                {inPageInfoEditModeFromURL() && editing && (
                    <Alert type="warning" statusIconAriaLabel="Warning">
                        Changes to the page title and description need to be published for the changes to take effect.
                    </Alert>
                )}
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="s">
                        <UIField.LabelField label={"Live Page Link"}>
                            <PageLink.Live id={props.state.value.pageId} />
                        </UIField.LabelField>
                    </SpaceBetween>
                    <SpaceBetween size="s">
                        <UIField.StateValueField
                            name="Page Title"
                            state={updateDraftMetaState.newPageTitleField}
                            constraintText={!!editing && `Maximum ${PageModel.TITLE_CHAR_LIMIT} characters`}
                            editing={editing}
                            disabled={updateDraftMetaRunner.status === "Running"}
                        />
                        <UIField.StateValueField
                            name="Page Description"
                            state={updateDraftMetaState.newPageDescription}
                            constraintText={!!editing && descriptionConstraint}
                            editing={editing}
                            variant="TextArea"
                            disabled={updateDraftMetaRunner.status === "Running"}
                        />
                    </SpaceBetween>
                </ColumnLayout>
            </SpaceBetween>
        </ExpandableSection>
    );
};

import { ButtonDropdown, ButtonDropdownProps, Header, Popover, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Builder } from "builder-pattern";
import * as React from "react";
import { AskLegalLayoutUtils } from "../../layout/AskLegalLayout";
import { ContainerGeneralInfo } from "../ContainerGeneralInfo";
import { ContainerLinkCopyButton } from "../ContainerButtons";
import { HashLink } from "react-router-hash-link";
import { UIModel } from "../../../model/ui-model";
import { Container } from "@amzn/ask-legal-domain";
import { BaseContainerHeaderProps } from "./BaseContainerHeaderPropsInterface";
const BACK_TO_TOP = require("../../../assets/back-to-top.png").default;

export namespace ContainerManageModeHeader {
    export interface Props extends BaseContainerHeaderProps {
        showDiff: UIModel.State<boolean>;
        buttonDropdownEventHandler: (buttonId: string) => void;
        showCompareFeature?: boolean;
    }

    export const Text = (props: Props) => {
        return <span className="dark-header-p-2">{props.containerMetaData.title}</span>;
    };

    export const Actions = (props: Props) => {
        const getButtonDropDown = () => {
            let items: ButtonDropdownProps.Items = [];
            if (props.containerMetaData.containerType === Container.Type.RICH_TEXT) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else if (props.containerMetaData.containerType === Container.Type.ACCORDION) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else if (props.containerMetaData.containerType === Container.Type.ACCORDION_GROUP) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else if (props.containerMetaData.containerType === Container.Type.LIST) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else if (props.containerMetaData.containerType === Container.Type.DOCUMENT) {
                items = [
                    ContainerButtonDropdown.Upload,
                    ContainerButtonDropdown.Edit,
                    ContainerButtonDropdown.Reorder,
                    ContainerButtonDropdown.Delete
                ];
            } else if (props.containerMetaData.containerType === Container.Type.DECISION_TREE) {
                items = [
                    ContainerButtonDropdown.Edit,
                    ContainerButtonDropdown.EditDecisionTree,
                    ContainerButtonDropdown.Reorder,
                    ContainerButtonDropdown.Delete
                ];
            } else if (props.containerMetaData.containerType === Container.Type.WHOS_MY_LAWYER) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else if (props.containerMetaData.containerType === Container.Type.ADVANCED_LIST) {
                items = [ContainerButtonDropdown.Edit, ContainerButtonDropdown.Reorder, ContainerButtonDropdown.Delete];
            } else {
                items = [];
            }
            return (
                <ButtonDropdown
                    loading={props.loading}
                    items={items}
                    onItemClick={(event: { detail: { id: string } }) => {
                        props.buttonDropdownEventHandler(event.detail.id);
                    }}
                    variant="icon"
                />
            );
        };

        return (
            <SpaceBetween direction="horizontal" size="xxxs" className="awsui-polaris-dark-mode horizontal-center">
                {!!props.showCompareFeature && !props.loading && (
                    <span title="Compare with live">
                        <Toggle
                            onChange={({ detail }) => props.showDiff.setValue(detail.checked)}
                            checked={props.showDiff.value}
                        >
                            <b>Compare</b>
                        </Toggle>
                    </span>
                )}
                {props.loading && <Spinner />}
                <span>
                    <ContainerLinkCopyButton containerId={props.containerMetaData.id} />
                </span>
                <span title="Navigate to top">
                    <HashLink
                        smoothed="true"
                        to={"#"}
                        style={{ textDecoration: "none" }}
                        scroll={(el) => AskLegalLayoutUtils.scrollWithOffset(el, -80)}
                    >
                        <span>
                            <img height="28px" src={BACK_TO_TOP} />
                        </span>
                    </HashLink>
                </span>
                <span>
                    {getButtonDropDown()}
                </span>
            </SpaceBetween>
        );
    };

  export const Info = (props: Props) => {
      return (
          <span>
              <Popover
                  position="bottom"
                  size="large"
                  triggerType="custom"
                  header={"Container Info"}
                  content={<ContainerGeneralInfo data={props.containerMetaData} />}
              >
                  <span title="info" style={{ color: "white", cursor: "pointer" }}>
                      <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                  </span>
              </Popover>
          </span>
      );
  };
}


export namespace ContainerButtonDropdown {
    export const Delete = Builder<ButtonDropdownProps.Item>()
        .id("delete")
        .text("Delete")
        .build();
    export const Edit = Builder<ButtonDropdownProps.Item>()
        .id("edit")
        .text("Edit")
        .build();
    export const Upload = Builder<ButtonDropdownProps.Item>()
        .id("upload")
        .text("Upload file")
        .build();
    export const AddQuestion = Builder<ButtonDropdownProps.Item>()
        .id("addQuestion")
        .text("Add Question")
        .build();
    export const Moveup = Builder<ButtonDropdownProps.Item>()
        .id("moveup")
        .text("Move Container Up")
        .build();
    export const Movedown = Builder<ButtonDropdownProps.Item>()
        .id("movedown")
        .text("Move Container Down")
        .build();
    export const Reorder = Builder<ButtonDropdownProps.ItemGroup>()
        .id("reorder")
        .text("Re-order Container")
        .items([Moveup, Movedown])
        .build();
    export const AddGroup = Builder<ButtonDropdownProps.Item>()
        .id("addGroup")
        .text("Add Question Group")
        .build();
    export const EditDecisionTree = Builder<ButtonDropdownProps.Item>()
        .id("editTree")
        .text("Edit Decision Tree")
        .external(true)
        .build();
}
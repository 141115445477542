import { Badge, Link, Popover } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { RoleViewContent } from "./modal-content/RoleModalContent";

export const RolePopover = (props: {
    id: string;
    variant?: "text" | "custom";
    badge?: boolean
}) => {
    const context = React.useContext(AppContext);
    const [roleValue, setRoleValue] = React.useState(props.id);
    const loadRoleRunner = useAPI(
        context.getRoleAPI().load
    );

    React.useEffect(() => {
        loadRoleRunner.submitRun(props.id);
    }, [props.id]);

    React.useEffect(() => {
        if (loadRoleRunner.status === "Succeeded") {
            if (!loadRoleRunner.data.output) {
                loadRoleRunner.reload();
            } else {
                setRoleValue(loadRoleRunner.data.output.name);
            }
        }
    }, [loadRoleRunner.status]);

    return (
    <Popover
        dismissAriaLabel="Close"
        fixedWidth
        header={
            `Role Details: ${roleValue}`}
        size="medium"
        triggerType={!props.variant ? "custom" : props.variant}
        content={ loadRoleRunner.status === "Succeeded" &&
            <RoleViewContent role={loadRoleRunner.data.output} />
        }>
        { props.badge ? <Badge color="blue">{roleValue}</Badge> : roleValue }
    </Popover>);
};
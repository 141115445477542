import { DecisionTreeContent, DecisionTreeNode } from "@amzn/ask-legal-domain";
import { useEffect, useState } from "react";
import * as d3 from "d3-hierarchy";
import { useEdgesState, useNodesState } from "reactflow";
import { getReactFlowEdges, getReactFlowNode } from "../factory/decision-tree-factory";
import { CustomNode } from "../components/decision-tree-container/cusom-resources/CustomNode";
import { CustomEdge } from "../components/decision-tree-container/cusom-resources/CustomEdge";

export type NodePosition = {
    [nodeId: string]: { x: number, y: number }
};

export const useAutoLayout = (props: {
    content: DecisionTreeContent
}) => {
    const [_nodeDataMap, _setNodeDataMap] = useState<{[nodeId: string]: DecisionTreeNode}>({}); // For fast lookup
    const [nodes, setNodes] = useNodesState<CustomNode.Data>([]);
    const [edges, setEdges] = useEdgesState<CustomEdge.Data>([]);

    /**
     * Initialization, all nodes are positioned at (0, 0)
     */
    useEffect(() => {
        let _map = {};
        for (const node of props.content.allNodes) {
            _map[node.id] = node;
        }
        _setNodeDataMap(_map);
        setNodes([]);
    }, []);

    /**
     * Reposition the node once the node map is updated
     */
    useEffect(() => {
        reposition();
    }, [JSON.stringify(_nodeDataMap)]);

    const update = (content: DecisionTreeContent) => {
        for (const node of content.allNodes) {
            _nodeDataMap[node.id] = node;
        }
        _setNodeDataMap(_nodeDataMap);
    };

    // Method to calculate optimal view size of the tree
    const getTreeSize = (nodes: { [nodeId: string]: DecisionTreeNode; }): { height: number, width: number }  => {
        if (!nodes) {
            return { height: 0, width: 0 };
        }
        const verticalSpacing = 150;
        const horizontalSpacing = 250; // Width of node is 200 + buffer 25 * 2
        const rootNode = nodes[props.content.rootNodeId];
        const queue: DecisionTreeNode[] = [rootNode];
        let maxWidth = 1;
        let maxHeight = 0;
        while (queue.length > 0) {
            const levelSize = queue.length;
            if (levelSize > 0) maxHeight++;
            // Traverse through the nodes at the current level and their children
            for (let i = 0; i < levelSize; i++) {
                const node = queue.shift()!;
                if (node.children) queue.push(...(node.children.map(x => nodes[x.nodeId])));
            }
            // Update the maximum width if the current level has more nodes
            if (queue.length > maxWidth) {
                maxWidth = queue.length;
            }
        }
        // Depth of tree impacts spacing width for edge leaf nodes
        let heightCoeff = 0.5;
        if (maxHeight > 4) {
            heightCoeff = 0.4;
        } else if (maxHeight > 5) {
            heightCoeff = 0.3;
        }
        return {
            height: maxHeight * verticalSpacing,
            width: maxWidth * horizontalSpacing  * maxHeight * heightCoeff
        };
    };

    const reposition = () => {
        const nodeCounts = Object.keys(_nodeDataMap).length;
        if (nodeCounts === 0) return;
        const rootNode = _nodeDataMap[props.content.rootNodeId];
        const findChildren = (node: DecisionTreeNode) => {
            if (!node) {
                return [];
            }
            const children = node.children.map(child => _nodeDataMap[child.nodeId]);
            return children;
        };
        const hierarchy = d3.hierarchy(rootNode, findChildren);
        const size = getTreeSize(_nodeDataMap);
        const positionedRoot = d3.tree<DecisionTreeNode>().size([size.width, size.height])(hierarchy);
        // Iterate the tree using BFS to fetch location of each node
        const queue = [positionedRoot];
        const _nodes: CustomNode.Node[] = [];
        const allNodes: DecisionTreeNode[] = [];
        while (queue.length > 0) {
            const positionedNode = queue.shift();
            const node = getReactFlowNode(positionedNode.data, {x: positionedNode.x, y: positionedNode.y});
            _nodes.push(node);
            allNodes.push(positionedNode.data);
            if (positionedNode.children) {
                for (const child of positionedNode.children) {
                    queue.push(child);
                }
            }
        }
        setNodes(_nodes);
        const edges = getReactFlowEdges(rootNode, allNodes);
        setEdges(edges);
    };

    return {
        nodes,
        edges,
        update,
        reposition
    };
};
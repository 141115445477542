import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import {
    Checkbox,
    CollectionPreferences,
    ColumnLayout,
    Header,
    Select,
    SpaceBetween,
    Tabs,
    TabsProps
} from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { ConfigurableOption, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { LegalContactsPolarisFactory } from "../../../factory/polaris/legal-contacts-polaris-factory";
import { ConfigurableOptionsInput } from "../../common/ConfigurableOptionsInput";

export const WhosMyLawyerContentEdit = (props: {
    state: UIModel.State<WhosMyLawyerContent>;
}) => {
    const renderedTabs: TabsProps.Tab[] = [{
        id: "scope",
        label: "Scope",
        content: <ConfigurableOptionsInput
            state={UIModel.State.use<ConfigurableOption[]>({
                initialValue: props.state.value?.dataOptions?.scopeOptions || []
            })}
            onChange={(updatedOptions) => {
                props.state.setValue(
                    WhosMyLawyerContent.update(props.state.value, {
                        dataOptions: { scopeOptions: updatedOptions }
                    })
                );
            }}
            headerTitle="Scope Dropdown Options"
        />
    }, {
        id: "team",
        label: "Team",
        content: <ConfigurableOptionsInput
            state={UIModel.State.use<ConfigurableOption[]>({
                initialValue: props.state.value?.dataOptions?.teamOptions || []
            })}
            onChange={(updatedOptions) => {
                props.state.setValue(
                    WhosMyLawyerContent.update(props.state.value, {
                        dataOptions: { teamOptions: updatedOptions }
                    })
                );
            }}
            headerTitle="Team Dropdown Options"
        />
    }, {
        id: "subTeam",
        label: "Sub-Team",
        content: <ConfigurableOptionsInput
            state={UIModel.State.use<ConfigurableOption[]>({
                initialValue: props.state.value?.dataOptions?.subTeamOptions || []
            })}
            onChange={(updatedOptions) => {
                props.state.setValue(
                    WhosMyLawyerContent.update(props.state.value, {
                        dataOptions: { subTeamOptions: updatedOptions }
                    })
                );
            }}
            headerTitle="Sub-Team Dropdown Options"
        />
    }];

    return <>
        <hr />
        <Header variant="h3">Default Table View Configuration</Header>
        <ColumnLayout columns={2}>
            <UIField.CustomField
                name="Sorting field"
                child={<Select
                    selectedOption={WhosMyLawyerFactory.toOptionDefinition(
                        props.state.value?.defaultConfiguration.sorting?.field
                    )}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.update(props.state.value, {
                            configuration: {
                                sorting: {
                                    field: detail.selectedOption.value,
                                    order: props.state.value.defaultConfiguration.sorting.order
                                }
                            }
                        })
                    )}
                    options={WhosMyLawyerFactory.getSortSelectOptions()}
                />}
            />
            <UIField.CustomField
                name="Sorting order"
                child={<Checkbox
                    checked={props.state.value?.defaultConfiguration.sorting?.order === "ASC"}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.update(props.state.value, {
                            configuration: {
                                sorting: {
                                    field: props.state.value.defaultConfiguration.sorting.field,
                                    order: detail.checked ? "ASC" : "DESC"
                                }
                            }
                        })
                    )}
                >
                    isAscending
                </Checkbox>}
            />
            <UIField.LabelField
                label="Manage view preferences"
                children={<CollectionPreferences
                    preferences={
                        WhosMyLawyerFactory.toPreferencesFromDisplayConfiguration(
                            props.state.value?.defaultConfiguration
                        )
                    }
                    onConfirm={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.update(props.state.value, {
                            configuration: WhosMyLawyerFactory.toDisplayConfigurationFromPreferences(
                                detail,
                                props.state.value.defaultConfiguration
                            )
                        })
                    )}
                    pageSizePreference={{
                        options: LegalContactsPolarisFactory.DEFAULT_PAGE_SIZE_OPTIONS,
                        title: "Number of contacts per page"
                    }}
                    stripedRowsPreference={{
                        label: "Striped rows",
                        description: "Select to add alternating shaded rows"
                    }}
                    contentDisplayPreference={{
                        options: LegalContactsPolarisFactory.Table.getColumnDisplayOptions(),
                        title: "Default column view preferences",
                        description: "Customize the columns visibility and order"
                    }}
                    cancelLabel="Cancel"
                    confirmLabel="Save"
                    title="Set default table preferences"
                />}
            />
        </ColumnLayout>
        <hr />
        <SpaceBetween size="s">
            <Header variant="h3">Configure Dropdown Options</Header>
            <Tabs
                tabs={renderedTabs}
                variant="container"
            />
        </SpaceBetween>
    </>;
};
import { AcceptedExtension } from "@amzn/ask-legal-domain";
import { Box, Icon, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import * as React from "react";
import { useDropzone, FileRejection, Accept } from "react-dropzone";
import "../../styles/component/FileUpload/FileUpload.scss";

export namespace FileUpload {
    export interface Props {
        onDrop: <T extends File>(acceptedFiles: T[], rejectedFiles: FileRejection[], event: any) => any;
        dragInactiveText?: string;
        dragActiveText?: string;
        acceptedFileTypes: Accept;
        maxFiles?: number;
    }

    export const Comp = (props: Props) => {
        const dragActiveText = props.dragActiveText ? props.dragActiveText : "Drop";
        const dragInactiveText = props.dragInactiveText ? props.dragInactiveText : "Drag and drop a file here or click to browse";

        const {
            getRootProps,
            getInputProps,
            isDragActive,
            isDragAccept,
            isDragReject
        } = useDropzone({
            accept: props.acceptedFileTypes,
            onDrop: props.onDrop,
            maxFiles: 1
        });

        return (
            <div
                className={`drag-n-drop-zone-default
                    ${isDragActive ? " drag-active" : ""}
                    ${isDragAccept ? " drag-accept" : ""}
                    ${isDragReject ? " drag-reject" : ""}`
                }
                {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        (<div><Icon name="download" />{dragActiveText}</div>) :
                        (<SpaceBetween direction="vertical" size={"s"}>
                            <Box textAlign="center">
                                <Icon name="upload" size="normal"/> {dragInactiveText}
                            </Box>
                            <TextContent>
                                <p>
                                    <small>
                                        Accepted file types: {AcceptedExtension.join(", ")}
                                    </small>
                                </p>
                            </TextContent>
                        </SpaceBetween>)
                }
            </div>
        );
    };
}
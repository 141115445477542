import { DocumentContent, Document, S3File, Container } from "@amzn/ask-legal-domain";
import { PropertyFilter, PropertyFilterProps, SpaceBetween } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useAPI2 } from "../../../hooks/api-hook";
import { useComponnentProps } from "../../../hooks/polaris-component-hooks";
import { propertyFilterI18nStrings } from "../../../i18n/propertyFilteringI18nString";
import { AppContext } from "../../../setup/context";
import { DocumentDownload } from "../../common/DocumentComp";
import { ContainerDescription } from "../ContainerDescription";
import { DocumentGroupExpandable } from "../document-group/DocumentGroupView";
import { documentsComparator } from "../../../utils/common-utils";

const DOCUMENT_FILTER_ENABLE_THRESHOLD = 25;

export const DocumentContentView = (props: {
    headerData?: Container.Margin;
    footerData?: Container.Margin;
    content: DocumentContent;
}) => {
    let count = 0;
    count += props.content.files.length;
    if (!!props.content.fileGroups && props.content.fileGroups.length > 0) {
        for (const group of props.content.fileGroups) {
            count += group.files.length;
        }
    }
    return (
        <React.Fragment>
            <ContainerDescription.View
                variant="Header"
                data={props.headerData}
            />
            {count >= DOCUMENT_FILTER_ENABLE_THRESHOLD ?
                <DocumentContentFilterView content={props.content} /> :
                <DocumentContentDefaultView content={props.content} />
            }
            <ContainerDescription.View
                variant="Footer"
                data={props.footerData}
            />
        </React.Fragment>
    );
};

export const DocumentContentDefaultView = (props: {
    content: DocumentContent;
}) => {
    const fileGroups = !!props.content.fileGroups ? props.content.fileGroups : [];
    return (
        <SpaceBetween direction="vertical" size="m">
            {props.content.files.sort(!props.content.manualSort ? documentsComparator : undefined).map(f => <DocumentDownload document={f} />)}
            <DocumentGroupExpandable
                defaultExpanded={true}
                fileGroups={fileGroups}
                FileViewComponent={DocumentDownload}
                manualSort={props.content.manualSort}
            />
        </SpaceBetween>
    );
};

export const DocumentContentFilterView = (props: {
    content: DocumentContent;
}) => {
    const context = React.useContext(AppContext);
    const fileGroups = !!props.content.fileGroups ? props.content.fileGroups : [];
    const [allDocuments, setAllDocuments] = React.useState<Document[]>([]);
    const [filteredDocs, setFilteredDocs] = React.useState<Document[]>([]);
    const [documentGroupExpanded, setDocumentGroupsExpanded] = React.useState<boolean>(false);
    const getLabelsByMultiResourcesRunner = useAPI2(context.getLabelAPI().getLabelsByMultiResources);

    const documentFilterQuery = useComponnentProps<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });

    const nameFilter = Builder<PropertyFilterProps.FilteringProperty>()
        .key("FileName")
        .operators(["="])
        .propertyLabel("File Name")
        .build();
    const labelFilter = Builder<PropertyFilterProps.FilteringProperty>()
        .key("Label")
        .operators(["="])
        .propertyLabel("Label")
        .build();
    const descriptionFilter = Builder<PropertyFilterProps.FilteringProperty>()
        .key("Description")
        .operators(["="])
        .propertyLabel("Description")
        .build();

    const documentFilter = useComponnentProps<PropertyFilterProps>({
        query: documentFilterQuery.props,
        onChange: e => documentFilterQuery.setProps(prev => ({
            ...prev,
            operation: e.detail.operation,
            tokens: e.detail.tokens
        })),
        filteringOptions: [],
        i18nStrings: propertyFilterI18nStrings,
        filteringProperties: [nameFilter, descriptionFilter, labelFilter],
        hideOperations: true,
    });

    React.useEffect(() => {
        documentFilter.setProps(prev => ({
            ...prev,
            query: documentFilterQuery.props
        }));
        if (documentFilterQuery.props.tokens.length === 0) {
            setDocumentGroupsExpanded(false);
        } else {
            setDocumentGroupsExpanded(true);
        }
    }, [documentFilterQuery.props]);

    React.useEffect(() => {
        // Filter documents
        if (!!documentFilterQuery.props && !!documentFilterQuery.props.tokens && !!allDocuments) {
            let filteredDocs = allDocuments.slice();
            for (const token of documentFilterQuery.props.tokens) {
                if (token.propertyKey === nameFilter.key) {
                    filteredDocs = filteredDocs.filter(file =>
                        file.filename.toLowerCase().trim().includes(token.value.toLowerCase().trim())
                    );
                } else if (token.propertyKey === descriptionFilter.key) {
                    filteredDocs = filteredDocs.filter(file =>
                        file.description.toLowerCase().trim().includes(token.value.toLowerCase().trim())
                    );
                } else if (token.propertyKey === labelFilter.key && getLabelsByMultiResourcesRunner.status === "Succeeded") {
                    filteredDocs = filteredDocs.filter(doc => {
                        const found = getLabelsByMultiResourcesRunner.output.output.find(r =>
                            r.resourceId === S3File.AskLegalFileKey.toString(doc.s3File.key)
                        );
                        if (found) {
                            return found.labels.find(l => l.name.toLowerCase().includes(token.value.toLowerCase()));
                        }
                        return false;
                    });
                }
            }
            setFilteredDocs(filteredDocs);
        }
    }, [
        allDocuments,
        documentFilterQuery.props,
        getLabelsByMultiResourcesRunner.status
    ]);

    React.useEffect(() => {
        const _allDocuments: Document[] = [];
        for (const file of props.content.files) {
            _allDocuments.push(file);
        }
        for (const group of fileGroups) {
            for (const file of group.files) {
                _allDocuments.push(file);
            }
        }
        setAllDocuments(_allDocuments);
        setFilteredDocs(_allDocuments);
        documentFilter.setProps(prev => ({
            ...prev,
            filteringOptions: [
                ...documentFilter.props.filteringOptions,
                ..._allDocuments.map(file => ({
                    propertyKey: nameFilter.key,
                    value: file.filename
                }))
            ]
        }));
        getLabelsByMultiResourcesRunner.invoke({
            resourceIds: _allDocuments.map(doc => S3File.AskLegalFileKey.toString(doc.s3File.key))
        });
    }, [props.content]);

    React.useEffect(() => {
        if (getLabelsByMultiResourcesRunner.status === "Succeeded") {
            const allLabelNames: Set<string> = new Set();
            for (const result of getLabelsByMultiResourcesRunner.output.output) {
                for (const label of result.labels) {
                    if (!label.deprecated) {
                        allLabelNames.add(label.name);
                    }
                }
            }
            documentFilter.setProps(prev => ({
                ...prev,
                filteringOptions: [
                    ...documentFilter.props.filteringOptions,
                    ...Array.from(allLabelNames).sort().map(labelName => ({
                        propertyKey: labelFilter.key,
                        value: labelName
                    }))
                ],
                filteringStatusType: "finished"
            }));
        } else {
            documentFilter.setProps(prev => ({
                ...prev,
                filteringLoadingText: "Loading...",
                filteringStatusType: "loading"
            }));
        }
    }, [getLabelsByMultiResourcesRunner.status]);

    return (
        <SpaceBetween direction="vertical" size="m">
            <PropertyFilter {...documentFilter.props} />
            {props.content.files.filter(file => !!filteredDocs.find(doc =>
                S3File.AskLegalFileKey.toString(doc.s3File.key) === S3File.AskLegalFileKey.toString(file.s3File.key)
            )).map(f => <DocumentDownload key={f.filename} document={f} />)}
            <DocumentGroupExpandable
                defaultExpanded={false}
                fileGroups={fileGroups}
                filteredDocs={filteredDocs}
                FileViewComponent={DocumentDownload}
                controlledExpand={documentGroupExpanded}
                manualSort={props.content.manualSort}
            />
        </SpaceBetween>
    );
};
import { Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { Preference } from "../../setup/preference";

const pathfinderDevs = [
    "shuhaoxi",
    "debabhik",
    "jojoj",
    "lihaijun",
    "ananyraj",
    "allindqu",
    "hbhalani",
    "rpulyala",
    "jamden"
  ];

export const LabSetting = () => {
    const context = React.useContext(AppContext);

    const [labs, setLabs] = React.useState<Set<Preference.Lab>>(new Set());

    React.useEffect(() => {
        const pref = context.getPreference();
        const labs = (pref && pref.labs) ? pref.labs : new Set<Preference.Lab>();
        setLabs(labs);
    }, []);

    const configLab = (lab: Preference.Lab, enabled: boolean) => {
        const pref = context.getPreference();
        const oldLabs = (pref && pref.labs) ? pref.labs : new Set<Preference.Lab>();
        const newSet = new Set(oldLabs);
        enabled ? newSet.add(lab) : newSet.delete(lab);
        context.setPreference(null, newSet);
        setLabs(newSet);
    };

    const getTogglesForPublicLabs = () => {
        return Object.keys(Preference.Lab).map((k: keyof typeof Preference.Lab) => {
            const lab = Preference.Lab[k];
            if (lab === Preference.Lab.DevMode) { return null; }
            return getToggleLab(lab, labs.has(lab) ? true : false);
        });
    };

    const getToggleLab = (lab: Preference.Lab, enabled: boolean) => {
        return (
            <Toggle
                onChange={({ detail }) => configLab(lab, detail.checked)}
                checked={enabled}>
                {lab}
            </Toggle>
        );
    };

    const devModeToggle = () => {
        return (
            <Toggle
                onChange={({ detail }) => configLab(Preference.Lab.DevMode, detail.checked)}
                checked={labs.has(Preference.Lab.DevMode) ? true : false}>
                {Preference.Lab.DevMode}
            </Toggle>
        );
    };

    return <div><br/>
        {getTogglesForPublicLabs()}
        {pathfinderDevs.includes(context.getPreference().userId) && devModeToggle()}
    </div>;
};
import { Identity } from "@amzn/ask-legal-domain";
import { SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import "../../styles/util-styles.scss";
import { BaseRedirectContent } from "./redirect-content/base-redirect-content";

export const PageDeprecated = (props: {
    pageOwner: Identity;
    forwardingUrl?: string;
}) => {
    const deprecatedPageContent = <SpaceBetween direction="vertical" size="xxl">
        {!props.forwardingUrl && <div className="font-ember center-text fs-34">Sorry, this page is deprecated.</div>}
        {!!props.forwardingUrl && <div className="font-ember center-text fs-34">Sorry, this page has moved. Please <a
            href={props.forwardingUrl}
            target="_blank"
            rel="noreferrer"
        >click here</a> to continue.</div>}
        {props.pageOwner?.name && <div className="font-ember">
            Contact the page owner <a
                href={`https://phonetool.amazon.com/users/${props.pageOwner.id}`}
                target="_blank"
                rel="noreferrer">{props.pageOwner.name}</a> for more information.
        </div>}
    </SpaceBetween>;

    return <BaseRedirectContent children={deprecatedPageContent} />;
};
import { APIOutput, Container, EntityRef } from "@amzn/ask-legal-domain";
import { Select, SelectProps } from "@amzn/awsui-components-react";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import * as React from "react";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";
import { AppContext } from "../../setup/context";
import { UIConstants } from "../../utils/common-utils";

export namespace ContainerSelect {
    export const Single = (props: {
        data: EntityRef[];
        selected: EntityRef;
        onSelectChange: (t: Container.Data) => void;
    }) => {
        const context = React.useContext(AppContext);
        const [containersMetadata, setContainersMetadata] = React.useState<Container.Data[]>([]);

        const toOption = (entityRef: EntityRef) => {
            const found = containersMetadata.find(c => c.id === entityRef.id);
            if (found) {
                return {
                    label: found.title,
                    value: found.id,
                    labelTag: `Version-${found.version}`
                };
            }
            return {
                label: entityRef.id,
                value: entityRef.id,
                labelTag: `Version-${entityRef.version}`
            };
        };

        const fromOption = (option: OptionDefinition) => {
            return containersMetadata.find(c => c.id === option.value);
        };

        const selectProps = useComponnentProps<SelectProps>({
            filteringType: "manual",
            placeholder: "Select from dropdown",
            options: props.data.map(d => toOption(d)),
            filteringPlaceholder: "Type to filter",
            selectedOption: props.selected ? { label: props.selected.id, value: props.selected.id } : null,
        });

        const batchLoadMeta = async () => {
            selectProps.setProps(prev => ({
                ...prev,
                statusType: "loading",
                loadingText: "Loading container titles..."
            }));
            try {
                const rawOutput = await context.getContainerAPI().batchLoadMetadata(props.data);
                const output = APIOutput.fromRaw<Container.Data[]>(rawOutput.data);
                if (output.isErr()) {
                    selectProps.setProps(prev => ({
                        ...prev,
                        statusType: "error",
                        errorText: output.err.message
                    }));
                } else {
                    setContainersMetadata(output.data);
                }
                selectProps.setProps(prev => ({
                    ...prev,
                    statusType: "finished",
                }));
            } catch (e) {
                let code = 500;
                let message = UIConstants.ERROR_MESSAGE;
                if (!!e.response && !!e.response.data && !!e.response.data.message) {
                    message = e.response.data.message;
                }
                if (!!e.response && !!e.response.status && e.response.status > 0) {
                    code = e.response.status;
                }
                selectProps.setProps(prev => ({
                    ...prev,
                    statusType: "error",
                    errorText: message
                }));
            }
        };

        React.useEffect(() => {
            selectProps.setProps(prev => ({
                ...prev,
                options: containersMetadata.map(o => toOption(o))
            }));
        }, [containersMetadata]);

        React.useEffect(() => {
            batchLoadMeta();
        }, [JSON.stringify(props.data)]);

        return <Select {...selectProps.props} onChange={e => {
            props.onSelectChange(fromOption(e.detail.selectedOption));
            selectProps.setProps(prev => ({
                ...prev,
                selectedOption: e.detail.selectedOption
            }));
        }}/>;
    };

    export const Multi = <T extends any>(props: {
        options: T[];
        selected: T[];
        onSelectChange: (option: T[]) => void;
        fromOption: (option: SelectProps.Option) => T;
        toOption: (t: T) => SelectProps.Option
    }) => {
        return <div>Not implemented</div>;
    };
}

import { Navigation } from "@amzn/ask-legal-domain";
import { Alert, AttributeEditor, AttributeEditorProps, Box, FormField, Input, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { NavigationModel } from "../../../model/navigation-model";
import { TeamSearch } from "../../common/TeamSearch";

export const ManageNavTabsContent = (props: {
    state: NavigationModel.UpdateNavigationState
}) => {
    const nameDefinition = Builder<AttributeEditorProps.FieldDefinition<Navigation.NavTab>>()
        .label("Name")
        .control((item: Navigation.NavTab, index: number) => (
            <FormField>
                <Input
                    value={item.name}
                    placeholder="Enter name..."
                    onChange={(e: { detail: { value: string; }; }) => {
                        props.state.updateTab(
                            item.id, e.detail.value, item.legalOnly
                        );
                    }}
                />
            </FormField>
        ))
        .build();

    const isLegalOnlyDef = Builder<AttributeEditorProps.FieldDefinition<Navigation.NavTab>>()
        .label("LegalOnly")
        .control((item: Navigation.NavTab, index: number) => (
            <FormField>
                <Toggle
                    onChange={({ detail }) =>
                        props.state.updateTab(
                            item.id, item.name, detail.checked, !detail.checked ? null : item.viewers
                        )
                    }
                    checked={item.legalOnly}
                    >
                    Legal Only
                </Toggle>
            </FormField>
        ))
        .build();

    const permissionDefinition =  Builder<AttributeEditorProps.FieldDefinition<Navigation.NavTab>>()
        .label("Permissions")
        .control((item: Navigation.NavTab, index: number) => (
            !!item.legalOnly ? <TeamSearch.Multiple
                onTeamSelectChange={(teams) => props.state.updateTab(item.id, item.name, item.legalOnly, teams)}
                initialSelected={item.viewers}
            /> : <Box variant="div">-</Box>
        ))
        .build();

    const def = [
        nameDefinition,
        isLegalOnlyDef
    ];

    const tabRecommendationAlert = <Alert
        statusIconAriaLabel="Info"
        header="User Experience Guideline"
        >
            To enhance user experience, we recommend limiting your navigation bar to 4 tabs or fewer.
            Additional tabs may compromise interface clarity and ease of use.
    </Alert>;

    return <SpaceBetween size="m">
        {tabRecommendationAlert}
        <AttributeEditor
            definition={def}
            onAddButtonClick={props.state.addEmptyTab}
            onRemoveButtonClick={(e) => {
                const target = props.state.navTabs.value[e.detail.itemIndex];
                props.state.removeNavItem(target);
            }}
            items={props.state.navTabs.value}
            addButtonText={"Add new Tab"}
            removeButtonText={"Remove"}
            empty="No tabs associated with the Navigation."
        />
    </SpaceBetween>;
};
import * as React from "react";
import { Header, SpaceBetween, Spinner, TextContent } from "@amzn/awsui-components-react";
import { useAPI, useAPI2 } from "../../hooks/api-hook";
import {
    EntityFactory,
    EntityPermissionOperationNames,
    EntityType,
    IEntityPermission,
    IMetrics,
    InstanceFactory,
    PageLibraryFactory
} from "@amzn/ask-legal-domain";
import { PageSection } from "../../components/page-section/PageSectionComp";
import { PageBaseLayout } from "../../components/page/PageBaseLayout";
import { AppContext } from "../../setup/context";
import { UIModel } from "../../model/ui-model";
import { FavorButton } from "../../components/common/FavorButton";
import { PageDeprecated } from "../redirects/PageDeprecated";
import { LegalOnlyBadge } from "../../components/common/LegalOnlyTag";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { PageNotFound } from "../redirects/PageNotFound";
import { CaptureAnalyticsComp } from "../../components/analytics/CaptureAnalyticsComp";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import "../../styles/component/layout/AskLegalLayout.scss";

export const PageLive = (props: {
    id: string;
    version?: number
}) => {
    const context = React.useContext(AppContext);
    const loadPage2Runner = useAPI(
        context.getPageAPI().load2
    );
    const loadPageHistoryRunner = useAPI(
        context.getPageAPI().loadVersion
    );
    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );
    const isFavoritedState = UIModel.State.use<boolean>({
        initialValue: false
    });
    const isAuthorizedRunner = useAPI2(context.getEntityPermissionAPI().isAuthorized);

    const [hasEditorPermission, setHasEditorPermission] = React.useState(false);

    React.useEffect(() => { // Check if user has Instance admin permission
        isAuthorizedRunner.invoke(IEntityPermission.IsAuthorizedInput.create(
            InstanceFactory.fromEntityId(props.id),
            EntityType.Instance,
            EntityPermissionOperationNames.CAN_ADMIN
        ));
    }, []);

    React.useEffect(() => {
        if (isAuthorizedRunner.status === "Succeeded") {
            if (isAuthorizedRunner.output) {
                setHasEditorPermission(isAuthorizedRunner.output);
            } else if (isAuthorizedRunner.input.type === EntityType.Instance) {
                // Check if user has PageLibrary editor permission only if user is not an admin
                isAuthorizedRunner.invoke(IEntityPermission.IsAuthorizedInput.create(
                    PageLibraryFactory.fromEntityId(props.id),
                    EntityType.PageLibrary,
                    EntityPermissionOperationNames.CAN_EDIT
                ));
            }
        }
    }, [isAuthorizedRunner.status]);

    React.useEffect(() => {
        if (loadPage2Runner.status === "Succeeded") {
            isFavoritedState.setValue(loadPage2Runner.data.output.isFavorited);
            recordViewHistoryRunner.submitRun(
                IMetrics.RecordViewHistoryInput.create({
                    url: `/page/${props.id}/live`,
                    entityId: props.id,
                    type: "LivePage"
                })
            );
        }
    }, [loadPage2Runner.status]);

    React.useEffect(() => {
        if (props.version === null || props.version === undefined) {
            loadPage2Runner.submitRun(
                props.id
            );
        } else {
            loadPageHistoryRunner.submitRun(
                EntityFactory.fromEntityAttributes(
                    props.id,
                    EntityType.Page,
                    props.version
                )
            );
        }
    }, [props.id, props.version]);

    if (!props.version) {
        // Render live page
        return (
            <React.Fragment>
                <React.Fragment>
                    {loadPage2Runner.status === "Succeeded" && !loadPage2Runner.data.output.page.deprecated && (
                        <div className="sherpa-page-header">
                            <SpaceBetween direction="vertical" size="xxxs">
                                <div className="sherpa-page-title">
                                    <h2>{loadPage2Runner.data.output.page.title}</h2>
                                    <FavorButton
                                        size="big"
                                        isFavoritedState={isFavoritedState}
                                        targetEntityRef={EntityFactory.toEntityRef(loadPage2Runner.data.output.page)}
                                    />
                                    <LegalOnlyBadge pageLibraryId={PageLibraryFactory.fromEntityId(props.id)}/>
                                </div>
                                <TextContent className="sherpa-page-title-date">
                                    <small>
                                        &nbsp;&nbsp;&nbsp;&nbsp;Updated on {
                                        DateTimeFormatter.toSimpleDate(
                                            loadPage2Runner.data.output.page.freshnessDate ?
                                                loadPage2Runner.data.output.page.freshnessDate :
                                                loadPage2Runner.data.output.page.lastModifiedDate,
                                            1
                                        )
                                    }
                                    </small>
                                </TextContent>
                            </SpaceBetween>
                        </div>
                    )}
                    {loadPage2Runner.status === "Running" &&
                        <span
                            className="awsui-util-status-inactive vertical-center horizontal-center"
                            style={{ paddingTop: "25px" }}
                        >
                            <Spinner size="large"/> Locating Page Resources...
                        </span>
                    }
                </React.Fragment>
                <React.Fragment>
                    {loadPage2Runner.status === "Error" && (
                        loadPage2Runner.data.err.code === 403 ?
                            <PermissionDenied entityId={props.id} accessType="LivePage"/> : <PageNotFound/>
                    )}
                    {loadPage2Runner.status === "Succeeded" && <>
                        {loadPage2Runner.data.output.page.deprecated ?
                            <PageDeprecated
                                pageOwner={loadPage2Runner.data.output.page.pageOwner}
                                forwardingUrl={loadPage2Runner.data.output.page.forwardingUrl}
                            /> :
                            <CaptureAnalyticsComp
                                entityRef={EntityFactory.toEntityRef(loadPage2Runner.data.output.page)}
                            >
                                <PageBaseLayout expandable
                                    mainContent={
                                        <PageSection.View
                                            section={loadPage2Runner.data.output.page.sections.find(s => s.location === "Main")}
                                            editor={hasEditorPermission}
                                        />
                                    }
                                    rightContent={
                                        <PageSection.View
                                            section={loadPage2Runner.data.output.page.sections.find(s => s.location === "Right")}
                                            editor={hasEditorPermission}
                                        />
                                    }
                                    hideRightSection={
                                        !!loadPage2Runner.data.output.page.sections.find(s => s.location === "Right") &&
                                        loadPage2Runner.data.output.page.sections.find(s => s.location === "Right").containers.length === 0
                                    }
                                />
                            </CaptureAnalyticsComp>
                        }
                    </>}
                </React.Fragment>
            </React.Fragment>
        );
    }
    // Render history page for specific version
    return (
        <React.Fragment>
            <React.Fragment>
                {loadPageHistoryRunner.status === "Succeeded" ? (
                    <Header variant="h2">{loadPageHistoryRunner.data.output.title}</Header>
                ) : (
                    <Header variant="h2">{props.id.split("-")[2]}</Header>
                )}
            </React.Fragment>
            <React.Fragment>
                {loadPageHistoryRunner.status === "Running" && <Spinner />}
                {loadPageHistoryRunner.status === "Error" && (
                    loadPageHistoryRunner.data.err.code === 403 ?
                        <PermissionDenied entityId={props.id} accessType="LivePage"/> : <PageNotFound/>
                )}
                {loadPageHistoryRunner.status === "Succeeded" &&
                    <PageBaseLayout expandable
                        mainContent={
                            <PageSection.View
                                section={loadPageHistoryRunner.data.output.sections.find(s => s.location === "Main")}
                            />
                        }
                        rightContent={
                            <PageSection.View
                                section={loadPageHistoryRunner.data.output.sections.find(s => s.location === "Right")}
                            />
                        }
                    />
                }
            </React.Fragment>
        </React.Fragment>
    );
};
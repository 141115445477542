import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { EntityExtraVersionRef, FieldDefinition } from "@amzn/altar-sds-client";

export namespace AdvancedListModel {
    export const COLUMN_INFO_CHAR_LIMIT: number = 200;

    type WorkflowModelSourceType = "draft" | "live";
    export class WorkflowUIModel {
        constructor(
            readonly id: string,
            readonly name: string,
            readonly sourceType: WorkflowModelSourceType,
            readonly ref?: EntityExtraVersionRef,
            readonly disabled?: boolean
        ) {}

        static create({ id, name, sourceType, ref, disabled }) {
            return new WorkflowUIModel(id, name, sourceType, ref, disabled);
        }
    }

    export function deleteWorkflowModel(advancedListContent: AdvancedListContent, workflow: WorkflowUIModel): AdvancedListContent {
        if (workflow.sourceType === "draft") {
            return {
                ...advancedListContent,
                upsertWorkflowList: advancedListContent.upsertWorkflowList.filter(
                    w => w.id !== workflow.id
                ),
                deleteWorkflowList: (advancedListContent.deleteWorkflowList || []).filter(
                    w => w.id !== workflow.id
                )
            };
        }
        return AdvancedListContent.deleteWorkflow({
            content: advancedListContent,
            workflowPayload: workflow
        });
    }

    export function sortAdvancedListFieldDefinitions(fieldDefinitions: FieldDefinition[], sortOrder: string[]) {
        return sortOrder?.length ?
            fieldDefinitions?.sort((a, b) =>
                sortOrder?.indexOf(a.fieldKey) -
                sortOrder.indexOf(b.fieldKey)
            ) :
            fieldDefinitions;
    }
}
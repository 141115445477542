import * as React from "react";
import { Modal, Spinner } from "@amzn/awsui-components-react";

export const LoadingSpinner = (props: { msg?: string }) => {
    return (
        <span className="awsui-util-status-inactive">
            <Spinner /> {props.msg ? props.msg : "Loading"}
        </span>
    );
};

export const LoadingModal = () => {
    return <Modal
        visible={true}
        header="Resource Loading...">
        <LoadingSpinner/>
    </Modal>;
};
import { InstanceFactory, PageFactory } from "@amzn/ask-legal-domain";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { DecisionTreeEdit } from "../../components/decision-tree-container/DecisionTreeEdit";
import useRoutedParamsWithContext from "../../setup/useRoutedParamsWithContext";

export const decisionTreeRoutes = [{
    path: "/decision-tree/:containerId/edit/",
    content: (props: RouteComponentProps) => <DecisionTreeEdit.Comp/>,
    breadcrumbs: (props: RouteComponentProps) => <BreadcrumbGroup items={[]} />,
    header: () => {
        let { containerId } = useRoutedParamsWithContext<{ containerId: string }>();
        const instanceId = InstanceFactory.fromEntityId(containerId);
        return <SherpaHeader headerType="Other" instanceId={instanceId} />;
    },
    footer: () => {
        let { containerId } = useRoutedParamsWithContext<{ containerId: string }>();
        return <SherpaFooter variant="AdminPortal" entityId={PageFactory.fromEntityId(containerId)} />;
    }
}];
import { Document, DocumentFactory, EntityType, IFile, S3File } from "@amzn/ask-legal-domain";
import { Popover, Spinner, StatusIndicator } from "@amzn/awsui-components-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { BootstrapComp } from "../common/BootstrapComp";
import { AppURLFactory } from "../../factory/app-url-factory";
import "../../styles/util-styles.scss";
const COPY_LINK = require("../../assets/copy-link.png").default;
const COPY_LINK_DARK = require("../../assets/copy-link-dark.png").default;

export const ContainerLinkCopyButton = (props: {
    containerId: string
}) => {
    return (<span onClick={() => navigator.clipboard.writeText(
        window.location.origin + AppURLFactory.getContainer(props.containerId)
    )}>
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">Container Link Copied</StatusIndicator>}>
            <span title="Copy container link" style={{ cursor: "pointer" }}>
                <img alt="Copy container link" height={"30px"} src={COPY_LINK} />
            </span>
        </Popover ></span >);
};

export const DocumentLinkCopyButton = (props: {
    document: Document;
}) => {
    const documentURLPath = AppURLFactory.getContainer(
        S3File.AskLegalFileKey.toEntityIdFromKey(props.document.s3File.key, EntityType.Container),
        {
            params: new Map([
                ["document", DocumentFactory.encodeDocumentName(props.document.filename)]
            ])
        }
    );
    return <div onClick={() => {
        navigator.clipboard.writeText(
            window.location.origin + documentURLPath
        );
    }}>
        <Popover
            size="small"
            position="top"
            triggerType="custom"
            dismissButton={false}
            content={<StatusIndicator type="success">Document Link Copied</StatusIndicator>}>
            <span title="Copy document link" style={{ cursor: "pointer" }}>
                <img alt="Copy document link" height={"30px"} src={COPY_LINK_DARK} />
            </span>
        </Popover>
    </div>;
};

export const DocumentPreviewButton = (props: {
    document: Document;
}) => {

    const context = React.useContext(AppContext);
    const getObjectPresignedUrlForPreviewRunner = useAPI(
        context.getFileAPI().getPresignedUrl
    );
    const [previewInProgress, setPreviewInProgress] = React.useState<boolean>(false);
    const [previewURL, setPreviewURL] = React.useState<string>();
    const [previewURLForIframe, setPreviewURLForIframe] = React.useState<string>();
    const [previewModalVisible, setPreviewModalVisible] = React.useState<boolean>(false);

    const fetchPreSignedPreviewURL = () => {
        setPreviewInProgress(true);
        getObjectPresignedUrlForPreviewRunner.submitRun(
            IFile.PresignedUrlInput.create({
                key: props.document.s3File.key,
                operation: IFile.PresignedOperation.Get,
                versionId: props.document.s3File.versionId,
                preview: true
            })
        );
    };

    React.useEffect(() => {
        if (getObjectPresignedUrlForPreviewRunner.status === "Running")
            return;
        if (getObjectPresignedUrlForPreviewRunner.status === "Succeeded") {
            setPreviewURL(getObjectPresignedUrlForPreviewRunner.data.output.presignedUrl);
        }
        setPreviewInProgress(false);
    }, [getObjectPresignedUrlForPreviewRunner.status]);

    React.useEffect(() => {
        if (!previewURL) {
            return;
        }
        setPreviewModalVisible(true);
    }, [previewURL]);

    React.useEffect(() => {
        if (previewModalVisible) {
            setPreviewURLForIframe(previewURL);
            getObjectPresignedUrlForPreviewRunner.resetStatus();
            setPreviewURL(undefined);
        }
    }, [previewModalVisible]);

    return <div >
        <div title="Preview document" style={{ cursor: "pointer" }} onClick={fetchPreSignedPreviewURL}>
            {previewInProgress ? <Spinner></Spinner> : <FontAwesomeIcon icon={faSearch} size="lg" />}
        </div>
        {(previewModalVisible) && (<BootstrapComp.CustomModal
            size="xl"
            visible={previewModalVisible} onDismiss={() => {
                setPreviewModalVisible(false);
            }}
            body={<embed
                title="File Preview"
                type="application/pdf"
                // Note - preview is only available for PDF file currently
                // https://code.amazon.com/packages/AskLegalDomain/blobs/b4d915f690c4bc95a7aa4b1a54ace0476a1074d5/--/src/domain/value-object/container-content/document-content.ts
                width="100%"
                height="900px"
                src={previewURLForIframe}
            />}
            header={props.document.filename}
        />
        )}
    </div>;
};
import { Identity } from "@amzn/ask-legal-domain";
import { ColumnLayout, Container, Header, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { IdentityLink } from "../common/IdentityLink";
import { UIField } from "../common/UIField";

export const UserProfileInfo = (props: {
    userId: Identity;
}) => {
    return (
        <React.Fragment>
            <Container
                header={
                    <Header
                        variant="h2"
                    >
                        Contact Info
                    </Header>
                }
            >
                <ColumnLayout columns={2}>
                    <SpaceBetween size="m">
                        <UIField.LabelField
                            label="User Name"
                            children={props.userId.name}
                        />
                        <UIField.LabelField
                            label="User Id"
                            children={
                                <IdentityLink id={props.userId.id} />
                            }
                        />
                    </SpaceBetween>
                    <SpaceBetween size="m">
                        <UIField.LabelField
                            label="Email address"
                            children={props.userId.email}
                        />
                    </SpaceBetween>
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
};
import { EntityPermission, IEntityPermission, Input } from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface EntityPermissionAPI {
    update(input: IEntityPermission.UpdateEntityPermissionInput): Promise<APIResponse<EntityPermission.Data>>;
    load(id: string): Promise<APIResponse<EntityPermission.Data>>;
    isAuthorized(input: IEntityPermission.IsAuthorizedInput): Promise<APIResponse<boolean>>;
}

export class EntityPermissionAPIImpl implements EntityPermissionAPI {
    constructor (private context: AppContextValue) {}

    async isAuthorized(input: IEntityPermission.IsAuthorizedInput): Promise<APIResponse<boolean>> {
        const response: APIResponse<boolean> = await RestAPI.post(
            "custom-domain",
            "/entity-permission/is-authorized",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update (
        input: IEntityPermission.UpdateEntityPermissionInput
    ): Promise<APIResponse<EntityPermission.Data>> {
        const response: APIResponse<EntityPermission.Data> = await RestAPI.post(
            "custom-domain",
            "/entity-permission/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load (id: string): Promise<APIResponse<EntityPermission.Data>> {
        const response: APIResponse<EntityPermission.Data> = await RestAPI.post(
            "custom-domain",
            "/entity-permission/load",
            toDefaultRequestPayload(id)
        );
        return response;
    }
}

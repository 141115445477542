import {
  Badge,
  Button,
  Checkbox,
  Popover,
  PropertyFilterProps,
  SelectProps,
  SpaceBetween,
  StatusIndicator,
  TableProps,
} from "@amzn/awsui-components-react";
import {
  AddFieldDefinitionInput,
  AdvancedListItemSearchProperty,
  FieldDefinition,
  AdvancedListSearchItem,
  FieldDefinitionDataType,
  IdentityRef,
  ItemHyperlink,
  PropertyToken,
  ChoiceOption,
  ComparisonOperator,
  ItemChoiceOption,
  ReservedFieldKey
} from "@amzn/altar-sds-client";
import { EmployeeIdentity, FieldConfiguration } from "@amzn/ask-legal-domain";
import {
  PropertyFilterOperator,
  PropertyFilterOperatorExtended,
  PropertyFilterToken
} from "@amzn/awsui-collection-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { Builder } from "builder-pattern";
import { PersonViewComp } from "../../components/advanced-list/view-components/PersonViewComp";
import { AdvancedListConstants } from "../../utils/advanced-list.constant";
import { DateFieldView } from "../../components/advanced-list/fields/DateFields/DateFieldView";
import { NumberFieldView } from "../../components/advanced-list/fields/NumberFields/NumberFieldView";
import { ChoiceFieldView } from "../../components/advanced-list/fields/ChoiceFields/ChoiceFieldView";
import { HyperlinkFieldView } from "../../components/advanced-list/fields/HyperlinkFields/HyperlinkFieldView";
import { AdvancedListFactory } from "../advanced-list-factory";
import { DateForm } from "../../components/common/DateForm";
import "./../../styles/component/legal-contact/view-layout.scss";

const PAPER_CLIP_ICON = require("../../assets/paper-clip-icon.png").default;

export namespace AdvancedListPolarisFactory {
  export function getFieldTypeName(type: FieldDefinitionDataType): string {
    if (type === FieldDefinitionDataType.IdentityRef) {
      return "Person";
    }
    if (type === FieldDefinitionDataType.string) {
      return "Text";
    }
    if (type === FieldDefinitionDataType.boolean) {
      return "Yes/No";
    }
    if (type === FieldDefinitionDataType.date) {
      return "Date";
    }
    if (type === FieldDefinitionDataType.number) {
      return "Number";
    }
    if (type === FieldDefinitionDataType.choice) {
      return "Choice";
    }
    if (type === FieldDefinitionDataType.hyperlink) {
      return "Hyperlink";
    }
    if (type === FieldDefinitionDataType.multiIdentityRef) {
      return "Multiple Person";
    }
    if (type === FieldDefinitionDataType.sequence) {
      return "Sequence";
    }
    return type as string;
  }

  export namespace Table {
    export const DisplayName = Builder<
      TableProps.ColumnDefinition<FieldDefinition | AddFieldDefinitionInput>
    >()
      .id("displayName")
      .header("Display Name")
      .cell((e) => `${e.displayName}${!e.fieldKey ? " (pending)" : ""}`)
      .minWidth("300px")
      .isRowHeader(true)
      .build();

    export const DataType = Builder<
      TableProps.ColumnDefinition<FieldDefinition | AddFieldDefinitionInput>
    >()
      .id("dataType")
      .header("Data Type")
      .cell((e) => getFieldTypeName(e.dataType as FieldDefinitionDataType))
      .minWidth("300px")
      .maxWidth("300px")
      .build();

    export function renderItemDisplay(
      value: any,
      fieldDefintion: FieldDefinition,
      configuration?: FieldConfiguration.Item,
    ): React.ReactNode {
      if (value == null) {
        return "-";
      }
      switch (fieldDefintion.dataType) {
        case FieldDefinitionDataType.string:
          return value;
        case FieldDefinitionDataType.boolean:
          return !!value
            ? AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
            : AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No;
        case FieldDefinitionDataType.date:
          return <DateFieldView
            dateFieldValue={value as string}
            fieldConfiguration={configuration as FieldConfiguration.Date}
          />;
        case FieldDefinitionDataType.IdentityRef:
          const identityRef = value as any as IdentityRef;
          return <PersonViewComp identityRef={identityRef} />;
        case FieldDefinitionDataType.number:
          return <NumberFieldView
            numberFieldValue={value as string}
            fieldConfiguration={configuration as FieldConfiguration.Number}
          />;
        case FieldDefinitionDataType.choice:
          return <ChoiceFieldView
              value={value as unknown as ItemChoiceOption[]}
              fieldDefinition={fieldDefintion}
              fieldConfiguration={configuration as FieldConfiguration.Choice}
          />;
        case FieldDefinitionDataType.hyperlink:
          return <HyperlinkFieldView
            value={value as any as ItemHyperlink}
          />;
        case FieldDefinitionDataType.multiIdentityRef:
          const multiIdentityRef = value as any as IdentityRef[];
          return <SpaceBetween size="xs">
            {multiIdentityRef.map(i => <PersonViewComp identityRef={i} />)}
          </SpaceBetween>;
        case FieldDefinitionDataType.sequence:
          return value as number;
        default:
          return "-";
      }
    }

    export function buildManageColumnDefinitions(params: {
      visibileFields?: string[];
      onShowOnLivePageChange?: (fieldKey: string, checked: boolean) => void;
      onRequiredStatusChange?: (fieldKey: string, checked: boolean) => void;
      onActionItemClicked?: (
        fieldDef: FieldDefinition,
        actionType: string
      ) => void;
    }): TableProps.ColumnDefinition<FieldDefinition>[] {
      const AdminDisplayName = Builder<
        TableProps.ColumnDefinition<FieldDefinition | AddFieldDefinitionInput>
      >()
        .id("displayName")
        .header("Display Name")
        .cell((e) => <SpaceBetween size="s" direction="horizontal">
          {e.displayName}
          {!!AdvancedListFactory.isReservedField(e.fieldKey) &&
            <Badge color="red">
              {AdvancedListFactory.RESERVED_FIELDS[e.fieldKey].displayName}
            </Badge>
          }
        </SpaceBetween>)
        .minWidth("300px")
        .isRowHeader(true)
        .build();

      const displayShowOnLivePage = (fieldDef: FieldDefinition) => {
        const showOnLivePage = params.visibileFields?.includes(fieldDef.fieldKey);
        return (
          <Checkbox
            checked={showOnLivePage}
            onChange={({ detail }) =>
              params.onShowOnLivePageChange(fieldDef.fieldKey, detail.checked)
            }
            disabled={
              fieldDef.fieldKey === ReservedFieldKey.ID
            }
          />
        );
      };

      const displayRequired = (fieldDef: FieldDefinition) => {
        return (
          <Checkbox
            checked={fieldDef.required}
            onChange={({ detail }) =>
              params.onRequiredStatusChange(fieldDef.fieldKey, detail.checked)
            }
            disabled={
              AdvancedListFactory.isReservedField(fieldDef.fieldKey) ||
              fieldDef.dataType === FieldDefinitionDataType.sequence
            }
          />
        );
      };

      const displayActions = (fieldDef: FieldDefinition) => {
        return (
          <SpaceBetween size="s" direction="horizontal">
            <Button
              variant="inline-icon"
              iconName="edit"
              onClick={(e) => {
                params.onActionItemClicked?.(fieldDef, "edit");
              }}
            >
              Edit
            </Button>
            <Button
              variant="inline-icon"
              iconName="remove"
              onClick={(e) => {
                params.onActionItemClicked?.(fieldDef, "delete");
              }}
              disabled={fieldDef.fieldKey === ReservedFieldKey.ID}
            >
              Delete
            </Button>
          </SpaceBetween>
        );
      };

      const ShowOnLivePage = Builder<
        TableProps.ColumnDefinition<FieldDefinition>
      >()
        .id("showOnLivePage")
        .header("Show on live page")
        .cell((item) => displayShowOnLivePage(item))
        .minWidth("300px")
        .maxWidth("300px")
        .build();

      const RequiredField = Builder<
        TableProps.ColumnDefinition<FieldDefinition>
      >()
        .id("isRequired")
        .header("Required")
        .cell((item) => displayRequired(item))
        .minWidth("300px")
        .maxWidth("300px")
        .build();

      const Actions = Builder<TableProps.ColumnDefinition<FieldDefinition>>()
        .id("actions")
        .header("Actions")
        .cell((item) => displayActions(item))
        .minWidth("300px")
        .maxWidth("300px")
        .build();

      const columnDefinitions: TableProps.ColumnDefinition<FieldDefinition>[] =
        [AdminDisplayName, DataType, ShowOnLivePage, RequiredField, Actions];

      return columnDefinitions;
    }

    export function addOrUpdateFieldDefinitions(
      existingFieldDefinitions: FieldDefinition[],
      newOrUpdatedFieldDefinitions: AddFieldDefinitionInput[]
    ) {
      const updatedFields = updateFieldDefinitions(
        existingFieldDefinitions,
        newOrUpdatedFieldDefinitions
      );
      const newFields = addFieldDefinitions(
        existingFieldDefinitions,
        newOrUpdatedFieldDefinitions
      );
      return [...updatedFields, ...newFields];
    }

    export function updateFieldDefinitions(
      existingFieldDefinitions: FieldDefinition[],
      updatedFieldDefinitions: AddFieldDefinitionInput[]
    ) {
      const updatedFields = updatedFieldDefinitions.filter(
        (def) =>
          existingFieldDefinitions.findIndex(
            (existing) => existing.fieldKey! === def.fieldKey!
          ) > -1
      );

      const updatedExistingFields: FieldDefinition[] =
        existingFieldDefinitions.map((existing) => {
          const foundUpdated = updatedFields.find(
            (updated) => updated.fieldKey! === existing.fieldKey!
          );
          if (!!foundUpdated) {
            return {
              ...existing,
              ...foundUpdated,
            };
          }
          return existing;
        });

      return updatedExistingFields;
    }

    export function addFieldDefinitions(
      existingFieldDefinitions: FieldDefinition[],
      newFieldDefinitions: AddFieldDefinitionInput[]
    ) {
      const newFields = newFieldDefinitions.filter(
        (def) =>
          existingFieldDefinitions.findIndex(
            (existing) => existing.fieldKey! === def.fieldKey!
          ) === -1
      );

      return newFields;
    }

    export function filterFieldDefinitionsByVisibility(
      fieldDefinitions: FieldDefinition[],
      visibileFields?: string[]
    ) {
      return fieldDefinitions.filter((def) => visibileFields.includes(def.fieldKey!));
    }

    export function buildColumnDefinitions(
      fieldDefinitions: FieldDefinition[],
      fieldConfigurations?: FieldConfiguration.Record,
      visibileFields?: string[]
    ): TableProps.ColumnDefinition<AdvancedListSearchItem>[] {
      const Attachment_Column: TableProps.ColumnDefinition<AdvancedListSearchItem> = {
        id: "attachment",
        header: "Attachments",
        cell: (item: AdvancedListSearchItem) => {
          return item.attachments?.length
            ? <img alt="attachment" height="18px" src={PAPER_CLIP_ICON} />
            : "";
        },
      };

      const fieldColumns = fieldDefinitions.map((fieldDefinition) =>
        AdvancedListPolarisFactory.Table.buildFieldColumnDefinition(
          fieldDefinition,
          fieldConfigurations?.[fieldDefinition.fieldKey],
          visibileFields
        )
      );

      return [...fieldColumns, Attachment_Column];
    }

    export function buildFieldColumnDefinition(
      f: FieldDefinition,
      c?: FieldConfiguration.Item,
      visibileFields?: string[]
    ): TableProps.ColumnDefinition<AdvancedListSearchItem> {
      const showOnLivePage = visibileFields?.includes(f.fieldKey);

      const ret: TableProps.ColumnDefinition<AdvancedListSearchItem> = {
        id: f.fieldKey,
        header: (showOnLivePage === false)
          ? <SpaceBetween direction="horizontal" size="xxxs">
            <>{f.displayName}</>
            <FontAwesomeIcon icon={faEyeSlash} size="sm" />
          </SpaceBetween>
          : `${f.displayName}`,
        cell: (item: AdvancedListSearchItem) => {
          let value = item.values.find((e) => e.key === f.fieldKey)?.value;
          return renderItemDisplay(value, f, c);
        },
        sortingField: getSortingField(f),
        maxWidth: "350px",
      };

      return ret;
    }

    export type ItemActionType = "view" | "edit" | "delete" | "link";

    export function buildActionColumnDefinition(
      onClicked?: (
        item: AdvancedListSearchItem,
        action: AdvancedListPolarisFactory.Table.ItemActionType
      ) => void
    ): TableProps.ColumnDefinition<AdvancedListSearchItem> {
      const ret: TableProps.ColumnDefinition<AdvancedListSearchItem> = {
        header: "Actions",
        id: "actions",
        minWidth: "127px",
        cell: (item: AdvancedListSearchItem) => (
          <SpaceBetween size="xxxs" direction="horizontal">
            <Button
              variant="inline-icon"
              ariaLabel="View Item"
              iconSvg={<FontAwesomeIcon icon={faEye} />}
              iconAlt="View"
              onClick={(e) => {
                onClicked?.(item, "view");
              }}
            >
              View
            </Button>
            <Button
              variant="inline-icon"
              ariaLabel="Edit Item"
              iconName="edit"
              onClick={(e) => {
                onClicked?.(item, "edit");
              }}
            >
              Edit
            </Button>
            <Button
              variant="inline-icon"
              ariaLabel="Delete Item"
              iconName="remove"
              onClick={(e) => {
                onClicked?.(item, "delete");
              }}
            >
              Delete
            </Button>
            <Popover
              dismissButton={false}
              position="top"
              size="small"
              triggerType="custom"
              content={
                <StatusIndicator type="success">
                  Copied link to item
                </StatusIndicator>
              }
            >
              <Button
                variant="inline-icon"
                ariaLabel="Copy Item Link"
                iconName="anchor-link"
                onClick={(e) => {
                  onClicked?.(item, "link");
                }}
              />
            </Popover>
          </SpaceBetween>
        ),
      };
      return ret;
    }

    export const convertPropertyFilterTokenToFilterQueryValue = (
      token: PropertyFilterToken,
      fieldDefinitions: FieldDefinition[]
    ) => {
      const fieldDefinition = fieldDefinitions.find(
        (d) => d.fieldKey === token.propertyKey
      );
      if (fieldDefinition.dataType === FieldDefinitionDataType.boolean) {
        return token.value ===
          AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
          ? true
          : false;
      } else {
        return token.value;
      }
    };

    export const getFilterOptions = (
      fieldDefinitions: FieldDefinition[]
    ): PropertyFilterProps.FilteringOption[] => {
      let options: PropertyFilterProps.FilteringOption[] = [];
      fieldDefinitions?.map((d) => {
        if (d.dataType === FieldDefinitionDataType.boolean) {
          options.push({
            propertyKey: d.fieldKey,
            value: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes,
          });
          options.push({
            propertyKey: d.fieldKey,
            value: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No,
          });
        } else if (d.dataType === FieldDefinitionDataType.choice) {
          d.choiceOptions.map(o => {
            options.push({
              propertyKey: d.fieldKey,
              value: o.displayValue
            });
          });
        }
      });
      return options;
    };

    export function getSortingField(fieldDefinition: FieldDefinition) {
      switch (fieldDefinition.dataType) {
        case FieldDefinitionDataType.string:
        case FieldDefinitionDataType.number:
        case FieldDefinitionDataType.date:
        case FieldDefinitionDataType.boolean:
        case FieldDefinitionDataType.sequence:
          return fieldDefinition.fieldKey;
        case FieldDefinitionDataType.IdentityRef:
          return `${fieldDefinition.fieldKey}.id`;
        default:
          return undefined;
      }
    }
  }

  export namespace PropertyFilter {
    export const FILTER_PARAM_PREFIX = "filter-";
    const NAME_HIDDEN_VALUE = "hiddenValue";
    export type EnrichedFilterToken<T> = PropertyFilterProps.Token & { [NAME_HIDDEN_VALUE]: EnrichedFilterOptionValue<T> };

    export interface EnrichedFilterOptionValue<T> {
      label: string;
      value: T;
    }

    export function convertEnhancedPropertyFilterTokenToFilterQueryValue(
      token: EnrichedFilterToken<any>,
      fieldDefinitions: FieldDefinition[]
    ): PropertyToken {
      if (token.propertyKey === AdvancedListItemSearchProperty.KEYWORD_SEARCH) {
        return {
          name: AdvancedListItemSearchProperty.KEYWORD_SEARCH,
          value: token[NAME_HIDDEN_VALUE].value as string[],
          operator: token.operator
        };
      }
      const fieldDefinition = fieldDefinitions.find((d) => d.fieldKey === token.propertyKey);
      if (fieldDefinition.dataType === FieldDefinitionDataType.boolean) {
        return {
          name: fieldDefinition.fieldKey,
          value:
            (token[NAME_HIDDEN_VALUE].value as AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES) ===
              AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
              ? true
              : false,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.IdentityRef) {
        return {
          name: `${fieldDefinition.fieldKey}.id`,
          value: (token[NAME_HIDDEN_VALUE].value as EmployeeIdentity).id,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.multiIdentityRef) {
        return {
          name: `${fieldDefinition.fieldKey}.id`,
          value: (token[NAME_HIDDEN_VALUE].value as EmployeeIdentity).id,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.choice) {
        return {
          name: `${fieldDefinition.fieldKey}.displayValue`,
          value: (token[NAME_HIDDEN_VALUE].value as ChoiceOption).displayValue,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.number) {
        return {
          name: `${fieldDefinition.fieldKey}`,
          value: token[NAME_HIDDEN_VALUE].value,
          operator: token.operator,
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.hyperlink) {
        return {
          name: `${fieldDefinition.fieldKey}.title`,
          value: token[NAME_HIDDEN_VALUE].value,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.sequence) {
        return {
          name: `${fieldDefinition.fieldKey}`,
          value: token[NAME_HIDDEN_VALUE].value,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.date) {
        return {
          name: `${fieldDefinition.fieldKey}`,
          value: token[NAME_HIDDEN_VALUE].value,
          operator: token.operator
        };
      } else if (fieldDefinition.dataType === FieldDefinitionDataType.string) {
        return {
          name: fieldDefinition.fieldKey,
          value:
            token[NAME_HIDDEN_VALUE].value,
          operator: token.operator
        };
      } else {
        throw new Error(`Unexpected field datatype ${fieldDefinition?.dataType}`);
      }
    }

    export const getComparisonOperatorLabel = (
      operator: ComparisonOperator
    ): string => {
      switch (operator) {
        case ComparisonOperator.CONTAINS:
          return "Contains";
        case ComparisonOperator.NOT_CONTAIN:
          return "Does not contain";
        case ComparisonOperator.EQUALS:
          return "Equals";
        case ComparisonOperator.NOT_EQUAL:
          return "Does not equal";
        case ComparisonOperator.GREATER_THAN_OR_EQUAL:
          return "Greater than or equal to";
        case ComparisonOperator.LESS_THAN_OR_EQUAL:
          return "Less than or equal to";
        default:
          return "-";
      }
    };

    export const getFilterOperatorsByType = (
      fieldDefinitionType: FieldDefinitionDataType | string
    ): ComparisonOperator[] => {
      let operators: ComparisonOperator[] = [];

      switch (fieldDefinitionType) {
        case FieldDefinitionDataType.string:
          operators = [
            ComparisonOperator.CONTAINS,
            ComparisonOperator.NOT_CONTAIN,
            ComparisonOperator.EQUALS,
            ComparisonOperator.NOT_EQUAL
          ];
          break;
        case FieldDefinitionDataType.choice:
          operators = [
            ComparisonOperator.CONTAINS,
            ComparisonOperator.NOT_CONTAIN
          ];
          break;
        case FieldDefinitionDataType.boolean:
        case FieldDefinitionDataType.IdentityRef:
          operators = [
            ComparisonOperator.EQUALS,
            ComparisonOperator.NOT_EQUAL
          ];
          break;
        case FieldDefinitionDataType.number:
        case FieldDefinitionDataType.sequence:
        case FieldDefinitionDataType.date:
          operators = [
            ComparisonOperator.EQUALS,
            ComparisonOperator.NOT_EQUAL,
            ComparisonOperator.GREATER_THAN_OR_EQUAL,
            ComparisonOperator.LESS_THAN_OR_EQUAL
          ];
          break;
        case FieldDefinitionDataType.multiIdentityRef:
          operators = [
            ComparisonOperator.CONTAINS,
            ComparisonOperator.NOT_CONTAIN,
            ComparisonOperator.EQUALS
          ];
          break;
        default:
          break;
      }
      return operators;
    };

    export const getFilterProperties = (
      fieldDefinitions: FieldDefinition[]
    ): PropertyFilterProps.FilteringProperty[] => {
      let options: PropertyFilterProps.FilteringProperty[] = [];
      fieldDefinitions?.map((d) => {
        let operators: (PropertyFilterOperator | PropertyFilterOperatorExtended<any>)[] = [":", "!:"];
        if (d.dataType === FieldDefinitionDataType.boolean) {
          operators = ["=", "!="];
        }
        if (d.dataType === FieldDefinitionDataType.date) {
          operators = ["=", "!=", ">=", "<="]
            .map(operator => ({
              operator,
              form: ({ value, onChange, filter }) => DateForm({ value, onChange, filter }),
              format: (tokenValue) => tokenValue,
              match: "date"
            }));
        }
        if (
          d.dataType === FieldDefinitionDataType.number ||
          d.dataType === FieldDefinitionDataType.sequence
        ) {
          operators = ["=", "!=", ">=", "<="];
        }
        if (d.dataType === FieldDefinitionDataType.IdentityRef) {
          operators = ["=", "!="];
        }
        if (d.dataType === FieldDefinitionDataType.string) {
          operators = [":", "!:", "=", "!="];
        }
        if (d.dataType === FieldDefinitionDataType.choice) {
          operators = [":", "!:"];
        }
        options.push({
          key: d.fieldKey,
          operators: operators,
          propertyLabel: d.displayName,
          groupValuesLabel: d.displayName
        });
      });
      return options;
    };

    export function getFilterOptions<T>(
      fieldDef: FieldDefinition,
      values: T[],
      toLabel?: (t: T) => string
    ): PropertyFilterProps.FilteringOption[] {
      let options: PropertyFilterProps.FilteringOption[] = [];

      values.forEach((e) => {
        const value: EnrichedFilterOptionValue<T> = {
          label: toLabel?.(e) || JSON.stringify(e),
          value: e
        };

        options.push({
          propertyKey: fieldDef.fieldKey,
          label: value.label,
          value: JSON.stringify(value)
        });
      });
      return options;
    }

    export function toTokens(
      tokens: ReadonlyArray<PropertyFilterProps.Token>,
      fieldDefinitions: FieldDefinition[]
    ): EnrichedFilterToken<any>[] {
      const freeTextTokens = [...tokens.filter((t) => !t.propertyKey)];
      const tokensWithPropertyKey = [...tokens.filter((t) => !!t.propertyKey)];

      let enrichedFreeTextToken: EnrichedFilterToken<any> | undefined;
      if (freeTextTokens.length) {
        enrichedFreeTextToken = {
          propertyKey: AdvancedListItemSearchProperty.KEYWORD_SEARCH,
          value: freeTextTokens[0].value,
          operator: ComparisonOperator.CONTAINS,
          [NAME_HIDDEN_VALUE]: {
            label: freeTextTokens[0].value,
            value: freeTextTokens[0].value.split(" ") as string[],
          }
        };
      }

      const enrichedPropertyKeyTokens =  tokensWithPropertyKey.map((e) => {
        if (Object.keys(e).includes(NAME_HIDDEN_VALUE)) return e as EnrichedFilterToken<any>;

        const fieldDef = fieldDefinitions.find((def) => def.fieldKey === e.propertyKey);
        if (
          fieldDef.dataType === FieldDefinitionDataType.IdentityRef ||
          fieldDef.dataType === FieldDefinitionDataType.multiIdentityRef ||
          fieldDef.dataType === FieldDefinitionDataType.boolean
        ) {
          const filterOptionValue: EnrichedFilterOptionValue<any> = JSON.parse(e.value);
          return {
            propertyKey: e.propertyKey,
            value: filterOptionValue.label,
            operator: e.operator,
            [NAME_HIDDEN_VALUE]: filterOptionValue
          };
        }
        if (fieldDef.dataType === FieldDefinitionDataType.choice) {
          return toChoiceToken(e);
        }
        return {
          propertyKey: e.propertyKey,
          value: e.value,
          operator: e.operator,
          [NAME_HIDDEN_VALUE]: {
            label: e.value,
            value: e.value
          }
        };
      });

      return [
        ...enrichedFreeTextToken
          ? [enrichedFreeTextToken]
          : [],
        ...enrichedPropertyKeyTokens
      ];
    }

    export function toChoiceToken(token: PropertyFilterToken) {
      let filterOptionValue: EnrichedFilterOptionValue<any>;
      try {
        filterOptionValue = JSON.parse(token.value);
      } catch (e) {
        filterOptionValue = {
          label: token.value,
          value: {
            displayValue: token.value
          }
        };
      }

      return {
        propertyKey: token.propertyKey,
        value: filterOptionValue.label,
        operator: token.operator,
        [NAME_HIDDEN_VALUE]: filterOptionValue
      };
    }
  }

  export namespace Select {
    export const getFieldTypeOptions = (types: FieldDefinitionDataType[]): SelectProps.Option[] => {
      return types.map((o) => getFieldTypeOption(o)).filter((e) => e);
    };

    export const getFieldTypeOption = (type?: FieldDefinitionDataType): SelectProps.Option | undefined => {
      if (!type) return;
      return { value: type, label: getFieldTypeName(type) };
    };
  }
}

import { KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { Checkbox, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { PageLibraryDisplay } from "../../pageLibrary/PageLibraryLink";

export const PageLibraryFilter = (props: {
    pageLibraryIds: string[];
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
}) => {
    return (
        <ExpandableSection defaultExpanded={false} header="Search in PageLibrary">
            <SpaceBetween direction="vertical" size="s">
                {props.pageLibraryIds.map(id => (
                    <Checkbox
                        checked={SearchInterface.SearchQueryInput.isFiltered(props.queryInputState.value, {
                            attribute: KendraConst.Attributes.Custom.PageLibraryId,
                            valueMap: {
                                value: id,
                                valueType: KendraConst.IndexAttributeType.STRING
                            }
                        })}
                        onChange={e => {
                            if (e.detail.checked) {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Custom.PageLibraryId,
                                        operation: "EqualsTo",
                                        valueMap: {
                                            value: id,
                                            valueType: KendraConst.IndexAttributeType.STRING
                                        }
                                    })
                                );
                            } else {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.removeFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Custom.PageLibraryId,
                                        operation: "EqualsTo",
                                        valueMap: {
                                            value: id,
                                            valueType: KendraConst.IndexAttributeType.STRING
                                        }
                                    })
                                );
                            }
                        }}><PageLibraryDisplay id={id}/>
                    </Checkbox>
                ))}
            </SpaceBetween>
        </ExpandableSection>
    );
};
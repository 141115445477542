import * as React from "react";
import { RESTRICTED_HYPER_LINK_ONLY_CONFIG } from "./tinymce-config/hyperlink-only-config";
import { TinyMCEBaseEditor } from "./TinyMCEBaseEditor";

export const RestrictedHyperLinkOnlyRichTextEdit = (props: {
    content: string;
    onChange: (content: string) => void;
}) => {
    return (
        <React.Fragment>
            <TinyMCEBaseEditor
                value={props.content}
                onChange={e => props.onChange(e)}
                config={RESTRICTED_HYPER_LINK_ONLY_CONFIG}
            />
        </React.Fragment>
    );
};

import differ = require("node-htmldiff");

export namespace HtmlDiffer {
  export interface Input {
    before: string;
    after: string;
    className?: string;
    dataPrefix?: string;
    atomicTags?: string;
  }

  export const diff = (input: Input): string => {
    return differ(
      input.before ? input.before : "",
      input.after ? input.after : "",
      input.className,
      input.dataPrefix,
      input.atomicTags
    );
  };

  export function getHTMLDiffString(modifiedString?: string, originalString?: string): string {
      const diffInput: Input = {
        before: originalString ? originalString : "",
        after: modifiedString ? modifiedString : "",
        atomicTags: "iframe,object,math,svg,script,img,video,head,style"
      };
      return diff(diffInput);
  }
}

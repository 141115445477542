import * as React from "react";
import { Header, Spinner, Tabs } from "@amzn/awsui-components-react";
import { PageLibraryOverviewTab } from "../../components/pageLibrary/PageLibraryOverview";
import { PageHome } from "../../components/page/PageHome";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { EntityFactory, PageLibrary } from "@amzn/ask-legal-domain";
import { EntityPermissionDetail } from "../../components/common/EntityPermissionComp";
import { PageDraftTable } from "../../components/page-draft/PageDraftsTable";
import { LegalOnlyBadge } from "../../components/common/LegalOnlyTag";
import { PageLibraryApprovalSetting } from "../../components/pageLibrary/PageLibraryApprovalSetting";
import { PermissionDenied } from "../redirects/PermissionDenied";
import { PageNotFound } from "../redirects/PageNotFound";

export const PageLibraryPortal = (props: { id: string }) => {

    const context = React.useContext(AppContext);
    const loadPageLibraryRunner = useAPI(
        context.getPageLibraryAPI().load
    );
    const pageLibraryDataState = UIModel.State.use<PageLibrary>({});
    const [activeTabId, setActiveTabId] = React.useState("overview");

    React.useEffect(() => {
        loadPageLibraryRunner.submitRun(
            props.id
        );
    }, [props.id]);

    React.useEffect(() => {
        if (loadPageLibraryRunner.status === "Succeeded") {
            pageLibraryDataState.setValue(loadPageLibraryRunner.data.output);
        }
    }, [loadPageLibraryRunner.status]);

    return (
        <React.Fragment>
            <Header id="pageHeader" variant="h1">Page Library: {props.id.split("-")[1]} <LegalOnlyBadge pageLibraryId={props.id}/></Header>
            <React.Fragment>
                {loadPageLibraryRunner.status === "Running" && <Spinner />}
                {loadPageLibraryRunner.status === "Error" && (
                    loadPageLibraryRunner.data.err.code === 403 ?
                        <PermissionDenied entityId={props.id} accessType="PageAdminPortal"/> : <PageNotFound/>
                )}
                {loadPageLibraryRunner.status === "Succeeded" && !pageLibraryDataState.value && <Spinner />}
                {loadPageLibraryRunner.status === "Succeeded" && !!pageLibraryDataState.value &&
                    <Tabs
                        tabs={[
                            {
                                label: "Overview",
                                id: "overview",
                                content: <PageLibraryOverviewTab state={pageLibraryDataState} />
                            },
                            {
                                label: "Permissions",
                                id: "permissions",
                                content: <EntityPermissionDetail.PreloadComp id={props.id} />
                            },
                            {
                                label: "Approval Setting",
                                id: "approvalSetting",
                                content: <PageLibraryApprovalSetting.MainComp pageLibraryId={pageLibraryDataState.value.id}/>
                            },
                            {
                                label: "All Drafts",
                                id: "allDrafts",
                                content: <PageDraftTable
                                    entityRef={EntityFactory.toEntityRef(pageLibraryDataState.value)}
                                />
                            },
                            {
                                label: "Pages",
                                id: "pages",
                                content: <PageHome id={props.id} />
                            }
                        ]}
                        activeTabId={activeTabId}
                        onChange={(e) => { setActiveTabId(e.detail.activeTabId); }}
                    />
                }
            </React.Fragment>
        </React.Fragment>
    );
};
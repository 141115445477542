import { Editor } from "@tinymce/tinymce-react";
import * as React from "react";

export const TinyMCEBaseEditor = (props: {
    value: string,
    onChange?: (v: string) => void,
    onInit?: (e: any) => void;
    config: any,
    disabled?: boolean
}) => {
    return <Editor
        tinymceScriptSrc="./node_modules/tinymce/tinymce.min.js"
        onInit={(_, e) => {
            if (!!props.onInit) props.onInit(e);
        }}
        disabled={props.disabled}
        value={props.value}
        onEditorChange={e => {
            if (props.onChange) props.onChange(e);
        }}
        init={props.config}
    />;
};
import { AccordionContent, Container } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AccordionContentView } from "../view-content/AccordionContentView";
import { ContainerDiffContentUtils } from "./Utils";

export const AccordionContentDiff = (props: {
    newContent: AccordionContent;
    oldContent: AccordionContent;
    newMetadata: Container.Data;
    oldMetadata?: Container.Data;
}) => {
    return <React.Fragment>
        <AccordionContentView
            content={
                ContainerDiffContentUtils.getAccordionContentDiff(
                    props.newContent,
                    props.oldContent
                )}
            headerData={
                ContainerDiffContentUtils.getMarginDiff(
                    props.newMetadata.headerDescription,
                    props.oldMetadata?.headerDescription
                )}
            footerData={
                ContainerDiffContentUtils.getMarginDiff(
                    props.newMetadata.footerDescription,
                    props.oldMetadata?.footerDescription
                )}
            diffMode={true}
        />
    </React.Fragment>;
};
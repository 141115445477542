import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  floatingButton: {
    appearance: "none",
    background: "none",
    border: "none",
    width: 40,
    height: 40,
    padding: 0,
    position: "fixed",
    bottom: 60,
    right: 60,
    zIndex: 9999,
    "&:hover": {
      cursor: "pointer",
    },
  },
});
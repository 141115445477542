import { EntityRef } from "@amzn/ask-legal-domain";
import {
    ExpandableSection,
    Header,
    TextContent
} from "@amzn/awsui-components-react";
import * as React from "react";
import { Link } from "react-scroll";
import { AppURLFactory } from "../../factory/app-url-factory";
import "../../styles/custom-container-header.scss";

export const PageOverviewSection = (props: {
    state: EntityRef[];
    containerNames: { id: string, name: string }[];
    containersDiffMap?: Map<string, string>;
}) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const [data, setData] = React.useState<JSX.Element[]>();

    React.useEffect(() => {
        if (Object.keys(props.containerNames).length === 0) return;
        const data = props.state.map(c => {
            const containerName = props.containerNames.find((container) => container.id === c.id);
            return (
              <TextContent key={c.id}>
                <Link
                  activeClass="active"
                  style={{ textDecoration: "none" }}
                  to={c.id}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  {props.containersDiffMap?.size > 0 && props.containersDiffMap.get(c.id) ? (
                    <b>{containerName ? containerName.name : c.id}</b>
                  ) : containerName ? (
                    containerName.name
                  ) : (
                    c.id
                  )}
                </Link>
                {props.containersDiffMap?.get(c.id) === "INSERTED" && (
                  <span className="red-label">
                    &nbsp;
                    <sup>
                      <em>new</em>
                    </sup>
                  </span>
                )}
                <br />
              </TextContent>
            );
        });
        setData(data);
    }, [props.containerNames, props.state]);

    return (
      <ExpandableSection
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
        variant="container"
        className="custom-container"
        headerText={<span className="dark-header-p-2">On This Page</span>}
      >
        {data}
      </ExpandableSection>
    );
};
import { APIOutput, AppError, EntityRef, EntityType, ErrorCode, Page } from "@amzn/ask-legal-domain";
import { Box, Button, Input, Modal, StatusIndicator } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "./ErrorFlashbar";
import { UIConstants } from "../../utils/common-utils";

export const DeleteModal = (props: {
    entityRef: EntityRef,
    onDeleted: () => void;
    onCancel: () => void;
    customHeader?: string;
    customMessage?: React.ReactNode;
}) => {
    const context = React.useContext(AppContext);
    const [error, setError] = React.useState<AppError>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [value, setValue] = React.useState("");

    const deleteResource = async () => {
        let api = null;
        if (props.entityRef.type === EntityType.Page) {
            api = context.getPageAPI().delete;
        } else if (props.entityRef.type === EntityType.PageLibrary) {
            api = context.getPageLibraryAPI().delete;
        } else {
            setError(AppError.create(`Unable to delete entity type: ${props.entityRef.type}`, 400));
            return;
        }
        setError(null);
        setLoading(true);
        try {
            const deleteOutput = await api(props.entityRef.id);
            const output = APIOutput.fromRaw<Page.Data>(deleteOutput.data);
            if (output.isErr()) {
                setError(output.err);
            } else {
                props.onDeleted();
            }
        } catch (e) {
            let code = 500;
            let message = UIConstants.ERROR_MESSAGE;
            if (!!e.response && !!e.response.data && !!e.response.data.message) {
                message = e.response.data.message;
            }
            if (!!e.response && !!e.response.status && e.response.status > 0) {
                code = e.response.status;
            }
            setError(AppError.create(message, code as ErrorCode));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            visible={true}
            size="medium"
            header={props.customHeader || "Delete Resource"}
            footer={
                <Box float="right">
                    <Button variant="link" loading={loading} onClick={props.onCancel}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        loading={loading}
                        onClick={deleteResource}
                        disabled={value !== "delete"}>
                        Confirm
                    </Button>
                </Box>
            }
            onDismiss={props.onCancel}>
            {error && <ErrorFlashbar error={error} />}
            <strong><StatusIndicator type="warning">WARNING! Deletion is permanent and the data is not recoverable.</StatusIndicator></strong><br/><br/>
            {props.customMessage &&
                <>
                {props.customMessage}
                <br/><br/>
                </>
            }
            Please type <strong><em>delete</em></strong> to confirm deletion:
            <Input
                placeholder="delete"
                onChange={({ detail }) => setValue(detail.value)}
                value={value}
            />
        </Modal>
    );
};
import * as React from "react";
import { Button } from "@amzn/awsui-components-react";

export const ButtonAnswer = (props: {
    nodeId: string;
    answer: string;
    onClickHandler: (nodeId: string) => void;
    loading: boolean;
    disabled: boolean;
    actionType?: "previous";
}) => {
    return <Button
        loading={props.loading}
        disabled={props.disabled}
        variant={props.actionType ? "normal" : "primary"}
        onClick={() => {
            props.onClickHandler(props.nodeId);
        }}
        iconName={props.actionType && "undo"}
    >
        {props.answer}
    </Button>;
};
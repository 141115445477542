import {
    IContainer,
    Page,
    EntityFactory,
    ContainerRef
} from "@amzn/ask-legal-domain";
import { ExpandableSection, Header, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { HashLink } from "react-router-hash-link";
import { AskLegalLayoutUtils } from "../layout/AskLegalLayout";
import { AppContext } from "../../setup/context";
import { FavorButton } from "../common/FavorButton";
import { UIModel } from "../../model/ui-model";
import { ViewContainerContent } from "./modal-content/ViewContainerContent";
import { ContainerLinkCopyButton } from "./ContainerButtons";
import { getQueryParameterMap, isCompareFeatureActivated, scrollWithDelayInMs } from "../../utils/common-utils";
import { ContainerDiffView } from "./ContainerDiffView";
import { AppURLFactory } from "../../factory/app-url-factory";
import "../../styles/custom-container-header.scss";
const BACK_TO_TOP = require("../../assets/back-to-top.png").default;
const EDIT_BUTTON = require("../../assets/edit.png").default;

export const ContainerView = (props: ContainerViewProps) => {
    const context = React.useContext(AppContext);
    const loadContainerRunner = useAPI(
        context.getContainerAPI().loadVersion
    );
    const loadContainerVersionRunner = useAPI(
        context.getContainerAPI().loadVersion
    );
    const [showDiff, setShowDiff] = React.useState<boolean>(false);
    const [liveContainerData, setLiveContainerData] = React.useState<any>();
    const [expanded, setExpanded] = React.useState(props.isExpanded);

    const isFavoritedState = UIModel.State.use<boolean>({
        initialValue: false
    });

    React.useEffect(() => {
        if (expanded !== props.isExpanded) {
            props.pageControlDispatch({
                type: "toggle",
                index: props.containerIndex
            });
        }
    }, [expanded]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            isFavoritedState.setValue(loadContainerRunner.data.output.isFavorited);
        }
    }, [loadContainerRunner.status]);

    React.useEffect(() => {
        loadContainerRunner.submitRun(props.containerRef);
        if (
            !props.showCompare ||
            !props.liveContainerRef ||
            props.liveContainerRef.version === props.containerRef.version ||
            !isCompareFeatureActivated(props.liveContainerRef.containerType)
        ) return;
        loadContainerVersionRunner.submitRun(props.liveContainerRef);
    }, [
        props.containerRef.id,
        props.containerRef.version
    ]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            !!props.onLoadComplete && props.onLoadComplete(loadContainerRunner.data.output);
            const queryParamMap = getQueryParameterMap();
            if (queryParamMap.get("container") === props.containerRef.id) {
                const targetContainerId = queryParamMap.get("container");
                scrollWithDelayInMs(700, targetContainerId);
            }
        }
    }, [loadContainerRunner.status]);

    React.useEffect(() => {
        if (loadContainerVersionRunner.status === "Succeeded") {
            setLiveContainerData(loadContainerVersionRunner.data.output);
        }
    }, [loadContainerVersionRunner.status]);
    return (
        <React.Fragment>
            {loadContainerRunner.status === "Succeeded" && (
                <ExpandableSection
                    variant="container"
                    onChange={(e) => setExpanded(e.detail.expanded)}
                    className="custom-container"
                    expanded={props.isExpanded}
                    headerText={
                        <>
                            <span className="dark-header-p-2">
                                {loadContainerRunner.data.output.containerMetadata.title}
                            </span>
                            {props.showFavoriteButton && (
                                <span>
                                    <FavorButton
                                        size="normal"
                                        isFavoritedState={isFavoritedState}
                                        targetEntityRef={EntityFactory.toEntityRef(
                                            loadContainerRunner.data.output.containerMetadata
                                        )}
                                    />
                                </span>
                            )}
                        </>
                    }
                    headerActions={
                        <SpaceBetween
                            size="xxxs"
                            direction="horizontal"
                            className="awsui-polaris-dark-mode horizontal-center"
                        >
                            {(!!liveContainerData ||
                                (!!props.showCompare &&
                                    !props.liveContainerRef &&
                                    isCompareFeatureActivated(props.containerRef.containerType))) && (
                                <span title="Compare with live">
                                    <Toggle onChange={({ detail }) => setShowDiff(detail.checked)} checked={showDiff}>
                                        <b>Compare</b>
                                    </Toggle>
                                </span>
                            )}
                            {!liveContainerData && loadContainerVersionRunner.status === "Running" && <Spinner />}
                            {!!props.showEditButton && (
                                <span>
                                    <a
                                        href={AppURLFactory.getContainer(props.containerRef.id, {
                                            type: "draft",
                                            params: new Map([["edit", "true"]])
                                        })}
                                        target="_blank"
                                    >
                                        <img
                                            alt="Edit container"
                                            title="Edit container"
                                            height="26px"
                                            src={EDIT_BUTTON}
                                        />
                                    </a>
                                </span>
                            )}
                            <span>
                                <ContainerLinkCopyButton containerId={props.containerRef.id} />
                            </span>
                            {props.location === "Main" && (
                                <span title="Navigate to top">
                                    <a style={{ textDecoration: "none" }}>
                                        <HashLink
                                            smoothed
                                            to={"#"}
                                            style={{ textDecoration: "none" }}
                                            scroll={(el) => AskLegalLayoutUtils.scrollWithOffset(el, -80)}
                                        >
                                            <span>
                                                <img alt="Back to top" height="28px" src={BACK_TO_TOP} />
                                            </span>
                                        </HashLink>
                                    </a>
                                </span>
                            )}
                        </SpaceBetween>
                    }
                >
                    <a id={loadContainerRunner.data.output.containerMetadata.id}>
                        {!showDiff && (
                            <ViewContainerContent
                                containerMetadata={loadContainerRunner.data.output.containerMetadata}
                                content={loadContainerRunner.data.output.loadedContent}
                                pageSection={props.location}
                                expandCollapseAllTrigger={props.expandCollapseAllTrigger}
                                showEditControls={props.showEditButton}
                            />
                        )}
                        {!!showDiff && (
                            <ContainerDiffView
                                newContainer={loadContainerRunner.data.output}
                                oldContainer={liveContainerData}
                            />
                        )}
                    </a>
                </ExpandableSection>
            )}
            {loadContainerRunner.status === "Error" && (
                <ExpandableSection
                    variant="container"
                    className="custom-container"
                    defaultExpanded={true}
                    headerText={<span className="dark-header-p-2">Error Loading Container</span>}
                >
                    Error Loading Container
                </ExpandableSection>
            )}
            {(loadContainerRunner.status === "Running" || loadContainerRunner.status === "New") && (
                <ExpandableSection
                    variant="container"
                    className="custom-container"
                    defaultExpanded={true}
                    headerText={<span className="dark-header-p-2">Loading...</span>}
                >
                    <Spinner />
                </ExpandableSection>
            )}
        </React.Fragment>
    );
};

export interface ContainerViewProps {
    containerRef: ContainerRef;
    location?: Page.SectionLocation;
    isExpanded: boolean;
    containerIndex: number;
    /**
     * If set to false, this container cannot be favorited, and favor button is hidden
     */
    showFavoriteButton?: boolean;
    /**
     * If set to false, hides compare diff toggle
     */
    showCompare?: boolean;
    /**
     * If set to true, show edit button that will navigate to draft page
     */
    showEditButton?: boolean;
    /**
     * Live container reference for computing diff
     */
    liveContainerRef?: ContainerRef;
    onLoadComplete?: (loadedData: IContainer.LoadContainerOutput) => void;
    pageControlDispatch: (params: { type: "expand-all" | "collapse-all" | "toggle", index: number }) => void;
    expandCollapseAllTrigger?: { value: string; };
}
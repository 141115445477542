import { FilterField, IPageDraft } from "@amzn/ask-legal-domain";
import { Badge, BadgeProps, PropertyFilterProps, SpaceBetween, StatusIndicator, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as  React from "react";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import { IdentityLink } from "../../components/common/IdentityLink";
import { PageLink } from "../../components/page/PageLink";
import { PageLibraryLink } from "../../components/pageLibrary/PageLibraryLink";
import { PageDraftModel } from "../../model/page-draft-model";
import { CommonPolarisFactory } from "./common-polaris-factory";
import { LegalOnlyBadgeComp } from "../../components/common/LegalOnlyTag";

export namespace PageDraftPolarisFactory {
    export namespace Table {
        export namespace PropertyFilter {
            export const Status = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.DraftStatus)
                .operators(["="])
                .propertyLabel("Status")
                .groupValuesLabel("Available statuses")
                .build();
            export const Name = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Name)
                .operators(["="])
                .propertyLabel("Title")
                .build();
            export const Deprecated = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Deprecated)
                .operators(["="])
                .propertyLabel("Is Deprecated?")
                .groupValuesLabel("Available Values")
                .build();
            export const Any = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Any)
                .operators(["="])
                .propertyLabel("Any")
                .build();
            export function toFilterAllDrafts() {
                return [
                    Any,
                    Status,
                    Name,
                    Deprecated
                ];
            }
        }
        export namespace ColumnDefinition {
            export const Id = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("id")
                .header("Id")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => item.id)
                .build();
            export const Title = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("title")
                .header("Title")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => (
                    <SpaceBetween direction="horizontal" size="s">
                        <PageLink.Admin
                            id={item.pageId}
                            externalTitle={item.newPageTitle}
                            tab="draftPage"
                        />
                        {!!item.pageLibraryLegalOnly && LegalOnlyBadgeComp}
                    </SpaceBetween>
                ))
                .build();
            export const Status = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("status")
                .header("Status")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => {
                    let badgeColor = "grey";
                    switch (item.status) {
                        case "DRAFT": badgeColor = "grey"; break;
                        case "DISCARDED": badgeColor = "grey"; break;
                        case "PENDING_APPROVAL": badgeColor = "grey"; break;
                        case "APPROVED": badgeColor = "green"; break;
                        case "PUBLISHED": badgeColor = "blue"; break;
                        case "REJECTED": badgeColor = "red"; break;
                        default: break;
                    }
                    return (
                    <Badge color={badgeColor as BadgeProps["color"]}>
                        {item.status}
                    </Badge>);
                })
                .build();
            export const LastModifiedCommit = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("lastModifiedCommit")
                .header("Last Modified Commit")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => item.lastModifiedCommit)
                .build();
            export const PageLibrary = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("pageLibraryId")
                .header("Page Library")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => (
                    <PageLibraryLink id={item.pageLibraryId} pageLibraryName={item.pageLibraryName}/>
                ))
                .build();
            export const PageOwner = Builder<TableProps.ColumnDefinition<IPageDraft.EntityPageDraftsByStatusOutput>>()
                .id("pageOwner")
                .header("Page Owner")
                .cell((item: IPageDraft.EntityPageDraftsByStatusOutput) => {
                    if (item.pageOwner) {
                        return <IdentityLink
                            id={item.pageOwner.id}
                            name={item.pageOwner.name}
                        />;
                    }
                })
                .build();
            export function toColumnDefinitionsAllDrafts() {
                return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                    Title,
                    PageOwner,
                    Status,
                ]);
            }
            export function toColumnDefinitionsAllDraftPageLibrary() {
                return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                    Title,
                    PageOwner,
                    PageLibrary,
                    Status,
                ]);
            }
            export function toColumnDefinitionsApprovals() {
                return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                    Title,
                    PageOwner,
                    Status,
                    LastModifiedCommit,
                ]);
            }
            export function toColumnDefinitionsApprovalsPageLibrary() {
                return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                    Title,
                    PageOwner,
                    PageLibrary,
                    Status,
                    LastModifiedCommit,
                ]);
            }
        }
    }
    export namespace VersionHistory {
        export namespace TableColumnDefinition {
            export const Version = Builder<TableProps.ColumnDefinition<PageDraftModel.VersionHistoryTableRowItemState>>()
                .id("version")
                .header("Draft Version")
                .cell((item: PageDraftModel.VersionHistoryTableRowItemState) => {
                    if (item.version) {
                        return <div>{item.version}</div>;
                    } else {
                        return <StatusIndicator type="loading">
                            Loading
                        </StatusIndicator>;
                    }
                })
                .build();
            export const LastModifiedBy = Builder<TableProps.ColumnDefinition<PageDraftModel.VersionHistoryTableRowItemState>>()
                .id("lastModifiedBy")
                .header("Last Modified By")
                .cell((item: PageDraftModel.VersionHistoryTableRowItemState) => {
                    if (item.data.value) {
                        return <IdentityLink
                            id={item.data.value.lastModifiedBy.id}
                            name={item.data.value.lastModifiedBy.name}
                        />;
                    } else {
                        return <StatusIndicator type="loading">
                            Loading
                        </StatusIndicator>;
                    }
                })
                .build();
            export const LastModifiedDate = Builder<TableProps.ColumnDefinition<PageDraftModel.VersionHistoryTableRowItemState>>()
                .id("lastModifiedDate")
                .header("Last Modified Date")
                .cell((item: PageDraftModel.VersionHistoryTableRowItemState) => {
                    if (item.data.value) {
                        return <div>{DateTimeFormatter.formatDate(item.data.value.lastModifiedDate)}</div>;
                    } else {
                        return <StatusIndicator type="loading">
                            Loading
                        </StatusIndicator>;
                    }
                })
                .build();
            export const LastModifiedComment = Builder<TableProps.ColumnDefinition<PageDraftModel.VersionHistoryTableRowItemState>>()
                .id("lastModifiedComment")
                .header("Last Modified Comment")
                .cell((item: PageDraftModel.VersionHistoryTableRowItemState) => {
                    if (item.data.value) {
                        return <div>{item.data.value.lastModifiedCommit}</div>;
                    } else {
                        return <StatusIndicator type="loading">
                            Loading
                        </StatusIndicator>;
                    }
                })
                .build();
            export const Status = Builder<TableProps.ColumnDefinition<PageDraftModel.VersionHistoryTableRowItemState>>()
                .id("status")
                .header("Draft Status")
                .cell((item: PageDraftModel.VersionHistoryTableRowItemState) => {
                    if (item.data.value) {
                        return <div>{item.data.value.status}</div>;
                    } else {
                        return <StatusIndicator type="loading">
                            Loading
                        </StatusIndicator>;
                    }
                })
                .build();
            export function toColumnDefinitions() {
                return [
                    Version,
                    LastModifiedBy,
                    LastModifiedDate,
                    LastModifiedComment,
                    Status
                ];
            }
        }
    }
}
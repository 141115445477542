import {
    Calendar,
    DateInput,
    DatePicker,
    FormField
} from "@amzn/awsui-components-react";
import * as React from "react";
import { calendarI18nStrings } from "../../i18n/calendarI18nStrings";
import { datePickerI18nStrings } from "../../i18n/datepickerI18nStrings";

export const DateForm = ({ filter, onChange, value }) => {
    // Using date picker for token edit flow.
    if (typeof filter === "undefined") {
        return (
            <FormField>
                <DatePicker
                    value={value ?? ""}
                    onChange={event => onChange(event.detail.value)}
                    placeholder="YYYY/MM/DD"
                    locale="en-GB"
                    i18nStrings={datePickerI18nStrings}
                />
            </FormField>
        );
    }
    // Using a combination of date input and calendar for token create flow
    // to optimise dropdown space and minimise user clicks.
    return (
        <div className="date-form">
            <FormField>
                <DateInput
                    value={value || ""}
                    onChange={event => onChange(event.detail.value)}
                    placeholder="YYYY/MM/DD"
                />
            </FormField>
            <Calendar
                value={value || ""}
                onChange={event => onChange(event.detail.value)}
                locale="en-GB"
                i18nStrings={calendarI18nStrings}
            />
        </div>
    );
};
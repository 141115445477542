import * as React from "react";
import { FieldConfiguration } from "@amzn/ask-legal-domain";
import { FieldDefinition, ItemChoiceOption } from "@amzn/altar-sds-client";
import { SpaceBetween } from "@amzn/awsui-components-react";

export const ChoiceFieldView = (props: {
    value: ItemChoiceOption[];
    fieldDefinition: FieldDefinition;
    fieldConfiguration?: FieldConfiguration.Choice;
}) => {
    const choiceOptions: ItemChoiceOption[] = [];

    props.value.forEach((itemChoiceOption) => {
        const preDefinedChoiceOption = props.fieldDefinition?.choiceOptions?.find((choiceOption) => choiceOption.key === itemChoiceOption.key);
        choiceOptions.push(preDefinedChoiceOption || itemChoiceOption);
    });

    if (props.fieldConfiguration?.type === FieldConfiguration.ChoiceType.MULTIPLE) {
        if (!choiceOptions.length) {
            return <>-</>;
        }
        return <SpaceBetween direction="vertical" size="xxs">
            {
                choiceOptions.map((option) => {
                    return (
                        <>
                            {
                                option.displayValue?.trim() || option.displayValue
                            }
                        </>
                    );
                })
            }
        </SpaceBetween>;
    }

    return (
        <div>{choiceOptions?.[0]?.displayValue}</div>
    );
};
export const DEV_MODE_CONFIG = {
    branding: false,
    height: 500,
    menubar: "edit view insert format tools table help",
    paste_data_images: true,
    convert_urls: false,
    resize: true,
    font_family_formats: "Amazon Ember=Amazon Ember; Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif;\
        Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif;\
        Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald;\
        Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times;\
        Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings",
    font_size_formats: "8px 12px 14px 16px 18px 24px 30px 36px 48px",
    content_style: `.mce-content-body { font-family:"Amazon Ember"; font-size: 14px; line-height: 1.4; overflow-x: auto; } ` +
        `.perm-content{
            border: 3px solid gray;
            margin: 20px 0px 20px 0px;
        }` +
        `.mce-item-anchor {
            background: none !important;
        }` +
        `.mce-content-body a{
            text-decoration: unset !important;
            color: rgb(5, 99, 193) !important;
        }`,
    custom_elements: "perm-content",
    extended_valid_elements: "amzn1\.abacus\.team.+,perm-content[role-id],div[*]",
    plugins: [
        "advlist", "autolink", "lists", "link", "image", "charmap", "preview",
        "anchor", "searchreplace", "visualblocks", "code", "fullscreen",
        "insertdatetime", "media", "table", "help", "wordcount",
        // "powerpaste" // Note: These two are premium plugins
    ],
    toolbar_sticky: true,
    block_formats: "Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Preformatted=pre",
    toolbar: "undo redo |\
        fontfamily fontsize |\
        bold italic underline strikethrough forecolor |\
        link image media table mergetags |\
        addcomment showcomments |\
        spellcheckdialog a11ycheck typography |\
        align lineheight |\
        checklist numlist bullist indent outdent |\
        emoticons charmap |\
        removeformat",
    table_column_resizing: "resizetable",
    entity_encoding: "raw",
    invalid_elements: "script",
    link_default_target: "_blank",
    promotion: false, // Hide premium upgrade promotion option button
};
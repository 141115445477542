import { AccordionGroupContent, Container } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AccordionGroupContentView } from "../view-content/AccordionGroupContentView";
import { ContainerDiffContentUtils } from "./Utils";

export const AccordionGroupContentDiff = (props: {
    newContent: AccordionGroupContent;
    oldContent: AccordionGroupContent;
    newMetadata: Container.Data;
    oldMetadata?: Container.Data;
}) => {
    return <React.Fragment>
        <AccordionGroupContentView
            content={
                ContainerDiffContentUtils.getAccordionGroupContentDiff(
                    props.newContent,
                    props.oldContent
                )
            }
            headerData={
                ContainerDiffContentUtils.getMarginDiff(
                    props.newMetadata.headerDescription,
                    props.oldMetadata?.headerDescription
                )
            }
            footerData={
                ContainerDiffContentUtils.getMarginDiff(
                    props.newMetadata.footerDescription,
                    props.oldMetadata?.footerDescription
                )
            }
            diffMode={true}
        />
    </React.Fragment>;
};
import { SearchInterface } from "@amzn/ask-legal-domain";
import { Autosuggest, AutosuggestProps, InputProps } from "@amzn/awsui-components-react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { getQueryParameterMap } from "../../utils/common-utils";
import "../../styles/home-page/instance-home-widgets.scss";

export const SearchInputBar = (props: {
    instanceId: string;
    disabled?: boolean;
}) => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const querySuggestionApiRunner = useAPI(
        context.getSearchAPI().querySuggestion
    );

    const [queryText, setQueryText] = React.useState<string>("");
    const [querySuggestions, setQuerySuggestions] = React.useState<AutosuggestProps.Option[]>([]);
    const [statusType, setStatusType] = React.useState<AutosuggestProps.StatusType>("pending");

    const search = (query?: string) => {
        if (!!props.instanceId && (queryText.length > 0 || query?.length > 0)) {
            history.push(`/search/${props.instanceId}?queryText=${!!query ? query : queryText}`);
        }
    };

    const getOptionsFromSuggestions = (
        suggestions: SearchInterface.QuerySuggestionOutput | undefined
    ): AutosuggestProps.Option[] => {
        const options: AutosuggestProps.Option[] = [];
        if (!suggestions?.output?.Suggestions) return options;
        suggestions.output.Suggestions.forEach(suggestion => {
            if (suggestion.Value?.Text?.Text) {
                options.push({
                    value: suggestion.Value.Text.Text
                });
            }
        });
        return options;
    };

    const onKeyDownHandler = (event: { detail: InputProps.KeyDetail }) => {
        if (
            event.detail.key === "Enter" &&
            queryText.length > 0
        ) search();
    };

    const onSelectHandler = (event: { detail: AutosuggestProps.SelectDetail }) => {
        setQueryText(event.detail.value);
        search(event.detail.value);
    };

    const onChangeHandler = (event: { detail: InputProps.ChangeDetail }) => {
        setQueryText(event.detail.value);
    };

    let runnerTimeOut: any;
    const onLoadHandler = async (e: any) => {
        if (e.detail.filteringText.length < 3) {
            setQuerySuggestions([]);
            return;
        }
        clearTimeout(runnerTimeOut);
        runnerTimeOut = setTimeout(() => {
            querySuggestionApiRunner.submitRun(
                SearchInterface.QuerySuggestionInput.create(
                    e.detail.filteringText,
                    props.instanceId
                )
            );
        }, 500);
    };

    React.useEffect(() => {
        // When component loads for search Results
        const windowHash = window.location.hash;
        if (windowHash.startsWith("#/search/")) {
            const queryParamMap = getQueryParameterMap();
            if (!!queryParamMap.get("queryText")) {
                setQueryText(queryParamMap.get("queryText"));
            }
        }
    }, []);

    React.useEffect(() => {
        if (querySuggestionApiRunner.status === "Succeeded") {
            setQuerySuggestions(
                getOptionsFromSuggestions(
                    querySuggestionApiRunner.data.output
                    )
            );
            setStatusType("finished");
        }
        // We don't show user when loading or error as these are just suggestions
    }, [querySuggestionApiRunner.status]);

    return (
        <Autosuggest
            className="search-bar-widget"
            value={queryText}
            placeholder="How can we help?"
            statusType={statusType}
            options={querySuggestions}
            filteringType="manual"
            enteredTextLabel={value => `Search for "${value}"`}
            onChange={onChangeHandler}
            onLoadItems={onLoadHandler}
            onKeyDown={onKeyDownHandler}
            onSelect={onSelectHandler}
            ariaLabel="Search for content"
            ariaRequired
            disabled={props.disabled}
        />);
};
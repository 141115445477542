import * as React from "react";
import { ExpandableSection, Flashbar } from "@amzn/awsui-components-react";
import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { AdvancedList, IdentityType } from "@amzn/altar-sds-client";

export const ChangeSummary = (props: {
    advancedList: AdvancedList;
    content: AdvancedListContent;
}) => {
    const permissionChanges = () => {
        let changes: string[] = [];
        if (props.content.updateAdvancedListPayload?.addEditorsList?.filter(i => i.type === IdentityType.TEAM).length) {
            changes.push(
                `${props.content.updateAdvancedListPayload?.addEditorsList?.filter(i => i.type === IdentityType.TEAM).length} editor team(s) will be added to the list`
            );
        }
        if (props.content.updateAdvancedListPayload?.addEditorsList?.filter(i => i.type === IdentityType.PERSON).length) {
            changes.push(
                `${props.content.updateAdvancedListPayload?.addEditorsList?.filter(i => i.type === IdentityType.PERSON).length} editor user(s) will be added to the list`
            );
        }
        if (props.content.updateAdvancedListPayload?.removeEditorsList?.filter(i => i.type === IdentityType.TEAM).length) {
            changes.push(
                `${props.content.updateAdvancedListPayload?.removeEditorsList?.filter(i => i.type === IdentityType.TEAM).length} editor team(s) will be removed from the list`
            );
        }
        if (props.content.updateAdvancedListPayload?.removeEditorsList?.filter(i => i.type === IdentityType.PERSON).length) {
            changes.push(
                `${props.content.updateAdvancedListPayload?.removeEditorsList?.filter(i => i.type === IdentityType.PERSON).length} editor user(s) will be removed from the list`
            );
        }
        if (props.content.updateAdvancedListPayload?.editorFieldKey) {
            changes.push(`Editor column "${props.advancedList?.fieldDefinitions?.find(
                f => f.fieldKey === props.content.updateAdvancedListPayload?.editorFieldKey
            )?.displayName ?? props.content.updateAdvancedListPayload?.editorFieldKey}" will be set for the list`);
        }
        if (props.content.updateAdvancedListPayload?.editorFieldKey === "" && props.advancedList?.editorFieldKey !== undefined) {
            changes.push("Editor column will be unset for the list");
        }
        return changes;
    };

    const columnChanges = () => {
        let changes: string[] = [];
        props.content.updateAdvancedListPayload.addFieldDefinitionsList
            ?.filter(f => props.advancedList?.fieldDefinitions.find(fd => fd.fieldKey === f.fieldKey))
            .map(f => {
                changes.push(`Column "${f.displayName}" will be updated`);
            });
        props.content.updateAdvancedListPayload.addFieldDefinitionsList
            ?.filter(f => !props.advancedList?.fieldDefinitions.find(fd => fd.fieldKey === f.fieldKey))
            .map(f => {
                changes.push(`Column "${f.displayName}" will be added to the list`);
            });
        if (props.content.updateAdvancedListPayload?.reorderFieldDefinitionSequence?.length) {
            changes.push(`Columns will be re-ordered`);
        }
        return changes;
    };

    const workflowChanges = () => {
        let changes: string[] = [];
        props.content.upsertWorkflowList
            ?.filter(wf => !!wf.ref)
            .map(wf => {
                changes.push(`Workflow "${wf.name}" will be updated`);
            });
        props.content.upsertWorkflowList
            ?.filter(wf => !wf.ref)
            .map(wf => {
                changes.push(`Workflow "${wf.name}" will be added`);
            });
        props.content.deleteWorkflowList?.map(wf => {
            changes.push(`Workflow "${wf.name}" will be deleted`);
        });
        return changes;
    };

    if (!props.content || (!props.content.updateAdvancedListPayload && !props.content.upsertWorkflowList)) {
        return <></>;
    }
    if (!columnChanges().length && !workflowChanges().length && !permissionChanges().length) {
        return <></>;
    }
    return (<Flashbar
        items={[{
            content: <>
                <p>You have made changes to this container. To make these changes visible on the live page, you must publish the page. Until then, the changes will only be visible in this draft.</p>
                <ExpandableSection
                    headerText="Change Summary"
                >
                    {!!columnChanges().length &&
                        (<>
                            <strong>Columns</strong>
                            {columnChanges().map(c => <ChangeBullet text={c} />)}
                        </>)
                    }
                    {!!workflowChanges().length &&
                        (<>
                            <strong>Workflows</strong>
                            {workflowChanges().map(c => <ChangeBullet text={c} />)}
                        </>)
                    }
                    {!!permissionChanges().length &&
                        (<>
                            <strong>Permissions</strong>
                            {permissionChanges().map(c => <ChangeBullet text={c} />)}
                        </>)
                    }
                </ExpandableSection>
            </>
        }]}
    />);
};


const ChangeBullet = (props: { text: string }) => {
    return <li>• {props.text}</li>;
};
import { SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { BaseRedirectContent } from "./redirect-content/base-redirect-content";
import "../../styles/util-styles.scss";

export const PageNotFound = () => {
    const pageNotFoundContent = <SpaceBetween direction="vertical" size="xxl">
        <div className="font-ember center-text">
            <h1>404: Page Not Found</h1>
        </div>
        <div className="font-ember center-text">
            The page "<b>{window.location.hash}</b>" does not exist.
        </div>
        <div className="font-ember center-text">
            Please make sure you have the right URL and try again.
        </div>
    </SpaceBetween>;

    return <BaseRedirectContent children={pageNotFoundContent} />;
};
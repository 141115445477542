import {
    ConfigurableOption,
    EntityRef,
    ILegalContact,
    Identity,
    LegalContact
} from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import { Builder } from "builder-pattern";
import { EntityVersionRef } from "@amzn/altar-sds-low-level-client";

export namespace LegalContactModel {
    export class UpdateState {
        legalContactUser: UIModel.State<Identity>;
        businessLeader: UIModel.State<Identity[]>;
        containerRef: EntityRef;
        entityVersionRef?: UIModel.State<EntityVersionRef>;
        scopeOptions: UIModel.State<ConfigurableOption[]>;
        teamOptions: UIModel.State<ConfigurableOption[]>;
        subTeamOptions: UIModel.State<ConfigurableOption[]>;
        init: (entity?: LegalContact.EntityData) => void;
        reset: () => void;
        isValid: () => boolean;

        static toUpdateInput (state: UpdateState): ILegalContact.UpdateLegalContactInput {
            return ILegalContact.UpdateLegalContactInput.create({
                entityVersionRef: state.entityVersionRef.value,
                containerId: state.containerRef.id,
                legalContactUser: state.legalContactUser.value,
                businessLeader: state.businessLeader.value,
                legalScopeOptions: state.scopeOptions.value,
                legalTeamOptions: state.teamOptions.value,
                legalSubTeamOptions: state.subTeamOptions.value
            });
        }

        static toCreateInput(state: UpdateState): ILegalContact.CreateLegalContactInput {
            return ILegalContact.CreateLegalContactInput.create({
                containerRef: state.containerRef,
                legalContactUser: state.legalContactUser.value,
                businessLeader: state.businessLeader.value,
                legalScopeOptions: state.scopeOptions.value,
                legalTeamOptions: state.teamOptions.value,
                legalSubTeamOptions: state.subTeamOptions.value
            });
        }

        static use(props: {
            containerRef: EntityRef;
        }): UpdateState {
            const legalContactUserField = UIModel.State.useRequired<Identity>({});
            const businessLeaderField = UIModel.State.useArrayWithConstraint<Identity>({
                initialValue: [],
                validation: (x) => {
                    if (x.length === 0) return "Value cannot be empty";
                    if (x.some(y => y.id === legalContactUserField.value?.id)) return "Legal contact cannot be their own business leader";
                }
            });
            const legalTeamOptionsField = UIModel.State.use<ConfigurableOption[]>({
                initialValue: [],
                validation: (t: ConfigurableOption[]) => {
                    if (!t?.length) return "Value cannot be empty";
                    if (!t?.every(team => team.displayValue?.trim().length > 0)) return "Team cannot be empty";
                }
            });
            const legalSubTeamOptionsField = UIModel.State.use<ConfigurableOption[]>({
                initialValue: [],
                validation: (t: ConfigurableOption[]) => {
                    if (!t?.length) return "Value cannot be empty";
                    if (!t?.every(subTeam => subTeam.displayValue?.trim().length > 0)) return "Sub-Team cannot be empty";
                }
            });
            const legalScopeOptionsField = UIModel.State.use<ConfigurableOption[]>({
                initialValue: [],
                validation: (t: ConfigurableOption[]) => {
                    if (!t?.length) return "Value cannot be empty";
                    if (!t?.every(scope => scope?.displayValue?.trim().length > 0)) return "Scope cannot be empty";
                }
            });
            const entityVersionRef = UIModel.State.use<EntityVersionRef>({});

            const isValid = () => {
                if (
                    !!legalContactUserField.errorText ||
                    !!businessLeaderField.errorText ||
                    !!legalScopeOptionsField.errorText ||
                    !!legalTeamOptionsField.errorText ||
                    !!legalSubTeamOptionsField.errorText
                ) return false;
                return true;
            };

            const init = (entity?: LegalContact.Record) => {
                if (entity) {
                    legalContactUserField.setValue(entity.legalContactUser);
                    businessLeaderField.setValue(entity.businessLeader);
                    entityVersionRef.setValue(entity.ref);
                    legalScopeOptionsField.setValue(entity.legalScopeOptions || []);
                    legalTeamOptionsField.setValue(entity.legalTeamOptions || []);
                    legalSubTeamOptionsField.setValue(entity.legalSubTeamOptions || []);
                } else {
                    legalContactUserField.setValue(null);
                    legalScopeOptionsField.setValue([]);
                    legalTeamOptionsField.setValue([]);
                    legalSubTeamOptionsField.setValue([]);
                    businessLeaderField.setValue([]);
                    entityVersionRef.setValue(undefined);
                }
            };

            const reset = () => {
                legalContactUserField.setValue(null);
                businessLeaderField.setValue([]);
                legalScopeOptionsField.setValue([]);
                legalTeamOptionsField.setValue([]);
                legalSubTeamOptionsField.setValue([]);
                entityVersionRef.setValue(undefined);
            };

            return Builder<UpdateState>()
                .legalContactUser(legalContactUserField)
                .businessLeader(businessLeaderField)
                .containerRef(props.containerRef)
                .entityVersionRef(entityVersionRef)
                .scopeOptions(legalScopeOptionsField)
                .teamOptions(legalTeamOptionsField)
                .subTeamOptions(legalSubTeamOptionsField)
                .init(init)
                .reset(reset)
                .isValid(isValid)
                .build();
        }
    }
}
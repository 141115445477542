import { FilterField, Role } from "@amzn/ask-legal-domain";
import { CollectionPreferencesProps, PropertyFilterProps, SpaceBetween, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { CommonPolarisFactory } from "./common-polaris-factory";
import { TeamIdentityLink } from "../../components/common/IdentityLink";

export namespace RolePolarisFactory {
    export namespace Table {
        export namespace PropertyFilter {
            export const Name = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Name)
                .operators(["="])
                .propertyLabel("Name")
                .build();
                export const Any = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Any)
                .operators(["="])
                .propertyLabel("Any")
                .build();
        }

        export const InstanceRef = Builder<TableProps.ColumnDefinition<Role.Data>>()
            .id("instanceRef")
            .header("Instance")
            .cell((item: Role.Data) => item.instanceId)
            .build();
        export const RoleMembers = Builder<TableProps.ColumnDefinition<Role.Data>>()
            .id("members")
            .header("Teams")
            .cell((item: Role.Data) => ( !item.members ? [] :
                <SpaceBetween size="s" direction="horizontal">
                    {item.members.map(t => <TeamIdentityLink id={t.teamId} name={t.teamName} />)}
                </SpaceBetween>
            ))
            .build();
        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                InstanceRef,
                RoleMembers
            ]);
        }
        export function toDefaultTablePreferences() {
            return Builder<CollectionPreferencesProps.Preferences<any>>()
                .pageSize(10)
                .visibleContent([
                    CommonPolarisFactory.Table.ColumnDefinition.Name.id,
                    RoleMembers.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id
                ])
                .build();
        }
    }
}
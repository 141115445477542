import { Navigation } from "@amzn/ask-legal-domain";
import { Box, Button, Flashbar, SpaceBetween } from "@amzn/awsui-components-react";
import { useContext } from "react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { NavigationModel } from "../../model/navigation-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { CommonNavigationModal } from "./CommonNavigationModal";
import { ManageNavTabsContent } from "./modal-content/ManageNavTabsContent";
import { NavTabComp } from "./NavTabDetail";

export const NavigationDetail = (props: {
    state: UIModel.State<Navigation.Data>
}) => {
    const context = useContext(AppContext);
    const updateNavigationRunner = useAPI(
        context.getNavigationAPI().update
    );

    const updateNavigationState = NavigationModel.UpdateNavigationState.use({
        template: props.state.value
    });

    const update = () => {
        updateNavigationRunner.submitRun(
            NavigationModel.UpdateNavigationState.toInput(updateNavigationState)
        );
    };

    React.useEffect(() => {
        if (updateNavigationRunner.status === "Succeeded") {
            updateNavigationState.setTemplate(updateNavigationRunner.data.output);
        }
    }, [updateNavigationRunner.status]);

    const [modalVisisble, setModalVisible] = React.useState<boolean>(false);

    return (
        <React.Fragment>
            {!!modalVisisble && (
                <CommonNavigationModal
                    header="Edit"
                    loading={false}
                    size="large"
                    child={<ManageNavTabsContent state={updateNavigationState}/>}
                    onComplete={() => setModalVisible(false)}
                    onCancel={() => setModalVisible(false)}
                />
            )}
            {updateNavigationState.isDirty() && (
                <Flashbar items={[{
                    type: "warning",
                    content: (
                        <React.Fragment>
                            <p>You have made edits to Navigation items</p>
                            <p>- you can continue to make more changes</p>
                            <p>- if you are done with the edits, please click <strong>Save</strong> button to commit the change</p>
                        </React.Fragment>
                    ),
                    dismissible: false,
                    action: (
                        <SpaceBetween direction="horizontal" size="s">
                            <Button
                                loading={updateNavigationRunner.status === "Running"}
                                onClick={() => updateNavigationState.reset()}>Reset</Button>
                            <Button
                                loading={updateNavigationRunner.status === "Running"}
                                variant="primary" onClick={update}>Save</Button>
                        </SpaceBetween>
                    )
                }]}/>
            )}
            <br/>
            <SpaceBetween direction="vertical" size="s">
                <Box float="left">
                    <Button variant="primary" onClick={() => setModalVisible(true)}>Manage Navigation Tabs</Button>
                </Box>
                {updateNavigationState.navTabs.value.map(tab =>
                    <NavTabComp.Detail navTab={tab} state={updateNavigationState}/>
                )}
            </SpaceBetween>
        </React.Fragment>
    );
};
import { Popover, SpaceBetween } from "@amzn/awsui-components-react";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { HashLink } from "react-router-hash-link";
import { AskLegalLayoutUtils } from "../../layout/AskLegalLayout";
import { ContainerLinkCopyButton } from "../ContainerButtons";
import { ContainerGeneralInfo } from "../ContainerGeneralInfo";
import { BaseContainerHeaderProps } from "./BaseContainerHeaderPropsInterface";
const BACK_TO_TOP = require("../../../assets/back-to-top.png").default;

export namespace ContainerEditModeHeader {
    export interface Props extends BaseContainerHeaderProps {}

    export const Text = (props: Props) => {
        return (
            <span className="dark-header-p-2">{props.containerMetaData.title}</span>
        );
    };

    export const Actions = (props: Props) => {
        return (
            <SpaceBetween direction="horizontal" size="xxxs" className="awsui-polaris-dark-mode horizontal-center">
                <span>
                    <ContainerLinkCopyButton containerId={props.containerMetaData.id} />
                </span>
                <span title="Navigate to top">
                    <a style={{ textDecoration: "none" }}>
                        <HashLink
                            smoothed
                            to={"#"}
                            style={{ textDecoration: "none" }}
                            scroll={(el) => AskLegalLayoutUtils.scrollWithOffset(el, -80)}
                        >
                            <span>
                                <img height="28px" src={BACK_TO_TOP} />
                            </span>
                        </HashLink>
                    </a>
                </span>
            </SpaceBetween>
        );
    };

    export const Info = (props: Props) => {
        return (
            <span>
                <Popover
                    position="bottom"
                    size="large"
                    triggerType="custom"
                    header={"Container Info"}
                    content={<ContainerGeneralInfo data={props.containerMetaData} />}
                >
                    <span title="info" style={{ color: "white", cursor: "pointer" }}>
                        <FontAwesomeIcon icon={faInfoCircle} size="1x" />
                    </span>
                </Popover>
            </span>
        );
    };
}

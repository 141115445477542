import { EntityFactory, Page } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    Grid,
    Icon,
    SpaceBetween,
    Spinner,
    StatusIndicator
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { DateTimeFormatter } from "../../utils/date-time-utils";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { IdentityLink } from "../common/IdentityLink";
import { PageDraftVersionHistory } from "../page-draft/PageDraftVersionHistoryTable";

export namespace PageVersionHistory {
    export const TableView = (props: {
        state: UIModel.State<Page.Data>
    }) => {
        const context = useContext(AppContext);
        const expandedVersionState = UIModel.State.use<number>({
            initialValue: -1
        });

        const loadAllVersionsRunner = useAPI(
            context.getPageAPI().loadAllVersions
        );

        useEffect(() => {
            loadAllVersionsRunner.submitRun(
                EntityFactory.toEntityRef(props.state.value)
            );
        }, [
            props.state.value.id,
            props.state.value.version
        ]);

        return (
            <Box margin={{left: "xxl", right: "xxl"}}>
                <SpaceBetween direction="vertical" size="s">
                    <Grid gridDefinition={[
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 }]}>
                        <div>Version number</div>
                        <div>Contributors</div>
                        <div>Reviewer</div>
                        <div>Published by</div>
                        <div>Published Date</div>
                        <div>Actions</div>
                    </Grid>
                    {loadAllVersionsRunner.status === "Error" && (
                        <ErrorFlashbar error={loadAllVersionsRunner.data.err}/>
                    )}
                    {loadAllVersionsRunner.status === "Running" && (
                        <Spinner></Spinner>
                    )}
                    {loadAllVersionsRunner.status === "Succeeded" && (
                        loadAllVersionsRunner.data.output.sort((p1, p2) => p2.version - p1.version).map(page =>
                            <PageVersionHistory.TableRow
                                data={page}
                                expanded={expandedVersionState}/>
                        )
                    )}
                </SpaceBetween>
            </Box>
        );
    };

    export const TableRow = (props: {
        data: Page.Data;
        expanded: UIModel.State<number>;
    }) => {
        const history = useHistory();
        const context = useContext(AppContext);
        const draftHistoryRunner = useAPI(
            context.getPageDraftAPI().loadVersion
        );

        useEffect(() => {
            draftHistoryRunner.submitRun(
                props.data.publishedFromDraft
            );
        }, [
            props.data.id,
            props.data.version
        ]);

        const buttonDropdownEventHandler = (event) => {
            switch (event.detail.id) {
                case ButtonDropdownItems.View.id:
                    history.push(`/page/${props.data.id}/live?version=${props.data.version}`);
                    break;
                default:
                    break;
            }
        };

        return (
            <React.Fragment>
                <SpaceBetween direction="vertical" size="s">
                    <Grid gridDefinition={[
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 },
                        { colspan: 2 }]}>
                        <div>
                            {props.expanded.value === props.data.version ? (
                                <Button
                                    disabled={props.data.version <= 1}
                                    variant="icon"
                                    iconName="caret-down-filled"
                                    onClick={() => props.expanded.setValue(-1)}
                                />
                            ) : (
                                <Button
                                    disabled={props.data.version <= 1}
                                    variant="icon"
                                    iconName="caret-right-filled"
                                    onClick={() => props.expanded.setValue(props.data.version)}
                                />
                            )}
                            {props.data.version}
                        </div>
                        <div>
                            {draftHistoryRunner.status === "Running" && <StatusIndicator type="loading">
                                Loading
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Error" && <StatusIndicator type="error">
                                Error
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Succeeded" && draftHistoryRunner.data.output.contributors
                                .map(c =>
                                    <IdentityLink id={c.id} name={c.name}/>
                                )}
                        </div>
                        <div>
                            {draftHistoryRunner.status === "Running" && <StatusIndicator type="loading">
                                Loading
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Error" && <StatusIndicator type="error">
                                Error
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Succeeded" && draftHistoryRunner.data.output.reviewedBy ? <IdentityLink
                                id={draftHistoryRunner.data.output.reviewedBy.id}
                                name={draftHistoryRunner.data.output.reviewedBy.name}
                            /> : <></>}
                        </div>
                        <div>
                            {draftHistoryRunner.status === "Running" && <StatusIndicator type="loading">
                                Loading
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Error" && <StatusIndicator type="error">
                                Error
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Succeeded" && draftHistoryRunner.data.output.publishedBy ? (
                                <IdentityLink
                                    id={draftHistoryRunner.data.output.publishedBy.id}
                                    name={draftHistoryRunner.data.output.publishedBy.name}
                                />) : <></>
                            }
                        </div>
                        <div>
                            {draftHistoryRunner.status === "Running" && <StatusIndicator type="loading">
                                Loading
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Error" && <StatusIndicator type="error">
                                Error
                            </StatusIndicator>}
                            {draftHistoryRunner.status === "Succeeded" &&
                                DateTimeFormatter.formatDate(draftHistoryRunner.data.output.lastModifiedDate)
                            }
                        </div>
                        <div>
                            <ButtonDropdown
                                disabled={props.data.version <= 1}
                                items={[ButtonDropdownItems.View]}
                                onItemClick={buttonDropdownEventHandler}>
                                <Icon name="menu" />
                            </ButtonDropdown>
                        </div>
                    </Grid>
                    {props.expanded.value === props.data.version && (
                        <PageDraftVersionHistory entityRef={props.data.publishedFromDraft}/>
                    )}
                </SpaceBetween>
            </React.Fragment>
        );
    };

    export namespace ButtonDropdownItems {
        export const View = Builder<ButtonDropdownProps.Item>()
            .id("view")
            .text("View")
            .build();
    }
}

import { AdminPreferences, IMetrics, KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { SearchResultPanel } from "../../components/search/ResultPanel";
import { LoadingSpinner } from "../../components/common/loading-spinner";
import { ErrorFlashbar } from "../../components/common/ErrorFlashbar";
import { UIModel } from "../../model/ui-model";
import { Alert, Button, Pagination, Spinner, TextContent } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { SmartSearchFilterView } from "../../components/search/search-components/SmartSearchFilterView";
import { PermissionDenied } from "../redirects/PermissionDenied";
import "../../styles/component/layout/SearchResultPageLayout.scss";
import "../../styles/component/layout/PageBaseLayout.scss";

export const SearchHome = (props: {
    instanceId: string;
    queryText?: string;
}) => {
    if (!props.queryText?.trim().length) {
        return <div className="top-margin-5p">
            <Alert
                type="error"
                header="Cannot search for an empty query"
                children={
                    <TextContent>
                        Please provide a valid search query and try again.
                    </TextContent>
                }
            />
        </div>;
    }

    const context = React.useContext(AppContext);
    const loadInstanceRunner = useAPI(
        context.getInstanceAPI().load
    );
    const searchRunner = useAPI(
        context.getSearchAPI().search
    );
    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );

    // Main state to track user search query
    const queryInputState = UIModel.State.use<SearchInterface.SearchQueryInput>({});

    const [authors, setAuthors] = React.useState<string[]>([]);
    const [pageLibs, setPageLibs] = React.useState<string[]>([]);
    const [instancePreferences, setInstancePreferences] = React.useState<AdminPreferences.InstancePreferences>();
    const [hasLegalOnlyAccess, setHasLegalOnlyAccess] = React.useState(false);

    const initAuthorOptions = () => {
        const allAuthors = [];
        searchRunner.data.output.result.ResultItems.forEach(r => {
            const author = r.DocumentAttributes.find(a => a.Key === KendraConst.Attributes.Default.Authors);
            if (author) {
                allAuthors.push(...author.Value.StringListValue);
            }
        });
        const authorDeDuplicate = new Set(allAuthors);
        setAuthors(Array.from(authorDeDuplicate));
    };

    const initPageLibraries = () => {
        const pageLibs = [];
        searchRunner.data.output.result.ResultItems.forEach(r => {
            const pageLib = r.DocumentAttributes.find(a =>
                a.Key === KendraConst.Attributes.Custom.PageLibraryId
            );
            if (pageLib) {
                pageLibs.push(pageLib.Value.StringValue);
            }
        });
        const pageLibraryDeDuplicate = new Set(pageLibs);
        setPageLibs(Array.from(pageLibraryDeDuplicate));
    };

    const getClientSidePaginatedSearchResults = () => {
        const currentPageIndex = queryInputState.value.pageNumber;
        const pageSize = queryInputState.value.pageSize;
        const startIndex = (currentPageIndex - 1) * pageSize;
        const endIndex = currentPageIndex * pageSize;
        const paginatedResults = searchRunner.data.output.result.ResultItems.slice(startIndex, endIndex);
        return SearchInterface.SearchQueryResponse.create({
            result: {
                ...searchRunner.data.output.result,
                ResultItems: paginatedResults
            },
            isLegalOnlyAuthorized: searchRunner.data.output.isLegalOnlyAuthorized
        });
    };

    const constructSearchQueryInput = (
        queryText: string,
        preferences?: AdminPreferences.InstancePreferences
    ): SearchInterface.SearchQueryInput => {
        let searchQuery = SearchInterface.SearchQueryInput.create({
            instanceId: props.instanceId,
            queryText: queryText
        }, {
            pageSize: 10,
            pageNumber: 1
        });
        if (preferences) {
            if (preferences.search.filterPrefs.showFileType.length > 0) {
                preferences.search.filterPrefs.showFileType.forEach(t => {
                    searchQuery = SearchInterface.SearchQueryInput.applyFilter(searchQuery, {
                        attribute: KendraConst.Attributes.Custom.ContentType,
                        operation: "EqualsTo",
                        valueMap: {
                            value: t,
                            valueType: KendraConst.IndexAttributeType.STRING
                        }
                    });
                });
            }
        }
        return searchQuery;
    };

    React.useEffect(() => {
        loadInstanceRunner.submitRun(props.instanceId);
    }, [props.instanceId]);

    // Initialize search preferences
    React.useEffect(() => {
        if (loadInstanceRunner.status !== "Succeeded") return;
        if (!!loadInstanceRunner.data.output.preferences) {
            setInstancePreferences(loadInstanceRunner.data.output.preferences);
        } else {
            setInstancePreferences(AdminPreferences.DEFAULT_ADMIN_PREFERENCES);
        }
    }, [loadInstanceRunner.status]);

    // Re-construct a brand new query input object if queryText changed
    React.useEffect(() => {
        if (!instancePreferences) return;
        queryInputState.setValue(
            constructSearchQueryInput(props.queryText, instancePreferences)
        );
        setAuthors([]);
        setPageLibs([]);
    }, [props.queryText, instancePreferences]);

    // When query input object changed, search again
    React.useEffect(() => {
        if (
            queryInputState.value &&
            queryInputState.value.queryText?.length > 0 &&
            instancePreferences
        ) {
            searchRunner.submitRun(queryInputState.value);
            recordViewHistoryRunner.submitRun(
                IMetrics.RecordViewHistoryInput.create({
                    url: `/search/${props.instanceId}?queryText=${props.queryText}`,
                    entityId: props.instanceId,
                    type: "Search"
                })
            );
        }
    }, [JSON.stringify(queryInputState.value)]);

    React.useEffect(() => {
        if (searchRunner.status === "Succeeded" && !!searchRunner.data.output) {
            if (authors.length === 0) {
                initAuthorOptions();
            }
            if (pageLibs.length === 0) {
                initPageLibraries();
            }
            setHasLegalOnlyAccess(searchRunner.data.output.isLegalOnlyAuthorized);
        }
    }, [searchRunner.status]);

    return (
        <React.Fragment>
            {loadInstanceRunner.status === "Error" &&
                (loadInstanceRunner.data.err.code === 403 ?
                    <PermissionDenied
                        entityId={props.instanceId}
                        accessType="HomePage"
                    /> :
                    <div className="top-margin-5p">
                        <ErrorFlashbar
                            error={loadInstanceRunner.data.err}
                            action={
                                <Button onClick={() => loadInstanceRunner.reload()}>
                                    Retry
                                </Button>
                            }
                        />
                    </div>
                )
            }
            {loadInstanceRunner.status === "Running" &&
                <div className="vertical-center top-margin-5p">
                    <Spinner />&nbsp;Loading search module...
                </div>
            }
            {loadInstanceRunner.status === "Succeeded" && queryInputState.value &&
                <div className="search-page-layout">
                    <div className="search-page-layout-filter">
                        <SmartSearchFilterView
                            preferences={instancePreferences.search}
                            queryInputState={queryInputState}
                            authors={authors}
                            pageLibraries={pageLibs}
                            hasLegalOnlyAccess={hasLegalOnlyAccess}
                        />
                    </div>
                    <div className="search-page-layout-result">
                        {searchRunner.status === "Running" &&
                            <div className="vertical-center top-margin-5p">
                                <LoadingSpinner msg={`Searching for ${queryInputState.value.queryText} ...`}/>
                            </div>
                        }
                        {searchRunner.status === "Error" && (
                            searchRunner.data.err.code === 403 ?
                                <PermissionDenied entityId={props.instanceId} accessType="HomePage"/> :
                                <ErrorFlashbar error={searchRunner.data.err}/>
                        )}
                        {searchRunner.status === "Succeeded" && !!searchRunner.data.output && (
                            <React.Fragment>
                                <h4>
                                    Results for <em>"{queryInputState.value.queryText}"</em>
                                </h4>
                                {!!searchRunner.data.output.result.SpellCorrectedQueries &&
                                    <div className="search-result-spell-correction">
                                        {searchRunner.data.output.result.SpellCorrectedQueries.map(spellCorrectionQuery => (
                                            <div>Do you mean "<a onClick={() => {
                                                queryInputState.setValue(
                                                    constructSearchQueryInput(spellCorrectionQuery.SuggestedQueryText)
                                                );
                                                // Update URL without triggering page reload
                                                history.replaceState(null, "", `/#/search/${props.instanceId}?queryText=${spellCorrectionQuery.SuggestedQueryText}`);
                                            }}>{spellCorrectionQuery.SuggestedQueryText}</a>"?</div>
                                        ))}
                                        <br />
                                    </div>
                                }
                                <SearchResultPanel
                                    results={getClientSidePaginatedSearchResults()}
                                    instanceId={props.instanceId}
                                    queryInputState={queryInputState}
                                />
                                <nav className="centered-pagination" aria-label="pagination">
                                    <Pagination
                                        ariaLabels={{
                                            nextPageLabel: "Next page",
                                            previousPageLabel: "Previous page",
                                            pageLabel: pageNumber =>
                                                `Page ${pageNumber} of all pages`
                                        }}
                                        currentPageIndex={queryInputState.value.pageNumber}
                                        onChange={({ detail }) =>
                                            queryInputState.setValue(
                                                Builder<SearchInterface.SearchQueryInput>(queryInputState.value)
                                                    .pageNumber(detail.currentPageIndex)
                                                    .build()
                                            )
                                        }
                                        pagesCount={Math.ceil(
                                            searchRunner.data.output.result.TotalNumberOfResults / queryInputState.value.pageSize
                                        )}
                                    />
                                </nav>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            }
        </React.Fragment>
    );
};
import { AccordionGroupContent, Container } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AccordionGroupView } from "../accordian-group/component";
import { ContainerDescription } from "../ContainerDescription";

export const AccordionGroupContentView = (props: {
    content: AccordionGroupContent;
    headerData?: Container.Margin;
    footerData?: Container.Margin;
    diffMode?: boolean;
    expandCollapseAllTrigger?: { value: string; };
}) => {
    return <React.Fragment>
        <ContainerDescription.View
            variant="Header"
            data={props.headerData}
            diffMode={props.diffMode}
        />
        <AccordionGroupView
            existingQuestions={props.content.contentValue}
            diffMode={props.diffMode}
            expandCollapseAllTrigger={props.expandCollapseAllTrigger}
        />
        <ContainerDescription.View
            variant="Footer"
            data={props.footerData}
            diffMode={props.diffMode}
        />
    </React.Fragment>;
};
import {
    EntityRef,
    IPage,
    EntityType,
    Identity,
    Page,
    EntityFactory,
    validUrlRegex
  } from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import * as React from "react";
import { Builder } from "builder-pattern";

export namespace PageModel {
    export const TITLE_CHAR_LIMIT = 50;
    export const DESCRIPTION_CHAR_LIMIT = 140;

    export class CreateState {
        name: UIModel.State<string>;
        title: UIModel.State<string>;
        description: UIModel.State<string>;
        pageOwner: UIModel.State<Identity>;
        pageLibraryRef: EntityRef;
        errorText: string;
        reset: () => void;

        static toInput (state: CreateState): IPage.CreatePageInput {
            return IPage.CreatePageInput.create({
                name: state.name.value,
                title: state.title.value,
                description: state.description.value,
                pageOwner: state.pageOwner.value,
                pageLibraryId: state.pageLibraryRef.id
            });
        }

        static use (props: {
            template?: IPage.CreatePageInput
            pageLibraryid: string
        }): CreateState {
            const name = UIModel.State.useWithRegexAndCharLimit({
                regex: new RegExp(/^[a-zA-Z0-9]+$/),
                characterLimit: TITLE_CHAR_LIMIT
            });
            const title = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: TITLE_CHAR_LIMIT });
            const description = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: DESCRIPTION_CHAR_LIMIT });
            const pageOwner = UIModel.State.useRequired<Identity>({});

            const reset = () => {
                name.setValue(null);
                title.setValue(null);
                description.setValue(null);
                pageOwner.setValue(null);
            };

            React.useEffect(() => {
                if (!props.template) return;
                name.setValue(props.template.name);
                title.setValue(props.template.title);
                description.setValue(props.template.description);
                pageOwner.setValue(props.template.pageOwner);
            }, []);

            return Builder<CreateState>(new CreateState())
                .name(name)
                .title(title)
                .description(description)
                .pageOwner(pageOwner)
                .pageLibraryRef(Builder<EntityRef>().id(props.pageLibraryid).type(EntityType.PageLibrary).version(1).build())
                .reset(reset)
                .build();
        }
    }

    export class UpdateMetadataState {
        targetRef: EntityRef;
        pageOwner: UIModel.State<Identity>;
        forwardingUrl: UIModel.State<string>;
        errorText: string;
        reset: () => void;
        isDirty: () => boolean;

        static toInput (state: UpdateMetadataState): IPage.UpdatePageMetadataInput {
            return IPage.UpdatePageMetadataInput.create({
                newPageOwner: state.pageOwner.value,
                forwardingUrl: state.forwardingUrl.value?.length === 0 ? undefined : state.forwardingUrl.value,
                targetRef: state.targetRef
            });
        }

        static use (props: {
            template: Page.Data
        }): UpdateMetadataState {
            const newPageOwner = UIModel.State.useRequired<Identity>({});
            const forwardingUrl = UIModel.State.useOptionalWithRegexValidation({ regex: validUrlRegex });

            const reset = () => {
                newPageOwner.setValue(props.template.pageOwner);
                forwardingUrl.setValue(props.template.forwardingUrl);
            };

            const isDirty = () => {
                if (
                    newPageOwner.value === props.template.pageOwner &&
                    forwardingUrl.value === props.template.forwardingUrl
                ) return false;
                return true;
            };

            React.useEffect(() => {
                newPageOwner.setValue(props.template.pageOwner);
                forwardingUrl.setValue(props.template.forwardingUrl);
            }, []);

            return Builder<UpdateMetadataState>(new UpdateMetadataState())
                .targetRef(EntityFactory.toEntityRef(props.template))
                .pageOwner(newPageOwner)
                .forwardingUrl(forwardingUrl)
                .reset(reset)
                .isDirty(isDirty)
                .build();
        }
    }

    export class DeprecatePageState {
        targetRef: EntityRef;
        forwardingUrl: UIModel.State<string>;
        reset: () => void;
        isDirty: () => boolean;

        static toInput (state: DeprecatePageState): IPage.DeprecatePageInput {
            return IPage.DeprecatePageInput.create(
                state.targetRef.id,
                state.forwardingUrl.value?.length === 0 ? undefined : state.forwardingUrl.value
            );
        }

        static use (props: {
            entityRef: EntityRef;
            forwardingUrl?: string;
        }): DeprecatePageState {
            const forwardingUrl = UIModel.State.useOptionalWithRegexValidation({ regex: validUrlRegex });

            const reset = () => {
                forwardingUrl.setValue(props.forwardingUrl);
            };

            const isDirty = () => {
                if (forwardingUrl.value === props.forwardingUrl) return false;
                return true;
            };

            React.useEffect(() => {
                if (!props.entityRef) return;
                forwardingUrl.setValue(props.forwardingUrl);
            }, []);

            return Builder<DeprecatePageState>(new DeprecatePageState())
                .targetRef(props.entityRef)
                .forwardingUrl(forwardingUrl)
                .reset(reset)
                .isDirty(isDirty)
                .build();
        }
    }
}
import { Instance } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ColumnLayout,
    Container,
    Form,
    Header,
    SpaceBetween
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useAPI } from "../../hooks/api-hook";
import { InstanceModel } from "../../model/instance-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { UIField } from "../common/UIField";

export const InstanceOverviewTab = (props: {
    state: UIModel.State<Instance>;
    viewOnly?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const updateInstanceRunner = useAPI(
        context.getInstanceAPI().update
    );
    const updateInstanceState = InstanceModel.UpdateState.use({
        template: props.state.value
    });
    const [editing, setEditing] = React.useState<boolean>(false);

    const update = () => {
        updateInstanceRunner.submitRun(
            InstanceModel.UpdateState.toInput(updateInstanceState)
        );
    };

    React.useEffect(() => {
        if (updateInstanceRunner.status === "Succeeded") {
            setEditing(false);
            props.state.setValue(updateInstanceRunner.data.output);
        } else if (updateInstanceRunner.status === "Error") {
            updateInstanceState.reset();
        }
    }, [updateInstanceRunner.status]);

    return (
        <React.Fragment>
            {updateInstanceRunner.status === "Error" && <ErrorFlashbar error={updateInstanceRunner.data.err} />}
            <Container
                header={
                    <Header
                        variant="h3"
                        actions={
                            !editing && !props.viewOnly &&
                            <Box float="right">
                                <Button
                                    onClick={() => {
                                        updateInstanceState.reset();
                                        setEditing(true);
                                    }}
                                    variant="primary">
                                    Edit
                                </Button>
                            </Box>
                        }>
                        Instance Details
                    </Header>
                }>
                <ColumnLayout columns={1}>
                    <Form
                        actions={editing && (
                            <div>
                                <Button onClick={() => {
                                    updateInstanceState.reset();
                                    setEditing(false);
                                }} variant="link">
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={update}
                                    disabled={
                                        !updateInstanceState.isDirty() ||
                                        updateInstanceState.nameField.errorText ||
                                        updateInstanceState.emailField.errorText ||
                                        updateInstanceState.permissionRequestTicketTemplateField.errorText ||
                                        updateInstanceState.feedbackTemplateField.errorText
                                    }
                                    loading={updateInstanceRunner.status === "Running"}>
                                    Submit
                                </Button>
                            </div>
                        )}>
                        <SpaceBetween direction="vertical" size="l">
                            <UIField.ValueField
                                value={props.state.value.id}
                                name="Instance Id"
                            />
                            <UIField.StateValueField
                                state={updateInstanceState.nameField}
                                name="Instance Name"
                                constraintText={editing && `Maximum ${InstanceModel.TITLE_CHAR_LIMIT} characters`}
                                editing={editing}
                            />
                            <UIField.StateValueField
                                state={updateInstanceState.emailField}
                                name="Owner Email"
                                editing={editing}
                            />
                            <UIField.StateValueField
                                state={updateInstanceState.permissionRequestTicketTemplateField}
                                constraintText={`Full URL including "https://" needs to be provided`}
                                name="SIM Ticket template URL for access request (Displayed on access denied page)"
                                editing={editing}
                            />
                            <UIField.StateValueField
                                state={updateInstanceState.feedbackTemplateField}
                                constraintText={`Full URL including "https://" needs to be provided`}
                                name="Feedback Ticket template URL - OPTIONAL"
                                editing={editing}
                            />
                        </SpaceBetween>
                    </Form>
                </ColumnLayout>
            </Container>
        </React.Fragment>
    );
};
import { RichTextContent } from "@amzn/ask-legal-domain";
import * as React from "react";
import { SmartRichTextView } from "../../common/RichTextEditor/SmartRichTextView";

export const RichTextContentView = (props: {
    content: RichTextContent;
}) => {
    return <SmartRichTextView
        content={props.content.contentValue}
    />;
};
import {
    Box,
    Button,
    ColumnLayout,
    FormField,
    Grid,
    Link,
    Multiselect,
    Popover,
    Select,
    SelectProps,
    SpaceBetween,
    Spinner,
    TokenGroup
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIField } from "../common/UIField";
import { UserSearch } from "../common/UserSearch";
import { LegalContactModel } from "../../model/legal-contact-model";
import { ConfirmLeavePageModal } from "../common/ConfirmLeavePageModal";
import {
    ConfigurableOption,
    ContainerRef,
    ILegalContact,
    PrimitiveDataFactory,
    WhosMyLawyerContent,
    WhosMyLawyerContentFactory
} from "@amzn/ask-legal-domain";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { Input } from "@amzn/awsui-components-react-v3";
import { UIModel } from "../../model/ui-model";
import { ErrorFlashbar } from "../common/ErrorFlashbar";

type OverrideableConfigurableOption = ConfigurableOption & { overrideDisplayValue?: string };

namespace LegalContactFormUtils {
    export const getDisplayValue = (option: OverrideableConfigurableOption) => {
        return option.overrideDisplayValue || option.displayValue || option.id;
    };

    export const toConfigurableOption = (selectOption: SelectProps.Option, options: OverrideableConfigurableOption[]): ConfigurableOption => {
        return {
            id: selectOption.value,
            displayValue: options.find(op => op.id === selectOption.value)?.displayValue || selectOption.value
        };
    };

    export const fromConfigurableOption = (option: OverrideableConfigurableOption): SelectProps.Option => {
        return {
            value: option.id,
            label: getDisplayValue(option)
        };
    };
}

export const EditLegalContactForm = (props: {
    state: LegalContactModel.UpdateState;
    content: WhosMyLawyerContent;
    liveContainerRef?: ContainerRef;
}) => {
    const [scopeOptions, setScopeOptions] = React.useState<OverrideableConfigurableOption[]>([]);
    const [teamOptions, setTeamOptions] = React.useState<OverrideableConfigurableOption[]>([]);
    const [subTeamOptions, setSubTeamOptions] = React.useState<OverrideableConfigurableOption[]>([]);
    const context = React.useContext(AppContext);
    const loadContainerVersionRunner = useAPI(
        context.getContainerAPI().loadVersion
    );
    const loadLegalContactRunner = useAPI(
        context.getLegalContactAPI().load
    );

    const getValidOptions = (
        live?: ConfigurableOption[],
        draft?: ConfigurableOption[]
    ): OverrideableConfigurableOption[] => {
        // Return options from live whose id exist in draft options
        if (!live) return [];
        if (!draft) return live;
        const validOptions: OverrideableConfigurableOption[] = [];
        live.forEach(liveOption => {
            const draftOption = draft.find(draftOption => (draftOption.id === liveOption.id) && !draftOption.deprecated);
            if (draftOption) {
                const op = {
                    ...liveOption,
                    overrideDisplayValue: (draftOption.displayValue !== liveOption.displayValue) && draftOption.displayValue
                };
                validOptions.push(op);
            }
        });
        return validOptions;
    };

    React.useEffect(() => {
        loadContainerVersionRunner.submitRun(
            props.liveContainerRef || props.state.containerRef
        );
        if (!props.state.entityVersionRef.value) return;
        loadLegalContactRunner.submitRun(
            ILegalContact.LoadLegalContactInput.create({
                containerRef: props.state.containerRef,
                entityRef: props.state.entityVersionRef.value.entityRef
            })
        );
    }, [props.state?.containerRef, props.liveContainerRef]);

    React.useEffect(() => {
        if (loadContainerVersionRunner.status === "Succeeded") {
            const dataOptions = (loadContainerVersionRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions;
            if (dataOptions) {
                setScopeOptions(
                    getValidOptions(
                        dataOptions?.scopeOptions,
                        props.content.dataOptions?.scopeOptions
                    )
                );
                setTeamOptions(
                    getValidOptions(
                        dataOptions?.teamOptions,
                        props.content.dataOptions?.teamOptions
                    )
                );
                setSubTeamOptions(
                    getValidOptions(
                        dataOptions?.subTeamOptions,
                        props.content.dataOptions?.subTeamOptions
                    )
                );
            }
        }
    }, [loadContainerVersionRunner.status]);

    React.useEffect(() => {
        if (loadLegalContactRunner.status === "Succeeded") {
            if (!loadLegalContactRunner.data.output) return;
            props.state.init(loadLegalContactRunner.data.output);
        }
    }, [loadLegalContactRunner.status]);

    if (loadContainerVersionRunner.status === "Running" || loadLegalContactRunner.status === "Running") {
        return <Box textAlign="center">
            <span><Spinner /> Loading ...</span>
        </Box>;
    }

    if (loadContainerVersionRunner.status === "Error" || loadLegalContactRunner.status === "Error") {
        return <SpaceBetween size="m">
            {loadContainerVersionRunner.status === "Error" && <ErrorFlashbar error={loadContainerVersionRunner.data.err} />}
            {loadLegalContactRunner.status === "Error" && <ErrorFlashbar error={loadLegalContactRunner.data.err} />}
        </SpaceBetween>;
    }

    return (
        <>
            <ConfirmLeavePageModal showModal={true} />
            <ColumnLayout columns={2}>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Legal Contact"
                        errorText={props.state.legalContactUser.errorText}
                        child={
                            <UserSearch.Single
                                selected={props.state.legalContactUser.value}
                                onUserSelectChange={(selected) => props.state.legalContactUser.setValue(selected)}
                            />
                        }
                    />
                </div>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Business Leader(s)"
                        helpInfo={<Popover
                            dismissAriaLabel="Close"
                            header="Legal Contact's Business Leaders"
                            triggerType="custom"
                            content={<Box color="text-body-secondary">
                                Select the L8 and/or L10 leaders of the organizations for which they are a primary business line legal contact.
                                <br /><br />
                                Business partners using this tool will receive recommendations on which legal contact to reach out to based on the leaders entered here.
                            </Box>}
                        >
                            <Link variant="info">Need Help?</Link>
                        </Popover>}
                        errorText={props.state.businessLeader.errorText}
                        child={
                            <UserSearch.Multiple
                                initialSelected={props.state.businessLeader.value}
                                onUserSelectChange={(selected) => props.state.businessLeader.setValue(selected)}
                            />
                        }
                    />
                </div>
                <UIField.CustomField
                    name="Legal Team"
                    errorText={props.state.teamOptions?.errorText}
                    child={
                        <Select
                            selectedOption={props.state.teamOptions?.value?.map((option) => ({
                                value: teamOptions.find(s => s.id === option.id)?.id,
                                label: LegalContactFormUtils.getDisplayValue(teamOptions.find(s => s.id === option.id) || option)
                            }))[0]}
                            loadingText="Loading..."
                            options={teamOptions
                                .filter(o => !o.deprecated)
                                .map(o => LegalContactFormUtils.fromConfigurableOption(o))
                                .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                            }
                            onChange={(d) => {
                                props.state.teamOptions.setValue(
                                    [LegalContactFormUtils.toConfigurableOption(d.detail.selectedOption, teamOptions)]
                                );
                            }}
                            filteringType="auto"
                            placeholder="Choose a team"
                            empty="No teams found"
                        />
                    }
                />
                <UIField.CustomField
                    name="Legal Sub-Team"
                    errorText={props.state.subTeamOptions?.errorText}
                    child={
                        <Multiselect
                            selectedOptions={props.state.subTeamOptions?.value?.map((option) => ({
                                value: subTeamOptions.find(s => s.id === option.id)?.id,
                                label: LegalContactFormUtils.getDisplayValue(subTeamOptions.find(s => s.id === option.id) || option)
                            }))}
                            loadingText="Loading..."
                            options={subTeamOptions
                                .filter(o => !o.deprecated)
                                .map(o => LegalContactFormUtils.fromConfigurableOption(o))
                                .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))
                            }
                            onChange={(d) => {
                                props.state.subTeamOptions.setValue(
                                    d.detail.selectedOptions.map(x => LegalContactFormUtils.toConfigurableOption(x, subTeamOptions))
                                );
                            }}
                            filteringType="auto"
                            placeholder="Choose a sub-team"
                            empty="No sub-teams found"
                        />
                    }
                />
                <UIField.CustomField
                    name="Legal Scope"
                    errorText={props.state.scopeOptions?.errorText}
                    child={
                        <ConfigurableOptionMultiselectWithFreeText
                            configurableOptionsEditState={props.state.scopeOptions}
                            options={scopeOptions}
                            loadingOptions={false}
                        />
                    }
                />
            </ColumnLayout>
        </>
    );
};

const ConfigurableOptionMultiselectWithFreeText = (props: {
    configurableOptionsEditState: UIModel.State<ConfigurableOption[]>;
    options: OverrideableConfigurableOption[];
    loadingOptions: boolean;
}) => {
    const [adhocMode, setAdhocMode] = React.useState(false);
    const addOptionState = UIModel.State.use<string>({
        initialValue: "",
        validation: (t: string) => {
            if (t.length > WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT) {
                return `Length must not exceed ${WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT}`;
            }
            if (props.configurableOptionsEditState?.value?.find(o => o.displayValue === t)) {
                return "Option must be unique";
            }
        }
    });

    const onItemAdd = () => {
        const configOption: ConfigurableOption = {
            id: `${WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX}${PrimitiveDataFactory.id()}`,
            displayValue: addOptionState.value.trim(),
            deprecated: false
        };
        props.configurableOptionsEditState.setValue(
            [...props.configurableOptionsEditState.value, configOption]
        );
        addOptionState.setValue("");
        setAdhocMode(false);
    };

    return <>
        {adhocMode ? (
            <SpaceBetween size="s">
                <FormField errorText={addOptionState.errorText}>
                    <Grid
                        gridDefinition={[
                            { colspan: 10 },
                            { colspan: 2 }
                        ]}
                    >
                        <Input
                            placeholder={"Enter another option"}
                            value={addOptionState.value}
                            autoFocus
                            onChange={({ detail }) => addOptionState.setValue(detail.value)}
                            onKeyDown={({ detail }) => {
                                if (detail.keyCode === 13) {
                                    onItemAdd();
                                }
                            }}
                        />
                        <SpaceBetween size="s" direction="horizontal">
                            <Button
                                variant="icon"
                                iconName="close"
                                onClick={() => {
                                    setAdhocMode(false);
                                    addOptionState.setValue("");
                                }}
                            />
                            <Button
                                disabled={
                                    props.options?.find(o => o.displayValue === addOptionState.value) ||
                                    addOptionState.value.length > WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_CHAR_LIMIT
                                }
                                variant="icon"
                                iconName="add-plus"
                                onClick={onItemAdd}
                            />
                        </SpaceBetween>
                    </Grid>
                </FormField>
            </SpaceBetween>
        ) : (
            <Multiselect
                selectedOptions={props.configurableOptionsEditState?.value
                    ?.filter(o => !o.id?.includes(WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX))
                    .map((o) => ({
                        value: props.options.find(s => s.id === o.id)?.id,
                        label: LegalContactFormUtils.getDisplayValue(props.options.find(s => s.id === o.id) || o)
                    }))
                }
                disabled={props.loadingOptions}
                statusType={props.loadingOptions && "loading"}
                loadingText="Loading..."
                options={
                    [
                        ...props.options
                            .filter(o => !o.deprecated)
                            .map(o => LegalContactFormUtils.fromConfigurableOption(o))
                            .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
                        { label: "Other", value: "other", description: "Add your own scope" }
                    ]
                }
                onChange={(d) => {
                    if (d.detail.selectedOptions.find(o => o.value === "other")) {
                        setAdhocMode(true);
                    } else {
                        props.configurableOptionsEditState.setValue([
                            ...d.detail.selectedOptions.map(o => LegalContactFormUtils.toConfigurableOption(o, props.options)),
                            ...props.configurableOptionsEditState?.value?.filter(v => v.id.includes(WhosMyLawyerContentFactory.CONFIGURABLE_OPTION_ADHOC_PREFIX))
                        ]);
                    }
                }}
                hideTokens={true}
                filteringType="auto"
                placeholder="Choose all applicable scope options"
                empty="No scopes found"
            />
        )}
        <TokenGroup
            items={props.configurableOptionsEditState?.value?.map((o) => {
                return {
                    value: o.id,
                    label: LegalContactFormUtils.getDisplayValue(props.options.find(x => x.id === o.id) || o)
                };
            })}
            onDismiss={({ detail: { itemIndex } }) => {
                props.configurableOptionsEditState?.setValue([
                    ...props.configurableOptionsEditState?.value?.slice(0, itemIndex),
                    ...props.configurableOptionsEditState?.value?.slice(itemIndex + 1)
                ]);
            }}
        />
    </>;
};
export namespace Preference {
    export const KEY = "preference";

    export enum Lab {
        InPlaceContainerEditing = "In-place Container Editing",
        DevMode = "Development Mode",
        WMLSearch = "WMLSearch",
        UIRefresh = "UI Refresh",
        UIHeaderV2 = "UI Header V2",
        ALViews = "AL Views",
    }
    export class Value {
        userId: string;
        instanceId: string;
        labs: Set<Lab>;
    }
}
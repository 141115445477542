import { QuestionnaireGroupComponent } from "./index";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { QuestionnaireGroup } from "@amzn/ask-legal-domain";
import { QuestionnaireGroupModel } from "../../../model/questionnaire-group-model";

export const AccordionGroupView = (props: {
  existingQuestions: QuestionnaireGroup.Data;
  diffMode?: boolean;
  expandCollapseAllTrigger?: { value: string; };
}) => {
  return (
    <>
      <QuestionnaireGroupComponent.View.Main
        inputState={props.existingQuestions.questionGroups}
        diffMode={props.diffMode}
        expandCollapseAllTrigger={props.expandCollapseAllTrigger}
      />
    </>
  );
};

export const AccordionGroupEdit = (props: {
  existingQuestions: QuestionnaireGroup.Data;
  onChange?: (content: QuestionnaireGroup.QuestionGroup[]) => void;
}) => {
  const updateQuestionnaireGroupState =
    QuestionnaireGroupModel.UI.Update.UpdateQuestionnaireGroupState.use({
      template: props.existingQuestions,
    });

  const editingQuestionnaireGroupState = UIModel.State.useRequired<boolean>({
    initialValue: true,
  });

  React.useEffect(() => {
    props.onChange ? props.onChange(updateQuestionnaireGroupState.questionnaireState) : null;
  }, [updateQuestionnaireGroupState.questionnaireState]);

  return (
    <>
      <QuestionnaireGroupComponent.Edit.Main
        inputState={updateQuestionnaireGroupState}
        editingQuestionnaireGroupState={editingQuestionnaireGroupState}
        onSubmit={() => null}
      />
    </>
  );
};
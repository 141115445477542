import * as React from "react";
const MS_WORD_ICON = require("../../assets/file-icons/ms-word-icon.png").default;
const MS_EXCEL_ICON = require("../../assets/file-icons/ms-excel-icon.png").default;
const MS_PPT_ICON = require("../../assets/file-icons/ms-ppt-icon.png").default;
const PDF_ICON = require("../../assets/file-icons/pdf-icon.png").default;
const HTML_ICON = require("../../assets/file-icons/html-icon.png").default;
const PNG_ICON = require("../../assets/file-icons/png-icon.png").default;
const JPG_ICON = require("../../assets/file-icons/jpg-icon.png").default;
const TXT_ICON = require("../../assets/file-icons/txt-icon.png").default;
const COMMON_FILE_ICON = require("../../assets/file-icons/general-icon.png").default;
const CSV_ICON = require("../../assets/file-icons/csv-icon.png").default;
const MS_OUTLOOK_ICON = require("../../assets/file-icons/ms-outlook-icon.png").default;
const EMAIL_ICON = require("../../assets/file-icons/email-icon.png").default;
const ICON_HEIGHT = 40;

export const DocumentIcon = (props: {filename: string}) => {
    const extension = props.filename.split(".").slice(-1)[0];
    if (extension.toLowerCase() === "doc" || extension.toLowerCase() === "docx") {
        return <img alt="word" src={String(MS_WORD_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "pdf") {
        return <img alt="pdf" src={String(PDF_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "xls" || extension.toLowerCase() === "xlsx") {
        return <img alt="excel" src={String(MS_EXCEL_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "ppt" || extension.toLowerCase() === "pptx") {
        return <img alt="powerpoint" src={String(MS_PPT_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "html") {
        return <img alt="html" src={String(HTML_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "txt") {
        return <img alt="text" src={String(TXT_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "csv") {
        return <img alt="csv" src={String(CSV_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "png") {
        return <img alt="png" src={String(PNG_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "jpeg" || extension.toLowerCase() === "jpg") {
        return <img alt="jpg" src={String(JPG_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "eml") {
        return <img alt="email" src={String(EMAIL_ICON)} height={ICON_HEIGHT}/>;
    } else if (extension.toLowerCase() === "msg") {
        return <img alt="outlook" src={String(MS_OUTLOOK_ICON)} height={ICON_HEIGHT}/>;
    }
    return <img alt="file" src={String(COMMON_FILE_ICON)} height={ICON_HEIGHT}/>;
};
import { useContext } from "react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { UIField } from "../common/UIField";
import { UIModel } from "../../model/ui-model";
import { Box, Button, CollectionPreferences, ColumnLayout, Header, Input, SpaceBetween, Table } from "@amzn/awsui-components-react";
import { Geo, GeoType, IRecommendation, Recommendation } from "@amzn/ask-legal-domain";
import { RecommendationPolarisFactory } from "../../factory/polaris/rec-polaris-factory";
import { useCollectionProps } from "../../hooks/polaris-hooks";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { GeoSelect } from "../common/GeoSelect";

export const RecommendationSimulator = (props: {
    id: string
}) => {
    const context = useContext(AppContext);
    const recommendRunner = useAPI(context.getRecommendationAPI().recommend);

    const tenureInYears = UIModel.State.use<number>({initialValue: 0});
    const tenureInMonths = UIModel.State.use<number>({initialValue: 0});
    const login = UIModel.State.use<string>({initialValue: ""});
    const geo = UIModel.State.use<Geo>({initialValue: {
        type: GeoType.CountryCode,
        value: "US"
    }});

    const [tableItems, setTableItems] = React.useState<Recommendation.Data[]>([]);

    const submit = () => {
        recommendRunner.submitRun(IRecommendation.RecommendInput.create(
            props.id, null, {
                geo: geo.value.value,
                tenureInYears: tenureInYears.value,
                tenureInMonths: tenureInMonths.value,
                login: login.value
            }
        ));
    };

    React.useEffect(() => {
        const setDefaultLogin = async () => {
            const identity = await context.getIdentity();
            login.setValue(identity.id);
        };
        setDefaultLogin();
    }, []);

    React.useEffect(() => {
        if (recommendRunner.status === "Succeeded") {
            setTableItems(recommendRunner.data.output.results);
        }
    }, [recommendRunner.status]);

    const tablePreference = useCollectionProps({
        defaultPreferences: RecommendationPolarisFactory.Table.toDefaultTablePreferences(),
        pageSizePreference: CommonPolarisFactory.Table.PageSizeSelection.toPageSizeSelection([
            CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
            CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
            CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
        ]),
        visibleContentPreference: CommonPolarisFactory.Table.VisibleContentPreference.toVisibleContentPreference(
            RecommendationPolarisFactory.Table.toColumnDefinitions()
        )
    });

    const header = (
        <Header variant="h2">
            Matching Recommendations
        </Header>
    );
    const empty = (
        <Box textAlign="center" color="inherit">
            <b>
                No matching Recommendations found
            </b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No matching Recommendations found in this Instance
            </Box>
        </Box>
    );

    return (
        <ColumnLayout columns={1} borders="horizontal">
            <SpaceBetween direction="horizontal" size="s">
                <UIField.CustomField name="Tenure (year)" child={
                    <Input
                        type="number"
                        placeholder="Number of months"
                        value={tenureInYears.value}
                        onChange={e => {
                            const num = parseInt(e.detail.value);
                            if (!isNaN(num) && num >= 0) {
                                tenureInYears.setValue(num);
                            }
                        }}
                    />
                }/>
                <UIField.CustomField name="Tenure (month)" child={
                    <Input
                        type="number"
                        placeholder="Number of months"
                        value={tenureInMonths.value}
                        onChange={e => {
                            const num = parseInt(e.detail.value);
                            if (!isNaN(num) && num >= 0) {
                                if (num > 11) tenureInMonths.setValue(11);
                                else tenureInMonths.setValue(num);
                            }
                        }}
                    />
                }/>
                <UIField.StateValueField name="Login" state={login} editing={true}/>
                <UIField.CustomField name="Country" child={
                    <GeoSelect.Single selected={geo.value} onSelectionChange={g => geo.setValue(g)}/>}
                />
                <Button onClick={submit}>Submit</Button>
            </SpaceBetween>
            <Table
                header={header}
                empty={empty}
                loading={recommendRunner.status === "Running"}
                visibleColumns={tablePreference.preferences.preferences.visibleContent}
                preferences={<CollectionPreferences {...tablePreference.preferences}/>}
                columnDefinitions={RecommendationPolarisFactory.Table.toColumnDefinitions()}
                items={tableItems}
            />
        </ColumnLayout>
    );
};
import * as React from "react";
import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { PagePolarisFactory } from "../../factory/polaris/page-polaris-factory";
import { Builder } from "builder-pattern";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { PageCreateModal } from "./PageCreate";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { AppContext } from "../../setup/context";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";
import { FilterField, Page } from "@amzn/ask-legal-domain";

export const PageHome = (props: { id: string }) => {
    const context = React.useContext(AppContext);
    const columnDefinitions = PagePolarisFactory.Table.toColumnDefinitions();

    const tableDefaultPreferences = Builder<CollectionPreferencesProps.Preferences<any>>()
        .pageSize(10)
        .visibleContent([
            PagePolarisFactory.Table.PageTitle.id,
            PagePolarisFactory.Table.PageOwner.id,
            PagePolarisFactory.Table.PageFreshness.id,
            CommonPolarisFactory.Table.ColumnDefinition.IsDeprecated.id,
            CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
            CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id,
        ])
        .build();

    const collection = useLoadingWithServerSidePagination(
        context.getPageAPI().loadAll,
        props.id,
        {
            columnDefinitions: columnDefinitions,
            defaultTablePreferences: tableDefaultPreferences,
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ]
        },
        {
            filteringProperties: PagePolarisFactory.Table.PropertyFilter.toFilterPages(),
            toOption: () => [
                { propertyKey: FilterField.PageFreshnessStatus, value: Page.Freshness.FRESH },
                { propertyKey: FilterField.PageFreshnessStatus, value: Page.Freshness.EXPIRED },
                { propertyKey: FilterField.Deprecated, value: "Yes" },
                { propertyKey: FilterField.Deprecated, value: "No" }
            ]
        }
    );
    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };

    const header = <Header
        variant="h2"
        counter={counter()}
        actions={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                <PageCreateModal id={props.id} />
                </SpaceBetween>
            </Box>
        }>
        Pages
    </Header>;

    const empty = (<Box textAlign="center" color="inherit">
        <b>No Pages</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Pages to display
        </Box>
        <PageCreateModal id={props.id} />
    </Box>);

    return (
        <React.Fragment>
            { !!collection.table.error && <ErrorFlashbar error={collection.table.error}/>}
            <Table
                {...collection.table.props}
                empty={empty}
                header={header}
                preferences={
                    <CollectionPreferences {...collection.tablePreference.props}/>
                }
                pagination={
                    <Pagination {...collection.tablePagination.props}/>
                }
                filter={
                    <PropertyFilter {...collection.tableFiltering.props}/>
                }
            />
        </React.Fragment>
    );
};
import {
    IdentityRef,
    EntityExtraVersionRef,
    GenInstanceSandboxFilePutUrlCommandInput,
    GenInstanceSandboxFilePutUrlCommandOutput,
    SandboxFileKeyFileName
} from "@amzn/altar-sds-client";
import axios, { AxiosRequestConfig } from "axios";
import { APIOutput, MIMEType } from "@amzn/ask-legal-domain";
import {
    FileUpload,
    Flashbar,
    FlashbarProps,
    FormField,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { Builder } from "builder-pattern";
import { FileUtils } from "../../../utils/file-utils";

const ITEM_ATTACHMENT_FILE_SIZE_LIMIT_MB = 50; // 50MB

export function AttachmentUpload(props: {
    itemRef: EntityExtraVersionRef;
    by: IdentityRef;
    setUploadingSandboxFiles: (val: boolean) => void;
    onUpdated?: (sandboxFiles: SandboxFileKeyFileName[]) => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [files, setFiles] = React.useState<File[]>([]);

    async function validateFiles(files: File[]) {
        let errorFiles: string[] = [];
        for (const file of files) {
            const valid = await isValidFile(file);
            if (!valid) { errorFiles.push(file.name); }
        }
        if (errorFiles.length) {
            setFlashbarProps({
                items:
                    errorFiles.map(e => ({
                        type: "error",
                        content: `File ${e} failed validation. Check that ${e} is within ${ITEM_ATTACHMENT_FILE_SIZE_LIMIT_MB}MB and is in the list of allowed file types.`
                    }))
            });
        } else {
            setFlashbarProps({ items: [] });
        }
        return files.filter(f => !errorFiles.includes(f.name));
    }

    async function isValidFile(file: File) {
        const extension = FileUtils.getExtension(file.name);
        // 1. check if file is part of allowed extensions
        if (!allowedFileTypes.includes(extension)) { return false; }
        // 2. check if file extension matches file mime type
        if (MIMEType[extension] !== file.type) { return false; }
        // 3. perform file signature check
        const validFileSignature = await FileUtils.isValidFileSignature(file);
        if (!validFileSignature) { return false; }
        // 4. check file size
        if (file.size > ITEM_ATTACHMENT_FILE_SIZE_LIMIT_MB * 1000000) {
            return false;
        }

        // above checks passed; valid file
        return true;
    }

    async function uploadAttachments(files: File[]) {
        props.setUploadingSandboxFiles(true);
        setSpinnerProps({});
        const validFiles =  await validateFiles(files);
        // validation failure - don't proceed with upload
        if (validFiles.length !== files.length) {
            props.setUploadingSandboxFiles(false);
            setSpinnerProps(undefined);
            setFiles([]);
            return;
        }

        let sandboxFiles: SandboxFileKeyFileName[] = [];
        for await (const file of files) {
            const putSignedUrlOutput = await context
            .getAdvancedListAPI()
            .generateSandboxPutUrl(Builder<GenInstanceSandboxFilePutUrlCommandInput>()
                .by(props.by)
                .contentType(file.type)
                .fileName(file.name)
                .instanceId(props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId!)
                .build()
            );
            const output = APIOutput.fromRaw<GenInstanceSandboxFilePutUrlCommandOutput>(putSignedUrlOutput.data);
            if (output.isErr()) {
                setSpinnerProps(undefined);
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
                props.setUploadingSandboxFiles(false);
            } else {
                const req: AxiosRequestConfig = {
                    url: output.data.url,
                    method: "PUT",
                    data: file,
                };
                await axios(req);
                sandboxFiles.push({
                    fileKey: output.data.fileKey,
                    fileName: file.name
                });
            }
        }
        setSpinnerProps(undefined);
        setFlashbarProps({
            items: []
        });
        props.onUpdated(sandboxFiles);
        props.setUploadingSandboxFiles(false);
    }

    React.useEffect(() => {
        if (files.length) {
            uploadAttachments(files);
        }
    }, [files]);

    return (
        <>
            {spinnerProps && (
                <Spinner />
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            <FormField
                label={<h5>Upload Attachments</h5>}
                description={<>
                    <div>You can also drop files here to upload</div>
                    <br />
                    <div>Supported file types include: {Object.keys(allowedFileTypes).join(", ")}</div>
                    <div>Attachments support file sizes up to {ITEM_ATTACHMENT_FILE_SIZE_LIMIT_MB}MB</div>
                </>}
            >
                <FileUpload
                    onChange={({ detail }) => {
                        setFiles(detail.value);
                    }}
                    multiple
                    value={files}
                    i18nStrings={{
                        uploadButtonText: e =>
                        e ? "Choose files" : "Choose file",
                        dropzoneText: e =>
                        e
                            ? "Drop files to upload"
                            : "Drop file to upload",
                        removeFileAriaLabel: e =>
                        `Remove file ${e + 1}`,
                        limitShowFewer: "Show fewer files",
                        limitShowMore: "Show more files",
                        errorIconAriaLabel: "Error"
                    }}
                    showFileSize
                    tokenLimit={5}
                />
            </FormField>
        </>
    );
}

// TODO - export allowed file types list from SDS
// Derived from destination bucket resource policy
// https://code.amazon.com/packages/ALTARSimpleDomainServiceCDK/blobs/f4dd831c0664d59f26638a619c9de00d8ba1cb6f/--/lib/stack/infra-stack.ts#L539-L552,L623-L636
const allowedFileTypes = [
    "txt",
    "jpeg",
    "png",
    "doc",
    "docx",
    "xls",
    "xlsx",
    "csv",
    "ppt",
    "pptm",
    "rtf",
    "pdf",
    "html",
    "json"
];

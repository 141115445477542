import { PageFactory } from "@amzn/ask-legal-domain";

export namespace AppURLFactory {
    function setQueryStringFromParams(params: Map<string, string>): string {
        let query = "";
        params.forEach((val, key) => {
            if (query.length > 0) {
                query += "&";
            }
            query += `${key}=${val}`;
        });
        return query;
    }

    export function getHome(instanceId: string): string {
        return `/#/instance/${instanceId}/home`;
    }

    export function getPage(pageId: string, options?: {
        subPath?: "live" | "admin",
        queryParams?: Map<string, string>,
    }): string {
        const basePageURL = `/#/page/${pageId}/${options.subPath || "live"}`;
        return options?.queryParams ? `${basePageURL}?${setQueryStringFromParams(options.queryParams)}` : basePageURL;
    }

    export function getContainer(containerId: string, options?: {
        type?: "live" | "draft",
        params?: Map<string, string>,
    }): string {
        const subPath = options?.type === "draft" ? "admin" : "live";
        const queryParams = new Map<string, string>();
        if (options?.type === "draft") {
            queryParams.set("tab", "draftPage");
        }
        queryParams.set("container", containerId);
        if (options?.params) {
            options.params.forEach((val, key) => {
                queryParams.set(key, val);
            });
        }
        return getPage(
            PageFactory.fromEntityId(containerId),
            { subPath, queryParams },
        );
    }
}
import { Container, EntityFactory, Page, PageLibrary, RecommendationRule } from "@amzn/ask-legal-domain";
import { Button, Checkbox, ColumnLayout, Link, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import * as React from "react";
import { RecommendationModel } from "../../../model/recommendation-model";
import { AppContext } from "../../../setup/context";
import { EntitySelection } from "../../common/EntitySelection";
import { UIField } from "../../common/UIField";
import { ContainerSelect } from "../../container/ContainerSelect";
import { AppURLFactory } from "../../../factory/app-url-factory";

export const RecModalContent = (props: {
    state: RecommendationModel.RecommendationState;
    isCreate: boolean;
}) => {
    const context = React.useContext(AppContext);
    const [external, setExternal] = React.useState<boolean>(true);
    const [selectedPageLibrary, setSelectedPageLibrary] = React.useState<PageLibrary>(null);
    const [selectedPage, setSelectedPage] = React.useState<Page.Data>(null);
    const [selectedContainer, setSelectedContainer] = React.useState<Container.Data>(null);
    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);

    React.useEffect(() => {
        if (selectedPageLibrary) {
            setSelectedPage(null);
            setSelectedContainer(null);
            props.state.url.setValue("");
            props.state.title.setValue("");
            props.state.description.setValue("");
        }
    }, [selectedPageLibrary]);

    React.useEffect(() => {
        if (!!selectedPage) {
            setSelectedContainer(null);
            props.state.description.setValue(selectedPage.description);
            props.state.title.setValue(selectedPage.title);
            const url = window.location.origin + AppURLFactory.getPage(selectedPage.id);
            props.state.url.setValue(url);
        }
    }, [selectedPage]);

    React.useEffect(() => {
        if (!!selectedContainer) {
            props.state.description.setValue(selectedContainer.description);
            props.state.title.setValue(selectedContainer.title);
            const url = window.location.origin + AppURLFactory.getContainer(selectedContainer.id);
            props.state.url.setValue(url);
        }
    }, [selectedContainer]);

    React.useEffect(() => {
        if (!props.state.description.value || (RecommendationModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length === RecommendationModel.DESCRIPTION_CHAR_LIMIT)) {
            setDescriptionConstraint(`Maximum ${RecommendationModel.DESCRIPTION_CHAR_LIMIT} characters`);
        } else if (RecommendationModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length >= 0) {
            setDescriptionConstraint(`Maximum ${RecommendationModel.DESCRIPTION_CHAR_LIMIT} characters ( ${RecommendationModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length} remaining )`);
        } else if (RecommendationModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                        Maximum {RecommendationModel.DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color: "#d13212" }}>{props.state.description.value.length - RecommendationModel.DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
            );
        }
    }, [props.state.description.value]);

    return (
        <React.Fragment>
            <ColumnLayout columns={2}>
                <SpaceBetween size="l">
                    <UIField.StateValueField
                        state={props.state.title}
                        name="Title"
                        constraintText={`Maximum ${RecommendationModel.TITLE_CHAR_LIMIT} characters`}
                        editing={true}
                    />
                    <UIField.StateValueField
                        state={props.state.description}
                        name="Description"
                        editing={true}
                        variant="TextArea"
                        constraintText={descriptionConstraint}
                    />
                </SpaceBetween>
                <SpaceBetween size="l">
                    <Checkbox
                        onChange={({ detail }) =>
                            setExternal(detail.checked)
                        }
                        checked={external}>Use External URL
                    </Checkbox>
                    {!external && (
                        <SpaceBetween direction="vertical" size="m">
                            <Button variant="normal" onClick={() => {
                                setSelectedContainer(null);
                                setSelectedPage(null);
                                setSelectedPageLibrary(null);
                                props.state.url.setValue("");
                                props.state.title.setValue("");
                                props.state.description.setValue("");
                            }}>Clear Selections</Button>
                            <UIField.CustomField name="Select PageLibrary" child={
                                <EntitySelection.SingleWithPagination
                                    api={context.getPageLibraryAPI().loadAll}
                                    partitionKey={props.state.instanceRef.id}
                                    onSelectionChange={e => setSelectedPageLibrary(e)}
                                    selected={selectedPageLibrary}
                                />
                            } />
                            {!!selectedPageLibrary &&
                                <UIField.CustomField name="Select Page" child={
                                    <EntitySelection.SingleWithPagination
                                        api={context.getPageAPI().loadAll}
                                        partitionKey={selectedPageLibrary.id}
                                        onSelectionChange={e => setSelectedPage(e)}
                                        selected={selectedPage}
                                    />
                                } />
                            }
                            {!!selectedPage && selectedPage.sections.flatMap(s => s.containers).length > 0 &&
                                <UIField.CustomField name="Select Container (Optional)" child={
                                    <ContainerSelect.Single
                                        data={selectedPage.sections.flatMap(s => s.containers)}
                                        selected={selectedContainer}
                                        onSelectChange={c => setSelectedContainer(c)}
                                    />
                                } />
                            }
                            <UIField.CustomField name="Generated URL" child={
                                <Link external target="_blank" href={props.state.url.value}>
                                    {props.state.url.value}
                                </Link>
                            } />
                        </SpaceBetween>
                    )}
                    {external && <UIField.StateValueField
                        state={props.state.url}
                        name="Page URL"
                        editing={true}
                    />}
                    <UIField.CustomField
                        name="Rules"
                        child={
                            <EntitySelection.MultiWithPagination
                                api={context.getRecommendationRuleAPI().loadByInstanceId}
                                partitionKey={props.state.instanceRef.id}
                                onSelectionChange={(entities: RecommendationRule.Data[]) => {
                                    props.state.rules.setValue(entities.map(e => EntityFactory.toEntityRef(e)));
                                }}
                                selected={props.state.rules.value}
                            />}
                    />
                    {!!props.isCreate && <UIField.NumberValueField
                        name="Rank (optional)"
                        state={props.state.initialRank}
                        editing={true}
                        placeholder="Assign initial rank, leave blank or assign 0 so it will appear at end of list"
                    />}
                </SpaceBetween>
            </ColumnLayout>
        </React.Fragment>
    );
};
import { ItemHyperlink } from "@amzn/altar-sds-client";
import { Link } from "@amzn/awsui-components-react";
import * as React from "react";

export const hasHttpPrefix = (url) => {
    const pattern = /^([a-z]+:)?\/\//i;
    return pattern.test(url);
};

export const HyperlinkFieldView = (props: {
    value: ItemHyperlink;
}) => {
    const url = !hasHttpPrefix(props.value.href) ?
        `https://${props.value.href}` :
        props.value.href;

    return (
        <Link
            external
            href={url}
        >
            {props.value.title || props.value.href}
        </Link>
    );
};
import { AccordionContent, AccordionGroupContent, Container, Questionnaire, QuestionnaireGroup } from "@amzn/ask-legal-domain";
import { HtmlDiffer } from "../../common/HtmlDiffer";

export namespace ContainerDiffContentUtils {
    /**
     * Zips two unequal length arrays by id attribute
     * @returns Array of [newData, oldData]
     */
    function zipById(newArray?: any[], oldArray?: any[]) {
        if (!newArray && !oldArray) return [];
        const ids: string[] = [];
        const result = oldArray ? oldArray.map(x => {
            ids.push(x.id);
            return [newArray.find(y => y.id === x.id), x];
        }) : []; // Add intersection(X, Y) + (X-Y)
        if (newArray?.length > 0) newArray.forEach(y => {
            if (!ids.includes(y.id)) result.push([y, undefined]);
        }); // Add Remaining (Y-X)
        return result;
    }

    export function getMarginDiff(modifiedMargin: Container.Margin, originalMargin: Container.Margin): Container.Margin {
        if (!modifiedMargin && !originalMargin) return;
        return {
            header: HtmlDiffer.getHTMLDiffString(modifiedMargin?.header, originalMargin?.header),
            body: HtmlDiffer.getHTMLDiffString(modifiedMargin?.body, originalMargin?.body)
        };
    }

    export function getAccordionGroupContentDiff(modifiedAccordionGroupContent: AccordionGroupContent, originalAccordionGroupContent: AccordionGroupContent): AccordionGroupContent {
        if (!modifiedAccordionGroupContent && !originalAccordionGroupContent) return AccordionGroupContent.create();
        return AccordionGroupContent.create(
            zipById(
                modifiedAccordionGroupContent?.contentValue.questionGroups,
                originalAccordionGroupContent?.contentValue.questionGroups
            ).map(([newQuestionGroup, oldQuestionGroup]) => {
                return QuestionnaireGroup.QuestionGroup.create({
                    id: newQuestionGroup ? newQuestionGroup.id : oldQuestionGroup?.id,
                    groupName: HtmlDiffer.getHTMLDiffString(newQuestionGroup?.groupName, oldQuestionGroup?.groupName),
                    questions: getQuestionListDiff(newQuestionGroup?.questions, oldQuestionGroup?.questions)
                });
            })
        );
    }

    export function getAccordionContentDiff(modifiedAccordionContent: AccordionContent, originalAccordionContent: AccordionContent): AccordionContent {
        if (!modifiedAccordionContent && !originalAccordionContent) return AccordionContent.create();
        return AccordionContent.create(
            getQuestionListDiff(
                modifiedAccordionContent?.contentValue.questions,
                originalAccordionContent?.contentValue.questions
            )
        );
    }

    function getQuestionListDiff(newQuestionList: Questionnaire.Question[], oldQuestionList: Questionnaire.Question[]): Questionnaire.Question[] {
        if (!newQuestionList && !oldQuestionList) return [];
        return zipById(
            newQuestionList ? newQuestionList : [],
            oldQuestionList ? oldQuestionList : []
        ).map(([newQuestion, oldQuestion]) => getQuestionDiff(newQuestion, oldQuestion));
    }

    function getQuestionDiff(newQuestion: Questionnaire.Question, oldQuestion: Questionnaire.Question): Questionnaire.Question {
        if (!newQuestion && !oldQuestion) return;
        return Questionnaire.Question.create({
            id: newQuestion ? newQuestion.id : oldQuestion?.id,
            question: HtmlDiffer.getHTMLDiffString(newQuestion?.question, oldQuestion?.question),
            answer: HtmlDiffer.getHTMLDiffString(newQuestion?.answer, oldQuestion?.answer)
        });
    }
}
import * as React from "react";
import * as History from "history";
import { Prompt } from "react-router";

/**
 * User confirmation modal to control navigation away from unsaved forms on pages
 * This has an open issue as tagged in https://github.com/remix-run/react-router/issues/5405
 * Bypassing the issue by adding changes to App.tsx: HashRouter
 */
export const ConfirmLeavePageModal = (props: {showModal: boolean}) => {
    const displayMessage = "Unsaved changes will be lost. Are you sure you want to leave?";

    const onMessage = (_nextLocation: History.Location, action: History.Action) => {
        return JSON.stringify({
            action,
            message: displayMessage
        });
    };

    React.useEffect(() => {
        if (!props.showModal) return () => {};

        const beforeUnloadHandler = (event: any) => {
            event.preventDefault();
            event.returnValue = displayMessage;
            return displayMessage;
        };

        window.addEventListener("beforeunload", beforeUnloadHandler);
        return () => {
            window.removeEventListener("beforeunload", beforeUnloadHandler);
        };
    }, [props.showModal]);

    return <Prompt when={props.showModal} message={onMessage} />;
};
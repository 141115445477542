import * as React from "react";
import {
    Box,
    Button,
    Grid,
    Header,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { UIModel } from "../../../../../model/ui-model";
import {
    AdvancedListContent,
    Identity,
    Team
} from "@amzn/ask-legal-domain";
import {
    AdvancedList,
    IdentityRef,
    IdentityType,
    IdentityRealm,
    FieldDefinitionDataType
} from "@amzn/altar-sds-client";
import { Builder } from "builder-pattern";
import { UserSearch } from "../../../../common/UserSearch";
import { TeamSearch } from "../../../../common/TeamSearch";
import { UIField } from "../../../../common/UIField";
import { IdentityToken } from "../../../../common/IdentityToken";

export const Permission = (props: {
    state: UIModel.State<AdvancedListContent>;
    advancedList: AdvancedList;
}) => {
    const [identityRefs, setIdentityRefs] = React.useState<IdentityRef[]>([
        ...(props.state?.value?.updateAdvancedListPayload?.addEditorsList || []),
        ...(props.advancedList?.editors.filter(e => !props.state?.value?.updateAdvancedListPayload?.removeEditorsList?.find(r => r.id === e.id)) || [])
    ]);
    const [editorFieldKey, setEditorFieldKey] = React.useState<string>(
        (props.state?.value?.updateAdvancedListPayload && "editorFieldKey" in props.state.value.updateAdvancedListPayload) ?
            props.state.value.updateAdvancedListPayload.editorFieldKey :
            props.advancedList.editorFieldKey
    );

    React.useEffect(() => {
        props.state.setValue({
            ...props.state.value,
            updateAdvancedListPayload: {
                ...props.state.value.updateAdvancedListPayload,
                addEditorsList: identityRefs.filter(i => !props.advancedList.editors.find(e => e.id === i.id)),
                removeEditorsList: props.advancedList.editors.filter(e => !identityRefs.find(i => i.id === e.id)),
            }
        });
    }, [identityRefs]);

    React.useEffect(() => {
        props.state.setValue({
            ...props.state.value,
            updateAdvancedListPayload: {
                ...props.state.value.updateAdvancedListPayload,
                editorFieldKey: editorFieldKey,
            }
        });
    }, [editorFieldKey]);

    return (<>
        <SpaceBetween direction="vertical" size="s">
            <Header variant="h2">Configure Editors</Header>
            <Box>Editors can create, edit, and delete all List Items</Box>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <UIField.CustomField
                    name="Editor Users"
                    child={
                        <SpaceBetween size="xs">
                            <UserSearch.Single
                                selected={null}
                                onUserSelectChange={(user) => {
                                    if (user && !identityRefs.find(i => i.id === user.id)) {
                                        setIdentityRefs(
                                            [...identityRefs, getIdentityRefFromIdentity(user)]
                                        );
                                    }
                                }}
                            />
                            <SpaceBetween direction="horizontal" size="m">
                                {identityRefs
                                    .filter(i => i.type === IdentityType.PERSON)
                                    .map(i => <IdentityToken
                                        key={i.id}
                                        identityRef={i}
                                        onRemove={(id) =>
                                            setIdentityRefs(identityRefs.filter(i => i.id !== id))
                                        }
                                    />)
                                }
                            </SpaceBetween>
                        </SpaceBetween>
                    }
                />
                <UIField.CustomField
                    name="Editor Teams"
                    child={
                        <SpaceBetween size="s">
                            <TeamSearch.Single
                                selected={null}
                                onTeamSelectChange={(team) => {
                                    if (team && !identityRefs.find(i => i.id === team.teamId)) {
                                        setIdentityRefs(
                                            [...identityRefs, getIdentityRefFromTeam(team)]
                                        );
                                    }
                                }}
                            />
                            <SpaceBetween direction="horizontal" size="xs">
                                {identityRefs
                                    .filter(i => i.type === IdentityType.TEAM)
                                    .map(i => <IdentityToken
                                        key={i.id}
                                        identityRef={i}
                                        onRemove={(id) =>
                                            setIdentityRefs(identityRefs.filter(i => i.id !== id))
                                        }
                                    />)
                                }
                            </SpaceBetween>
                        </SpaceBetween>
                    }
                />
            </Grid>

            <UIField.CustomField
                name="Editor Column"
                description="A user matching an Item's Editor Column value is able to edit and delete that specific Item.  "
                child={
                    <Grid
                        gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}
                    >
                        <Select
                            options={props.advancedList.fieldDefinitions
                                ?.filter((c) => (
                                    [
                                        FieldDefinitionDataType.IdentityRef,
                                        FieldDefinitionDataType.multiIdentityRef
                                    ].includes(c.dataType as FieldDefinitionDataType)
                                    && !c.deprecated
                                ))
                                .map((d) => ({
                                    value: d.fieldKey,
                                    label: d.displayName,
                                }))
                            }
                            selectedOption={editorFieldKey && {
                                value: editorFieldKey,
                                label: props.advancedList.fieldDefinitions?.find(
                                    (f) => f.fieldKey === editorFieldKey
                                )?.displayName,
                            }}
                            onChange={(e) =>
                                setEditorFieldKey(e.detail.selectedOption.value)
                            }
                            placeholder="Select a field"
                            empty="No fields available"
                        />
                        <Button
                            onClick={() => setEditorFieldKey("")}
                        >Reset</Button>
                    </Grid>
                }
            />
        </SpaceBetween>
    </>);
};

function getIdentityRefFromTeam(team: Team) {
    return Builder<IdentityRef>()
        .id(team.teamId)
        .realm(IdentityRealm.AMAZON)
        .type(IdentityType.TEAM)
        .build();
}

function getIdentityRefFromIdentity(identity: Identity) {
    return Builder<IdentityRef>()
        .id(identity.id)
        .realm(IdentityRealm.AMAZON)
        .type(IdentityType.PERSON)
        .build();
}
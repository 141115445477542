import {
    EntityFactory,
    EntityRef,
    EntityType,
    IRecommendation,
    Recommendation,
    validUrlRegex
} from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { UIModel } from "./ui-model";

export namespace RecommendationModel {
    export const TITLE_CHAR_LIMIT = 50;
    export const DESCRIPTION_CHAR_LIMIT = 140;

    export class RecommendationState {
        title: UIModel.State<string>;
        description: UIModel.State<string>;
        url: UIModel.State<string>;
        rules: UIModel.State<EntityRef[]>;
        instanceRef: EntityRef;
        initialRank: UIModel.State<number>;
        init: (entity?: Recommendation.Data) => void;
        reset: () => void;

        public static toCreateInput(state: RecommendationState) {
            return IRecommendation.CreateRecommendationInput.create({
                instanceRef: state.instanceRef,
                title: state.title.value,
                description: state.description.value,
                url: state.url.value,
                rules: state.rules.value.map(v => v.id),
                initialRank: state.initialRank.value
            });
        }

        public static toUpdateInput(state: RecommendationState, recommendationRef: EntityRef) {
            return IRecommendation.UpdateRecommendationInput.create({
                recommendationRef: recommendationRef,
                newTitle: state.title.value,
                newDescription: state.description.value,
                newUrl: state.url.value,
                newRules: state.rules.value.map(v => v.id)
            });
        }

        public static use (props: {
            instanceId: string;
        }): RecommendationState {
            const title = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: TITLE_CHAR_LIMIT
            });
            const description = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: DESCRIPTION_CHAR_LIMIT
            });
            const url = UIModel.State.useWithRegexValidation({ initialValue: "", regex: validUrlRegex });
            const rules = UIModel.State.useRequiredArray<EntityRef>({ initialValue: [] });
            const instanceRef = EntityFactory.fromEntityAttributes(props.instanceId, EntityType.Instance);
            const initialRank = UIModel.State.use<number>({
                initialValue: undefined,
                validation: val => {
                    if (val !== undefined && isNaN(val)) {
                        return "Rank must be a number";
                    }
                }
            });

            const init = (entity?: Recommendation.Data) => {
                if (entity) {
                    title.setValue(entity.title);
                    description.setValue(entity.description);
                    url.setValue(entity.url);
                    rules.setValue(entity.rules.map(v => EntityFactory.fromEntityAttributes(v, EntityType.RecommendationRule)));
                } else {
                    title.setValue("");
                    description.setValue("");
                    url.setValue("");
                    rules.setValue([]);
                }
            };

            const reset = () => {
                title.setValue(null);
                description.setValue(null);
                url.setValue(null);
                rules.setValue(null);
            };

            return Builder<RecommendationState>()
                .title(title)
                .description(description)
                .url(url)
                .rules(rules)
                .initialRank(initialRank)
                .instanceRef(instanceRef)
                .init(init)
                .reset(reset)
                .build();
        }
    }
}
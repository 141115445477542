import { EntityRef } from "@amzn/ask-legal-domain";
import { Table } from "@amzn/awsui-components-react";
import * as React from "react";
import { PageDraftPolarisFactory } from "../../factory/polaris/page-draft-polaris-factory";
import { PageDraftModel } from "../../model/page-draft-model";

export const PageDraftVersionHistory = (props: {
    entityRef: EntityRef;
}) => {
    const items = [];
    for (let version = props.entityRef.version; version > 0; version--) {
        items.push(
            PageDraftModel.VersionHistoryTableRowItemState.use({
                draftId: props.entityRef.id,
                version: version
            })
        );
    }

    return (
        <Table
            columnDefinitions={PageDraftPolarisFactory.VersionHistory.TableColumnDefinition.toColumnDefinitions()}
            items={items}
        />
    );
};
import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Form,
  Header,
  Icon,
  Input,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react";
import { Questionnaire, QuestionnaireGroup } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { QuestionnaireModel } from "../../../model/questionnaire-model";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { Accordion, Card } from "react-bootstrap";
import { LoadingSpinner } from "../../common/loading-spinner";
import { UIField } from "../../common/UIField";
import { QuestionnaireGroupModel } from "../../../model/questionnaire-group-model";
import { BasicAccordionGroupEdit } from "../basic-accordian/component";
import { CustomCommonContainerModal } from "../CommonContainerModal";
import { QuestionnaireComponent } from "../basic-accordian";
import { SmartRichTextView } from "../../common/RichTextEditor/SmartRichTextView";
import "../../../styles/component/accordion/accordion.scss";
import "../../../styles/component/layout/AskLegalLayout.scss";
import { RestrictedHyperLinkOnlyRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/RestrictedHyperLinksOnlyEdit";
import { StrongRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export namespace QuestionnaireGroupComponent {
  export namespace View {
    export const Main = (props: {
      inputState: QuestionnaireGroup.QuestionGroup[];
      diffMode?: boolean;
      expandCollapseAllTrigger?: { value: string; };
    }) => {
      const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

      const toggleGroupHandler = (id: string) => {
        if (!id || !expandedItems) return;
        if (expandedItems.includes(id)) {
          collapseAccordionGroups([id]);
        } else {
          expandAccordionGroups([id]);
        }
      };

      const checkGroupsExpanded = () => {
        if (props.inputState.length <= 0) return false;
        return props.inputState.every(v => expandedItems.includes(v.id));
      };

      const expandAccordionGroups = (items: string[], expandChildren?: boolean) => {
        if (!expandChildren) {
          setExpandedItems(expandedItems.concat(items));
        } else {
          let allItems = [];
          allItems = allItems.concat(items);
          items.forEach(v => {
            allItems = allItems.concat(props.inputState.filter(f => f.id === v)[0].questions.map(g => g.id));
          });
          setExpandedItems(expandedItems.concat(allItems));
        }
      };

      const collapseAccordionGroups = (items: string[], collapseChildren?: boolean) => {
        if (!collapseChildren) {
          const expandedList = expandedItems.filter(e => !items.includes(e));
          setExpandedItems(expandedList);
        } else {
          let allItems = [];
          allItems = allItems.concat(items);
          items.forEach(v => {
            allItems = allItems.concat(props.inputState.filter(f => f.id === v)[0].questions.map(g => g.id));
          });
          const expandedList = expandedItems.filter(e => !allItems.includes(e));
          setExpandedItems(expandedList);
        }
      };

      const expandAccordionChild = (items: string[]) => {
        if (!items) return;
        setExpandedItems(expandedItems.concat(items));
      };

      const collapseAccordionChild = (items: string[]) => {
        if (!items) return;
        const expandedList = expandedItems.filter(e => !items.includes(e));
        setExpandedItems(expandedList);
      };

      const expandAllAccordionGroups = () => {
        expandAccordionGroups(props.inputState.map(e => e.id), true);
      };

      const collapseAllAccordionGroups = () => {
        collapseAccordionGroups(props.inputState.map(e => e.id), true);
      };

      const renderExpandAllGroupsIcon = () => {
        return (
          <TextContent>
            <small>
              <a style={{ textDecoration: "none" }} onClick={() => {
                if (checkGroupsExpanded()) {
                  collapseAllAccordionGroups();
                } else {
                  expandAllAccordionGroups();
                }
              }}>
                [{checkGroupsExpanded() && `Collapse All`}{!checkGroupsExpanded() && `Expand All`}]
              </a>
            </small>
          </TextContent>
        );
      };

      const createAccordionHeader = (headerElement: JSX.Element, index: string) => {
        return (
          <div className="sherpa-accordion-title" onClick={() => {
            toggleGroupHandler(index);
          }}>
            {expandedItems.includes(index) ? <Icon name="caret-down-filled" size="normal" variant="normal" /> : <Icon name="caret-right-filled" size="normal" variant="normal" />}
            {headerElement}
          </div>
        );
      };

      const createAccordionRow = (questionGroup: QuestionnaireGroup.QuestionGroup, index) => (
        <Accordion
          key={questionGroup.id}
          activeKey={expandedItems.includes(index) ? questionGroup.id : null}
        >
          <Card>
            <Card.Header>
              <Header variant="h3">
                {createAccordionHeader(
                  <SmartRichTextView content={questionGroup.groupName} fontSize={18} diffMode={props.diffMode}/>,
                  questionGroup.id
                )}
              </Header>
            </Card.Header>
            <Accordion.Collapse eventKey={questionGroup.id}>
              <Card.Body>
                <QuestionnaireComponent.View.Main
                  inputState={Questionnaire.Data.create({ questions: questionGroup.questions }).questions}
                  parentState={expandedItems}
                  expandHandler={expandAccordionChild}
                  collapseHandler={collapseAccordionChild}
                  diffMode={props.diffMode}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );

      React.useEffect(() => {
        if (props.diffMode) expandAccordionGroups(props.inputState.map(e => e.id), true);
        const currentURL = window.location.href;
        if (!currentURL.includes("/live?")) return;
        const urlsearchparams = new URLSearchParams(currentURL.split("/live?")[1]);
        if (urlsearchparams && urlsearchparams.get("question")) {
          const groupId = urlsearchparams.get("question").split("-")[0];
          const questionId = urlsearchparams.get("question").split("-")[1];
          expandAccordionGroups([groupId, questionId], false);
        }
      }, []);

      React.useEffect(() => {
        if (!props.expandCollapseAllTrigger) return;
        if (props.expandCollapseAllTrigger.value === "expand") {
          expandAllAccordionGroups();
        } else if (props.expandCollapseAllTrigger.value === "collapse") {
          collapseAllAccordionGroups();
        }
      }, [props.expandCollapseAllTrigger]);

      return (
        <React.Fragment>
          {props.inputState.length > 0 && renderExpandAllGroupsIcon()}
          {props.inputState.map((questionGroup) =>
            createAccordionRow(
              QuestionnaireGroup.QuestionGroup.create({
                id: questionGroup.id,
                groupName: questionGroup.groupName,
                questions: questionGroup.questions
              }),
              questionGroup.id
            )
          )}
        </React.Fragment>
      );
    };

    export const EditQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.EditQuestionState
    }) => {
      return (
        <div>
          <b>Question</b>
          <RestrictedHyperLinkOnlyRichTextEdit
            content={props.state.editQuestionInputState.value.question}
            onChange={updated =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .question(updated)
                  .build()
              )
            }
          />
          <br />
          <b>Answer</b>
          <StrongRichTextEdit
            content={props.state.editQuestionInputState.value.answer}
            onChange={(value) =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .answer(value)
                  .build()
              )
            }
          />
        </div>
      );
    };

    export const DeleteQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.DeleteQuestionState;
    }) => {
      return (
        <div>
          Question: "
          <strong>{props.state.activeQuestionState.value.question}</strong>"
          will be deleted, are you sure?
        </div>
      );
    };
  }

  export namespace Edit {
    const buttonDropdownItems = {
      addQuestionGroup: Builder<ButtonDropdownProps.Item>()
        .id("addQuestionGroup")
        .text("Add Group")
        .build(),
      editQuestionGroup: Builder<ButtonDropdownProps.Item>()
        .id("editQuestionGroup")
        .text("Edit")
        .build(),
      deleteQuestionGroup: Builder<ButtonDropdownProps.Item>()
        .id("deleteQuestionGroup")
        .text("Delete")
        .build(),
      moveQuestionGroupUp: Builder<ButtonDropdownProps.Item>()
        .id("moveQuestionGroupUp")
        .text("Move Up")
        .build(),
      moveQuestionGroupDown: Builder<ButtonDropdownProps.Item>()
        .id("moveQuestionGroupDown")
        .text("Move Down")
        .build(),
    };

    export const Main = (props: {
      inputState: QuestionnaireGroupModel.UI.Update.UpdateQuestionnaireGroupState;
      editingQuestionnaireGroupState: UIModel.State<boolean>;
      onSubmit: () => void;
    }) => {
      const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

      const addQuestionGroupState =
        QuestionnaireGroupModel.UI.Update.AddQuestionGroupState.use();
      const editQuestionGroupState =
        QuestionnaireGroupModel.UI.Update.EditQuestionGroupState.use();
      const deleteQuestionGroupState =
        QuestionnaireGroupModel.UI.Update.DeleteQuestionGroupState.use();


      React.useEffect(() => {
        if (!addQuestionGroupState.result) return;
        props.inputState.addOrUpdateQuestionGroup(addQuestionGroupState.result);
      }, [addQuestionGroupState.result]);

      React.useEffect(() => {
        if (!editQuestionGroupState.result) return;
        props.inputState.addOrUpdateQuestionGroup(editQuestionGroupState.result);
      }, [editQuestionGroupState.result]);

      React.useEffect(() => {
        if (!deleteQuestionGroupState.result) return;
        props.inputState.deleteQuestionGroup(deleteQuestionGroupState.result);
      }, [deleteQuestionGroupState.result]);

      const toggleGroupHandler = (id: string) => {
        if (!id || !expandedItems) return;
        if (expandedItems.includes(id)) {
          collapseAccordionGroups([id]);
        } else {
          expandAccordionGroups([id]);
        }
      };

      const checkGroupsExpanded = () => {
        if (props.inputState.questionnaireState.length <= 0) return false;
        return props.inputState.questionnaireState.every(v => expandedItems.includes(v.id));
      };

      const expandAccordionGroups = (items: string[], expandChildren?: boolean) => {
        if (!expandChildren) {
          setExpandedItems(expandedItems.concat(items));
        } else {
          let allItems = [];
          allItems = allItems.concat(items);
          items.forEach(v => {
            allItems = allItems.concat(props.inputState.questionnaireState.filter(f => f.id === v)[0].questions.map(g => g.id));
          });
          setExpandedItems(expandedItems.concat(allItems));
        }
      };

      const collapseAccordionGroups = (items: string[], collapseChildren?: boolean) => {
        if (!collapseChildren) {
          const expandedList = expandedItems.filter(e => !items.includes(e));
          setExpandedItems(expandedList);
        } else {
          let allItems = [];
          allItems = allItems.concat(items);
          items.forEach(v => {
            allItems = allItems.concat(props.inputState.questionnaireState.filter(f => f.id === v)[0].questions.map(g => g.id));
          });
          const expandedList = expandedItems.filter(e => !allItems.includes(e));
          setExpandedItems(expandedList);
        }
      };

      const expandAccordionChild = (items: string[]) => {
        if (!items) return;
        setExpandedItems(expandedItems.concat(items));
      };

      const collapseAccordionChild = (items: string[]) => {
        if (!items) return;
        const expandedList = expandedItems.filter(e => !items.includes(e));
        setExpandedItems(expandedList);
      };

      const renderExpandAllGroupsIcon = () => {
        return (
          <TextContent>
            <small>
              <a style={{ textDecoration: "none" }} onClick={() => {
                if (checkGroupsExpanded()) {
                  collapseAccordionGroups(props.inputState.questionnaireState.map(e => e.id), true);
                } else {
                  expandAccordionGroups(props.inputState.questionnaireState.map(e => e.id), true);
                }
              }}>
                [{checkGroupsExpanded() && `Collapse All`}{!checkGroupsExpanded() && `Expand All`}]
              </a>
            </small>
          </TextContent>
        );
      };

      const createAccordionHeader = (headerElement: JSX.Element, index: string) => {
        return (
          <div className="sherpa-accordion-title" onClick={() => {
            toggleGroupHandler(index);
          }}>
            <SpaceBetween size="xxxs" direction="horizontal">
              {expandedItems.includes(index) ? <Icon name="caret-down-filled" size="normal" variant="normal" /> : <Icon name="caret-right-filled" size="normal" variant="normal" />}
              {headerElement}
            </SpaceBetween>
          </div>
        );
      };

      const createAccordionRow = (questionGroup: QuestionnaireGroup.QuestionGroup, index: string) => (
        <Accordion
          key={questionGroup.id}
          activeKey={expandedItems.includes(index) ? questionGroup.id : null}
        >
          <Card>
            <Card.Header>
              <Header
                variant="h3"
                actions={props.editingQuestionnaireGroupState.value === true ?
                  <ButtonDropdown
                    expandToViewport
                    items={[
                      buttonDropdownItems.editQuestionGroup,
                      buttonDropdownItems.moveQuestionGroupDown,
                      buttonDropdownItems.moveQuestionGroupUp,
                      buttonDropdownItems.deleteQuestionGroup,
                    ]}
                    onItemClick={(e) =>
                      buttonGroupClickEventHandler({
                        buttonId: e.detail.id,
                        actionQuestionGroup: questionGroup,
                      })
                    }
                  >
                    Actions
                </ButtonDropdown>
                  : ""}
              >
                {createAccordionHeader(<React.Fragment>&nbsp;&nbsp;{questionGroup.groupName}</React.Fragment>, questionGroup.id)}
              </Header>
            </Card.Header>
            <Accordion.Collapse eventKey={questionGroup.id}>
              <Card.Body>
                <QuestionnaireComponent.View.Main
                  inputState={Questionnaire.Data.create({ questions: questionGroup.questions }).questions}
                  parentState={expandedItems}
                  expandHandler={expandAccordionChild}
                  collapseHandler={collapseAccordionChild}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );

      const buttonGroupClickEventHandler = (params: {
        buttonId: string;
        actionQuestionGroup?: QuestionnaireGroup.QuestionGroup;
      }) => {
        if (props.editingQuestionnaireGroupState.value)
          switch (params.buttonId) {
            case "addQuestionGroup":
              addQuestionGroupState.reset();
              addQuestionGroupState.activate();
              break;
            case "editQuestionGroup":
              editQuestionGroupState.reset();
              editQuestionGroupState.activate();
              editQuestionGroupState.activeQuestionGroupState.setValue(
                params.actionQuestionGroup
              );
              editQuestionGroupState.editQuestionInputState.setValue(
                QuestionnaireGroupModel.UI.Update.QuestionGroupInputItem.fromQuestion(params.actionQuestionGroup)
              );
              break;
            case "deleteQuestionGroup":
              deleteQuestionGroupState.reset();
              deleteQuestionGroupState.activate();
              deleteQuestionGroupState.activeQuestionGroupState.setValue(
                params.actionQuestionGroup
              );
              break;
            case "moveQuestionGroupUp":
              props.inputState.moveQuestionGroup(
                params.actionQuestionGroup,
                QuestionnaireModel.UI.MoveAction.MOVE_UP
              );
              break;
            case "moveQuestionGroupDown":
              props.inputState.moveQuestionGroup(
                params.actionQuestionGroup,
                QuestionnaireModel.UI.MoveAction.MOVE_DOWN
              );
              break;
          }
      };

      const onReset = () => {
        props.inputState.reset();
        props.editingQuestionnaireGroupState.setValue(false);
      };

      if (!props.inputState.questionnaireState) {
        return <LoadingSpinner />;
      }
      return (
        <React.Fragment>
          <div>
            {addQuestionGroupState.active && (
              <EditModal
                child={<AddQuestionGroupModalContent state={addQuestionGroupState} />}
                size="lg"
              />
            )}
            {editQuestionGroupState.active && (
              <EditModal
                child={<EditQuestionGroupModalContent state={editQuestionGroupState} />}
                size="lg"
              />
            )}
            {deleteQuestionGroupState.active && (
              <EditModal
                child={
                  <DeleteQuestionModalContent state={deleteQuestionGroupState} />
                }
              />
            )}
          </div>
          <Form
            header={
              <div>
                <div>
                  <div>
                    {props.editingQuestionnaireGroupState.value ? (
                      <>
                        {props.inputState.questionnaireState.length === 0 &&
                          <Box className="vertical-center" variant="strong">
                            No question groups added
                          </Box>
                        }
                        <Button
                          variant="normal"
                          onClick={(e) =>
                            buttonGroupClickEventHandler({
                              buttonId: buttonDropdownItems.addQuestionGroup.id,
                              actionQuestionGroup: null
                            })
                          }
                        >
                          Add Question Group
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() =>
                          props.editingQuestionnaireGroupState.setValue(true)
                        }
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
                <br></br>
              </div>
            }
          >
            {props.inputState.questionnaireState.length > 0 && renderExpandAllGroupsIcon()}
            {props.inputState.questionnaireState.map((questionGroup) =>
              createAccordionRow(
                QuestionnaireGroup.QuestionGroup.create({
                  id: questionGroup.id,
                  groupName: questionGroup.groupName,
                  questions: questionGroup.questions
                }),
                questionGroup.id
              )
            )}
          </Form>
        </React.Fragment>
      );
    };

    export const EditModal = (props: {
      child: JSX.Element;
      size?: "sm" | "lg" | "xl";
    }) => {
      const onCancel = () => {
        props.child.props.state.reset();
        props.child.props.state.deactivate();
      };

      const onSave = () => {
        props.child.props.state.save();
        props.child.props.state.deactivate();
      };

      return (
        <CustomCommonContainerModal
          header={props.child.props.state.actionName}
          loading={false}
          size={props.size}
          onCancel={onCancel}
          onSave={onSave}
          child={props.child}
        />
      );
    };

    export const AddQuestionGroupModalContent = (props: {
      state: QuestionnaireGroupModel.UI.Update.AddQuestionGroupState;
    }) => {
      return (
        <div>
          <b>Group Name</b>
          <UIField.StateValueField
            state={props.state.groupField}
            name=""
            editing={true}
          />
        </div>
      );
    };

    export const EditQuestionGroupModalContent = (props: {
      state: QuestionnaireGroupModel.UI.Update.EditQuestionGroupState;
    }) => {
      return (
        <div>
          <b>Group Name</b>
          <Input
            value={props.state.editQuestionInputState.value.groupName}
            onChange={({ detail }) =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .groupName(detail.value)
                  .build()
              )
            }
          />
          <br />
          <b>Questions</b>
          <BasicAccordionGroupEdit
            existingQuestions={Questionnaire.Data.create({ questions: props.state.editQuestionInputState.value.questions })}
            onChange={(content) =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .questions(content)
                  .build()
              )
            } />
        </div>
      );
    };

    export const DeleteQuestionModalContent = (props: {
      state: QuestionnaireGroupModel.UI.Update.DeleteQuestionGroupState;
    }) => {
      return (
        <div>
          Question Group: "
          <strong>{props.state.activeQuestionGroupState.value.groupName}</strong>"
          will be deleted, are you sure?
        </div>
      );
    };
  }
}
import {
  AdvancedList,
  ChoiceOption,
  ItemChoiceOption,
  ItemHyperlink,
  CreateAdvancedListItemCommandInput,
  EntityRef,
  FieldDefinition,
  FieldDefinitionDataType,
  IdentityRef,
  IdentityRealm,
  IdentityType,
} from "@amzn/altar-sds-client";
import {
  Box,
  Checkbox,
  CheckboxProps,
  DatePicker,
  DatePickerProps,
  FormField,
  FormFieldProps,
  Input,
  InputProps,
  SpaceBetween,
  Textarea,
  TextareaProps,
} from "@amzn/awsui-components-react";
import * as React from "react";
import {
  AdvancedListConstants,
  NumberFormatUtil,
} from "../../utils/advanced-list.constant";
import { UserSearch } from "../common/UserSearch";
import { EmployeeIdentity, FieldConfiguration } from "@amzn/ask-legal-domain";
import { NumberFieldView } from "./fields/NumberFields/NumberFieldView";
import { DateFieldView } from "./fields/DateFields/DateFieldView";
import { MultiselectWithFreeText, MultiselectWithFreeTextProps } from "../advanced-list/fields/ChoiceFields/MultiselectWithFreeText";
import { SelectWithFreeText, SelectWithFreeTextProps } from "./fields/ChoiceFields/SingleSelectWithFreeText";
import { HyperLinkEditProps, HyperlinkFieldEdit } from "./fields/HyperlinkFields/HyperlinkFieldEdit";
import { AdvancedListFactory } from "../../factory/advanced-list-factory";

export function CreateAdvancedListItemCommandInputEdit(props: {
  listRef: EntityRef;
  advancedList: AdvancedList;
  by: IdentityRef;
  fieldConfigurations?: FieldConfiguration.Record;
  value?: CreateAdvancedListItemCommandInput;
  onChanged?: (value?: CreateAdvancedListItemCommandInput) => void;
}) {
  const fieldDefinitions = (props.advancedList.fieldDefinitions ?? []).filter(
    e => !AdvancedListFactory.isReservedField(e.fieldKey)
  );
  const [formFieldPropsList, setFormFieldPropsList] = React.useState<
    (Pick<FormFieldProps, "label" | "description" | "errorText"> & {
      fieldDef: FieldDefinition;
    })[]
  >(
    fieldDefinitions.map((e) => ({
      label: e.displayName,
      description: e.description,
      fieldDef: e,
    }))
  );

  const [stringPropsList, setStringPropsList] = React.useState<
    (Pick<TextareaProps, "value"> & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.string)
      .map((e) => ({
        value:
          (props.value?.values?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) || "",
        fieldKey: e.fieldKey,
      }))
  );

  const [selectWithFreeTextPropsList, setSelectWithFreeTextPropsList] = React.useState<
    (SelectWithFreeTextProps & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.choice)
      .filter((e) => (props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Choice)?.type
        === FieldConfiguration.ChoiceType.SINGLE
      )
      .map((e) => {
        return {
          fieldKey: e.fieldKey,
          selectedOption: null,
          isFreeTextAllowed: e.allowFreeTextOption,
          options: e.choiceOptions?.map(o => {
            return {
              key: o.key,
              displayValue: o.displayValue
            };
          })
        };
      }
      )
  );

  const [multiSelectWithFreeTextPropsList, setMultiSelectWithFreeTextPropsList] = React.useState<
    (MultiselectWithFreeTextProps & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.choice)
      .filter((e) => (props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Choice)?.type
        === FieldConfiguration.ChoiceType.MULTIPLE
      )
      .map((e) => {
        return {
          fieldKey: e.fieldKey!,
          isFreeTextAllowed: e.allowFreeTextOption,
          selectedOptions: [],
          options: [
            ...e.choiceOptions?.map(o => {
              return {
                key: o.key,
                displayValue: o.displayValue
              };
            })
          ]
        };
      })
  );

  const [checkboxPropsList, setCheckboxPropsList] = React.useState<
    (Pick<CheckboxProps, "checked"> & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.boolean)
      .map((e) => ({
        checked:
          (props.value?.values?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as boolean) || false,
        fieldKey: e.fieldKey,
      }))
  );

  const [numberPropsList, setNumberPropsList] = React.useState<
    (Pick<InputProps, "value"> & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.number)
      .map((e) => ({
        value:
          (props.value?.values?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) || "",
        fieldKey: e.fieldKey,
      }))
  );

  const [datePropsList, setDatePropsList] = React.useState<
    (Pick<DatePickerProps, "value" | "placeholder"> & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.date)
      .map((e) => ({
        value:
          (props.value?.values?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) || "",
        fieldKey: e.fieldKey,
        placeholder: AdvancedListConstants.DEFAULT_DATE_FORMAT,
      }))
  );

  const [peopleSingleSelectPropsList, setPeopleSingleSelectPropsList] =
    React.useState<
      (Pick<Parameters<typeof UserSearch.Single>[0], "selected"> & {
        fieldKey: string;
      })[]
    >(
      fieldDefinitions
        .filter((e) => e.dataType === FieldDefinitionDataType.IdentityRef)
        .map((e) => ({
          fieldKey: e.fieldKey,
        }))
    );

  const [peopleMultiSelectPropsList, setPeopleMultiSelectPropsList] =
    React.useState<
      (Pick<Parameters<typeof UserSearch.Multiple>[0], "initialSelected"> & {
        fieldKey: string;
      })[]
    >(
      fieldDefinitions
        .filter((e) => e.dataType === FieldDefinitionDataType.multiIdentityRef)
        .map((e) => ({
          fieldKey: e.fieldKey,
        }))
    );

  const [hyperlinkPropsList, setHyperlinkPropsList] = React.useState<
    (Pick<HyperLinkEditProps, "value"> & { fieldKey: string })[]
  >(
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.hyperlink)
      .map((e) => ({
        fieldKey: e.fieldKey,
        value: props.value?.values?.find(
          (v) => v.key === e.fieldKey && v.type === e.dataType
        )?.value as any as ItemHyperlink || { href: "" },
      }))
  );

  const sequencePropsList: (Pick<InputProps, "value" | "disabled"> & { fieldKey: string })[] = (
    fieldDefinitions
      .filter((e) => e.dataType === FieldDefinitionDataType.sequence)
      .map((e) => ({
        value:
          (props.value?.values?.find(
            (v) => v.key === e.fieldKey && v.type === e.dataType
          )?.value as string) || "",
        fieldKey: e.fieldKey,
        disabled: true,
      }))
  );

  function updateHyperlinkPropsValue(fieldKey: string, value?: ItemHyperlink) {
    setHyperlinkPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateCheckboxPropsValue(fieldKey: string, value?: boolean) {
    setCheckboxPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          checked: value,
        };
      })
    );
  }

  function updateSelectWithFreeTextPropsValue(fieldKey: string, value?: ItemChoiceOption) {
    setSelectWithFreeTextPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selectedOption: value,
        };
      })
    );
  }


  function updateMultiSelectWithFreeTextPropsValue(fieldKey: string, value?: ItemChoiceOption[]) {
    setMultiSelectWithFreeTextPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selectedOptions: value,
        };
      })
    );
  }

  function updateDatePropsValue(fieldKey: string, value?: string) {
    setDatePropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateStringPropsValue(fieldKey: string, value?: string) {
    setStringPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updateNumberPropsValue(fieldKey: string, value?: string) {
    setNumberPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          value: value,
        };
      })
    );
  }

  function updatePeopleSingleSelectPropsValue(
    fieldKey: string,
    value?: EmployeeIdentity
  ) {
    setPeopleSingleSelectPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          selected: value,
        };
      })
    );
  }

  function updatePeopleMultiSelectPropsValue(
    fieldKey: string,
    value?: EmployeeIdentity[]
  ) {
    setPeopleMultiSelectPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldKey !== fieldKey) return e;
        return {
          ...e,
          initialSelected: value,
        };
      })
    );
  }

  function updateFormFieldProps(
    fieldKey: string,
    value?: Pick<FormFieldProps, "label" | "errorText">
  ) {
    setFormFieldPropsList((prev) =>
      prev.map((e) => {
        if (e.fieldDef.fieldKey !== fieldKey) return e;
        return {
          ...e,
          ...value,
        };
      })
    );
  }

  function validate() {
    let valid = true;
    for (const stringProps of stringPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === stringProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !stringProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const checkboxProps of checkboxPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === checkboxProps.fieldKey
      );
      if (
        formFieldProps.fieldDef.required &&
        typeof checkboxProps === "undefined"
      ) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const dateProps of datePropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === dateProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !dateProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const selectWithFreeTextProps of selectWithFreeTextPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === selectWithFreeTextProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !selectWithFreeTextProps.selectedOption?.displayValue?.trim()?.length) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const multiSelectWithFreeTextProps of multiSelectWithFreeTextPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === multiSelectWithFreeTextProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !multiSelectWithFreeTextProps.selectedOptions?.length) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const numberProps of numberPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === numberProps.fieldKey
      );

      if (formFieldProps.fieldDef.required && !numberProps.value) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }

      if (
        numberProps.value &&
        !NumberFormatUtil.numberRegex.test(numberProps.value)
      ) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Not a valid number",
        });
        valid = false;
        continue;
      }

      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const peopleSelectProps of peopleMultiSelectPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === peopleSelectProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !peopleSelectProps.initialSelected) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const peopleSelectProps of peopleSingleSelectPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === peopleSelectProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !peopleSelectProps.selected) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    for (const hyperlinkProps of hyperlinkPropsList) {
      const formFieldProps = formFieldPropsList.find(
        (e) => e.fieldDef.fieldKey === hyperlinkProps.fieldKey
      );
      if (formFieldProps.fieldDef.required && !hyperlinkProps.value.href) {
        updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
          errorText: "Required",
        });
        valid = false;
        continue;
      }
      updateFormFieldProps(formFieldProps.fieldDef.fieldKey, {
        errorText: undefined,
      });
    }

    let ret: CreateAdvancedListItemCommandInput | undefined;
    if (valid) {
      ret = {
        repositoryId: props.listRef.repositoryRef.repositoryId,
        entityId: props.listRef.entityId,
        by: props.by,
        values: [
          ...stringPropsList
            .filter((e) => e.value)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.string,
              value: e.value,
            })),
          ...selectWithFreeTextPropsList
            .filter((e) => !!e.selectedOption?.displayValue)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.choice,
              value: [{
                key: e.selectedOption.key,
                displayValue: e.selectedOption.displayValue!,
              }]
            })),
          ...multiSelectWithFreeTextPropsList
            .filter((e) => !!e.selectedOptions)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.choice,
              value: e.selectedOptions.map((option: ItemChoiceOption) => ({
                key: option.key,
                displayValue: option.displayValue!,
              }))
            })),
          ...checkboxPropsList
            .filter((e) => typeof e.checked !== "undefined")
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.boolean,
              value: e.checked,
            })),
          ...datePropsList
            .filter((e) => e.value)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.date,
              value: e.value,
            })),
          ...numberPropsList
            .filter((e) => e.value)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.number,
              value: Number(e.value),
            })),
          ...peopleMultiSelectPropsList
            .filter((e) => e.initialSelected)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.multiIdentityRef,
              value: e.initialSelected.map(v => ({
                realm: IdentityRealm.AMAZON,
                type: IdentityType.PERSON,
                id: v.id
              }))
            })),
          ...peopleSingleSelectPropsList
            .filter((e) => e.selected)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.IdentityRef,
              value: {
                realm: IdentityRealm.AMAZON,
                type: IdentityType.PERSON,
                id: e.selected.id,
              },
            })),
          ...hyperlinkPropsList
            .filter((e) => e.value)
            .map((e) => ({
              key: e.fieldKey,
              type: FieldDefinitionDataType.hyperlink,
              value: {
                ...e.value,
                title: e.value.title || e.value.href
              },
            })),
        ],
      };
    }
    props.onChanged(ret);
  }

  React.useEffect(() => {
    validate();
  }, [
    stringPropsList,
    checkboxPropsList,
    datePropsList,
    numberPropsList,
    peopleSingleSelectPropsList,
    selectWithFreeTextPropsList,
    multiSelectWithFreeTextPropsList,
    hyperlinkPropsList,
    peopleMultiSelectPropsList
  ]);

  return (
    <SpaceBetween size={"s"}>
      {formFieldPropsList.map((formFieldProps) => (
        <FormField {...formFieldProps}>
          {hyperlinkPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <HyperlinkFieldEdit
                {...e}
                onChange={(event) => {
                  updateHyperlinkPropsValue(e.fieldKey, event);
                }}
              />
            ))
          }
          {checkboxPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <Checkbox
                {...e}
                onChange={(event) => {
                  updateCheckboxPropsValue(e.fieldKey, event.detail.checked);
                }}
              />
            ))}
          {selectWithFreeTextPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SelectWithFreeText
                {...e}
                onChange={(selectedChoiceOption: ItemChoiceOption) => {
                  updateSelectWithFreeTextPropsValue(e.fieldKey, selectedChoiceOption);
                }}
              />
            ))
          }
          {multiSelectWithFreeTextPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <MultiselectWithFreeText
                {...e}
                onChange={(selectedChoiceOptions: ChoiceOption[]) => {
                  updateMultiSelectWithFreeTextPropsValue(e.fieldKey, selectedChoiceOptions);
                }}
              />
            ))
          }
          {datePropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SpaceBetween direction="vertical" size="xxxs">
                <DatePicker
                  {...e}
                  onChange={(event) => {
                    updateDatePropsValue(e.fieldKey, event.detail.value);
                  }}
                />
                {datePropsList
                  .find((n) => n.fieldKey === e.fieldKey)
                  .value?.trim().length > 0 && (
                    <SpaceBetween direction="horizontal" size="xxxs">
                      <Box variant="awsui-key-label" fontSize="body-s">
                        Preview:
                      </Box>
                      <DateFieldView
                        dateFieldValue={datePropsList
                          .find((n) => n.fieldKey === e.fieldKey)
                          .value?.trim()}
                        fieldConfiguration={
                          props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Date
                        }
                      />
                    </SpaceBetween>
                  )}
              </SpaceBetween>
            ))}
          {stringPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <Textarea
                {...e}
                onChange={(event) => {
                  updateStringPropsValue(e.fieldKey, event.detail.value);
                }}
              />
            ))}
          {numberPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <SpaceBetween direction="vertical" size="xxxs">
                <Input
                  {...e}
                  onChange={(event) => {
                    updateNumberPropsValue(e.fieldKey, event.detail.value);
                  }}
                />
                {numberPropsList
                  .find((n) => n.fieldKey === e.fieldKey)
                  .value?.trim().length > 0 && (
                    <SpaceBetween direction="horizontal" size="xxxs">
                      <Box variant="awsui-key-label" fontSize="body-s">
                        Preview:
                      </Box>
                      <Box variant="awsui-key-label" fontSize="body-s">
                        <NumberFieldView
                          numberFieldValue={numberPropsList
                            .find((n) => n.fieldKey === e.fieldKey)
                            .value?.trim()}
                          fieldConfiguration={
                            props.fieldConfigurations[e.fieldKey] as FieldConfiguration.Number
                          }
                        />
                      </Box>
                    </SpaceBetween>
                  )}
              </SpaceBetween>
            ))}
          {peopleSingleSelectPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <UserSearch.Single
                {...e}
                onUserSelectChange={(selected) => {
                  updatePeopleSingleSelectPropsValue(e.fieldKey, selected);
                }}
              />
            ))}
          {peopleMultiSelectPropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => (
              <UserSearch.Multiple
                {...e}
                onUserSelectChange={(selected) => {
                  updatePeopleMultiSelectPropsValue(e.fieldKey, selected);
                }}
              />
            ))}
          {sequencePropsList
            .filter((e) => e.fieldKey === formFieldProps.fieldDef.fieldKey)
            .map((e) => <Input {...e} placeholder="Auto generated" />)
          }
        </FormField>
      ))}
    </SpaceBetween>
  );
}

import { AdminPreferences, SearchInterface } from "@amzn/ask-legal-domain";
import { ExpandableSection, RadioGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

export const PaginationSetting = (props: {
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
    prefs: AdminPreferences.PaginationOptions[];
}) => {
    const [selected, setSelected] = React.useState(props.queryInputState.value.pageSize.toString());

    const options = props.prefs.map(v => {
        return { label: v, value: v };
    });

    React.useEffect(() => {
        if (!selected) {
            setSelected("10");
        }
        let inputState = Object.assign({}, props.queryInputState.value);
        inputState.pageSize = +selected;
        props.queryInputState.setValue(inputState);
    }, [selected]);

    React.useEffect(() => {
        setSelected(props.queryInputState.value.pageSize.toString());
    }, [props.queryInputState.value.queryText]);

    return (
        <ExpandableSection header="Results per page">
            <RadioGroup
                value={selected}
                onChange={({ detail }) => setSelected(detail.value)}
                items={options}
            />
        </ExpandableSection>
    );
};
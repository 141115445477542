import {
  AdvancedList,
  AdvancedListItem,
  CreateAdvancedListItemCommandOutput,
  EntityRef,
  IdentityRef,
} from "@amzn/altar-sds-client";
import {
  APIOutput,
  ContainerRef,
  FieldConfiguration
} from "@amzn/ask-legal-domain";
import {
  Box,
  Button,
  Flashbar,
  FlashbarProps,
  Header,
  Modal,
  SpaceBetween,
  Spinner,
  SpinnerProps,
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { CreateAdvancedListItemCommandInputEdit } from "./CreateAdvancedListItemCommandInputEdit";
import { Builder } from "builder-pattern";

export function CreateAdvancedListItemModal(props: {
  listRef: EntityRef;
  advancedList: AdvancedList;
  by: IdentityRef;
  containerRef: ContainerRef;
  fieldConfigurations?: FieldConfiguration.Record;
  onCreated?: (item: AdvancedListItem) => void;
  onCanceled?: () => void;
}) {
  const context = React.useContext(AppContext);
  const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
  const [flashbarProps, setFlashbarProps] =
    React.useState<Pick<FlashbarProps, "items">>();
  const [
    createAdvancedListItemCommandInputEditProps,
    setCreateAdvancedListItemCommandInputEditProps,
  ] = React.useState<
    Parameters<typeof CreateAdvancedListItemCommandInputEdit>[0]
  >({
    listRef: props.listRef,
    advancedList: Builder(props.advancedList)
      .fieldDefinitions(props.advancedList.fieldDefinitions
      .filter((def) => !def.deprecated))
      .build(),
    by: props.by,
    fieldConfigurations: props.fieldConfigurations,
  });

  async function createItem() {
    try {
      setSpinnerProps({});

      const createItemOutput = await context
        .getAdvancedListAPI()
        .createItem({
          ...createAdvancedListItemCommandInputEditProps.value,
          containerId: props.containerRef.id
        });

      const output = APIOutput.fromRaw<CreateAdvancedListItemCommandOutput>(createItemOutput.data);
      if (output.isErr()) {
        setFlashbarProps({
          items: [
            {
              type: "error",
              content: output.err.message
            }
          ]
        });
      } else {
        setFlashbarProps({
          items: []
        });
        props.onCreated?.(output.data.body);
      }
    } catch (err) {
      setFlashbarProps({
        items: [
          {
            type: "error",
            content: (err as Error).message
          }
        ]
      });
    } finally {
      setSpinnerProps(undefined);
    }
  }

  return (
    <Modal
      visible={true}
      header={<Header>New Item</Header>}
      footer={<Box float="right">
        <SpaceBetween size={"s"} direction="horizontal">
          <Button
            onClick={(e) => {
              props.onCanceled?.();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!createAdvancedListItemCommandInputEditProps?.value}
            onClick={(e) => {
              createItem();
            }}
          >
            Confirm
          </Button>
        </SpaceBetween>
      </Box>}
      onDismiss={() => props.onCanceled?.()}
    >
      {spinnerProps && <Modal visible={true}><Spinner /></Modal>}
      {flashbarProps && <Flashbar {...flashbarProps} />}
      <CreateAdvancedListItemCommandInputEdit
        {...createAdvancedListItemCommandInputEditProps}
        onChanged={(value) => {
          setCreateAdvancedListItemCommandInputEditProps((prev) => ({
            ...prev,
            value: value
          }));
        }} />
    </Modal>
  );
}

import { EntityFactory } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ButtonDropdown,
    CollectionPreferences,
    CollectionPreferencesProps,
    Flashbar,
    Header,
    Pagination,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { RecommendationRulePolarisFactory } from "../../factory/polaris/rec-rule-polaris-factory";
import { useAPI } from "../../hooks/api-hook";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";
import { RecommendationRuleModel } from "../../model/recommendation-rule-model";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { SimpleModal } from "../common/SimpleModal";
import { RuleDeleteContent, RuleFieldsEditing } from "./modal-content/RuleFieldsEditing";

const INITIAL_PAGE_SIZE = 10;

export const RecommendationRuleTable = (props: { id: string }) => {
    const context = React.useContext(AppContext);
    const [buttonAction, setButtonAction] = React.useState<string>("");
    const [statusUpdates, setStatusUpdates] = React.useState<Array<any>>([]);
    const [canDelete, setCanDelete] = React.useState<boolean>(false);

    const editState = RecommendationRuleModel.EditState.use();

    const createRuleRunner = useAPI(
        context.getRecommendationRuleAPI().create
    );
    const updateRuleRunner = useAPI(
        context.getRecommendationRuleAPI().update
    );
    const deleteRuleRunner = useAPI(
        context.getRecommendationRuleAPI().remove
    );

    const columnDefinitions = RecommendationRulePolarisFactory.Table.toColumnDefinitions();
    const tableDefaultPreferences = Builder<CollectionPreferencesProps.Preferences<any>>()
        .pageSize(INITIAL_PAGE_SIZE)
        .visibleContent([
            CommonPolarisFactory.Table.ColumnDefinition.Name.id,
            RecommendationRulePolarisFactory.Table.RuleType.id,
            RecommendationRulePolarisFactory.Table.GeoCodes.id,
            RecommendationRulePolarisFactory.Table.Roles.id,
            RecommendationRulePolarisFactory.Table.Tenure.id,
        ])
        .build();

    const collection = useLoadingWithServerSidePagination(
        context.getRecommendationRuleAPI().loadByInstanceId,
        props.id,
        {
            columnDefinitions: columnDefinitions,
            defaultTablePreferences: tableDefaultPreferences,
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ],
            selectionType: "single"
        }
    );

    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };

    const header = (<Header
        variant="h2"
        counter={counter()}
        actions={
            <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button iconName="refresh" variant="icon" onClick={collection.loadItems}/>
                    <ButtonDropdown
                        loading={false}
                        items={[
                            {
                                text: "Edit",
                                id: "update",
                                disabled: collection.table.props.selectedItems.length !== 1
                            }, {
                                text: "Delete",
                                id: "delete",
                                disabled: collection.table.props.selectedItems.length !== 1
                            }
                        ]}
                        onItemClick={(event: { detail: { id: string } } ) => {
                            if (event.detail.id === "update") {
                                editState.init(collection.table.props.selectedItems[0]);
                                setButtonAction("update");
                            } else if (event.detail.id === "delete") {
                                setButtonAction("delete");
                            }
                        }}
                    >
                        Actions
                    </ButtonDropdown>
                    <Button
                        variant="primary"
                        onClick={() => {
                            editState.init();
                            setButtonAction("create");
                        }}
                    >Create Rule</Button>
                </SpaceBetween>
            </Box>
        }>
        Recommendation Rules
    </Header>);

    const empty = (<Box textAlign="center" color="inherit">
        <b>No Recommendation Rules</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Recommendation Rules to display
        </Box>
        <Button variant="primary" onClick={() => {
            editState.init();
            setButtonAction("create");
        }}>Create Rule</Button>
    </Box>);

    const setDependency = (exists: boolean) => {
        setCanDelete(exists);
    };

    React.useEffect(() => {
        if (createRuleRunner.status === "Succeeded") {
            setButtonAction("");
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Rule: ${createRuleRunner.data.output.name} was successfully created.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (createRuleRunner.status === "Error") {
            setButtonAction("");
            setStatusUpdates([{
                type: "error",
                content: `Failed to create rule. Reason: ${createRuleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [createRuleRunner.status]);

    React.useEffect(() => {
        if (updateRuleRunner.status === "Succeeded") {
            setButtonAction("");
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Rule: ${updateRuleRunner.data.output.name} was successfully updated.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (updateRuleRunner.status === "Error") {
            setButtonAction("");
            setStatusUpdates([{
                type: "error",
                content: `Failed to update rule. Reason: ${updateRuleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [updateRuleRunner.status]);

    React.useEffect(() => {
        if (deleteRuleRunner.status === "Succeeded") {
            setButtonAction("");
            collection.loadItems();
            setStatusUpdates([{
                type: "success",
                content: `Rule: ${deleteRuleRunner.data.output.name} was successfully deleted.`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        } else if (deleteRuleRunner.status === "Running") {
            setStatusUpdates([{
                type: "success",
                content: `Deleting selected rule.`,
                loading: true
            }]);
        } else if (deleteRuleRunner.status === "Error") {
            setStatusUpdates([{
                type: "error",
                content: `Failed to delete rule. Reason: ${deleteRuleRunner.data.err.message}`,
                dismissible: true,
                onDismiss: () => setStatusUpdates([])
            }]);
        }
    }, [deleteRuleRunner.status]);

    return (
        <React.Fragment>
            <SpaceBetween size="s">
                <Flashbar
                    items={statusUpdates}
                />
                { !!collection.table.error && <ErrorFlashbar error={collection.table.error}/>}
                { buttonAction === "create" && (
                    <SimpleModal
                        header="Create new Rule"
                        child={<RuleFieldsEditing state={editState}  instanceId={props.id}/>}
                        loading={createRuleRunner.status === "Running"}
                        size="large"
                        disabled={editState.errorText.length > 0}
                        onCancel={() => {
                            setButtonAction("");
                            editState.reset();
                        }}
                        onSave={ () => createRuleRunner.submitRun(
                            RecommendationRuleModel.EditState.toCreateInput({
                                state: editState,
                                instanceId: props.id
                            })
                        )}
                    />
                )}
                { buttonAction === "update" && (
                    <SimpleModal
                        header="Update Rule"
                        size="large"
                        child={<RuleFieldsEditing state={editState} instanceId={props.id}/>}
                        loading={updateRuleRunner.status === "Running"}
                        disabled={editState.errorText.length > 0}
                        onCancel={() => {
                            setButtonAction("");
                            editState.reset();
                        }}
                        onSave={() => updateRuleRunner.submitRun(
                            RecommendationRuleModel.EditState.toUpdateInput({
                                state: editState,
                                ruleRef: EntityFactory.toEntityRef(collection.table.props.selectedItems[0])
                            })
                        )}
                    />
                )}
                { buttonAction === "delete" && (
                    <SimpleModal
                        header="Delete Rule"
                        disableLeavingAlert
                        child={
                            <RuleDeleteContent
                                instanceId={props.id}
                                ruleId={collection.table.props.selectedItems[0].id}
                                setCanDelete={setDependency}
                            />}
                        loading={false}
                        onCancel={() => setButtonAction("")}
                        onSave={() => {
                            deleteRuleRunner.submitRun(collection.table.props.selectedItems[0].id);
                            setButtonAction("");
                        }}
                        disabled={!canDelete}
                    />
                )}
                <Table
                    {...collection.table.props}
                    empty={empty}
                    header={header}
                    preferences={
                        <CollectionPreferences {...collection.tablePreference.props}/>
                    }
                    pagination={
                        <Pagination {...collection.tablePagination.props}/>
                    }
                    // filter={
                    //     <PropertyFilter {...collection.tableFiltering.props}/>
                    // }
                />
            </SpaceBetween>
        </React.Fragment>
    );
};
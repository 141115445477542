import { EntityRef, EntityType, FilterField } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { Box, CollectionPreferences, CollectionPreferencesProps, Header, Pagination, PropertyFilter, Table } from "@amzn/awsui-components-react";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { Builder } from "builder-pattern";
import { PageDraftPolarisFactory } from "../../factory/polaris/page-draft-polaris-factory";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";
import { ErrorFlashbar } from "../common/ErrorFlashbar";

export const PageDraftTable = (props: {
    entityRef: EntityRef;
}) => {
    const context = React.useContext(AppContext);
    const columnDefinitions = props.entityRef.type === EntityType.Instance ?
        PageDraftPolarisFactory.Table.ColumnDefinition.toColumnDefinitionsAllDraftPageLibrary() :
        PageDraftPolarisFactory.Table.ColumnDefinition.toColumnDefinitionsAllDrafts();

    const tableDefaultPreferences = Builder<CollectionPreferencesProps.Preferences<any>>()
        .pageSize(10)
        .visibleContent(
            props.entityRef.type === EntityType.Instance ? [
                PageDraftPolarisFactory.Table.ColumnDefinition.Status.id,
                PageDraftPolarisFactory.Table.ColumnDefinition.Title.id,
                PageDraftPolarisFactory.Table.ColumnDefinition.PageOwner.id,
                PageDraftPolarisFactory.Table.ColumnDefinition.PageLibrary.id,
                CommonPolarisFactory.Table.ColumnDefinition.IsDeprecated.id,
                CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
                CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id,
            ] : [
                PageDraftPolarisFactory.Table.ColumnDefinition.Status.id,
                PageDraftPolarisFactory.Table.ColumnDefinition.Title.id,
                PageDraftPolarisFactory.Table.ColumnDefinition.PageOwner.id,
                CommonPolarisFactory.Table.ColumnDefinition.IsDeprecated.id,
                CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
                CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id,
            ])
        .build();
    const collection = useLoadingWithServerSidePagination(
        context.getPageDraftAPI().getPageDraftsByStatus,
        props.entityRef.id,
        {
            columnDefinitions: columnDefinitions,
            defaultTablePreferences: tableDefaultPreferences,
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ],

        },
        {
            filteringProperties: PageDraftPolarisFactory.Table.PropertyFilter.toFilterAllDrafts(),
            toOption: () => [
                { propertyKey: FilterField.DraftStatus, value: "PENDING_APPROVAL" },
                { propertyKey: FilterField.DraftStatus, value: "APPROVED" },
                { propertyKey: FilterField.DraftStatus, value: "REJECTED" },
                { propertyKey: FilterField.DraftStatus, value: "DRAFT" },
                { propertyKey: FilterField.DraftStatus, value: "PUBLISHED" },
                { propertyKey: FilterField.Deprecated, value: "Yes" },
                { propertyKey: FilterField.Deprecated, value: "No" }
            ]
        },
        props.entityRef
    );

    const counter = () => {
        if (collection.table.props.loading) return "";
        if (collection.table.props.items) {
            return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
        }
        else {
            return `(0)`;
        }
    };

    const header = (<Header
        variant="h2"
        counter={counter()}>
        Draft Pages
    </Header>);

    const empty = (<Box textAlign="center" color="inherit">
        <b>No Drafts</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Drafts to display
        </Box>
    </Box>);

    return (
        <React.Fragment>
            {!!collection.table.error && <ErrorFlashbar error={collection.table.error} />}
            <Table
                {...collection.table.props}
                empty={empty}
                header={header}
                preferences={
                    <CollectionPreferences {...collection.tablePreference.props} />
                }
                pagination={
                    <Pagination {...collection.tablePagination.props} />
                }
                filter={
                    <PropertyFilter {...collection.tableFiltering.props} />
                }
            />
        </React.Fragment>
    );
};
import { ColumnLayout } from "@amzn/awsui-components-react";
import * as React from "react";
import { LabelModel } from "../../../model/label-model";
import { UIField } from "../../common/UIField";

export const LabelModalContent = (props: {
    state: LabelModel.LabelState;
}) => {
    return (
        <React.Fragment>
            <ColumnLayout columns={1}>
                <UIField.StateValueField
                    state={props.state.name}
                    name="Name"
                    constraintText={`Maximum ${LabelModel.NAME_CHAR_LIMIT} characters`}
                    editing={true}
                />
            </ColumnLayout>
        </React.Fragment>
    );
};
import * as React from "react";
import {
    FilterField,
    IResourceLabelLink,
    Label,
    ResourceLabelLink,
    ServerSidePaginatedLoadingInput
} from "@amzn/ask-legal-domain";
import { EntitySelection } from "../common/EntitySelection";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { Box, Button, Modal, Spinner, TokenGroup, TokenGroupProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { ErrorFlashbar } from "../common/ErrorFlashbar";

export const LabelAssignModal = (props: {
    /**
     * Please refer to AskLegalDomain-Entity-ResourceLabelLink for definition of resourceId
     */
    resourceId: string;
    resourceType: ResourceLabelLink.Type;
    instanceId: string;
    onCloseModal: () => void;
    onLinkSucceeded?: (label: Label.Data) => void;
    onUnlinkSucceeded?: (labelId: string) => void;
}) => {
    const context = React.useContext(AppContext);
    const [selectedLabel, setSelectedLabel] = React.useState<Label.Data>(null);
    const [linkedLabels, setLinkedLabels] = React.useState<Label.Data[]>([]);

    const linkRunner = useAPI2(context.getLabelAPI().link);
    const unlinkRunner = useAPI2(context.getLabelAPI().unlink);
    const getLabelsByResourceRunner = useAPI2(context.getLabelAPI().getLabelsByResource);

    React.useEffect(() => {
        // Load all labels at once for this resource
        getLabelsByResourceRunner.invoke(ServerSidePaginatedLoadingInput.create({
            partitionKey: props.resourceId,
            currentPageIndex: 1,
            pageSize: 100,
            filters: []
        }));
    }, [props.resourceId]);

    React.useEffect(() => {
        if (getLabelsByResourceRunner.status === "Succeeded") {
            setLinkedLabels(getLabelsByResourceRunner.output.result);
        }
    }, [getLabelsByResourceRunner.status]);

    React.useEffect(() => {
        if (!!selectedLabel) {
            if (!!linkedLabels.find(l => l.id === selectedLabel.id)) {
                setSelectedLabel(null);
            } else {
                linkRunner.invoke(IResourceLabelLink.LinkInput.create({
                    resourceId: props.resourceId,
                    resourceType: props.resourceType,
                    labelId: selectedLabel.id
                }));
            }
        }
    }, [selectedLabel]);

    React.useEffect(() => {
        if (unlinkRunner.status === "Succeeded") {
            const findIndex = linkedLabels.findIndex(label => label.id === unlinkRunner.output.labelId);
            if (findIndex >= 0) {
                const temp = linkedLabels.slice();
                temp.splice(findIndex, 1);
                setLinkedLabels(temp);
                if (props.onUnlinkSucceeded) {
                    props.onUnlinkSucceeded(unlinkRunner.output.labelId);
                }
            }
        }
    }, [unlinkRunner.status]);

    React.useEffect(() => {
        if (linkRunner.status === "Succeeded") {
            const copySelectedLabel = Object.assign({}, selectedLabel);
            setLinkedLabels([...linkedLabels, copySelectedLabel]);
            if (props.onLinkSucceeded) {
                props.onLinkSucceeded(copySelectedLabel);
            }
            setSelectedLabel(null);
        }
    }, [linkRunner.status]);

    const isLoading = () => {
        return linkRunner.status === "Running" || unlinkRunner.status === "Running" || getLabelsByResourceRunner.status === "Running";
    };

    return (
        <Modal
            visible={true}
            size="medium"
            header="Attach a Label"
            footer={
                <Box float="right">
                    <Button variant="link" loading={isLoading()} onClick={props.onCloseModal}>
                        Close
                    </Button>
                </Box>
            }
            onDismiss={() => {
                if (!isLoading())
                    props.onCloseModal();
            }}>
            <div>
                {linkRunner.status === "Error" && <ErrorFlashbar error={linkRunner.err}/>}
                {unlinkRunner.status === "Error" && <ErrorFlashbar error={unlinkRunner.err}/>}
                {getLabelsByResourceRunner.status === "Error" && <ErrorFlashbar error={getLabelsByResourceRunner.err}/>}
                <EntitySelection.SingleWithPagination
                    api={context.getLabelAPI().loadByInstance}
                    partitionKey={props.instanceId}
                    selected={selectedLabel}
                    onSelectionChange={label => {
                        if (!linkedLabels.find(l => l.id === label.id))
                            setSelectedLabel(label);
                    }}
                    defaultFilters={[{
                        field: FilterField.Deprecated,
                        operator: "must",
                        negate: true,
                        value: "yes"
                    }]}
                    disabled={isLoading()}
                />
                <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => {
                        unlinkRunner.invoke({
                            resourceId: props.resourceId,
                            resourceType: props.resourceType,
                            labelId: linkedLabels[itemIndex].id
                        });
                    }}
                    items={linkedLabels.map(label =>
                        Builder<TokenGroupProps.Item>()
                            .label(label.name)
                            .disabled(isLoading())
                            .build()
                    )}
                />
                {isLoading() && <Spinner/>}
            </div>
        </Modal>
    );
};
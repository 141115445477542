import {
  Box,
  Button,
  ButtonDropdown,
  ButtonDropdownProps,
  Form,
  Header,
  Icon,
  SpaceBetween,
  TextContent,
} from "@amzn/awsui-components-react";
import { Questionnaire } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { QuestionnaireModel } from "../../../model/questionnaire-model";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { Accordion, Card } from "react-bootstrap";
import { LoadingSpinner } from "../../common/loading-spinner";
import { CustomCommonContainerModal } from "../CommonContainerModal";
import { SmartRichTextView } from "../../common/RichTextEditor/SmartRichTextView";
import { RestrictedHyperLinkOnlyRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/RestrictedHyperLinksOnlyEdit";
import { StrongRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";
import "../../../styles/component/accordion/accordion.scss";
import "../../../styles/component/layout/AskLegalLayout.scss";

export namespace QuestionnaireComponent {
  export namespace View {
    export const Main = (props: {
      inputState: Questionnaire.Question[];
      parentState?: string[];
      expandHandler?: (items: string[]) => void;
      collapseHandler?: (items: string[]) => void;
      diffMode?: boolean;
      expandCollapseAllTrigger?: { value: string; };
    }) => {
      const [expandedItems, setExpandedItems] = React.useState<string[]>(!props.parentState ? [] : props.parentState);

      const toggleHandler = (id: string) => {
        if (!id) return;
        if (props.expandHandler || props.collapseHandler) {
          if (props.parentState.includes(id)) {
            props.collapseHandler([id]);
          } else {
            props.expandHandler([id]);
          }
        } else {
          if (!expandedItems) return;
          if (expandedItems.includes(id)) {
            collapseAccordions([id]);
          } else {
            expandAccordions([id]);
          }
        }
      };

      const checkAllExpanded = () => {
        if (props.inputState.length <= 0) return false;
        return props.inputState.every(v => expandedItems.includes(v.id));
      };

      const expandAccordions = (items: string[]) => {
        setExpandedItems(expandedItems.concat(items));
      };

      const collapseAccordions = (items: string[]) => {
        const expandedList = expandedItems.filter(e => !items.includes(e));
        setExpandedItems(expandedList);
      };

      const expandAllAccordions = () => {
        expandAccordions(props.inputState.map(e => e.id));
      };

      const collapseAllAccordions = () => {
        collapseAccordions(props.inputState.map(e => e.id));
      };

      const renderExpandAllIcon = () => {
        return (
          <TextContent>
            <small>
              <a style={{ textDecoration: "none" }} onClick={() => {
                if (checkAllExpanded()) {
                  collapseAllAccordions();
                } else {
                  expandAllAccordions();
                }
              }}>
                [{checkAllExpanded() && `Collapse All`}{!checkAllExpanded() && `Expand All`}]
              </a>
            </small>
          </TextContent>
        );
      };

      const createAccordionHeader = (headerElement: JSX.Element, index: string) => {
        return (
          <div className="sherpa-accordion-title" onClick={() => {
            toggleHandler(index);
          }}>
            {!props.parentState ?
              (expandedItems.includes(index) ?
                <Icon name="caret-down-filled" size="normal" variant="normal" /> :
                <Icon name="caret-right-filled" size="normal" variant="normal" />) :
              (props.parentState.includes(index) ?
                <Icon name="caret-down-filled" size="normal" variant="normal" /> :
                <Icon name="caret-right-filled" size="normal" variant="normal" />)
            }
            {headerElement}
          </div>
        );
      };

      const createAccordionRow = (question: Questionnaire.Question, index: string) => (
        <Accordion
          key={question.id}
          activeKey={
            !props.parentState ?
            (expandedItems.includes(index) ? question.id : null) :
            (props.parentState.includes(index) ? question.id : null)
          }
        >
          <Card>
            <Card.Header>
              <Header variant="h3">
                {createAccordionHeader(<SmartRichTextView content={question.question} fontSize={18} diffMode={props.diffMode}/>, question.id)}
              </Header>
            </Card.Header>
            <Accordion.Collapse eventKey={question.id}>
              <Card.Body>
                <SmartRichTextView content={question.answer} diffMode={props.diffMode}/>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );

      React.useEffect(() => {
        if (props.diffMode) expandAccordions(props.inputState.map(e => e.id));
        const currentURL = window.location.href;
        if (!currentURL.includes("/live?")) return;
        const urlsearchparams = new URLSearchParams(currentURL.split("/live?")[1]);
        if (urlsearchparams && urlsearchparams.get("question")) {
          const qId = urlsearchparams.get("question");
          expandAccordions([qId]);
        }
      }, []);

      React.useEffect(() => {
        if (!props.expandCollapseAllTrigger) return;
        if (props.expandCollapseAllTrigger.value === "expand") {
          expandAllAccordions();
        } else if (props.expandCollapseAllTrigger.value === "collapse") {
          collapseAllAccordions();
        }
      }, [props.expandCollapseAllTrigger]);

      return (
        <React.Fragment>
          {props.inputState.length > 0 && !props.parentState && renderExpandAllIcon()}
          {props.inputState.map((question) =>
            createAccordionRow(
              Questionnaire.Question.create({
                id: question.id,
                question: question.question,
                answer: question.answer,
              }),
              question.id
            )
          )}
        </React.Fragment>
      );
    };

    export const EditQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.EditQuestionState
    }) => {
      return (
        <div>
          <b>Question text</b>
          <RestrictedHyperLinkOnlyRichTextEdit
            content={props.state.editQuestionInputState.value.question}
            onChange={updated =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .question(updated)
                  .build()
              )}
          />
          <br />
          <b>Answer</b>
          <StrongRichTextEdit
            content={props.state.editQuestionInputState.value.answer}
            onChange={(value) =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .answer(value)
                  .build()
              )
            }
          />
        </div>
      );
    };

    export const DeleteQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.DeleteQuestionState;
    }) => {
      return (
        <div>
          Question: "
          <strong>{props.state.activeQuestionState.value.question}</strong>"
          will be deleted, are you sure?
        </div>
      );
    };
  }

  export namespace Edit {
    const buttonDropdownItems = {
      addQuestion: Builder<ButtonDropdownProps.Item>()
        .id("addQuestion")
        .text("Add Question")
        .build(),
      editQuestion: Builder<ButtonDropdownProps.Item>()
        .id("editQuestion")
        .text("Edit")
        .build(),
      deleteQuestion: Builder<ButtonDropdownProps.Item>()
        .id("deleteQuestion")
        .text("Delete")
        .build(),
      moveQuestionUp: Builder<ButtonDropdownProps.Item>()
        .id("moveQuestionUp")
        .text("Move Up")
        .build(),
      moveQuestionDown: Builder<ButtonDropdownProps.Item>()
        .id("moveQuestionDown")
        .text("Move Down")
        .build(),
    };

    export const Main = (props: {
      inputState: QuestionnaireModel.UI.Update.UpdateQuestionnaireState;
      editingQuestionnaireState: UIModel.State<boolean>;
      onSubmit: () => void;
      onChange: (content: Questionnaire.Question[]) => void;
    }) => {
      const [expandedItems, setExpandedItems] = React.useState<string[]>([]);

      const addQuestionState =
        QuestionnaireModel.UI.Update.AddQuestionState.use();
      const editQuestionState =
        QuestionnaireModel.UI.Update.EditQuestionState.use();
      const deleteQuestionState =
        QuestionnaireModel.UI.Update.DeleteQuestionState.use();

      React.useEffect(() => {
        props.onChange(props.inputState.questionnaireState);
      }, [props.inputState.questionnaireState]);

      React.useEffect(() => {
        if (!addQuestionState.result) return;
        props.inputState.addOrUpdateQuestion(addQuestionState.result);
      }, [addQuestionState.result]);

      React.useEffect(() => {
        if (!editQuestionState.result) return;
        props.inputState.addOrUpdateQuestion(editQuestionState.result);
      }, [editQuestionState.result]);

      React.useEffect(() => {
        if (!deleteQuestionState.result) return;
        props.inputState.deleteQuestion(deleteQuestionState.result);
      }, [deleteQuestionState.result]);

      const toggleHandler = (id: string) => {
        if (!id || !expandedItems) return;
        if (expandedItems.includes(id)) {
          collapseAccordions([id]);
        } else {
          expandAccordions([id]);
        }
      };

      const checkAllExpanded = () => {
        if (props.inputState.questionnaireState.length <= 0) return false;
        return props.inputState.questionnaireState.every(v => expandedItems.includes(v.id));
      };

      const expandAccordions = (items: string[]) => {
        setExpandedItems(expandedItems.concat(items));
      };

      const collapseAccordions = (items: string[]) => {
        const expandedList = expandedItems.filter(e => !items.includes(e));
        setExpandedItems(expandedList);
      };

      const renderExpandAllIcon = () => {
        return (
          <TextContent>
            <small>
              <a style={{ textDecoration: "none" }} onClick={() => {
                if (checkAllExpanded()) {
                  collapseAccordions(props.inputState.questionnaireState.map(e => e.id));
                } else {
                  expandAccordions(props.inputState.questionnaireState.map(e => e.id));
                }
              }}>
                [{checkAllExpanded() && `Collapse All`}{!checkAllExpanded() && `Expand All`}]
              </a>
            </small>
          </TextContent>
        );
      };

      const createAccordionHeader = (headerElement: JSX.Element, index: string) => {
        return (
          <div className="sherpa-accordion-title" onClick={() => {
            toggleHandler(index);
          }}>
            <SpaceBetween size="xxxs" direction="horizontal">
              {expandedItems.includes(index) ?
                <Icon name="caret-down-filled" size="normal" variant="normal" /> :
                <Icon name="caret-right-filled" size="normal" variant="normal" />
              }
              {headerElement}
            </SpaceBetween>
          </div>
        );
      };

      const createAccordionRow = (question: Questionnaire.Question, index: string) => (
        <Accordion
          key={question.id}
          activeKey={expandedItems.includes(index) ? question.id : null}
        >
          <Card>
            <Card.Header>
              <Header
                variant="h3"
                actions={props.editingQuestionnaireState.value === true ?
                  <ButtonDropdown
                    expandToViewport
                    items={[
                      buttonDropdownItems.editQuestion,
                      buttonDropdownItems.moveQuestionDown,
                      buttonDropdownItems.moveQuestionUp,
                      buttonDropdownItems.deleteQuestion,
                    ]}
                    onItemClick={(e) =>
                      buttonGroupClickEventHandler({
                        buttonId: e.detail.id,
                        actionQuestion: question,
                      })
                    }
                  >
                    Actions
                </ButtonDropdown>
                  : ""}
              >
                {createAccordionHeader(<SmartRichTextView content={question.question} fontSize={18}/>, question.id)}
              </Header>
            </Card.Header>
            <Accordion.Collapse eventKey={question.id}>
              <Card.Body>
                <SmartRichTextView content={question.answer}/>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );

      const buttonGroupClickEventHandler = (params: {
        buttonId: string;
        actionQuestion?: Questionnaire.Question;
      }) => {
        if (props.editingQuestionnaireState.value)
          switch (params.buttonId) {
            case "addQuestion":
              addQuestionState.reset();
              addQuestionState.activate();
              break;
            case "editQuestion":
              editQuestionState.reset();
              editQuestionState.activate();
              editQuestionState.activeQuestionState.setValue(
                params.actionQuestion
              );
              editQuestionState.editQuestionInputState.setValue(
                QuestionnaireModel.UI.Update.QuestionInputItem.fromQuestion(params.actionQuestion)
              );
              break;
            case "deleteQuestion":
              deleteQuestionState.reset();
              deleteQuestionState.activate();
              deleteQuestionState.activeQuestionState.setValue(
                params.actionQuestion
              );
              break;
            case "moveQuestionUp":
              props.inputState.moveQuestion(
                params.actionQuestion,
                QuestionnaireModel.UI.MoveAction.MOVE_UP
              );
              break;
            case "moveQuestionDown":
              props.inputState.moveQuestion(
                params.actionQuestion,
                QuestionnaireModel.UI.MoveAction.MOVE_DOWN
              );
              break;
          }
      };

      const onReset = () => {
        props.inputState.reset();
        props.editingQuestionnaireState.setValue(false);
      };

      if (!props.inputState.questionnaireState) {
        return <LoadingSpinner />;
      }
      return (
        <React.Fragment>
          <div>
            {addQuestionState.active && (
              <EditModal
                child={<AddQuestionModalContent state={addQuestionState} />}
                size="lg"
              />
            )}
            {editQuestionState.active && (
              <EditModal
                child={<EditQuestionModalContent state={editQuestionState} />}
                size="lg"
              />
            )}
            {deleteQuestionState.active && (
              <EditModal
                child={
                  <DeleteQuestionModalContent state={deleteQuestionState} />
                }
              />
            )}
          </div>
          <Form
            header={
              <div>
                {props.editingQuestionnaireState.value ? (
                  <>
                    {props.inputState.questionnaireState.length === 0 &&
                      <Box className="vertical-center" variant="strong">
                        No questions added
                      </Box>
                    }
                    <Button
                      variant="normal"
                      onClick={(e) =>
                        buttonGroupClickEventHandler({
                          buttonId: buttonDropdownItems.addQuestion.id,
                          actionQuestion: null
                        })
                      }
                    >
                      Add Question
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    onClick={() =>
                      props.editingQuestionnaireState.setValue(true)
                    }
                  >
                    Edit
                  </Button>
                )}
              </div>
            }
          >
            {props.inputState.questionnaireState.length > 0 && renderExpandAllIcon()}
            {props.inputState.questionnaireState.map((question) =>
              createAccordionRow(
                Questionnaire.Question.create({
                  id: question.id,
                  question: question.question,
                  answer: question.answer,
                }),
                question.id
              )
            )}
          </Form>
        </React.Fragment>
      );
    };

    export const EditModal = (props: {
      child: JSX.Element;
      size?: "sm" | "lg" | "xl";
    }) => {
      const onCancel = () => {
        props.child.props.state.reset();
        props.child.props.state.deactivate();
      };

      const onSave = () => {
        props.child.props.state.save();
        props.child.props.state.deactivate();
      };

      return (
        <CustomCommonContainerModal
          header={props.child.props.state.actionName}
          loading={false}
          size={props.size}
          onCancel={onCancel}
          onSave={onSave}
          child={props.child}
        />
      );
    };

    export const AddQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.AddQuestionState;
    }) => {
      return (
        <div>
          <b>Question text</b>
          <RestrictedHyperLinkOnlyRichTextEdit
            content={props.state.questionField.value}
            onChange={updated =>
              props.state.questionField.setValue(updated)
            }
          />
          <br />
          <b>Question default answer</b>
          <StrongRichTextEdit
            content={props.state.answerField.value}
            onChange={(value) => props.state.answerField.setValue(value)}
          />
        </div>
      );
    };

    export const EditQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.EditQuestionState;
    }) => {
      return (
        <div>
          <b>Question text</b>
          <RestrictedHyperLinkOnlyRichTextEdit
            content={props.state.editQuestionInputState.value.question}
            onChange={updated =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .question(updated)
                  .build()
              )
            }
          />
          <br />
          <b>Question default answer</b>
          <StrongRichTextEdit
            content={props.state.editQuestionInputState.value.answer}
            onChange={(value) =>
              props.state.editQuestionInputState.setValue(
                Builder(props.state.editQuestionInputState.value)
                  .answer(value)
                  .build()
              )
            }
          />
        </div>
      );
    };

    export const DeleteQuestionModalContent = (props: {
      state: QuestionnaireModel.UI.Update.DeleteQuestionState;
    }) => {
      return (
        <div>
          Question: "
          <strong>{props.state.activeQuestionState.value.question}</strong>"
          will be deleted, are you sure?
        </div>
      );
    };
  }
}
import { Builder } from "builder-pattern";
import { TableProps, Badge, Popover } from "@amzn/awsui-components-react";
import { GeoType, RecommendationRule } from "@amzn/ask-legal-domain";
import { CommonPolarisFactory } from "./common-polaris-factory";
import * as React from "react";
import { RolePopover } from "../../components/role/RoleInfoLink";

export namespace RecommendationRulePolarisFactory {
    export namespace Table {
        export const InstanceRef = Builder<TableProps.ColumnDefinition<RecommendationRule.Data>>()
            .id("instanceRef")
            .header("Instance")
            .cell((item: RecommendationRule.Data) => item.instanceId)
            .build();
        export const GeoCodes = Builder<TableProps.ColumnDefinition<RecommendationRule.Data>>()
            .id("geoCodes")
            .header("Geo Codes")
            .cell((item: RecommendationRule.Data) => {
                return <React.Fragment>
                    {item.geoCodes.map(geo => {
                        let color: "blue" | "grey" | "green" | "red" = "blue";
                        switch (geo.value.type) {
                            case GeoType.Country: color = "blue"; break;
                            case GeoType.CountryCode: color = "green"; break;
                            case GeoType.RegionCode: color = "grey"; break;
                            case GeoType.GeoCode: color = "red"; break;
                        }
                        return <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            triggerType="custom"
                            content={
                                <div>
                                    {`${geo.value.type}: ${geo.value.value}`}
                                </div>
                            }>
                            <Badge color={color}>{geo.value.value}</Badge>
                        </Popover>;
                    })}
                </React.Fragment>;
            })
            .build();
        export const Roles = Builder<TableProps.ColumnDefinition<RecommendationRule.Data>>()
            .id("roles")
            .header("Roles")
            .cell((item: RecommendationRule.Data) => {
                if (!item) return "-";
                return <React.Fragment>
                    {item.roles.map(r => <Badge color="blue">
                    <RolePopover id={r} />
                    </Badge>)}
                </React.Fragment>;
            })
            .build();
        export const Tenure = Builder<TableProps.ColumnDefinition<RecommendationRule.Data>>()
            .id("tenure")
            .header("Tenure (Year - Month)")
            .cell((item: RecommendationRule.Data) => {
                if (!item.tenure) {
                    return "-";
                }
                const operator = item.tenure.operator;
                if (operator === RecommendationRule.Operator.Equal) {
                    return `= ${item.tenure.value.years}y ${item.tenure.value.months}m`;
                } else if (operator === RecommendationRule.Operator.LessThan) {
                    return `< ${item.tenure.value.years}y ${item.tenure.value.months}m`;
                } else if (operator === RecommendationRule.Operator.LessThanInclusive) {
                    return `<= ${item.tenure.value.years}y ${item.tenure.value.months}m`;
                } else if (operator === RecommendationRule.Operator.GreaterThan) {
                    return `> ${item.tenure.value.years}y ${item.tenure.value.months}m`;
                } else if (operator === RecommendationRule.Operator.GreaterThanInclusive) {
                    return `>= ${item.tenure.value.years}y ${item.tenure.value.months}m`;
                } else return `${operator}: ${item.tenure.value.years}y - ${item.tenure.value.months}m`;
            })
            .build();
        export const RuleType = Builder<TableProps.ColumnDefinition<RecommendationRule.Data>>()
            .id("ruleType")
            .header("RuleType")
            .cell((item: RecommendationRule.Data) => {
                if (!item) return "-";
                return <React.Fragment>
                    {item.ruleType.toUpperCase()}
                </React.Fragment>;
            })
            .build();
        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                InstanceRef,
                RuleType,
                Roles,
                Tenure,
                GeoCodes
            ]);
        }
    }
}
import { IRecommendationRule, Role } from "@amzn/ask-legal-domain";
import { Box, ColumnLayout, Popover, SpaceBetween, StatusIndicator, TextContent } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../../hooks/api-hook";
import { RoleModel } from "../../../model/role-model";
import { AppContext } from "../../../setup/context";
import { TeamIdentityLink } from "../../common/IdentityLink";
import { TeamSearch } from "../../common/TeamSearch";
import { UIField } from "../../common/UIField";

export const RoleModalContent = (props: {
    state: RoleModel.RoleState;
}) => {
    return (
        <React.Fragment>
            <ColumnLayout columns={2}>
                <SpaceBetween size="l">
                    <UIField.StateValueField
                        state={props.state.name}
                        name="Name"
                        constraintText={`Maximum ${RoleModel.NAME_CHAR_LIMIT} characters`}
                        editing={true}
                    />
                </SpaceBetween>
                <SpaceBetween size="l">
                    <UIField.CustomField
                        name="Select Team(s)"
                        child={
                            <TeamSearch.Multiple
                                disabled={false}
                                initialSelected={props.state.members.value}
                                onTeamSelectChange={props.state.members.setValue}
                            />
                        }
                    />
                </SpaceBetween>
            </ColumnLayout>
        </React.Fragment>
    );
};

export const RoleViewContent = (props: {
    role: Role.Data;
}) => {
    return (
        <ColumnLayout columns={1}>
            <UIField.LabelField
                label="Team(s)"
                children={
                    <SpaceBetween size="s" direction="horizontal">
                        {props.role.members.map(t => <TeamIdentityLink id={t.teamId} name={t.teamName} />)}
                    </SpaceBetween>
                }
            />
        </ColumnLayout>
    );
};

export const RoleDeleteContent = (props: {
    roleId: string;
    instanceId: string;
    setCanDelete: (exists: boolean) => void;
}) => {
    const context = React.useContext(AppContext);

    const searchRulesRunner = useAPI(
        context.getRecommendationRuleAPI().searchRole
    );

    React.useEffect(() => {
        searchRulesRunner.submitRun(
            IRecommendationRule.SearchRoleInput.create({
                instanceId: props.instanceId,
                roleId: props.roleId
            })
        );
    }, []);

    React.useEffect(() => {
        if (searchRulesRunner.status === "Succeeded" && searchRulesRunner.data.output.length === 0) {
            props.setCanDelete(true);
        }
    }, [searchRulesRunner.status]);

    return (
        <SpaceBetween size="m">
            {searchRulesRunner.status === "New" && <StatusIndicator type="pending">
                    Starting check on rules
                </StatusIndicator>}
            {searchRulesRunner.status === "Running" && <StatusIndicator type="loading">
                    Searching for dependent rules
                </StatusIndicator>}
            {searchRulesRunner.status === "Succeeded" && searchRulesRunner.data.output.length === 0 &&
                <StatusIndicator type="success">
                    Dependency checks complete
                </StatusIndicator>}
            {searchRulesRunner.status === "Succeeded" && searchRulesRunner.data.output.length !== 0 &&
                <StatusIndicator type="warning">
                    One or more rules are dependent on this role. <Popover
                        dismissAriaLabel="Close"
                        fixedWidth
                        size="small"
                        triggerType="text"
                        content={(
                            <UIField.LabelField
                                label="Rule(s)"
                                children={<ul>{searchRulesRunner.data.output.map(v => <li>{v.name}</li>)}</ul>}
                            />
                        )}>
                    View Details
                </Popover></StatusIndicator>}
            {searchRulesRunner.status === "Error" && <StatusIndicator type= "error">
                    Error checking rules. Reason: {searchRulesRunner.data.err.message}
                </StatusIndicator>}
            <Box textAlign="center">
                {searchRulesRunner.status === "Succeeded" && searchRulesRunner.data.output.length === 0 &&
                <TextContent>
                    Click on <em><strong>submit</strong></em> to delete selected role.
                </TextContent>}
                {searchRulesRunner.status === "Succeeded" && searchRulesRunner.data.output.length !== 0 &&
                <TextContent>
                    Please remove all dependencies to delete this role.
                </TextContent>}
            </Box>
        </SpaceBetween>
    );
};
import * as React from "react";
import {
  FormFieldProps,
  Header,
  Input,
  InputProps,
  SpaceBetween,
  Textarea,
  TextareaProps,
  Toggle,
} from "@amzn/awsui-components-react";
import { FieldDefinition } from "@amzn/altar-sds-client";
import { UIField } from "../../../../common/UIField";
import { AdvancedListModel } from "../../../../../model/advanced-list-model";

export const MultiPersonFieldAddEdit = (props: {
  value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
  };
  onUpdated?: (value?: {
    fieldDef?: FieldDefinition;
    fieldVisibility?: boolean;
  }) => void;
  onCancel?: () => void;
}) => {
  const [fieldColumnDisplayName, setFieldColumnDisplayName] =
    React.useState<FormFieldProps>({});
  const [columnDisplayName, setColumnDisplayName] = React.useState<InputProps>({
    value: props.value?.fieldDef?.displayName ?? "",
  });
  const [fieldColumnDescription, setFieldColumnDescription] =
    React.useState<FormFieldProps>({});
  const [columnDescription, setColumnDescription] = React.useState<TextareaProps>({
    value: props.value?.fieldDef?.description ?? "",
  });
  const [showOnLivePage, setShowOnLivePage] = React.useState<boolean>(
    props.value?.fieldVisibility ? true : false
  );

  function validate() {
    let valid = true;

    if (columnDisplayName.value?.length > 0) {
      setFieldColumnDisplayName({
        errorText: "",
      });
    } else {
      setFieldColumnDisplayName({
        errorText: "Column Display Name is required",
      });
      valid = false;
    }

    if (columnDescription.value?.length > AdvancedListModel.COLUMN_INFO_CHAR_LIMIT) {
      setFieldColumnDescription({
        errorText: "Column Info exceeds maximum character limit",
      });
      valid = false;
    } else {
      setFieldColumnDescription({
        errorText: "",
      });
    }

    if (valid) {
      props.onUpdated?.({
        fieldDef: {
          ...props.value.fieldDef,
          displayName: columnDisplayName.value!,
          description: columnDescription.value?.length ? columnDescription.value : undefined,
        },
        fieldVisibility: showOnLivePage,
      });
    }
  }

  React.useEffect(() => {
    validate();
  }, [columnDisplayName.value, columnDescription.value, showOnLivePage]);

  React.useEffect(() => {
    if (!props.value?.fieldDef) return;
    setColumnDisplayName({ value: props.value?.fieldDef.displayName });
    setColumnDescription({ value: props.value.fieldDef.description });
  }, [props.value]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Multi Person Column Details</Header>
        <UIField.CustomField
          name={"Column Display Name"}
          description={"How should column to be displayed on live page"}
          errorText={fieldColumnDisplayName.errorText}
          child={
            <Input
              value={columnDisplayName.value}
              onChange={(e) => setColumnDisplayName({ value: e.detail.value })}
              placeholder={"Enter Column Name"}
            />
          }
        />
        <UIField.CustomField
          name={"Column Info"}
          description={"Describe how the user should fill this field"}
          errorText={fieldColumnDescription.errorText}
          child={
            <Textarea
              value={columnDescription.value}
              onChange={(e) => setColumnDescription({ value: e.detail.value })}
              placeholder={"Enter Column Info"}
            />
          }
        />
      </SpaceBetween>
      <SpaceBetween direction="vertical" size="xxs">
        <Header headingTagOverride="h4">Options</Header>
        <SpaceBetween size="s" direction="vertical">
          <Toggle
            checked={showOnLivePage}
            onChange={({ detail }) => {
              setShowOnLivePage(detail.checked);
            }}
          >
            Show column on live page
          </Toggle>
        </SpaceBetween>
      </SpaceBetween>
    </SpaceBetween>
  );
};

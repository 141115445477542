import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import * as React from "react";
import { Button, Container, Form, FormField, Header, Link, Popover, Select, SpaceBetween } from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";
import { InstanceModel } from "../../model/instance-model";
import { ErrorFlashbar } from "../../components/common/ErrorFlashbar";
import { TeamSearch } from "../../components/common/TeamSearch";
import { UIField } from "../../components/common/UIField";
import { AdminPreferences } from "@amzn/ask-legal-domain";

export const InstanceCreate = () => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const createInstanceRunner = useAPI(
        context.getInstanceAPI().create
    );
    const createInstanceState = InstanceModel.CreateState.use({});

    const create = () => {
        const input = InstanceModel.CreateState.toInput(createInstanceState);
        createInstanceRunner.submitRun(
            input
        );
    };

    React.useEffect(() => {
        if (createInstanceRunner.status === "Succeeded") {
            createInstanceState.reset();
            history.push(`/instance/${createInstanceRunner.data.output.id}/admin`);
        } else if (createInstanceRunner.status === "Error") {
            createInstanceState.reset();
        }
    }, [createInstanceRunner.status]);

    return (
        <React.Fragment>
            {createInstanceRunner.status === "Error" && <ErrorFlashbar error={createInstanceRunner.data.err} />}
            <Form
                header={
                    <Header variant="h1">
                        Create Instance
                    </Header>
                }
                actions={
                    <div>
                        <Button onClick={() => history.goBack()} variant="link">
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={create}
                            disabled={
                                createInstanceState.idField.errorText ||
                                createInstanceState.nameField.errorText ||
                                createInstanceState.emailField.errorText ||
                                createInstanceState.permissionBindleIdField.errorText ||
                                createInstanceState.ownedByTeamField.errorText ||
                                createInstanceState.organization.errorText
                            }
                            loading={createInstanceRunner.status === "Running"}>
                            Submit
                        </Button>
                    </div>
                }>
                <Container header={<Header variant="h2">Instance Details</Header>}>
                    <SpaceBetween direction="vertical" size="l">
                        <UIField.StateValueField
                            state={createInstanceState.idField}
                            name="Instance Id"
                            constraintText={<React.Fragment>
                                Maximum 50 characters <Popover
                                dismissAriaLabel="Close"
                                header="Instance Naming Guidelines"
                                triggerType="custom"
                                content={<React.Fragment>
                                        <small>
                                            <ul>
                                                <li>Cannot contain spaces</li>
                                                <li>Cannot contain any special characters</li>
                                                <li>Can contain alphabets <b>A...Z</b> <b>a...z</b></li>
                                                <li>Can contain numbers <b>0-9</b></li>
                                                <li><b><em>Examples : </em></b> SampleInstanceName, NewInstance1, ... etc</li>
                                            </ul>
                                        </small>
                                    </React.Fragment>}
                                >
                                    <Link variant="info">Format</Link>
                                </Popover></React.Fragment>}
                            editing={true}
                        />
                        <UIField.StateValueField
                            state={createInstanceState.nameField}
                            name="Instance Name"
                            constraintText={`Maximum ${InstanceModel.TITLE_CHAR_LIMIT} characters`}
                            editing={true}
                        />
                        <UIField.StateValueField
                            state={createInstanceState.emailField}
                            name="Owner Email"
                            editing={true}
                        />
                        <UIField.StateValueField
                            state={createInstanceState.permissionBindleIdField}
                            name="Permission Bindle Id"
                            editing={true}
                        />
                        <FormField
                            label={<span>Team</span>}>
                            <TeamSearch.Single onTeamSelectChange={(event) => {
                                if (event === undefined) return;
                                createInstanceState.ownedByTeamField.setValue(event);
                            }} />
                        </FormField>
                        <FormField
                            label={<span>Organization</span>}>
                            <Select
                                selectedOption={{
                                    label: createInstanceState.organization.value,
                                    value: createInstanceState.organization.value
                                }}
                                onChange={e =>
                                    createInstanceState.organization.setValue(e.detail.selectedOption.value)
                                }
                                options={[{
                                    label: AdminPreferences.Organization.AMAZON_LEGAL,
                                    value: AdminPreferences.Organization.AMAZON_LEGAL
                                }, {
                                    label: AdminPreferences.Organization.AWS_LEGAL,
                                    value: AdminPreferences.Organization.AWS_LEGAL
                                }]}
                                selectedAriaLabel="Selected"
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </React.Fragment>
    );
};
import { Box, Flashbar, Grid } from "@amzn/awsui-components-react";
import {  FieldDefinition, Variable } from "@amzn/altar-sds-client";
import * as React from "react";
import "../../../../../../styles/component/smart-variable-editor/main.scss";

export const SmartVariableTextInput = (props: {
    fieldDefinitions: FieldDefinition[];
    onChange: (text: string) => void;
    variableList: Variable[];
    value?: string;
}) => {
    const [inputText, setInputText] = React.useState(props.value || "");
    const [cursorStartPosition, setCursorStartPosition] = React.useState<number>();
    const [errorVariables, setErrorVariables] = React.useState<string[]>([]);
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        props.onChange(
            convertDisplayTemplateToStorageTemplate(
                inputText,
                props.variableList,
                props.fieldDefinitions,
                (errorVariables => setErrorVariables(errorVariables))
            )
        );
    }, [inputText]);

    const handleChange = (e) => {
        const { selectionStart, selectionEnd } = e.target;
        setCursorStartPosition(selectionStart);
        setInputText(e.target.value);
    };

    const handleInteraction = (e) => {
        const { selectionStart, selectionEnd } = e.target;
        setCursorStartPosition(selectionStart);
        setInputText(e.target.value);
    };

    React.useEffect(() => {
        const inputElement = inputRef.current;
        inputElement.addEventListener("click", handleInteraction);
        inputElement.addEventListener("focus", handleInteraction);
        inputElement.addEventListener("keydown", handleInteraction);

        return () => {
            inputElement.removeEventListener("click", handleInteraction);
            inputElement.removeEventListener("focus", handleInteraction);
            inputElement.removeEventListener("keydown", handleInteraction);
        };
    }, []);

    return (<>
        <Flashbar
            items={errorVariables
                .map(v => ({
                    type: "warning",
                    content: `Variable ${v} was not found. ${v} will be removed from the content.`
                }))
            }
        />
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
            <div className="smart-variable-text-input">
                <textarea
                    value={inputText}
                    ref={inputRef}
                    onChange={handleChange}
                    placeholder="Enter text here"
                />
            </div>
            <div>
                <Box variant="p">Workflow Variables</Box>
                <Box color="text-body-secondary">Click a variable to add it to the content</Box>
                <div className="variable-list">
                    {props.variableList.filter(f => {
                        const field = props.fieldDefinitions.find(x => x.fieldKey === f.tags?.fieldKey);
                        if (field && !field.deprecated) return true;
                        return false;
                    }).map(v => {
                        const variableDisplayName = generateVariableDisplayFromFieldDefinitionsAndVariable(
                            v, props.fieldDefinitions
                        );
                        return <button className="variable-option"
                            onClick={() => {
                                setInputText(
                                    inputText.slice(0, cursorStartPosition) +
                                    `{${variableDisplayName}}` +
                                    inputText.slice(cursorStartPosition)
                                );
                            }}
                        >
                            {variableDisplayName}
                        </button>;
                    })}
                </div>
            </div>
        </Grid>
    </>);
};

const convertDisplayTemplateToStorageTemplate = (
    text: string,
    variables: Variable[],
    fieldDefinitions: FieldDefinition[],
    onError: (errorVariables: string[]) => void
) => {
    let errorVariables = [];
    // Match any text beginning with "{" and ending with "}"
    const regex = /{(.+?)}/g;

    const outputString = text.replace(regex, (match) => {
        const displaySuffix = match.includes("-OldValue") ? "-OldValue" : "-Value";
        const fieldDisplayName = match.split("{")[1].split(displaySuffix)[0];
        const parent = displaySuffix.includes("OldValue") ? "oldItem" : "item";
        const foundFieldDef = fieldDefinitions.find(fieldDef => fieldDef.displayName === fieldDisplayName);
        const foundVariable = variables
            .find(
                variable => variable.tags!["fieldKey"] === foundFieldDef?.fieldKey &&
                variable.tags!["parent"] === parent
            );
        if (!foundVariable) {
            console.info("No variable found for ", match);
            errorVariables.push(match);
            return "";
        }
        return "${context." + foundVariable?.name + "}";
    });
    if (errorVariables.length) {
        onError(errorVariables);
    }
    return "`" + outputString + "`";
};

const generateVariableDisplayFromFieldDefinitionsAndVariable = (variable: Variable, fieldDefinitions: FieldDefinition[]) => {
    let fieldDef = fieldDefinitions.find(d => d.fieldKey === variable.tags!["fieldKey"]);
    let suffix = variable.tags!["parent"] === "oldItem" ? "OldValue" : "Value";
    return `${fieldDef!.displayName}-${suffix}`;
};
import {
    AdvancedList,
    AdvancedListItem,
    IdentityRef,
    LoadAdvancedListItemCommandOutput,
    EntityExtraVersionRef
} from "@amzn/altar-sds-client";
import { APIOutput, FieldConfiguration } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    ColumnLayout,
    Flashbar,
    FlashbarProps,
    Header,
    Modal,
    SpaceBetween,
    Spinner,
    SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";
import { AdvancedListPolarisFactory } from "../../factory/polaris/advanced-list-polaris-factory";
import { AttachmentTable } from "./attachment-components/AttachmentsTable";

export function ViewAdvancedListItemModal(props: {
    itemRef: EntityExtraVersionRef;
    advancedList: AdvancedList;
    fieldConfigurations?: FieldConfiguration.Record;
    by: IdentityRef;
    onCanceled?: () => void;
}) {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [advancedListItem, setAdvancedListItem] = React.useState<AdvancedListItem>();

    async function init() {
        try {
            setSpinnerProps({});
            const loadItemOutput = await context
                .getAdvancedListAPI()
                .loadItem({
                  entityId: props.itemRef.entityExtraRef.entityRef.entityId,
                  repositoryId: props.itemRef.entityExtraRef.entityRef.repositoryRef.repositoryId,
                  entityExtraId: props.itemRef.entityExtraRef.extraId,
                  by: props.by
                });
            const output = APIOutput.fromRaw<LoadAdvancedListItemCommandOutput>(loadItemOutput.data);
            if (output.isErr()) {
                setFlashbarProps({
                    items: [
                        {
                            type: "error",
                            content: output.err.message
                        }
                    ]
                });
            } else {
                setFlashbarProps({
                    items: []
                });

                setAdvancedListItem(output.data.body);
            }
        } catch (err) {
            setFlashbarProps({
                items: [
                    {
                        type: "error",
                        content: (err as Error).message
                    }
                ]
            });
        } finally {
            setSpinnerProps(undefined);
        }
    }

    React.useEffect(() => {
        init();
    }, [props.itemRef]);

    return (
        <Modal
            visible={true}
            header={<Header>View Item</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween size={"s"} direction="horizontal">
                        <Button
                            onClick={(e) => {
                                props.onCanceled?.();
                            }}
                        >
                            Close
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={props.onCanceled}
        >
            {spinnerProps && (
                <Modal visible={true}>
                    <Spinner />
                </Modal>
            )}
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {advancedListItem && (<>
                <ColumnLayout columns={2}>
                    {
                        props.advancedList?.fieldDefinitions
                            ?.filter((fieldDef) => !fieldDef.deprecated)
                            .map((fieldDef) => (
                                <React.Fragment key={fieldDef.fieldKey!}>
                                    <span>{fieldDef.displayName!}:</span>
                                    <span>
                                        {
                                            AdvancedListPolarisFactory.Table.renderItemDisplay(
                                                advancedListItem.values.find((itemValue) => itemValue.key === fieldDef.fieldKey)?.value,
                                                fieldDef,
                                                props.fieldConfigurations[fieldDef.fieldKey]
                                            )
                                        }
                                    </span>
                                </React.Fragment>
                            ))
                    }
                </ColumnLayout>
                <hr />
                <AttachmentTable
                    item={advancedListItem}
                    itemRef={props.itemRef}
                    by={props.by}
                />
            </>)}
        </Modal>
    );
}

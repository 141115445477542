import {
  ALView,
  EmployeeIdentity,
  PrimitiveDataFactory
} from "@amzn/ask-legal-domain";
import {
  AdvancedList,
  ComparisonOperator,
  FieldDefinition,
  FieldDefinitionDataType,
  PropertyToken,
  Query,
  ReservedFieldKey
} from "@amzn/altar-sds-client";
import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Form,
  FormField,
  Grid,
  Header,
  Input,
  Modal,
  Select,
  SpaceBetween,
  Textarea
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ItemPickerSorter } from "../../../../common/ItemPickerSorter";
import { AdvancedListPolarisFactory } from "../../../../../factory/polaris/advanced-list-polaris-factory";
import { UserSearch } from "../../../../common/UserSearch";
import { SDSUtils } from "../../../../../utils/sds-utils";
import { AdvancedListConstants } from "../../../../../utils/advanced-list.constant";

export interface ViewAddEditModalProps {
  advancedList: AdvancedList;
  viewPayload?: ALView.Payload;
  onSubmit?: (value: ALView.Payload) => void;
  onCancel?: () => void;
}

export const ViewAddEditModal = (props: ViewAddEditModalProps) => {
  const [viewPayload, setViewPayload] = React.useState<ALView.Payload>(
    props.viewPayload ?? ALView.Payload.init({
      id: PrimitiveDataFactory.id(4),
      name: "",
      description: "",
      fieldKeys: [
        ReservedFieldKey.ID
      ],
    })
  );

  const handleFilterUpdate = (filter: Query) => {
    setViewPayload({
      ...viewPayload,
      filter
    });
  };

  return <Modal
    visible
    size="max"
    header={<Header variant="h3">Configure View</Header>}
    onDismiss={() => props.onCancel?.()}
    closeAriaLabel="Close modal"
    footer={
      <Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
          <Button
            variant="link"
            onClick={() => props.onCancel?.()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => props.onSubmit?.(viewPayload)}
            disabled={!viewPayload.name || !viewPayload.fieldKeys.length}
          >
            Save
          </Button>
        </SpaceBetween>
      </Box>
    }
  >
    <Form>
      <SpaceBetween size="m">
        <FormField label="Name" errorText={!viewPayload.name.length && "Required"}>
          <Input
            value={viewPayload.name}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                name: e.detail.value
              });
            }}
            placeholder="Enter view name"
          />
        </FormField>
        <FormField label="Description">
          <Textarea
            value={viewPayload.description}
            onChange={(e) => {
              setViewPayload({
                ...viewPayload,
                description: e.detail.value
              });
            }}
            placeholder="Enter brief description of view"
          />
        </FormField>
        <FormField label="Visible Fields and Order" errorText={!viewPayload.fieldKeys.length && "Cannot be empty"}>
          <ItemPickerSorter
            availableItems={props.advancedList.fieldDefinitions
              .filter(f => !f.deprecated && !viewPayload.fieldKeys.includes(f.fieldKey))
              .map(f => ({ id: f.fieldKey, content: f.displayName }))
            }
            selectedItems={viewPayload.fieldKeys.map((key) => ({
              id: key,
              content: props.advancedList.fieldDefinitions?.find(f => f.fieldKey === key)?.displayName ?? key
            }))}
            onSelectedItemsChange={(items) => {
              setViewPayload({
                ...viewPayload,
                fieldKeys: items.map(i => i.id)
              });
            }}
            options={{
              nonDeleteableIds: [ReservedFieldKey.ID]
            }}
          />
        </FormField>
        <FormField label="Filter">
          <AdvancedListFilterEditor
            advancedList={props.advancedList}
            viewPayload={viewPayload}
            handleFilterUpdate={handleFilterUpdate}
          />
        </FormField>
        <FormField label="Sort">
          <SpaceBetween size="s" direction="horizontal">
            <Select
              selectedOption={
                viewPayload.sort?.[0]?.propertyName ?
                {
                  label: props.advancedList.fieldDefinitions.find((field) => field.fieldKey === viewPayload.sort[0].propertyName)?.displayName,
                  value: viewPayload.sort[0].propertyName
                } :
                undefined
              }
              onChange={({ detail }) => {
                if (detail.selectedOption.value === viewPayload.sort?.[0]?.propertyName) {
                  setViewPayload({
                    ...viewPayload,
                    sort: undefined
                  });
                  return;
                }
                const fieldDefinition = props.advancedList.fieldDefinitions.find((field) => field.fieldKey === detail.selectedOption.value);
                if (!fieldDefinition) {
                  throw new Error(`Could not find field definition for ${detail.selectedOption.value}`);
                }
                setViewPayload({
                  ...viewPayload,
                  sort: [
                    {
                      propertyName: fieldDefinition.fieldKey,
                      descending: false
                    }
                  ]
                });
              }}
              options={[
                {
                  label: "None",
                  value: undefined
                },
                ...props.advancedList.fieldDefinitions
                  .filter(f => !f.deprecated)
                  .map((field) => ({
                    label: field.displayName,
                    value: field.fieldKey
                  }))
              ]}
              placeholder="Select field"
              filteringType="auto"
            />
            <Checkbox
              onChange={({ detail }) =>
                setViewPayload({
                  ...viewPayload,
                  sort: [
                    {
                      propertyName: viewPayload.sort?.[0]?.propertyName ?? ReservedFieldKey.ID,
                      descending: detail.checked
                    }
                  ]
                })
              }
              checked={viewPayload.sort?.[0]?.descending}
            >
              Descending
            </Checkbox>
          </SpaceBetween>
        </FormField>
      </SpaceBetween>
    </Form>
  </Modal>;
};

interface FilterToken {
  fieldDefinition: FieldDefinition;
  operator: ComparisonOperator;
  value: any;
}

function toFilterToken(propertyToken: PropertyToken, advancedList: AdvancedList): Partial<FilterToken> {
  const fieldDefinition = advancedList.fieldDefinitions.find((field) => field.fieldKey === propertyToken.name);
  return {
    fieldDefinition: fieldDefinition,
    operator: propertyToken.operator as ComparisonOperator,
    value: propertyToken.value as any
  };
}

function fromFilterToken(filterToken: Partial<FilterToken>): PropertyToken {
  return {
    name: filterToken?.fieldDefinition?.fieldKey ?? "",
    operator: filterToken.operator ?? "",
    value: filterToken.value ?? ""
  };
}

interface AdvancedListFilterEditorProps {
  advancedList: AdvancedList;
  viewPayload?: ALView.Payload;
  handleFilterUpdate?: (filter: Query) => void;
}

const AdvancedListFilterEditor = (props: AdvancedListFilterEditorProps) => {
  const [filters, setFilters] = React.useState<Partial<FilterToken>[]>(
    props.viewPayload?.filter?.propertyTokens?.length
      ? props.viewPayload.filter.propertyTokens.map((token) => toFilterToken(token, props.advancedList))
      : []
  );

  const handleAddFilter = () => {
    setFilters([...filters, {}]);
    props.handleFilterUpdate({
      propertyTokens: filters.map((filter) => fromFilterToken(filter))
    });
  };

  const handleUpdateFilter = (index: number, updatedFilter: Partial<FilterToken>) => {
    setFilters(filters.map((filter, i) => (i === index ? updatedFilter : filter)));
    props.handleFilterUpdate({
      propertyTokens: filters.map((filter) => fromFilterToken(filter))
    });
  };

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
    props.handleFilterUpdate({
      propertyTokens: filters.filter((_, i) => i !== index).map((filter) => fromFilterToken(filter))
    });
  };

  const getDefaultValue = (fieldDefinition: FieldDefinition) => {
    switch (fieldDefinition.dataType) {
      case FieldDefinitionDataType.string:
        return "";
      case FieldDefinitionDataType.number:
      case FieldDefinitionDataType.sequence:
        return 0;
      case FieldDefinitionDataType.date:
        return new Date().toISOString().split("T")[0];
      case FieldDefinitionDataType.boolean:
        return AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No;
      case FieldDefinitionDataType.choice:
      case FieldDefinitionDataType.IdentityRef:
      case FieldDefinitionDataType.multiIdentityRef:
      case FieldDefinitionDataType.hyperlink:
      default:
        return undefined;
    }
  };

  const empty = <Box textAlign="center" color="inherit">
    <br />
    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
      <em>No filters added</em>
    </Box>
  </Box>;

  const validateContentValue = (
    filter: Partial<FilterToken>
  ): string | undefined => {
    if (filter.value === undefined || filter.value === null) {
      return "Value cannot be empty";
    }
    switch (filter.fieldDefinition?.dataType) {
      case FieldDefinitionDataType.string:
        if (typeof filter.value !== "string") {
          return "Value must be a string";
        }
        if (!filter.value.trim().length) {
          return "Value cannot be empty";
        }
        if (filter.value.length > 255) {
          return "Value must be less than 255 characters";
        }
        break;
      case FieldDefinitionDataType.number:
        if (isNaN(Number(filter.value))) {
          return "Value must be a number";
        }
        break;
      case FieldDefinitionDataType.date:
        if (isNaN(Date.parse(filter.value))) {
          return "Value must be a date";
        }
        break;
      case FieldDefinitionDataType.choice:
      case FieldDefinitionDataType.IdentityRef:
      default:
        break;
    }
    return;
  };

  const renderValueField = (
    filter: Partial<FilterToken>,
    index: number
  ) => {
    switch (filter.fieldDefinition?.dataType) {
      case FieldDefinitionDataType.string:
        return <Input
          disabled={!filter.fieldDefinition}
          value={filter.value ?? ""}
          inputMode="text"
          onChange={(e) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: e.detail.value
            });
          }}
          placeholder="Enter value"
        />;
      case FieldDefinitionDataType.number:
      case FieldDefinitionDataType.sequence:
        return <Input
          disabled={!filter.fieldDefinition}
          value={filter.value ?? 0}
          inputMode="numeric"
          onChange={(e) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: e.detail.value
            });
          }}
          placeholder="Enter value"
        />;
      case FieldDefinitionDataType.date:
        return <DatePicker
          disabled={!filter.fieldDefinition}
          value={filter.value ?? ""}
          onChange={(e) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: e.detail.value
            });
          }}
          placeholder="Enter value"
        />;
      case FieldDefinitionDataType.IdentityRef:
        return <UserSearch.Single
          onUserSelectChange={(selected: EmployeeIdentity) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: selected && SDSUtils.getAmazonPersonRef(selected.id)
            });
          }}
          selected={filter.value}
        />;
      case FieldDefinitionDataType.multiIdentityRef:
        return <UserSearch.Multiple
          onUserSelectChange={(selected: EmployeeIdentity[]) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: selected.map((e) => SDSUtils.getAmazonPersonRef(e.id))
            });
          }}
        />;
      case FieldDefinitionDataType.boolean:
        return <Select
          disabled={!filter.fieldDefinition}
          selectedOption={
            filter.value !== undefined ?
            {
              label: filter.value ?
                AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes :
                AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No,
              value: filter.value ?
                AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes :
                AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No,
            } :
            undefined
          }
          options={[
            {
              label: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes,
              value: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
            },
            {
              label: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No,
              value: AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.No
            }
          ]}
          onChange={(e) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: e.detail.selectedOption.value === AdvancedListConstants.BOOLEAN_YES_NO_DISPLAY_VALUES.Yes
            });
          }}
        />;
      case FieldDefinitionDataType.choice:
        return <Select
          disabled={!filter.fieldDefinition}
          selectedOption={
            filter.value !== undefined ?
            {
              label: filter.fieldDefinition?.choiceOptions?.find((choice) => choice.key === filter.value)?.displayValue ?? "-",
              value: filter.value,
            } :
            undefined
          }
          options={
            filter.fieldDefinition?.choiceOptions?.map((choice) => ({
              label: choice.displayValue,
              value: choice.key,
            })) ?? []
          }
          onChange={(e) => {
            handleUpdateFilter(index, {
              fieldDefinition: filter.fieldDefinition,
              operator: filter.operator,
              value: e.detail.selectedOption.value
            });
          }}
        />;
      case FieldDefinitionDataType.hyperlink:
      default:
        return <Box textAlign="center" variant="awsui-key-label"><em>None</em></Box>;
    }
  };

  return (
    <SpaceBetween size="m" direction="vertical">
      <Grid key="filters-grid" gridDefinition={[
        { colspan: 4 },
        { colspan: 3 },
        { colspan: 3 },
        { colspan: 2 },
      ]}>
        <span><b>Field</b></span>
        <span><b>Operator</b></span>
        <span><b>Value</b></span>
        <span><b>Actions</b></span>
      </Grid>
      {!filters.length && empty}
      {filters.map((filter, index) => (
        <Grid key={`token-grid-${index}`} gridDefinition={[
          { colspan: 4 },
          { colspan: 3 },
          { colspan: 3 },
          { colspan: 2 },
        ]}>
          <FormField
            errorText={!filter.fieldDefinition && "Required"}
          >
            <Select
              selectedOption={
                filter.fieldDefinition ?
                {
                  label: filter.fieldDefinition.displayName,
                  value: filter.fieldDefinition.fieldKey,
                } :
                undefined
              }
              options={
                props.advancedList.fieldDefinitions
                  .filter(f => !f.deprecated)
                  .map(f => ({ label: f.displayName, value: f.fieldKey }))
              }
              placeholder="Select field"
              onChange={({ detail }) => {
                const fieldDefinition = props.advancedList.fieldDefinitions.find((field) => field.fieldKey === detail.selectedOption.value);
                if (fieldDefinition) {
                  handleUpdateFilter(index, {
                    fieldDefinition: fieldDefinition,
                    operator: undefined,
                    value: getDefaultValue(fieldDefinition)
                  });
                }
              }}
              filteringType="auto"
              invalid={!filter.fieldDefinition}
            />
          </FormField>
          <FormField
            errorText={!filter.operator && "Required"}
          >
            <Select
              selectedOption={
                filter.operator ?
                {
                  label: AdvancedListPolarisFactory.PropertyFilter.getComparisonOperatorLabel(filter.operator),
                  value: filter.operator
                } : undefined
              }
              options={
                AdvancedListPolarisFactory.PropertyFilter
                  .getFilterOperatorsByType(filter.fieldDefinition?.dataType)
                  .map(x => ({
                    label: AdvancedListPolarisFactory.PropertyFilter.getComparisonOperatorLabel(x),
                    value: x,
                    description: x
                  }))
              }
              disabled={!filter.fieldDefinition}
              placeholder="Select operator"
              onChange={({ detail }) => {
                handleUpdateFilter(index, {
                  fieldDefinition: filter.fieldDefinition,
                  operator: detail.selectedOption.value as ComparisonOperator,
                  value: filter.value
                });
              }}
              filteringType="auto"
              invalid={!filter.operator}
            />
          </FormField>
          <FormField errorText={validateContentValue(filter)}>
            {renderValueField(filter, index)}
          </FormField>
          <Button
            onClick={() => {
              handleRemoveFilter(index);
            }}
          >
            Remove
          </Button>
        </Grid>
      ))}
      <Button
        onClick={handleAddFilter}
        iconName="add-plus"
      >
        Add Filter
      </Button>
    </SpaceBetween>
  );
};
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI2 } from "../../hooks/api-hook";
import { EntityRef, IUserActivity, PrimitiveDataFactory, UserInteractionType } from "@amzn/ask-legal-domain";
import { useCookies } from "react-cookie";

/**
 * Component to capture user activity in application
 * User sessions are considered active for USER_SESSION_WINDOW (30 mins)
 *
 * @remarks
 * Currently does not support browser / tab close event recording
 *
 * @param entityRef - EntityRef of the entity that is being captured
 * @param children - Children to be rendered
 */
export const CaptureAnalyticsComp = (props: {
    entityRef: EntityRef;
    children: JSX.Element;
}) => {
    const USER_SESSION_WINDOW = 1000 * 60 * 30; // 30 mins
    const context = React.useContext(AppContext);
    const recordUserActivityRunner = useAPI2(
        context.getUserActivityAPI().record
    );
    const [cookies, setCookies] = useCookies(["sid", "sts"]);

    const updateSession = () => {
        // Update session timestamp
        setCookies("sts", Date.now(), { path: "/" });
        if (
            !!cookies.sts
            && !!cookies.sid
            && (Date.now() - cookies.sts as number <= USER_SESSION_WINDOW)
        ) return;
        setCookies("sid", PrimitiveDataFactory.id(), { path: "/" });
    };

    // TODO: Need to use sendBeacon events to capture browser / tab close events
    const recordUserActivityEvent = (interaction: UserInteractionType) => {
        // Update session details
        updateSession();
        recordUserActivityRunner.invoke(
            IUserActivity.RecordInput.create({
                entityRef: props.entityRef,
                interaction: interaction,
                sessionId: cookies.sid,
                url: window.location.href
            })
        );
    };

    const onVisibilityChange = () => {
        if (document.visibilityState === "hidden") {
            recordUserActivityEvent(UserInteractionType.Close);
        } else if (document.visibilityState === "visible") {
            recordUserActivityEvent(UserInteractionType.Open);
        }
    };

    React.useEffect(() => {
        // Capture first time open and mount listener
        recordUserActivityEvent(UserInteractionType.Open);
        document.addEventListener("visibilitychange", onVisibilityChange);
        return () => {
            document.removeEventListener("visibilitychange", onVisibilityChange);
        };
    }, []);

    return <>
        {props.children}
    </>;
};
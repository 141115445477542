import { Geo, GeoType } from "@amzn/ask-legal-domain";
import { Multiselect, MultiselectProps, Select, SelectProps } from "@amzn/awsui-components-react";
import * as React from "react";

export namespace GeoSelect {
    export const Single = (props: {
        pageSize?: number;
        selected: Geo;
        onSelectionChange: (geos: Geo) => void
    }) => {
        const [allOptions, setAllOptions] = React.useState<SelectProps.Options>([]);

        const [filteringText, setFilteringText] = React.useState<string>("");

        React.useEffect(() => {
            const allGeos = Geo.getUniqueCode();
            const defaultAllOptions = [
                ...allGeos.geoCodeList.map(v => toOption(v, GeoType.GeoCode)),
                ...allGeos.regionCodeList.map(v => toOption(v, GeoType.RegionCode)),
                ...allGeos.countryCodeList.map(v => toOption(v, GeoType.CountryCode)),
                ...allGeos.countryCodeAlpha3List.map(v => toOption(v, GeoType.CountryAlpha3code)),
                ...allGeos.countryList.map(v => toOption(v, GeoType.Country))
            ];
            setAllOptions(defaultAllOptions);
        }, []);

        const toOption = (value: string, type: GeoType): SelectProps.Option => {
            return { label: `${value} [${type}]`, value: `${value}::${type}` };
        };

        const fromOption = (option: SelectProps.Option): Geo => {
            const tokens = option.value.split("::");
            return {
                type: tokens[1] as GeoType,
                value: tokens[0]
            };
        };

        const [displayedOptions, setDisplayedOptions] = React.useState<SelectProps.Options>([]);

        React.useEffect(() => {
            if (!filteringText || filteringText.length === 0) {
                setDisplayedOptions(allOptions);
            } else {
                setDisplayedOptions(allOptions.filter(o =>
                    fromOption(o).value
                        .toLowerCase()
                        .includes(filteringText.toLowerCase())
                ));
            }
        }, [filteringText, allOptions]);

        return <Select
            options={displayedOptions}
            empty={filteringText.length > 0 ? "No match found" : "No availble options"}
            placeholder="Select Geos from dropdown"
            filteringType="manual"
            filteringPlaceholder="Type to filter"
            selectedOption={toOption(props.selected.value, props.selected.type)}
            onChange={e => {
                const selectedGeos = fromOption(e.detail.selectedOption);
                props.onSelectionChange(selectedGeos);
            }}
            virtualScroll={true} // set this flag to optimize the experience
            onLoadItems={e => setFilteringText(e.detail.filteringText)}
        />;
    };

    export const Multi = (props: {
        pageSize?: number;
        selected: Geo[];
        onSelectionChange: (geos: Geo[]) => void
    }) => {
        const [allOptions, setAllOptions] = React.useState<MultiselectProps.Options>([]);

        const [filteringText, setFilteringText] = React.useState<string>("");

        React.useEffect(() => {
            const allGeos = Geo.getUniqueCode();
            const defaultAllOptions = [
                ...allGeos.geoCodeList.map(v => toOption(v, GeoType.GeoCode)),
                ...allGeos.regionCodeList.map(v => toOption(v, GeoType.RegionCode)),
                ...allGeos.countryCodeList.map(v => toOption(v, GeoType.CountryCode)),
                ...allGeos.countryCodeAlpha3List.map(v => toOption(v, GeoType.CountryAlpha3code)),
                ...allGeos.countryList.map(v => toOption(v, GeoType.Country))
            ];
            setAllOptions(defaultAllOptions);
        }, []);

        const toOption = (value: string, type: GeoType): MultiselectProps.Option => {
            return { label: `${value} [${type}]`, value: `${value}::${type}` };
        };

        const fromOption = (option: MultiselectProps.Option): Geo => {
            const tokens = option.value.split("::");
            return {
                type: tokens[1] as GeoType,
                value: tokens[0]
            };
        };

        const [displayedOptions, setDisplayedOptions] = React.useState<MultiselectProps.Options>([]);

        React.useEffect(() => {
            if (!filteringText || filteringText.length === 0) {
                setDisplayedOptions(allOptions);
            } else {
                setDisplayedOptions(allOptions.filter(o =>
                    fromOption(o).value
                        .toLowerCase()
                        .includes(filteringText.toLowerCase())
                ));
            }
        }, [filteringText, allOptions]);

        return <Multiselect
            options={displayedOptions}
            empty={filteringText.length > 0 ? "No match found" : "No availble options"}
            placeholder="Select Geos from dropdown"
            filteringType="manual"
            filteringPlaceholder="Type to filter"
            selectedOptions={props.selected.map(g => toOption(g.value, g.type))}
            onChange={e => {
                const selectedGeos = e.detail.selectedOptions.map(o => fromOption(o));
                props.onSelectionChange(selectedGeos);
            }}
            virtualScroll={true} // set this flag to optimize the experience
            onLoadItems={e => setFilteringText(e.detail.filteringText)}
        />;
    };
}
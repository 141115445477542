import { CustomListContent, CustomListObject } from "@amzn/ask-legal-domain";
import { Link, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { useAPI } from "../../../hooks/api-hook";

export const CustomListContentView = (props: {
    content: CustomListContent;
    containerId?: string;
}) => {
    const context = React.useContext(AppContext);
    const protocolsRegex = new RegExp("^https?:\\/\\/");
    const mailtoRegex = new RegExp(/^mailto:(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/);

    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );

    const onClickHandler = (object: CustomListObject) => {
        if (props.containerId) {
            recordViewHistoryRunner.submitRun({
                type: "Toolkit",
                entityId: props.containerId,
                url: object.value,
            });
        }
    };

    return (
        <React.Fragment>
            <TextContent>
                <SpaceBetween direction="vertical" size="xxs">
                    {props.content.contentValue.map(item => (
                        <span onClick={() => onClickHandler(item)}>
                            <Link
                                external={true}
                                href={(!!protocolsRegex.test(item.value) || !!mailtoRegex.test(item.value)) ? item.value : "https://" + item.value}>
                                {item.key}
                            </Link>
                        </span>
                    ))}
                </SpaceBetween>
            </TextContent>
        </React.Fragment>
    );
};
import {
    Button,
    CollectionPreferences,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { LegalContact } from "@amzn/ask-legal-domain";
import * as React from "react";
import { ServerSideCollection } from "../../hooks/legal-contacts-collection-hook";
import { ErrorFlashbar } from "../common/ErrorFlashbar";

export const ServerSideLegalContactsTable = <T extends LegalContact.Record, >(props: {
    collection: ServerSideCollection<T>;
    headerActions?: React.ReactNode;
}) => {
    const header = <Header
        variant="h2"
        counter={`(${props.collection.tableProps.props.items.length}/${props.collection.tableProps.props.totalItemsCount})`}
        actions={
            <SpaceBetween size="m" direction="horizontal">
                <Button
                    variant="icon"
                    iconName="refresh"
                    loading={props.collection.tableProps.loading}
                    onClick={props.collection.loadItems}
                />
                {props.headerActions}
            </SpaceBetween>
        }
    >
        Legal Contacts
    </Header>;
    return <SpaceBetween size="s">
        {props.collection.tableProps.error && <ErrorFlashbar error={props.collection.tableProps.error} />}
        <div className="legal-contacts-table">
            <Table
                {...props.collection.tableProps.props}
                header={header}
                empty={<>Nothing found</>}
                preferences={
                    <CollectionPreferences {...props.collection.preferenceProps.props} />
                }
                pagination={
                    <Pagination {...props.collection.paginationProps.props} />
                }
                filter={
                    <PropertyFilter {...props.collection.filteringProps.props} />
                }
            />
        </div>
    </SpaceBetween>;
};
import {
    EntityExtraVersionRef,
    DeleteAdvancedListItemCommandOutput
} from "@amzn/altar-sds-client";
import { APIOutput, ContainerRef } from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Flashbar,
    FlashbarProps,
    Header,
    Input,
    Modal,
    SpaceBetween,
    Spinner,
    SpinnerProps,
    StatusIndicator
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../setup/context";

export function BatchDeleteAdvancedListItemModal(props: {
    itemRefList: EntityExtraVersionRef[];
    containerRef: ContainerRef;
    onDeleted?: (itemRef: EntityExtraVersionRef[]) => void;
    onCanceled?: () => void;
}) {
    const context = React.useContext(AppContext);
    const [value, setValue] = React.useState("");
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [deleteItemProgress, setDeleteItemProgress] = React.useState<Record<string, boolean>>();
    const [successfulResults, setSuccessfulResults] = React.useState<EntityExtraVersionRef[]>();

    const deleteItems = async (itemRefList: EntityExtraVersionRef[]) => {
      const identity = await context.getIdentity();
      setSpinnerProps({});

      const successfulResults: EntityExtraVersionRef[] = [];

      for (const item of itemRefList) {
        const deleteItemOutput = await context.getAdvancedListAPI().deleteItem({
          entityId: item.entityExtraRef.entityRef.entityId!,
          repositoryId:
            item.entityExtraRef.entityRef.repositoryRef.repositoryId!,
          entityExtraId: item.entityExtraRef.extraId!,
          by: {
            id: identity.id!,
            realm: "Amazon",
            type: "Person"
          },
          containerId: props.containerRef.id
        });

        const output = APIOutput.fromRaw<DeleteAdvancedListItemCommandOutput>(
          deleteItemOutput.data
        );
        if (output.isErr()) {
          setFlashbarProps({
            ...flashbarProps,
            items: [
              ...(flashbarProps?.items || []),
              {
                type: "error",
                content: output.err.message,
              },
            ],
          });
          setDeleteItemProgress((prev) => ({
            ...prev,
            [item.entityExtraRef.extraId]: false,
          }));
        } else {
          setDeleteItemProgress((prev) => ({
            ...prev,
            [item.entityExtraRef.extraId]: true,
          }));

          successfulResults.push(item);
        }
      }
      setSpinnerProps(undefined);
      setSuccessfulResults(successfulResults);
    };

    return (
        <Modal
            visible={true}
            header={<Header>Delete Items</Header>}
            footer={
                <Box float="right">
                    {successfulResults !== undefined ? (
                        // Close the deletion results
                        <Button
                            variant="primary"
                            onClick={() => {
                                props.onDeleted?.(successfulResults);
                            }}
                        >
                            Close
                        </Button>
                    ) :
                    (
                        // Perform the deletion
                        <SpaceBetween size={"s"} direction="horizontal">
                            <Button
                                onClick={(e) => {
                                    props.onCanceled?.();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                disabled={value !== "delete" || spinnerProps}
                                onClick={() => {
                                    deleteItems(props.itemRefList);
                                }}
                            >
                                Confirm
                            </Button>
                        </SpaceBetween>
                    )}
                </Box>
            }
        >
            {flashbarProps && <Flashbar {...flashbarProps} />}
            {(successfulResults === undefined || spinnerProps) && <div>
                {/* Hide input during & after deletion */}
                Are you sure you want to delete all the selected items?

                Please type <strong><em>delete</em></strong> to confirm deletion:
                <Input
                    placeholder="delete"
                    onChange={({ detail }) => setValue(detail.value)}
                    value={value}
                />
            </div>}
            {spinnerProps && (
                <Spinner size="normal" />
            )}
            {deleteItemProgress && (
            <SpaceBetween direction="vertical" size="m">{
                Object.entries(deleteItemProgress).map(([key, value]) => {
                    return (
                    <SpaceBetween direction="horizontal" size="s">
                        <>{key}</>
                        <>
                            {!value && <StatusIndicator type="error">Failed</StatusIndicator>}
                            {value && <StatusIndicator type="success">Deleted</StatusIndicator>}
                        </>
                    </SpaceBetween>
                    );
                })
            }</SpaceBetween>)}
        </Modal>
    );
}

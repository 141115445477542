import { CoreUtils } from "./core-utils";
import { distance } from "fastest-levenshtein";

export namespace FuzzySearchUtils {
    // Fuzzy match function for a single term
    function fuzzyMatch(value: string, term: string, maxErrorCount: number): boolean {
        const lowercaseValue = value.toLowerCase();
        const lowercaseTerm = term.toLowerCase();

        // Check if the term is included in the value (faster check)
        if (lowercaseValue.includes(lowercaseTerm)) return true;

        // If not, check using Levenshtein distance
        const words = lowercaseValue.split(" ");
        return words.some(word => {
            const errorCount = distance(word, lowercaseTerm);
            return  errorCount <= maxErrorCount;
        });
    }

    // Fuzzy search function with OR capability
    export function fuzzySearchOR<T>(
        item: T,
        searchTerms: string[],
        keys: string[],
        maxErrors: number = 2
    ): boolean {
        return searchTerms.some(term =>
            keys.some(key =>
                CoreUtils.getNestedValues(item, key as string).some(value =>
                    fuzzyMatch(value, term, maxErrors)
                )
            )
        );
    }
}
import { RichTextContent } from "@amzn/ask-legal-domain";
import * as React from "react";
import { HtmlDiffer } from "../../common/HtmlDiffer";
import { SmartRichTextView } from "../../common/RichTextEditor/SmartRichTextView";

export const RichTextContentDiff = (props: {
    oldContent: RichTextContent;
    newContent: RichTextContent;
}) => {
    return <React.Fragment>
        <SmartRichTextView
            content={HtmlDiffer.getHTMLDiffString(props.newContent?.contentValue, props.oldContent?.contentValue)}
            diffMode={true}
        />
    </React.Fragment>;
};
import { DocumentGroup, Document, S3File } from "@amzn/ask-legal-domain";
import { ExpandableSection } from "@amzn/awsui-components-react";
import * as React from "react";
import "../../../styles/component/document-group.scss";
import { documentsComparator } from "../../../utils/common-utils";

export const DocumentGroupExpandable = (props: {
    defaultExpanded?: boolean;
    fileGroups: DocumentGroup[];
    filteredDocs?: Document[];
    /**
     * Use this flag to control the expandable section to be expanded or not from parent component.
     */
    controlledExpand?: boolean;
    manualSort: boolean;
    FileViewComponent: (props: { document: Document, groupTitle: string }) => JSX.Element;
}) => {
    const [expand, setExpand] = React.useState<boolean[]>(props.fileGroups.map(() => !!props.defaultExpanded));

    React.useEffect(() => {
        if (
            props.controlledExpand !== undefined &&
            props.controlledExpand !== null
        ) {
            setExpand(props.fileGroups.map(() => !!props.controlledExpand));
        }
    }, [props.controlledExpand]);

    return (
      <React.Fragment>
        {props.fileGroups.map((group, index) => (
          <ExpandableSection
            className="document-group-expandable"
            key={index}
            variant="footer"
            expanded={expand[index]}
            defaultExpanded={!!props.defaultExpanded}
            onChange={(e) => {
              const newExpand = expand.slice();
              newExpand[index] = e.detail.expanded;
              setExpand(newExpand);
            }}
            headerText={<h5>{group.groupTitle}</h5>}
          >
            <div className="document-group-flex">
              <p>{group.groupDescription}</p>
              {!!group.files &&
                group.files
                  .filter((file) => {
                    if (!props.filteredDocs) return true;
                    const found = !!props.filteredDocs.find(
                      (doc) =>
                        S3File.AskLegalFileKey.toString(doc.s3File.key) ===
                        S3File.AskLegalFileKey.toString(file.s3File.key)
                    );
                    return !!found;
                  })
                  .sort(!props.manualSort ? documentsComparator : undefined)
                  .map((f, index) => (
                    <div key={index} className="document-row">
                      <props.FileViewComponent key={f.filename} document={f} groupTitle={group.groupTitle} />
                    </div>
                  ))}
            </div>
          </ExpandableSection>
        ))}
      </React.Fragment>
    );
};
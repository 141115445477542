import * as XLSX from "xlsx";

export namespace ExcelFactory {
    export interface CellData {
        [key: string]: any;
    }

    export interface CellDataValidation {
        row: number;
        column: string;
        errors: string[];
    }

    /**
     * Function to write JSON data to an Excel file
     * @param input
     * @returns {void}
     */
    export function writeJSONtoExcel(input: {
        data: any;
        sheetName: string;
        fileNameWithExtension: string;
    }): void {
        const worksheet = XLSX.utils.json_to_sheet(input.data, {});
        const workbook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workbook, worksheet, input.sheetName);

        // create xlsx file and save it
        XLSX.writeFile(
            workbook,
            input.fileNameWithExtension,
            { compression: true }
        );
    }

    /**
     * Function to load entire excel into memory
     * Larger files may need to be handled differently
     * @param input
     * @returns {Promise<ExcelData[]>}
     */
    export async function readExcel(input: {
        file: File;
        sheetName?: string;
    }): Promise<CellData[]> {
        const arrayBuffer = await input.file.arrayBuffer();
        const workbook = XLSX.read(arrayBuffer, { type: "array" });
        const sheetName = input.sheetName || workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const excelData = XLSX.utils.sheet_to_json(worksheet, {
            blankrows: false,
            defval: null,
        }) as CellData[];
        return excelData;
    }

    /**
     * Excel defaults date cell values to UTC based on offset from 1900
     * with a leap year bug on 1900
     */
    export function getDateFromCell(excelSerialDate: number): Date {
        return new Date(Date.UTC(0, 0, excelSerialDate - 1));
    }
}
import { Badge, Box } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";

interface LegalOnlyLocalStorage {
    values: Record<string, {value: boolean, timestamp: number}>;
}

const LEGALONLY_LOCAL_EXPIRE_TIME_IN_HOUR = 1;
const LOCAL_STORAGE_KEY = "legalonly";

export const LegalOnlyBadge = (props: {
    pageLibraryId: string
}) => {
    const context = React.useContext(AppContext);
    const isLegalOnlyRunner = useAPI2(context.getPageLibraryAPI().isLegalOnly);
    const [isLegalOnly, setIsLegalOnly] = React.useState<boolean>(false);

    const calculateDateDiff = (now: number, before: number) => {
        let diff = (now - before) / 1000;
        diff /= (60 * 60);
        return diff;
    };

    React.useEffect(() => {
        const legalOnlyLocalStorageValues = context.getLocalStorage<LegalOnlyLocalStorage>(LOCAL_STORAGE_KEY);
        if (
            legalOnlyLocalStorageValues !== null &&
            legalOnlyLocalStorageValues !== undefined &&
            !!legalOnlyLocalStorageValues.values[props.pageLibraryId] &&
            calculateDateDiff(Date.now(), legalOnlyLocalStorageValues.values[props.pageLibraryId].timestamp) <= LEGALONLY_LOCAL_EXPIRE_TIME_IN_HOUR
        ) {
            setIsLegalOnly(legalOnlyLocalStorageValues.values[props.pageLibraryId].value);
        } else {
            isLegalOnlyRunner.invoke(props.pageLibraryId);
        }
    }, [props.pageLibraryId]);

    React.useEffect(() => {
        if (isLegalOnlyRunner.status === "Succeeded") {
            setIsLegalOnly(isLegalOnlyRunner.output);

            let legalOnlyLocalStorageValues = context.getLocalStorage<LegalOnlyLocalStorage>(LOCAL_STORAGE_KEY);
            if (!legalOnlyLocalStorageValues) {
                legalOnlyLocalStorageValues = { values: {} };
            }
            legalOnlyLocalStorageValues[props.pageLibraryId] = { value: isLegalOnlyRunner.output, timestamp: Date.now() };
            context.setLocalStorage<LegalOnlyLocalStorage>(LOCAL_STORAGE_KEY, legalOnlyLocalStorageValues);
        }
    }, [isLegalOnlyRunner.status]);

    return <React.Fragment>
        {isLegalOnly === true && LegalOnlyBadgeComp}
    </React.Fragment>;
};

export const LegalOnlyBadgeComp = <Box variant="span" margin={{left: "s", right: "s"}}><Badge color="green">Legal Only</Badge></Box>;
import { Alert, Box, Button, Modal, ModalProps } from "@amzn/awsui-components-react";
import * as React from "react";

export const CommonNavigationModal = (props: {
    child: JSX.Element;
    header: any;
    loading: boolean;
    disabled?: boolean;
    size?: ModalProps.Size;
    onComplete: () => void;
    onCancel: () => void;
}) => {
    return (
        <Modal
            header={props.header}
            visible={true}
            onDismiss={props.onCancel}
            size={props.size ? props.size : "medium"}
            footer={
                <Box float="right">
                    <Button
                        variant="primary"
                        loading={props.loading}
                        disabled={props.disabled}
                        onClick={props.onComplete}>
                        Complete
                    </Button>
                </Box>
            }>
                <div>{props.child}</div>
        </Modal>
    );
};
import {
  ILabel,
  IResourceLabelLink,
  ResourceLabelLink,
  Label,
  ServerSidePaginatedLoadingInput,
  ServerSidePaginatedLoadingOutput,
  APIOutput
} from "@amzn/ask-legal-domain";
import { RestAPI } from "@aws-amplify/api-rest";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface LabelAPI {
  create(input: ILabel.CreateLabelInput): Promise<APIResponse<Label.Data>>;
  update(input: ILabel.UpdateLabelInput): Promise<APIResponse<Label.Data>>;
  load(input: string): Promise<APIResponse<Label.Data>>;
  loadByInstance(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>>>;
  link(input: IResourceLabelLink.LinkInput): Promise<APIResponse<ResourceLabelLink.Data>>;
  unlink(input: IResourceLabelLink.UnlinkInput): Promise<APIResponse<ResourceLabelLink.Data>>;
  getLabelsByResource(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>>>;
  getResourcesByLabel(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<ResourceLabelLink.Data>>>;
  getLabelsByMultiResources(
    input: IResourceLabelLink.GetLabelsByMultiResourcesInput
  ): Promise<APIResponse<IResourceLabelLink.GetLabelsByMultiResourcesOutput>>;
}

export class LabelAPIImpl implements LabelAPI {
  constructor(private context: AppContextValue) {}

  async getLabelsByMultiResources(
    input: IResourceLabelLink.GetLabelsByMultiResourcesInput
  ): Promise<APIResponse<IResourceLabelLink.GetLabelsByMultiResourcesOutput>> {
    const chunkSize = IResourceLabelLink.MAX_LENGTH_RESOURCE_IDS;
    const copyOfResourceIds = [...input.resourceIds];
    let copyOfInput = {
      ...input
    };
    const resourceIdLabelsArray: {
      resourceId: string;
      labels: Label.Data[];
    }[] = [];
    let chunk = copyOfResourceIds.splice(0, chunkSize);
    while (chunk.length > 0) {
      copyOfInput = {
        ...copyOfInput,
        resourceIds: chunk
      };
      const rawOutput: APIResponse<IResourceLabelLink.GetLabelsByMultiResourcesOutput> = await RestAPI.post(
        "custom-domain",
        "/label/get-labels-by-multi-resources",
        toDefaultRequestPayload(copyOfInput)
      );
      const output = APIOutput.fromRaw<IResourceLabelLink.GetLabelsByMultiResourcesOutput>(rawOutput.data);
      if (output.isErr()) {
        throw new Error(output.err.message);
      }
      resourceIdLabelsArray.push(...output.data.output);
      console.debug(`remaining resource ids is ${copyOfResourceIds.length}`);
      chunk = copyOfResourceIds.splice(0, chunkSize);
    }

    const response: APIResponse<IResourceLabelLink.GetLabelsByMultiResourcesOutput> = {
      data: APIOutput.createSuccessOutput(
        IResourceLabelLink.GetLabelsByMultiResourcesOutput.create({
          output: resourceIdLabelsArray
        })
      ),
      httpStatusCode: 200,
      statusText: "Success"
    };
    return response;
  }

  async getLabelsByResource(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>>> {
    const response: APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>> = await RestAPI.post(
      "custom-domain",
      "/label/get-labels-by-resource",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async getResourcesByLabel(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<ResourceLabelLink.Data>>> {
    const response: APIResponse<ServerSidePaginatedLoadingOutput<ResourceLabelLink.Data>> = await RestAPI.post(
      "custom-domain",
      "/label/get-resources-by-label",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async link(input: IResourceLabelLink.LinkInput): Promise<APIResponse<ResourceLabelLink.Data>> {
    const response: APIResponse<ResourceLabelLink.Data> = await RestAPI.post(
      "custom-domain",
      "/label/link",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async unlink(input: IResourceLabelLink.UnlinkInput): Promise<APIResponse<ResourceLabelLink.Data>> {
    const response: APIResponse<ResourceLabelLink.Data> = await RestAPI.post(
      "custom-domain",
      "/label/unlink",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async create(input: ILabel.CreateLabelInput): Promise<APIResponse<Label.Data>> {
    const response: APIResponse<Label.Data> = await RestAPI.post(
      "custom-domain",
      "/label/create",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async update(input: ILabel.UpdateLabelInput): Promise<APIResponse<Label.Data>> {
    const response: APIResponse<Label.Data> = await RestAPI.post(
      "custom-domain",
      "/label/update",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async load(input: string): Promise<APIResponse<Label.Data>> {
    const response: APIResponse<Label.Data> = await RestAPI.post(
      "custom-domain",
      "/label/load",
      toDefaultRequestPayload(input)
    );
    return response;
  }

  async loadByInstance(
    input: ServerSidePaginatedLoadingInput
  ): Promise<APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>>> {
    const response: APIResponse<ServerSidePaginatedLoadingOutput<Label.Data>> = await RestAPI.post(
      "custom-domain",
      "/label/load-by-instance",
      toDefaultRequestPayload(input)
    );
    return response;
  }
}

import { EntityType, ExternalReference, IExternalReference } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { UIModel } from "./ui-model";

export namespace ExternalReferenceModel {
    export const NAME_CHAR_LIMIT = 50;
    export const DESCRIPTION_CHAR_LIMIT = 200;
    export class CreateState<T> {
        referenceType: UIModel.State<ExternalReference.Type>;
        baseEntityId: UIModel.State<string>;
        baseEntityType: UIModel.State<EntityType>;
        payload: UIModel.State<T>;
        name: UIModel.State<string>;
        description: UIModel.State<string>;
        errorText: string;
        reset: () => void;

        static toInput<T> (state: CreateState<T>): IExternalReference.CreateExternalReferenceInput<T> {
            return IExternalReference.CreateExternalReferenceInput.create<T>({
                name: state.name.value,
                description: state.description.value,
                baseEntityId: state.baseEntityId.value,
                baseEntityType: state.baseEntityType.value,
                referenceType: state.referenceType.value,
                payload: state.payload.value
            });
        }

        static use<T> (props: {
            template?: IExternalReference.CreateExternalReferenceInput<T>
            baseEntityId: string;
            baseEntityType: EntityType;
            referenceType: ExternalReference.Type;
            initialPayload: T
        }): CreateState<T> {
            const referenceType = UIModel.State.use<ExternalReference.Type>({
                initialValue: props.referenceType
            });
            const baseEntityId = UIModel.State.use<string>({
                initialValue: props.baseEntityId
            });
            const baseEntityType = UIModel.State.use<EntityType>({
                initialValue: props.baseEntityType
            });
            const payload = UIModel.State.use<T>({
                initialValue: props.initialPayload
            });
            const name = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: null,
                characterLimit: 50
            });
            const description = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: null,
                characterLimit: 200
            });
            const [errorText, setErrorText] = useState<string>("");

            useEffect(() => {
                let _errorText = "";
                if (!name.value) {
                    _errorText = _errorText + "Must have name\n";
                }
                if (!description.value) {
                    _errorText = _errorText + "Must have description\n";
                }
                if (!payload.value) {
                    _errorText = _errorText + "Must have non-empty payload\n";
                }
                if (!baseEntityId.value) {
                    _errorText = _errorText + "Must have valid base entity\n";
                }
                if (!baseEntityType.value) {
                    _errorText = _errorText + "Must have valid base entity\n";
                }
                if (!referenceType.value) {
                    _errorText = _errorText + "Must have valid reference type\n";
                }
                setErrorText(_errorText);
            }, [
                description.value,
                name.value,
                payload.value,
                baseEntityId.value,
                baseEntityType.value,
                referenceType.value
            ]);

            const reset = () => {
                name.setValue("");
                description.setValue("");
                payload.setValue(props.initialPayload);
            };

            return Builder<CreateState<T>>(new CreateState<T>())
                .name(name)
                .description(description)
                .baseEntityId(baseEntityId)
                .baseEntityType(baseEntityType)
                .payload(payload)
                .referenceType(referenceType)
                .errorText(errorText)
                .reset(reset)
                .build();
        }

    }
}
import {
    EntityFactory,
    EntityRef,
    INavigation,
    Navigation,
    Team,
    validUrlRegex
} from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import * as React from "react";
import { UIModel } from "./ui-model";

export namespace NavigationModel {
    export const NAME_CHAR_LIMIT = 75;

    export class AddNavItemState {
        nameField: UIModel.State<string>;
        urlField: UIModel.State<string>;
        parentTab: UIModel.State<Navigation.NavTab>;
        parentGroup: UIModel.State<Navigation.NavGroup>;
        parentLink: UIModel.State<Navigation.NavLink>;
        isLink: UIModel.State<boolean>;
        reset: () => void;

        static use(props: {
            parentTab: Navigation.NavTab;
            parentGroup?: Navigation.NavGroup;
            parentLink?: Navigation.NavLink;
        }): AddNavItemState {
            const nameField = UIModel.State.useNotNullStringWithCharLimit({
                characterLimit: NAME_CHAR_LIMIT
            });
            const urlField = UIModel.State.useWithRegexValidation({
                regex: validUrlRegex
            });
            const parentTab = UIModel.State.use<Navigation.NavTab>({
                initialValue: props.parentTab
            });
            const parentGroup = UIModel.State.use<Navigation.NavGroup>({
                initialValue: props.parentGroup
            });
            const parentLink = UIModel.State.use<Navigation.NavLink>({
                initialValue: props.parentLink
            });
            const isLink = UIModel.State.use<boolean>({
                initialValue: true
            });
            const reset = () => {
                nameField.setValue(null);
                urlField.setValue(null);
                parentTab.setValue(null);
                parentGroup.setValue(null);
                parentLink.setValue(null);
                isLink.setValue(true);
            };

            return Builder<AddNavItemState>()
                .nameField(nameField)
                .urlField(urlField)
                .parentTab(parentTab)
                .parentGroup(parentGroup)
                .parentLink(parentLink)
                .isLink(isLink)
                .reset(reset)
                .build();
        }
    }

    export class EditNavItemState {
        tabField: UIModel.State<Navigation.NavTab>;
        navGroupField: UIModel.State<Navigation.NavGroup>;
        navLinkField: UIModel.State<Navigation.NavLink>;
        navSubLinkField: UIModel.State<Navigation.NavSubLink>;
        nameField: UIModel.State<string>;
        urlField: UIModel.State<string>;
        isLink: UIModel.State<boolean>;

        reset: () => void;

        static use(props: {
            isLink: boolean;
            navTab: Navigation.NavTab;
            navGroup: Navigation.NavGroup;
            navLink?: Navigation.NavLink;
            navSubLink?: Navigation.NavSubLink;
        }): EditNavItemState {
            const nameField = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: props.navSubLink ? props.navSubLink.name :
                    props.navLink ? props.navLink.name :
                        props.navGroup ? props.navGroup.name : props.navTab.name,
                characterLimit: NAME_CHAR_LIMIT
            });
            const urlField = UIModel.State.useWithRegexValidation({
                initialValue: props.navSubLink ? props.navSubLink.url :
                    props.navLink ? props.navLink.url :
                    props.navGroup ? props.navGroup.url : null,
                regex: validUrlRegex
            });
            const tabField = UIModel.State.use<Navigation.NavTab>({
                initialValue: props.navTab
            });
            const navGroupField = UIModel.State.use<Navigation.NavGroup>({
                initialValue: props.navGroup
            });
            const navLinkField = UIModel.State.use<Navigation.NavLink>({
                initialValue: props.navLink
            });
            const navSubLinkField = UIModel.State.use<Navigation.NavSubLink>({
                initialValue: props.navSubLink
            });
            const isLink = UIModel.State.use<boolean>({
                initialValue: props.isLink
            });

            const reset = () => {
                if (props.navLink && props.navSubLink) {
                    nameField.setValue(props.navSubLink.name);
                    urlField.setValue(props.navSubLink.url);
                } else if (props.navLink) {
                    nameField.setValue(props.navLink.name);
                    urlField.setValue(props.navLink.url);
                } else {
                    nameField.setValue(props.navGroup.name);
                    urlField.setValue(props.navGroup.url);
                }
                isLink.setValue(!!urlField.value);
            };

            return Builder<EditNavItemState>()
                .nameField(nameField)
                .urlField(urlField)
                .tabField(tabField)
                .navGroupField(navGroupField)
                .navLinkField(navLinkField)
                .navSubLinkField(navSubLinkField)
                .isLink(isLink)
                .reset(reset)
                .build();
        }
    }

    export class UpdateNavigationState {
        navigationRef: EntityRef;
        navTabs: UIModel.State<Navigation.NavTab[]>;
        readonly _template: Navigation.Data;
        setTemplate: (template: Navigation.Data) => void;
        addEmptyTab: () => void;
        updateTab: (tabId: string, tabName: string, legalOnly: boolean, viewers?: Team[]) => void;
        addNavItem: (addNavItemState: AddNavItemState) => void;
        editNavItem: (editNavitemState: EditNavItemState) => void;
        removeNavItem: (
            navTab: Navigation.NavTab,
            navGroup?: Navigation.NavGroup,
            navLink?: Navigation.NavLink,
            navSubLink?: Navigation.NavSubLink
        ) => void;
        reorder: (
            target: {
                navTab: Navigation.NavTab,
                navGroup?: Navigation.NavGroup,
                navLink?: Navigation.NavLink,
                navSubLink?: Navigation.NavSubLink
            },
            direction: "Up" | "Down"
        ) => void;
        isDirty: () => boolean;
        reset: () => void;

        static toInput(state: UpdateNavigationState) {
            return INavigation.UpdateNavigationInput.create({
                navigationRef: state.navigationRef,
                updatedTabs: state.navTabs.value
            });
        }

        static use(props: {
            template: Navigation.Data
        }): UpdateNavigationState {
            const [dirty, setDirty] = React.useState<boolean>(false);
            const navTabs = UIModel.State.useArray<Navigation.NavTab>({
                initialValue: props.template.tabs
            });
            const [template, _setTemplate] = React.useState<Navigation.Data>(
                props.template
            );

            const addEmptyTab = () => {
                const tabs = navTabs.value.slice();
                if (tabs.length >= Navigation.MAX_NUMBER_OF_TABS) {
                    return;
                }
                tabs.push(Navigation.NavTab.create({
                    name: "",
                    items: [],
                    legalOnly: false,
                }));
                navTabs.setValue(tabs);
                setDirty(true);
            };

            const updateTab = (tabId: string, tabName: string, legalOnly: boolean, viewers?: Team[]) => {
                const tabs = navTabs.value.slice();
                const foundIndex = tabs.findIndex(t => t.id === tabId);
                if (foundIndex < 0) return;
                const updated = Navigation.NavTab.update(tabs[foundIndex], {
                    legalOnly: legalOnly,
                    name: tabName
                });
                tabs.splice(foundIndex, 1, updated);
                navTabs.setValue(tabs);
                setDirty(true);
            };

            const addNavItem = (addNavItemState: AddNavItemState) => {
                const targetTabIndex = navTabs.value.findIndex(t => t.id === addNavItemState.parentTab.value.id);
                if (targetTabIndex < 0) return;
                let updatedTab = navTabs.value.slice()[targetTabIndex];
                if (!!addNavItemState.parentGroup.value && !!addNavItemState.parentLink.value) {
                    // Add to NavSubLink
                    const targetGroup = updatedTab.items.find(g => g.id === addNavItemState.parentGroup.value.id);
                    if (!targetGroup) return;
                    const targetLink = targetGroup.nested.find(l => l.id === addNavItemState.parentLink.value.id);
                    const addedSubLink = Navigation.NavSubLink.create({
                        name: addNavItemState.nameField.value,
                        url: addNavItemState.urlField.value
                    });
                    const updatedLink = Navigation.NavLink.addOrUpdateItem(targetLink, addedSubLink);
                    const updatedGroup = Navigation.NavGroup.addOrUpdateItem(targetGroup, updatedLink);
                    updatedTab = Navigation.NavTab.addOrUpdateGroup(updatedTab, updatedGroup);
                } else if (!!addNavItemState.parentGroup.value) {
                    // Add to NavLink
                    const targetGroup = updatedTab.items.find(g => g.id === addNavItemState.parentGroup.value.id);
                    if (!targetGroup) return;
                    const addedLink = Navigation.NavLink.create({
                        name: addNavItemState.nameField.value,
                        url: !addNavItemState.isLink.value ? undefined : addNavItemState.urlField.value
                    });
                    const updatedGroup = Navigation.NavGroup.addOrUpdateItem(targetGroup, addedLink);
                    updatedTab = Navigation.NavTab.addOrUpdateGroup(updatedTab, updatedGroup);
                } else {
                    // Add to NavGroups
                    const addedGroup = Navigation.NavGroup.create({
                        items: [],
                        name: addNavItemState.nameField.value,
                        url: !addNavItemState.isLink.value ? undefined : addNavItemState.urlField.value,
                    });
                    updatedTab = Navigation.NavTab.addOrUpdateGroup(updatedTab, addedGroup);
                }
                navTabs.setValue(
                    Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                );
                setDirty(true);
            };

            const editNavItem = (editNavItemState: EditNavItemState) => {
                if (!editNavItemState.tabField.value || !editNavItemState.navGroupField.value) return;
                // Since edit is allowed from group level only
                // Tab Field & Group field are mandatory
                if (!!editNavItemState.navLinkField.value && !!editNavItemState.navSubLinkField.value) {
                    // edit navSubLink
                    const updatedNavSubLink = Navigation.NavSubLink.update(
                        editNavItemState.navSubLinkField.value,
                        {
                            name: editNavItemState.nameField.value,
                            url: editNavItemState.urlField.value
                        }
                    );
                    const updatedNavLink = Navigation.NavLink.addOrUpdateItem(
                        editNavItemState.navLinkField.value,
                        updatedNavSubLink
                    );
                    const updatedNavGroup = Navigation.NavGroup.addOrUpdateItem(
                        editNavItemState.navGroupField.value,
                        updatedNavLink
                    );
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(
                        editNavItemState.tabField.value,
                        updatedNavGroup
                    );
                    navTabs.setValue(Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab));
                } else if (!!editNavItemState.navLinkField.value) {
                    // edit navLink
                    const updatedNavLink = Navigation.NavLink.update(
                        editNavItemState.navLinkField.value,
                        {
                            name: editNavItemState.nameField.value,
                            url: !editNavItemState.isLink.value ? undefined : editNavItemState.urlField.value
                        }
                    );
                    const updatedNavGroup = Navigation.NavGroup.addOrUpdateItem(
                        editNavItemState.navGroupField.value,
                        updatedNavLink
                    );
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(
                        editNavItemState.tabField.value,
                        updatedNavGroup
                    );
                    navTabs.setValue(Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab));
                } else {
                    // edit navGroup
                    const updatedNavGroup = Navigation.NavGroup.update(
                        editNavItemState.navGroupField.value,
                        {
                            name: editNavItemState.nameField.value,
                            url: !editNavItemState.isLink.value ? undefined : editNavItemState.urlField.value
                        }
                    );
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(
                        editNavItemState.tabField.value,
                        updatedNavGroup
                    );
                    navTabs.setValue(Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab));
                }
                setDirty(true);
            };

            const removeNavItem = (
                navTab: Navigation.NavTab,
                navGroup?: Navigation.NavGroup,
                navLink?: Navigation.NavLink,
                navSubLink?: Navigation.NavSubLink
            ) => {
                if (!!navGroup && !!navLink && !!navSubLink) {
                    // remove navSublink
                    const updatedNavLink = Navigation.NavLink.removeItem(navLink, navSubLink);
                    const updatedNavGroup = Navigation.NavGroup.addOrUpdateItem(navGroup, updatedNavLink);
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(navTab, updatedNavGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else if (!!navGroup && !!navLink) {
                    // remove navLink
                    const updatedNavGroup = Navigation.NavGroup.removeItem(navGroup, navLink);
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(navTab, updatedNavGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else if (!!navGroup) {
                    // remove navGroup
                    const updatedTab = Navigation.NavTab.removeGroup(navTab, navGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else {
                    // remove navTab
                    navTabs.setValue(
                        Navigation.Data.removeTab(navTabs.value, navTab.id)
                    );
                }
                setDirty(true);
            };

            const reorder = (
                target: {
                    navTab: Navigation.NavTab,
                    navGroup?: Navigation.NavGroup,
                    navLink?: Navigation.NavLink,
                    navSubLink?: Navigation.NavSubLink
                },
                direction: "Up" | "Down"
            ) => {
                if (!!target.navGroup && !!target.navLink && !!target.navSubLink) {
                    // reorder navSubLink
                    const func = direction === "Up" ?
                        Navigation.NavLink.moveItemOrderUp :
                        Navigation.NavLink.moveItemOrderDown;
                    const updatedLink = func(target.navLink, target.navSubLink);
                    const updatedGroup = Navigation.NavGroup.addOrUpdateItem(target.navGroup, updatedLink);
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(target.navTab, updatedGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else if (!!target.navGroup && !!target.navLink) {
                    // reorder navLink
                    const func = direction === "Up" ?
                        Navigation.NavGroup.moveItemOrderUp :
                        Navigation.NavGroup.moveItemOrderDown;
                    const updatedGroup = func(target.navGroup, target.navLink);
                    const updatedTab = Navigation.NavTab.addOrUpdateGroup(target.navTab, updatedGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else if (!!target.navGroup) {
                    // reorder navGroup
                    const func = direction === "Up" ?
                        Navigation.NavTab.moveGroupOrderUp :
                        Navigation.NavTab.moveGroupOrderDown;
                    const updatedTab = func(target.navTab, target.navGroup);
                    navTabs.setValue(
                        Navigation.Data.addOrUpdateTab(navTabs.value, updatedTab)
                    );
                } else {
                    // reorder navTab
                    const func = direction === "Up" ?
                        Navigation.Data.moveTabOrderUp :
                        Navigation.Data.moveTabOrderDown;
                    navTabs.setValue(
                        func(navTabs.value, target.navTab)
                    );
                }
                setDirty(true);
            };

            const reset = () => {
                navTabs.setValue(template.tabs);
                setDirty(false);
            };

            const setTemplate = (template: Navigation.Data) => {
                _setTemplate(template);
                setDirty(false);
                navTabs.setValue(template.tabs);
            };

            return Builder<UpdateNavigationState>()
                .navTabs(navTabs)
                .navigationRef(EntityFactory.toEntityRef(props.template))
                .addEmptyTab(addEmptyTab)
                .addNavItem(addNavItem)
                .editNavItem(editNavItem)
                .removeNavItem(removeNavItem)
                .reorder(reorder)
                .updateTab(updateTab)
                .reset(reset)
                .isDirty(() => dirty)
                .setTemplate(setTemplate)
                ._template(template)
                .build();
        }
    }
}
import * as React from "react";
import { AppContext } from "../../setup/context";
import { Box, Button, ColumnLayout, Form, FormField, Link, Modal, Popover, SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import { useHistory } from "react-router-dom";
import { useAPI } from "../../hooks/api-hook";
import { PageLibraryModel } from "../../model/page-library-model";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { UIField } from "../common/UIField";

export const PageLibraryCreate = (props: {
    id: string;
}) => {
    const [openWindow, setOpenWindow] = React.useState(false);
    const history = useHistory();
    const context = React.useContext(AppContext);
    const createPageLibraryRunner = useAPI(
        context.getPageLibraryAPI().create
    );
    const createPageLibraryState = PageLibraryModel.CreateState.use({ instanceId: props.id });

    const create = () => {
        createPageLibraryRunner.submitRun(PageLibraryModel.CreateState.toInput(createPageLibraryState));
    };

    const handleWindow = (flag: boolean) => {
        if (!flag) {
            setOpenWindow(false);
            createPageLibraryState.reset();
        } else {
            setOpenWindow(true);
        }
    };

    React.useEffect(() => {
        setOpenWindow(openWindow);
    });

    React.useEffect(() => {
        if (createPageLibraryRunner.status === "Succeeded") {
            createPageLibraryState.reset();
            history.push(`/page-library/${createPageLibraryRunner.data.output.id}/admin`);
        }
    }, [createPageLibraryRunner.status]);

    return (
        <React.Fragment>
            <Button variant="primary" onClick={() => handleWindow(true)}>Create New Page Library</Button>
            <Modal
                visible={openWindow}
                header="Create New Page Library"
                closeAriaLabel="Close New Page Library Form"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => handleWindow(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                disabled={
                                    createPageLibraryState.displayName.errorText ||
                                    createPageLibraryState.name.errorText ||
                                    createPageLibraryState.freshness.errorText
                                }
                                loading={createPageLibraryRunner.status === "Running"}
                                onClick={create}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>}
                size="max"
                onDismiss={() => handleWindow(false)}>
                <React.Fragment>
                    {createPageLibraryRunner.status === "Error" && <ErrorFlashbar error={createPageLibraryRunner.data.err} />}
                    <Form>
                        <ColumnLayout columns={2}>
                            <Box margin={{ left: "xxl" }}>
                                <SpaceBetween direction="vertical" size="l">
                                    <UIField.StateValueField
                                        state={createPageLibraryState.name}
                                        name="Page Library Name"
                                        constraintText={<React.Fragment>
                                            Maximum {PageLibraryModel.TITLE_CHAR_LIMIT} characters <Popover
                                            dismissAriaLabel="Close"
                                            header="Page Library Naming Guidelines"
                                            triggerType="custom"
                                            content={<React.Fragment>
                                                    <small>
                                                        <ul>
                                                            <li>Cannot contain spaces</li>
                                                            <li>Cannot contain any special characters</li>
                                                            <li>Can contain alphabets <b>A...Z</b> <b>a...z</b></li>
                                                            <li>Can contain numbers <b>0-9</b></li>
                                                            <li><b><em>Examples : </em></b> SamplePageLibrary, NewDocument1Library2, ... etc</li>
                                                        </ul>
                                                    </small>
                                                </React.Fragment>}
                                            >
                                                <Link variant="info">Format</Link>
                                            </Popover></React.Fragment>}
                                        editing={true}
                                    />
                                    <UIField.StateValueField
                                        state={createPageLibraryState.displayName}
                                        name="Page Library Display Name"
                                        constraintText={`Maximum ${PageLibraryModel.TITLE_CHAR_LIMIT} characters`}
                                        editing={true}
                                    />
                                    <FormField>
                                        <Toggle
                                            onChange={(e: { detail: { checked: boolean; }; }) => createPageLibraryState.restrictToLegal.setValue(e.detail.checked)}
                                            checked={createPageLibraryState.restrictToLegal.value}>
                                            Restrict to Legal Only
                                        </Toggle>
                                    </FormField>
                                    <FormField>
                                        <Toggle
                                            onChange={(e: { detail: { checked: boolean; }; }) => createPageLibraryState.shared.setValue(e.detail.checked)}
                                            checked={createPageLibraryState.shared.value}
                                        >
                                            Shared Page Library
                                        </Toggle>
                                    </FormField>
                                </SpaceBetween>
                            </Box>
                            <SpaceBetween size="l">
                                <UIField.StateValueField
                                    state={createPageLibraryState.freshness}
                                    name="Page Freshness Expiration (Days)"
                                    editing={true}
                                />
                                <FormField>
                                    <Toggle
                                        onChange={(e: { detail: { checked: boolean; }; }) => createPageLibraryState.enableApprovalWorkflow.setValue(e.detail.checked)}
                                        checked={createPageLibraryState.enableApprovalWorkflow.value}>
                                        Enable Approval Workflow
                                    </Toggle>
                                </FormField>
                                <UIField.StateValueField
                                    state={createPageLibraryState.permissionRequestTemplateUrl}
                                    constraintText={`Full URL including "https://" needs to be provided`}
                                    name="SIM Template URL for access request - OPTIONAL"
                                    editing={true}
                                />
                            </SpaceBetween>
                        </ColumnLayout>
                    </Form>
                </React.Fragment>
            </Modal>
        </React.Fragment>
    );
};
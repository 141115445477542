import {
    AdvancedList,
    EntityWorkflow,
    FieldDefinitionDataType,
    Variable
} from "@amzn/altar-sds-client";
import {
    Condition,
    FieldConfiguration,
    Workflow,
    WorkflowFactory
} from "@amzn/ask-legal-domain";
import {
    Box,
    Button,
    Header,
    Modal,
    RadioGroup,
    SpaceBetween,
    Toggle
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ItemChange } from "./ItemChange";
import { ItemCreate } from "./ItemCreate";
import { ItemFieldValueChange } from "./ItemFieldValueChange";
import { ItemFieldValueMatch } from "./ItemFieldValueMatch";
import { ItemDateReached } from "./ItemDateReached";
import { ItemDateCadenceReached } from "./ItemDateCadennceReached";

const workflowConditionTypeConstants = {
    [Condition.Type.ItemFieldValueChanged]: "Item Field Changed",
    [Condition.Type.ItemChanged]: "Item Changed",
    [Condition.Type.ItemCreated]: "Item Created",
    [Condition.Type.ItemChoiceFieldValueMatched]: "Item Field Choice Value Match",
    [Condition.Type.ItemDateFieldValueReached]: "On a date",
    [Condition.Type.ItemDateFieldValueCadenceReached]: "Every X days since"
};

export interface WorkflowAddEditModalProps {
    advancedList: AdvancedList;
    fieldConfigurations?: FieldConfiguration.Record;
    workflowPayload?: Workflow.Payload;
    workflow?: EntityWorkflow;
    onCreated?: (value: Workflow.Payload) => void;
    onCancel?: () => void;
}

export const WorkflowAddEditModal = (props: WorkflowAddEditModalProps) => {
    const [workflowPayload, setWorkflowPayload] = React.useState<Workflow.Payload>(
        props.workflowPayload ?? (props.workflow && Workflow.Payload.fromEntityWorkflow(props.workflow))
    );
    const [workflowConditionType, setWorkflowConditionType] = React.useState<Condition.Type>(
        workflowPayload?.conditionType
    );
    const [variableList, setVariableList] = React.useState<Variable[]>(workflowPayload?.variables || []);
    const hasChoiceField = props.advancedList.fieldDefinitions
        .filter(d => d.dataType === FieldDefinitionDataType.choice)
            .length > 0;

    React.useEffect(() => {
        if (props.advancedList.fieldDefinitions?.length) {
            setVariableList([
                ...workflowPayload?.variables || [],
                ...WorkflowFactory.generateVariablesFromFieldDefinitions(
                props.advancedList.fieldDefinitions, workflowConditionType, props.fieldConfigurations || {}
                ).filter(v => !workflowPayload?.variables.find(existing => existing.value === v.value))
            ]);
        }
    }, [props.advancedList.fieldDefinitions, workflowConditionType]);

    const addContentByWorkflowConditionType = () => {
        switch (workflowConditionType) {
            case Condition.Type.ItemCreated:
                return (
                    <ItemCreate
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            case Condition.Type.ItemChanged:
                return (
                    <ItemChange
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            case Condition.Type.ItemFieldValueChanged:
                return (
                    <ItemFieldValueChange
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            case Condition.Type.ItemChoiceFieldValueMatched:
                return (
                    <ItemFieldValueMatch
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            case Condition.Type.ItemDateFieldValueReached:
                return (
                    <ItemDateReached
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            case Condition.Type.ItemDateFieldValueCadenceReached:
                return (
                    <ItemDateCadenceReached
                        advancedList={props.advancedList}
                        fieldConfigurations={props.fieldConfigurations}
                        value={workflowPayload}
                        onUpdated={(value: Workflow.Payload) => {
                            setWorkflowPayload(value);
                        }}
                        variables={variableList}
                    />
                );
            default:
                return <>Not implemented yet!!!</>;
        }
    };

    const onCreateNewWorkflow = () => {
        props.onCreated?.({
            ...workflowPayload,
            variables: WorkflowFactory.filterUnusedVariables(
                variableList, workflowPayload?.action?.body + workflowPayload?.action?.subject
            )
        });
    };

    return (
        <Modal
            visible={true}
            size="large"
            header={<Header variant="h3">Configure Workflow</Header>}
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            variant="link"
                            onClick={(e) => {
                                props.onCancel?.();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={(e) => {
                                onCreateNewWorkflow();
                            }}
                        >
                            Save
                        </Button>
                    </SpaceBetween>
                </Box>
            }
            onDismiss={() => { props.onCancel(); }}
        >
            <SpaceBetween direction="vertical" size="s">
                <SpaceBetween direction="vertical" size="xxs">
                    <Header headingTagOverride="h4">Active</Header>
                    <Toggle
                        onChange={({ detail }) => {
                            setWorkflowPayload({
                                ...workflowPayload,
                                disabled: !detail.checked
                            });
                        }}
                        checked={!workflowPayload?.disabled}
                    />

                    <Header headingTagOverride="h4">When to start the workflow</Header>
                    <RadioGroup
                        items={[
                            {
                                label: workflowConditionTypeConstants[Condition.Type.ItemCreated],
                                value: Condition.Type.ItemCreated
                            },
                            {
                                label: workflowConditionTypeConstants[Condition.Type.ItemChanged],
                                value: Condition.Type.ItemChanged
                            },
                            {
                                label: workflowConditionTypeConstants[Condition.Type.ItemFieldValueChanged],
                                value: Condition.Type.ItemFieldValueChanged
                            },
                            {
                                label: workflowConditionTypeConstants[
                                    Condition.Type.ItemChoiceFieldValueMatched
                                ],
                                value: Condition.Type.ItemChoiceFieldValueMatched,
                                disabled: !hasChoiceField,
                                description: !hasChoiceField ?
                                    "No choice fields exist in this List.  A choice field must be added before using this Workflow type" :
                                    ""
                            },
                            {
                                label: workflowConditionTypeConstants[
                                    Condition.Type.ItemDateFieldValueReached
                                ],
                                value: Condition.Type.ItemDateFieldValueReached,
                            },
                            {
                                label: workflowConditionTypeConstants[
                                    Condition.Type.ItemDateFieldValueCadenceReached
                                ],
                                value: Condition.Type.ItemDateFieldValueCadenceReached,
                            }
                        ]}
                        value={workflowConditionType ?? null}
                        onChange={(e) => setWorkflowConditionType(e.detail.value as Condition.Type)}
                    />
                </SpaceBetween>

                {workflowConditionType && <>{addContentByWorkflowConditionType()}</>}
            </SpaceBetween>
        </Modal>
    );
};

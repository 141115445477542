import {
    AdminPreferences,
    Email,
    EntityFactory,
    EntityRef,
    Instance,
    InstanceInterface,
    KendraConst,
    Team
} from "@amzn/ask-legal-domain";
import { UIModel } from "./ui-model";
import * as React from "react";
import { Builder } from "builder-pattern";

export namespace InstanceModel {
    export const TITLE_CHAR_LIMIT = 50;

    export class CreateState {
        idField: UIModel.State<string>;
        nameField: UIModel.State<string>;
        emailField: UIModel.State<string>;
        permissionBindleIdField: UIModel.State<string>;
        feedbackTemplateField: UIModel.State<string>;
        ownedByTeamField: UIModel.State<Team>;
        organization: UIModel.State<AdminPreferences.Organization>;
        errorText: string;
        reset: () => void;

        static toInput (state: CreateState): InstanceInterface.CreateInstanceInput {
            return InstanceInterface.CreateInstanceInput.create(
                state.idField.value,
                state.nameField.value,
                Email.createEmail(state.emailField.value),
                state.permissionBindleIdField.value,
                state.ownedByTeamField.value,
                state.organization.value,
                state.feedbackTemplateField.value
            );
        }

        static use (props: {
            template?: InstanceInterface.CreateInstanceInput
        }): CreateState {
            const idField = UIModel.State.useWithRegexValidation({
                regex: new RegExp(/^[a-zA-Z0-9]+$/)
            });
            const nameField = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: TITLE_CHAR_LIMIT });
            const emailField = UIModel.State.use<string>({
                validation: (v: string) => {
                    if (!v) {
                        return "Value can't be null";
                    }
                    if (v.indexOf("@") < 0) {
                        return "Invalid email address";
                    }
                }
            });
            const bindleId = UIModel.State.useNotNullString({});
            const ownenByTeam = UIModel.State.use<Team>({});
            const feedbackTemplate = UIModel.State.use<string>({});
            const organization = UIModel.State.useRequired<AdminPreferences.Organization>({});

            const reset = () => {
                idField.setValue(null);
                nameField.setValue(null);
                emailField.setValue(null);
                bindleId.setValue(null);
                ownenByTeam.setValue(null);
                feedbackTemplate.setValue(undefined);
                organization.setValue(undefined);
            };

            React.useEffect(() => {
                if (!props.template) return;
                idField.setValue(props.template.id);
                nameField.setValue(props.template.name);
                emailField.setValue(props.template.email.address);
                bindleId.setValue(props.template.permissionsBindleId);
                ownenByTeam.setValue(props.template.team);
                feedbackTemplate.setValue(props.template.feedbackTicketTemplate);
            }, []);

            return Builder<CreateState>(new CreateState())
                .idField(idField)
                .nameField(nameField)
                .emailField(emailField)
                .permissionBindleIdField(bindleId)
                .ownedByTeamField(ownenByTeam)
                .feedbackTemplateField(feedbackTemplate)
                .organization(organization)
                .reset(reset)
                .build();
        }
    }

    export class UpdateState {
        target: EntityRef;
        nameField: UIModel.State<string>;
        emailField: UIModel.State<string>;
        permissionRequestTicketTemplateField: UIModel.State<string>;
        feedbackTemplateField: UIModel.State<string>;
        errorText: string;
        reset: () => void;
        isDirty: () => boolean;

        static toInput (state: UpdateState): InstanceInterface.UpdateInstanceInput {
            return InstanceInterface.UpdateInstanceInput.create(
                state.target,
                {
                    name: state.nameField.value,
                    email: Email.createEmail(state.emailField.value),
                    permissionRequestTicketTemplate: state.permissionRequestTicketTemplateField.value,
                    feedbackTicketTemplate: state.feedbackTemplateField.value
                }
            );
        }

        static use (props: {
            template: Instance
        }): UpdateState {
            const nameField = UIModel.State.useNotNullStringWithCharLimit({ characterLimit: TITLE_CHAR_LIMIT });
            const emailField = UIModel.State.use<string>({
                validation: (v: string) => {
                    if (!v) {
                        return "Value can't be null";
                    }
                    if (v.indexOf("@") < 0) {
                        return "Invalid email address";
                    }
                }
            });

            const permissionRequestTicketTemplateField = UIModel.State.use<string>({
                initialValue: "",
                validation: (v: string) => {
                    if (!!v && !v.startsWith("https://")) {
                        return "Template URL must start with 'https://'";
                    }
                }
            });
            const feedbackTemplateField = UIModel.State.use<string>({
                initialValue: "",
                validation: (v: string) => {
                    if (!!v && !v.startsWith("https://")) {
                        return "Template URL must start with 'https://'";
                    }
                }
            });

            const reset = () => {
                nameField.setValue(props.template.name);
                emailField.setValue(props.template.email.address);
                permissionRequestTicketTemplateField.setValue(props.template.permissionRequestTicketTemplate);
                feedbackTemplateField.setValue(!props.template.feedbackTicketTemplate ? "" : props.template.feedbackTicketTemplate);
            };

            const isDirty = () => {
                if (
                    nameField.value === props.template.name &&
                    emailField.value === props.template.email.address &&
                    permissionRequestTicketTemplateField.value === props.template.permissionRequestTicketTemplate &&
                    feedbackTemplateField.value === props.template.feedbackTicketTemplate
                ) return false;
                return true;
            };

            React.useEffect(() => {
                nameField.setValue(props.template.name);
                emailField.setValue(props.template.email.address);
                permissionRequestTicketTemplateField.setValue(props.template.permissionRequestTicketTemplate);
                feedbackTemplateField.setValue(!props.template.feedbackTicketTemplate ? "" : props.template.feedbackTicketTemplate);
            }, []);

            return Builder<UpdateState>(new UpdateState())
                .target(EntityFactory.toEntityRef(props.template))
                .nameField(nameField)
                .emailField(emailField)
                .permissionRequestTicketTemplateField(permissionRequestTicketTemplateField)
                .feedbackTemplateField(feedbackTemplateField)
                .reset(reset)
                .isDirty(isDirty)
                .build();
        }
    }

    export class UpdatePreferencesState {
        target: EntityRef;
        sortByUpdated: UIModel.State<boolean>;
        sortByCreated: UIModel.State<boolean>;
        filterByAuthor: UIModel.State<boolean>;
        filterByFileType: UIModel.State<KendraConst.KendraContentType[]>;
        filterByPageLibrary: UIModel.State<boolean>;
        filterByLastUpdated: UIModel.State<boolean>;
        filterByPermissionGroup: UIModel.State<boolean>;
        paginationPrefs: UIModel.State<AdminPreferences.PaginationOptions[]>;
        showAuthorizedSearchResultsOnly: UIModel.State<boolean>;
        showResultsFromShared: UIModel.State<string>;
        notifyPageFreshnessReminders: UIModel.State<boolean>;
        notifyLegalContactFreshnessReminders: UIModel.State<boolean>;
        legalContactFreshness: UIModel.State<number>;
        organization: UIModel.State<AdminPreferences.Organization>;
        errorText: string;
        reset: () => void;
        isDirty: () => boolean;
        updateFileTypePreference: (type: KendraConst.KendraContentType, enabled: boolean) => void;
        updatePaginationPreference: (value: string, enabled: boolean) => void;

        static toInput (state: UpdatePreferencesState): InstanceInterface.UpdateInstanceInput {
            return InstanceInterface.UpdateInstanceInput.create(
                state.target,
                {
                    preferences: Builder<AdminPreferences.InstancePreferences>()
                        .search({
                            sortPrefs: {
                                showCreatedDate: state.sortByCreated.value,
                                showUpdatedDate: state.sortByUpdated.value
                            },
                            filterPrefs: {
                                showAuthors: state.filterByAuthor.value,
                                showFileType: state.filterByFileType.value,
                                showPageLibrary: state.filterByPageLibrary.value,
                                showLastUpdated: state.filterByLastUpdated.value,
                                showPermissionGroups: state.filterByPermissionGroup.value
                            },
                            paginationPrefs: state.paginationPrefs.value,
                            searchShared: state.showResultsFromShared.value
                        })
                        .notifications({
                            sendPageFreshnessReminders: state.notifyPageFreshnessReminders.value,
                            legalContactFreshness: {
                                sendReminders: state.notifyLegalContactFreshnessReminders.value,
                                defaultFreshness: state.legalContactFreshness.value
                            }
                        })
                        .organization(state.organization.value)
                        .showAuthorizedSearchResultsOnly(state.showAuthorizedSearchResultsOnly.value)
                        .build()
                }
            );
        }

        static use (props: {
            template: Instance;
        }): UpdatePreferencesState {
            const sortByUpdated = UIModel.State.use({initialValue: false});
            const sortByCreated = UIModel.State.use({initialValue: false});
            const filterByAuthor = UIModel.State.use({initialValue: false});
            const filterByFileType = UIModel.State.useArray<KendraConst.KendraContentType>({initialValue: []});
            const filterByPageLibrary = UIModel.State.use({initialValue: false});
            const filterByLastUpdated = UIModel.State.use({initialValue: false});
            const filterByPermissionGroup = UIModel.State.use({initialValue: false});
            const paginationPrefs = UIModel.State.useArray<AdminPreferences.PaginationOptions>({initialValue: []});
            const showAuthorizedSearchResultsOnly = UIModel.State.use<boolean>({initialValue: false});
            const showResultsFromShared = UIModel.State.use<string>({});
            const sendPageFreshnessReminders = UIModel.State.use<boolean>({ initialValue: false });
            const sendLegalContactFreshnessReminders = UIModel.State.use<boolean>({ initialValue: false });
            const legalContactFreshness = UIModel.State.use<number>({ initialValue: AdminPreferences.DEFAULT_LEGAL_CONTACT_FRESHNESS });
            const organization = UIModel.State.use<AdminPreferences.Organization>({ initialValue: props.template.preferences?.organization });

            const reset = () => {
                if (!props.template.preferences) {
                    sortByUpdated.setValue(false);
                    sortByCreated.setValue(false);
                    filterByAuthor.setValue(false);
                    filterByFileType.setValue([]);
                    filterByPageLibrary.setValue(false);
                    filterByLastUpdated.setValue(false);
                    filterByPermissionGroup.setValue(false);
                    paginationPrefs.setValue([]);
                    sendPageFreshnessReminders.setValue(false);
                    sendLegalContactFreshnessReminders.setValue(false);
                    legalContactFreshness.setValue(AdminPreferences.DEFAULT_LEGAL_CONTACT_FRESHNESS);
                    organization.setValue(null);
                    showAuthorizedSearchResultsOnly.setValue(false);
                    showResultsFromShared.setValue(null);
                } else {
                    sortByUpdated.setValue(props.template.preferences.search.sortPrefs.showUpdatedDate);
                    sortByCreated.setValue(props.template.preferences.search.sortPrefs.showCreatedDate);
                    filterByAuthor.setValue(props.template.preferences.search.filterPrefs.showAuthors);
                    filterByFileType.setValue(props.template.preferences.search.filterPrefs.showFileType);
                    filterByPageLibrary.setValue(props.template.preferences.search.filterPrefs.showPageLibrary);
                    filterByLastUpdated.setValue(props.template.preferences.search.filterPrefs.showLastUpdated);
                    filterByPermissionGroup.setValue(props.template.preferences.search.filterPrefs.showPermissionGroups);
                    paginationPrefs.setValue(props.template.preferences.search.paginationPrefs);
                    showAuthorizedSearchResultsOnly.setValue(props.template.preferences.showAuthorizedSearchResultsOnly);
                    showResultsFromShared.setValue(props.template.preferences.search.searchShared);
                    sendPageFreshnessReminders.setValue(
                        !props.template.preferences.notifications ?
                        false : props.template.preferences.notifications.sendPageFreshnessReminders
                    );
                    sendLegalContactFreshnessReminders.setValue(
                        !props.template.preferences.notifications?.legalContactFreshness ?
                        false : props.template.preferences.notifications.legalContactFreshness.sendReminders
                    );
                    legalContactFreshness.setValue(
                        !props.template.preferences.notifications?.legalContactFreshness?.defaultFreshness ?
                        AdminPreferences.DEFAULT_LEGAL_CONTACT_FRESHNESS : props.template.preferences.notifications.legalContactFreshness.defaultFreshness
                    );
                    organization.setValue(props.template.preferences?.organization);
                }
            };

            const isDirty = () => {
                if (!props.template.preferences) return true;
                if (
                    sortByUpdated.value === props.template.preferences.search.sortPrefs.showUpdatedDate &&
                    sortByCreated.value === props.template.preferences.search.sortPrefs.showCreatedDate &&
                    filterByAuthor.value === props.template.preferences.search.filterPrefs.showAuthors &&
                    filterByFileType.value === props.template.preferences.search.filterPrefs.showFileType &&
                    filterByPageLibrary.value === props.template.preferences.search.filterPrefs.showPageLibrary &&
                    filterByLastUpdated.value === props.template.preferences.search.filterPrefs.showLastUpdated &&
                    filterByPermissionGroup.value === props.template.preferences.search.filterPrefs.showPermissionGroups &&
                    paginationPrefs.value === props.template.preferences.search.paginationPrefs &&
                    showAuthorizedSearchResultsOnly.value === props.template.preferences.showAuthorizedSearchResultsOnly &&
                    showResultsFromShared.value === props.template.preferences.search.searchShared &&
                    sendPageFreshnessReminders.value === (
                        !props.template.preferences.notifications ?
                        false : props.template.preferences.notifications.sendPageFreshnessReminders
                    ) &&
                    sendLegalContactFreshnessReminders.value === (
                        !props.template.preferences.notifications ?
                        false : props.template.preferences.notifications.legalContactFreshness?.sendReminders
                    ) &&
                    legalContactFreshness.value === props.template.preferences?.notifications?.legalContactFreshness?.defaultFreshness &&
                    organization.value === props.template.preferences.organization
                ) return false;
                else return true;
            };

            const updateFileTypePreference = (type: KendraConst.KendraContentType, enabled: boolean) => {
                let val = filterByFileType.value;
                if (enabled) {
                    val = val.concat(type);
                } else {
                    val = val.filter(f => f !== type);
                }
                filterByFileType.setValue(val);
            };

            const updatePaginationPreference = (value: AdminPreferences.PaginationOptions, enabled: boolean) => {
                let val = paginationPrefs.value;
                if (enabled) {
                    val = val.concat(value);
                } else {
                    val = val.filter(f => f !== value);
                }
                paginationPrefs.setValue(val);
            };

            React.useEffect(() => {
                reset();
            }, []);

            return Builder<UpdatePreferencesState>()
                .target(EntityFactory.toEntityRef(props.template))
                .sortByCreated(sortByCreated)
                .sortByUpdated(sortByUpdated)
                .filterByAuthor(filterByAuthor)
                .filterByFileType(filterByFileType)
                .filterByLastUpdated(filterByLastUpdated)
                .filterByPageLibrary(filterByPageLibrary)
                .paginationPrefs(paginationPrefs)
                .filterByPermissionGroup(filterByPermissionGroup)
                .organization(organization)
                .reset(reset)
                .isDirty(isDirty)
                .updateFileTypePreference(updateFileTypePreference)
                .updatePaginationPreference(updatePaginationPreference)
                .showAuthorizedSearchResultsOnly(showAuthorizedSearchResultsOnly)
                .showResultsFromShared(showResultsFromShared)
                .notifyPageFreshnessReminders(sendPageFreshnessReminders)
                .notifyLegalContactFreshnessReminders(sendLegalContactFreshnessReminders)
                .legalContactFreshness(legalContactFreshness)
                .build();
        }
    }
}

import { DecisionTreeNode } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { CustomNode } from "../components/decision-tree-container/cusom-resources/CustomNode";
import { CustomEdge } from "../components/decision-tree-container/cusom-resources/CustomEdge";
import { MarkerType } from "reactflow";
import { CSSProperties } from "react";

export const getReactFlowNode = (node: DecisionTreeNode, location: {x: number, y: number}): CustomNode.Node => {
    const defaultNodeStyle: CSSProperties = {
        border: "2px solid black",
        padding: "5px",
        borderRadius: "5px",
        background: "white",
        minHeight: "20px",
        height: "fit-content",
        width: "200px",
        fontFamily: "Amazon Ember"
    };

    return Builder<CustomNode.Node>()
        .id(node.id)
        .type(CustomNode.Type)
        .data({
            overviewText: node.overviewText,
            isLeaf: DecisionTreeNode.isLeafNode(node),
            isRoot: DecisionTreeNode.isRootNode(node)
        })
        .position(location)
        .style({
            ...defaultNodeStyle,
            background: node.parentNodeId ? ( node.children.length ? "white" : "#E78C8C") : "lightgreen"
        })
        .selectable(false)
        .build();
};

export const getReactFlowEdgeId = (from: string, to: string): string => {
    return `${from}::${to}`;
};

export const getReactFlowEdge = (parentNodeId: string, childNodeId: string, answer: string): CustomEdge.Edge => {
    const edge = {
        id: getReactFlowEdgeId(parentNodeId, childNodeId),
        source: parentNodeId,
        target: childNodeId,
        animated: true,
        type: CustomEdge.Type,
        markerEnd: {
            type: MarkerType.Arrow
        },
        data: {
            optionText: answer
        }
    };
    return edge;
};

export const getReactFlowEdges = (rootNode: DecisionTreeNode, nodes: DecisionTreeNode[]): CustomEdge.Edge[] => {
    const edges = [];
    // Constructing all edges using BFS
    const queue: DecisionTreeNode[] = [rootNode];
    while (queue.length > 0) {
        const parentNode = queue.shift();
        if (DecisionTreeNode.isLeafNode(parentNode)) {
            continue;
        }
        for (const child of parentNode.children) {
            const childNode = nodes.find(n => n.id === child.nodeId);
            queue.push(childNode);
            const edge = getReactFlowEdge(parentNode.id, childNode.id, child.answer);
            edges.push(edge);
        }
    }
    return edges;
};
import { APIOutput, Input } from "@amzn/ask-legal-domain";

export interface APIResponse<T> {
    data: APIOutput<T>;
    httpStatusCode: number;
    statusText: string;
}

export function toDefaultRequestPayload<T>(body: T) {
    return {
        body: Input.create(body),
        response: true,
        headers: {}
    };
}
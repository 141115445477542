import * as React from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  Icon,
  SpaceBetween
} from "@amzn/awsui-components-react";

interface Item {
  id: string;
  content: React.ReactNode;
}

const SortableItem = (props: {
  item: Item;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props.item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "8px",
    marginBottom: "8px",
    backgroundColor: "white",
    cursor: "grab",
    opacity: isDragging ? 0.5 : 1,
    boxShadow: isDragging ? "0 0 10px rgba(0, 0, 0, 0.3)" : "none",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <SpaceBetween size="xs" direction="horizontal" alignItems="center">
        <Icon name="drag-indicator" />{props.item.content}
      </SpaceBetween>
    </div>
  );
};

interface ItemPickerSorterProps {
  availableItems: Item[];
  selectedItems: Item[];
  onSelectedItemsChange: (items: Item[]) => void;
  options?: {
    nonDeleteableIds?: string[]
  };
}

export const ItemPickerSorter = (props: ItemPickerSorterProps) => {
  const [availableItems, setAvailableItems] = React.useState<Item[]>(props.availableItems);
  const [activeId, setActiveId] = React.useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: { active: { id: string } }) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      props.onSelectedItemsChange(
        arrayMove(
          props.selectedItems,
          props.selectedItems.findIndex((i) => i.id === active.id),
          props.selectedItems.findIndex((i) => i.id === over?.id)
        )
      );
    }

    setActiveId(null);
  };

  const handleAddItem = (item: Item) => {
    props.onSelectedItemsChange([...props.selectedItems, item]);
    setAvailableItems(availableItems.filter((i) => i.id !== item.id));
  };

  const handleRemoveItem = (id: string) => {
    const removedItem = props.selectedItems.find((item) => item.id === id);
    if (removedItem) {
      props.onSelectedItemsChange(props.selectedItems.filter((item) => item.id !== id));
      setAvailableItems([...availableItems, removedItem]);
    }
  };

  const emptyComponent = (message: string) => (
    <Box
      textAlign="center"
      color="text-body-secondary"
      padding={{ top: "xxl" }}
    >
      <Box
        padding={{ bottom: "s" }}
        variant="p"
        color="inherit"
      >
        <em>{message}</em>
      </Box>
    </Box>
  );

  return (
    <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
      <Container
        header={<Header variant="h3">Selected Items</Header>}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={props.selectedItems}
            strategy={verticalListSortingStrategy}
          >
            {!props.selectedItems.length && emptyComponent("No items selected")}
            {props.selectedItems.map((item) => (
              <Grid
                gridDefinition={[
                  { colspan: 10 },
                  { colspan: 2 },
                ]}
              >
                <SortableItem
                  key={item.id}
                  item={item}
                />
                <Button
                  variant="normal"
                  iconName="remove"
                  onClick={() => handleRemoveItem(item.id)}
                  disabled={props.options?.nonDeleteableIds?.includes(item.id)}
                />
              </Grid>
            ))}
          </SortableContext>
          <DragOverlay>
            {activeId ? (
              <div style={{
                padding: "8px",
                backgroundColor: "white",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
                cursor: "grabbing",
              }}>
                {props.selectedItems.find(item => item.id === activeId)?.content}
              </div>
            ) : null}
          </DragOverlay>
        </DndContext>
      </Container>
      <Container
        header={
          <Header variant="h3" info={<Box variant="small">Click to select</Box>}>
            Available Items
          </Header>
        }
      >
        {!availableItems.length && emptyComponent("No items available")}
        <SpaceBetween size="s" direction="vertical" alignItems="start">
          {availableItems.map((item) => <Button onClick={() => handleAddItem(item)} variant="normal">
            {item.content}
          </Button>)}
        </SpaceBetween>
      </Container>
    </Grid>
  );
};
import { Container } from "@amzn/ask-legal-domain";
import { Box, Button, Modal } from "@amzn/awsui-components-react";
import * as React from "react";

export const RemoveContainerConfirmModal = (props: {
    container: Container.Data;
    visible: boolean;
    loading?: boolean;
    onDismiss: () => void;
    onDelete: () => void;
}) => {
    return (
        <Modal
            visible={props.visible}
            header={`Delete Container`}
            onDismiss={props.onDismiss}
            footer={
                <Box float="right">
                    <Button variant="link" loading={props.loading} onClick={props.onDismiss}>
                        Cancel
                    </Button>
                    <Button variant="primary" loading={props.loading} onClick={props.onDelete}>
                        Confirm
                    </Button>
                </Box>
            }
        >
            Remove <strong><em>"{props.container.title}"</em></strong> from draft?
        </Modal>
    );
};
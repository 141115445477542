import { AdminPreferences, KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { Box, FormField, Select } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

export const Sorting = (props: {
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
    preferences?: AdminPreferences.SearchSorts;
}) => {
    const defaultOption = { label: "Relevance", value: "RELEVANCE" };
    const [selected, setSelected] = React.useState(defaultOption);
    const [options, setOptions] = React.useState([defaultOption]);

    const updatedDateOptions = [
        { label: "Most recently updated", value: "UD_DESC" },
        { label: "Least recently updated", value: "UD_ASC" }
    ];

    const createdDateOptions = [
        { label: "Most recently created", value: "CD_DESC" },
        { label: "Least recently created", value: "CD_ASC" }
    ];

    React.useEffect(() => {
        switch (selected.value) {
            case "CD_ASC":
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applySorting(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.CreationDate,
                        order: "ASC"
                    })
                );
                break;
            case "CD_DESC":
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applySorting(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.CreationDate,
                        order: "DESC"
                    })
                );
                break;
            case "UD_ASC":
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applySorting(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        order: "ASC"
                    })
                );
                break;
            case "UD_DESC":
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applySorting(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        order: "DESC"
                    })
                );
                break;
            default:
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.removeSorting(props.queryInputState.value)
                );
                break;
        }
    }, [selected]);

    React.useEffect(() => {
        if (!props.preferences) return;
        let filteredOptions = [defaultOption];
        if (props.preferences.showCreatedDate) {
            filteredOptions = filteredOptions.concat(createdDateOptions);
        }
        if (props.preferences.showUpdatedDate) {
            filteredOptions = filteredOptions.concat(updatedDateOptions);
        }
        setOptions(filteredOptions);
    }, [props.preferences]);

    React.useEffect(() => {
        if (!!props.queryInputState.value.sortingConfiguration) {
            let x = props.queryInputState.value.sortingConfiguration;
            if (x.attribute === KendraConst.Attributes.Default.CreationDate) {
                if (x.order === "ASC") setSelected(createdDateOptions[1]);
                else setSelected(createdDateOptions[0]);
            } else if (x.attribute === KendraConst.Attributes.Default.LastUpdatedDate) {
                if (x.order === "ASC") setSelected(updatedDateOptions[1]);
                else setSelected(updatedDateOptions[0]);
            }
        } else {
            setSelected(defaultOption);
        }
    }, [props.queryInputState.value.queryText]);

    return (
        <FormField
            label={
                <Box color="text-body-secondary">
                    <strong>Sort By</strong>
                </Box>}>
            <Select
                selectedOption={selected}
                onChange={({ detail }) => setSelected(detail.selectedOption) }
                options={options}
                selectedAriaLabel="Selected sorting order"
            />
        </FormField>
    );
};
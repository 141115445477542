import { Label } from "@amzn/ask-legal-domain";
import { CollectionPreferencesProps, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { CommonPolarisFactory } from "./common-polaris-factory";

export namespace LabelPolarisFactory {
    export namespace Table {
        export const InstanceRef = Builder<TableProps.ColumnDefinition<Label.Data>>()
            .id("instanceRef")
            .header("Instance")
            .cell((item: Label.Data) => item.instanceId)
            .build();

        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                InstanceRef
            ]);
        }
        export function toDefaultTablePreferences() {
            return Builder<CollectionPreferencesProps.Preferences<any>>()
                .pageSize(10)
                .visibleContent([
                    CommonPolarisFactory.Table.ColumnDefinition.Name.id,
                    CommonPolarisFactory.Table.ColumnDefinition.IsDeprecated.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
                    CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id
                ])
                .build();
        }
    }
}
import * as React from "react";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import {
    EmployeeDetails,
    EmployeeIdentity,
    Identity,
    IdentityType,
    UserSearchInterface
} from "@amzn/ask-legal-domain";
import {
    Select,
    Multiselect,
    SelectProps,
    MultiselectProps,
    AutosuggestProps
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";

export namespace UserSearch {
    const API_RESULTS_LIMIT = 5;

    const toOptionTags = (details: EmployeeDetails): string[] => {
        if (!details) return [];
        return Object.keys(details).map(key => `${key}=${details[key]}`);
    };

    const fromOptionTags = (tags: readonly string[]): EmployeeDetails => {
        if (!tags) return {};
        return tags.reduce((acc, tag) => {
            const [key, value] = tag.split("=");
            acc[key] = value;
            return acc;
        }, {} as EmployeeDetails);
    };

    export const toSelectOption = (t: EmployeeIdentity) => {
        if (!t) return;
        return Builder<SelectProps.Option>()
            .value(t.id)
            .label(t.id)
            .labelTag(t.name)
            .description(t.email)
            .tags(toOptionTags(t.employeeDetails))
            .build();
    };

    export const fromSelectOption = (option: SelectProps.Option) => {
        if (!option) return;
        return Builder<EmployeeIdentity>()
            .id(option.value)
            .name(option.labelTag)
            .email(option.description)
            .type(IdentityType.Person)
            .employeeDetails(option.tags && fromOptionTags(option.tags))
            .build();
    };

    export const toMultiSelectOptions = (t: Identity[]) => {
        if (!t || t.length === 0) return [];
        return t.map(e => toSelectOption(e));
    };

    export const fromMultiSelectOptions = (t: MultiselectProps.Option[]) => {
        if (!t || t.length === 0) return [];
        return t.map(e => fromSelectOption(e));
    };

    export const Single = (props: {
        selected?: EmployeeIdentity;
        disabled?: boolean;
        onUserSelectChange: (selected: EmployeeIdentity) => void;
        includeEmployeeDetails?: string[];
    }) => {
        let fillOptions: Array<EmployeeIdentity> = [];
        let populateOptions: SelectProps.Option[] = [];

        const context = React.useContext(AppContext);
        const userSearchByLoginApiRunner = useAPI(
            context.getUserSearchAPI().searchUserByLogin
        );

        const [selectedOption, setSelectedOption] = React.useState<SelectProps.Option>(
            toSelectOption(props.selected)
        );
        const [selectItems, setSelectItems] = React.useState<SelectProps.Option[]>([]);
        const [selectStatusType, setSelectStatusType] = React.useState<AutosuggestProps.StatusType>("finished");

        const onChangeHandler = async (e: any) => {
            setSelectedOption(e.detail.selectedOption);
        };

        let runnerTimeOut: any;
        const onLoadHandler = async (e: any) => {
            if (e.detail.filteringText.length < 2) return;
            clearTimeout(runnerTimeOut);
            runnerTimeOut = setTimeout(() => {
                userSearchByLoginApiRunner.submitRun(
                    UserSearchInterface.SearchUserByLoginInput.create(
                        e.detail.filteringText,
                        API_RESULTS_LIMIT,
                        props.includeEmployeeDetails
                    )
                );
            }, 750);
        };

        React.useEffect(() => {
            if (userSearchByLoginApiRunner.status === "Succeeded") {
                fillOptions = userSearchByLoginApiRunner.data.output;
                if (!fillOptions || fillOptions.length === 0) {
                    populateOptions = [];
                } else {
                    populateOptions = fillOptions.map((item) => toSelectOption(item));
                }
                setSelectItems(populateOptions);
                setSelectStatusType("finished");
            } else if (userSearchByLoginApiRunner.status === "Error") {
                setSelectStatusType("error");
            } else if (userSearchByLoginApiRunner.status === "Running") {
                setSelectStatusType("loading");
            }
        }, [userSearchByLoginApiRunner.status]);

        React.useEffect(() => {
            if (!props.onUserSelectChange) return;
            props.onUserSelectChange(fromSelectOption(selectedOption));
        }, [selectedOption]);

        React.useEffect(() => {
            if (!props.selected) {
                setSelectedOption(null);
                setSelectItems([]);
                setSelectStatusType("finished");
            }
        }, [props.selected]);

        const selectProperties = Builder<SelectProps>()
            .statusType(selectStatusType)
            .options(selectItems)
            .filteringType("manual")
            .filteringPlaceholder("Search by alias...")
            .ariaRequired(true)
            .onChange(onChangeHandler)
            .onLoadItems(onLoadHandler)
            .placeholder("Search by alias...")
            .selectedAriaLabel("Selected")
            .empty("No match found")
            .selectedOption(selectedOption)
            .errorText("Error fetching users")
            .recoveryText("Retry")
            .disabled(props.disabled)
            .triggerVariant("option")
            .build();

        return <Select {...selectProperties}/>;
    };

    export const Multiple = (props: {
        initialSelected?: Identity[];
        disabled?: boolean;
        onUserSelectChange: (selected: Identity[]) => void;
    }) => {
        const context = React.useContext(AppContext);
        const userSearchByLoginApiRunner = useAPI(
            context.getUserSearchAPI().searchUserByLogin
        );

        const [selectedOptions, setSelectedOptions] = React.useState<MultiselectProps.Option[]>(
            toMultiSelectOptions(props.initialSelected)
        );
        const [allItems, setAllItems] = React.useState<MultiselectProps.Option[]>([]);
        const [selectStatusType, setSelectStatusType] = React.useState<AutosuggestProps.StatusType>("finished");

        const onChangeHandler = async (e: any) => {
            setSelectedOptions(e.detail.selectedOptions);
        };

        let runnerTimeOut: any;
        const onLoadHandler = async (e: any) => {
            if (e.detail.filteringText.length < 2) return;
            clearTimeout(runnerTimeOut);
            runnerTimeOut = setTimeout(() => {
                userSearchByLoginApiRunner.submitRun(
                    UserSearchInterface.SearchUserByLoginInput.create(
                        e.detail.filteringText,
                        API_RESULTS_LIMIT
                    )
                );
            }, 750);
        };

        React.useEffect(() => {
            if (userSearchByLoginApiRunner.status === "Succeeded") {
                let populateOptions = [];
                const fillOptions = userSearchByLoginApiRunner.data.output;
                if (!fillOptions || fillOptions.length === 0) {
                    populateOptions = [];
                } else {
                    populateOptions = toMultiSelectOptions(fillOptions);
                }
                setAllItems(populateOptions);
                setSelectStatusType("finished");
            } else if (userSearchByLoginApiRunner.status === "Error") {
                setSelectStatusType("error");
            } else if (userSearchByLoginApiRunner.status === "Running") {
                setSelectStatusType("loading");
            }
        }, [userSearchByLoginApiRunner.status]);

        React.useEffect(() => {
            if (!props.onUserSelectChange || !selectedOptions) return;
            props.onUserSelectChange(fromMultiSelectOptions(selectedOptions));
        }, [selectedOptions]);

        const multiSelectProperties = Builder<MultiselectProps>()
            .statusType(selectStatusType)
            .options(allItems)
            .filteringType("manual")
            .filteringPlaceholder("Search by alias...")
            .filteringType("auto")
            .ariaRequired(true)
            .onChange(onChangeHandler)
            .onLoadItems(onLoadHandler)
            .placeholder("Search by alias...")
            .selectedAriaLabel("Selected")
            .empty("No match found")
            .selectedOptions(selectedOptions)
            .deselectAriaLabel((e: any) => "Remove " + e.label)
            .errorText("Error fetching users")
            .recoveryText("Retry")
            .disabled(props.disabled)
            .build();

        return <Multiselect {...multiSelectProperties}/>;
    };
}

import { Link } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";

export const PageLibraryLink = (props: {
    id: string;
    external?: boolean;
    pageLibraryName?: string;
}) => {
    const context = React.useContext(AppContext);
    const loadPageLibraryRunner = useAPI(
        context.getPageLibraryAPI().load
    );
    React.useEffect(() => {
        if (!props.id || !!props.pageLibraryName) return;
        loadPageLibraryRunner.submitRun(
            props.id
        );
    }, [props.id]);

    return (
        <Link external={props.external} variant="secondary" href={`#/page-library/${props.id}/admin`}>
            {!!props.pageLibraryName && props.pageLibraryName}
            {!props.pageLibraryName && loadPageLibraryRunner.status === "Succeeded" &&
                loadPageLibraryRunner.data.output.name
            }
            {!props.pageLibraryName && loadPageLibraryRunner.status !== "Succeeded" && props.id}
        </Link>
    );
};

export const PageLibraryDisplay = (props: {
    id: string;
}) => {
    const context = React.useContext(AppContext);
    const loadPageLibraryRunner = useAPI(
        context.getPageLibraryAPI().load
    );
    React.useEffect(() => {
        loadPageLibraryRunner.submitRun(
            props.id
        );
    }, [props.id]);

    return (
        <React.Fragment>
            {loadPageLibraryRunner.status === "Succeeded" &&
                !!loadPageLibraryRunner.data.output.displayName ?
                    loadPageLibraryRunner.data.output.displayName :
                    props.id
            }
        </React.Fragment>
    );
};
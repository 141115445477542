import { Alert, Box, Button, Modal, ModalProps } from "@amzn/awsui-components-react";
import * as React from "react";

export const SimpleModal = (props: {
    child: JSX.Element;
    header: any;
    loading: boolean;
    disabled?: boolean;
    size?: ModalProps.Size;
    disableLeavingAlert?: boolean;
    onSave: () => void;
    onCancel: () => void;
}) => {
    const [dismissConfirmation, setDismissConfirmation] = React.useState<boolean>();

    const onDismiss = () => {
        if (!props.disableLeavingAlert) {
            setDismissConfirmation(true);
        } else {
            props.onCancel();
        }
    };

    const LeavingAlertModal = () => {
        return (
            <Modal header="Leave editing" visible={dismissConfirmation} onDismiss={null} footer={
                <Box float="right">
                    <Button variant="link" loading={props.loading} onClick={() => {
                        props.onCancel();
                        setDismissConfirmation(false);
                    }}>
                        Leave
                    </Button>
                    <Button variant="primary" onClick={() => setDismissConfirmation(false)}>
                        Continue Editing
                    </Button>
                </Box>
            }>
                <Alert type="warning">
                    Are you sure that you want to leave the current page? All changes that you made won't be saved.
                </Alert>
            </Modal>
        );
    };

    return (
        <Modal
            header={props.header}
            visible={true}
            onDismiss={onDismiss}
            size={props.size ? props.size : "medium"}

            footer={
                <Box float="right">
                    <Button variant="link" loading={props.loading} onClick={onDismiss}>
                        Cancel
                    </Button>
                    <Button variant="primary" loading={props.loading} onClick={props.onSave} disabled={props.disabled}>
                        Submit
                    </Button>
                </Box>
            }>
                { !props.disableLeavingAlert && <LeavingAlertModal/> }
                <div>{props.child}</div>
        </Modal>
    );
};
import { SpaceBetween, Toggle } from "@amzn/awsui-components-react";
import * as React from "react";
import { NavigationModel } from "../../../model/navigation-model";
import { UIField } from "../../common/UIField";

export const EditNavItemContent = (props: {
    state: NavigationModel.EditNavItemState;
}) => {
    const getBreadCrumbPre = (label: string) => <em>&nbsp;&nbsp;<b>&gt;</b>&nbsp;&nbsp;{label}</em>;

    return (
        <React.Fragment>
            <SpaceBetween direction="vertical" size="m">
                <span>
                    <em>{props.state.tabField.value.name}</em>
                    {props.state.navGroupField.value && getBreadCrumbPre(props.state.navGroupField.value.name)}
                    {props.state.navLinkField.value && getBreadCrumbPre(props.state.navLinkField.value.name)}
                    {props.state.navSubLinkField.value && getBreadCrumbPre(props.state.navSubLinkField.value.name)}
                </span>
                {!props.state.navSubLinkField.value && <SpaceBetween direction="horizontal" size="xxs">
                    <Toggle
                        onChange={({ detail }) => props.state.isLink.setValue(detail.checked)}
                        checked={props.state.isLink.value}>Link
                    </Toggle>
                    &nbsp;<small><em>(Toggle off to set to Text only)</em></small>
                </SpaceBetween>}
                <UIField.StateValueField
                    name="Name"
                    state={props.state.nameField}
                    editing={true}
                />
                {props.state.isLink.value && <UIField.StateValueField
                    name="URL"
                    state={props.state.urlField}
                    editing={true}
                />}
            </SpaceBetween>
        </React.Fragment>
    );
};
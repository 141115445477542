import { LegalContact } from "@amzn/ask-legal-domain";
import { TableProps } from "@amzn/awsui-components-react";

export const legalContactAriaLabels: TableProps.AriaLabels<LegalContact.Record> = {
    itemSelectionLabel: (data, row) => `select ${row?.legalContactUser.name}`,
    selectionGroupLabel: "Legal contacts selected",
    tableLabel: "Legal Contacts View",
    allItemsSelectionLabel: (data) => `select ${data.selectedItems.length} legal contacts`
};

export const legalContactAriaLiveLabels = (data: TableProps.LiveAnnouncement) => {
    return `Displaying ${data.totalItemsCount || 0} legal contacts`;
};
import {
    Button,
    ColumnLayout,
    FileUpload,
    FileUploadProps,
    Flashbar,
    FlashbarProps,
    Header,
    Link,
    ProgressBar,
    ProgressBarProps,
    SpaceBetween,
    Spinner,
    SpinnerProps,
    Table,
    TableProps,
    TextContent
} from "@amzn/awsui-components-react";
import {
    AdvancedList,
    AdvancedListItem,
    AdvancedListItemField,
    EntityRef,
    FieldDefinition,
    FieldDefinitionDataType,
    CreateAdvancedListItemCommandInput,
    CreateAdvancedListItemCommandOutput,
    LoadAdvancedListCommandOutput
} from "@amzn/altar-sds-client";
import {
    APIOutput,
    CommonUtils,
    ContainerRef,
    FieldConfiguration
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { Builder } from "builder-pattern";
import { CustomCommonContainerModal } from "../../container/CommonContainerModal";
import { AppContext } from "../../../setup/context";
import { fileUploadI18nStrings } from "../../../i18n/fileUploadI18nStrings";
import { AdvancedListConstants } from "../../../utils/advanced-list.constant";
import { AdvancedListPolarisFactory } from "../../../factory/polaris/advanced-list-polaris-factory";
import { SDSUtils } from "../../../utils/sds-utils";
import { ExcelFactory } from "../../../factory/file/excel-factory";
import { AdvancedListFactory } from "../../../factory/advanced-list-factory";
import { FileUtils } from "../../../utils/file-utils";

const SUPPORTED_FILE_EXTENSIONS = new Set([FileUtils.Extension.XLSX]);
const SUPPORTED_FILE_UPLOAD_SIZE_MB = 1;
const PREVIEW_ROW_LIMIT = 5;

export const ImportAdvancedListItemsModal = (props: {
    containerRef: ContainerRef;
    advancedListRef: EntityRef;
    fieldConfigurations: FieldConfiguration.Record;
    onCreated: (items: AdvancedListItem[]) => void;
    onCanceled: () => void;
}) => {
    const context = React.useContext(AppContext);
    const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
    const [flashbarProps, setFlashbarProps] = React.useState<Pick<FlashbarProps, "items">>();
    const [advancedList, setAdvancedList] = React.useState<Pick<AdvancedList, "fieldDefinitions">>();
    const [fileUploadProps, setFileUploadProps] = React.useState<FileUploadProps>({
        i18nStrings: fileUploadI18nStrings,
        value: [],
        accept: [...SUPPORTED_FILE_EXTENSIONS].map((ext) => `.${ext}`).join(","),
        showFileLastModified: true,
        showFileSize: true,
        constraintText: <b>{`NOTE: File size should not exceed ${SUPPORTED_FILE_UPLOAD_SIZE_MB} MB`}</b>
    });
    const [tableProps, setTableProps] =
        React.useState<TableProps<Pick<AdvancedListItem, "values">> & { allItems: Pick<AdvancedListItem, "values">[] }>();
    const [progressBarProps, setProgressBarProps] = React.useState<ProgressBarProps>();
    const [validationTableProps, setValidationTableProps] = React.useState<TableProps<ExcelFactory.CellDataValidation>>();

    React.useEffect(() => {
        if (!props.advancedListRef) return;
        init();
    }, [props.advancedListRef]);

    const init = async () => {
        try {
            setSpinnerProps({});
            // Load latest advanced list payload
            const loadOutput = await context.getAdvancedListAPI().load({
                entityId: props.advancedListRef.entityId,
                repositoryId: props.advancedListRef.repositoryRef.repositoryId,
                by: SDSUtils.getAmazonPersonRef(context.userId),
            });
            const output = APIOutput.fromRaw<LoadAdvancedListCommandOutput>(loadOutput.data);
            if (output.isOk()) {
                const advancedListOutput = output.data.body;
                setAdvancedList({
                    fieldDefinitions: advancedListOutput.fieldDefinitions?.filter((def) => !def.deprecated)
                });
            } else if (output.isErr()) {
                handleError(output.err.message);
                return;
            }
        } catch (error) {
            handleError((error as Error).message);
        } finally {
            setSpinnerProps(undefined);
        }
    };

    const handleError = (message: string) => {
        setFlashbarProps({
            items: [{
                type: "error",
                content: message
            }]
        });
    };

    /**
     * Function to validate file properties
     */
    const validFile = (file: File): boolean => {
        if (!file) {
            setFileUploadProps((prev) => ({
                ...prev,
                invalid: true,
                errorText: "Please select a file",
            }));
            return false;
        }
        if (!SUPPORTED_FILE_EXTENSIONS.has(
            FileUtils.getExtension(file.name)
        )) {
            setFileUploadProps((prev) => ({
                ...prev,
                invalid: true,
                fileErrors: [`Only ${[...SUPPORTED_FILE_EXTENSIONS].map(x => `.${x}`).join(", ")} files are supported`],
            }));
            return false;
        }
        if (file.size > CommonUtils.getValueInBytes(SUPPORTED_FILE_UPLOAD_SIZE_MB, "mb")) {
            setFileUploadProps((prev) => ({
                ...prev,
                invalid: true,
                fileErrors: [`File size should not exceed ${SUPPORTED_FILE_UPLOAD_SIZE_MB} MB`],
            }));
            return false;
        }
        return true;
    };

    /**
     * Function to parse file headers
     */
    const handleFileHeader = (headersRawKeys: string[]): Record<string, FieldDefinition> | undefined => {
        let headers: Record<string, FieldDefinition> = {};
        let headersNotFound = [];
        headersRawKeys.forEach(header => {
            const fieldDefinition = advancedList?.fieldDefinitions?.find(field => field.displayName === header.trim());
            if (fieldDefinition) {
                headers[header] = fieldDefinition;
            } else {
                headersNotFound.push(header);
            }
        });
        if (headersNotFound.length) {
            handleError(`Invalid column headers found: "${headersNotFound.join(`" | "`)}"`);
            setFileUploadProps((prev) => ({
                ...prev,
                invalid: true,
                fileErrors: ["Invalid column headers detected"]
            }));
            return;
        }
        if (!Object.keys(headers).length) {
            handleError("No headers found");
            setFileUploadProps((prev) => ({
                ...prev,
                invalid: true,
                fileErrors: ["No headers found"]
            }));
            return;
        }
        return headers;
    };

    /**
     * Function to parse file data
     */
    const handleFileData = (excelData: ExcelFactory.CellData[], headers: Record<string, FieldDefinition>) => {
        const items: Pick<AdvancedListItem, "values">[] = [];
        const validationErrors: ExcelFactory.CellDataValidation[] = [];
        const columns = Object.keys(headers);

        // Parse AL Item field values from every cell in each row
        excelData.forEach((rowData, row) => {
            const values: AdvancedListItemField[] = [];
            columns.forEach(column => {
                const { item, errors } = getFieldValue(headers[column], rowData[column]);
                if (errors?.length) {
                    validationErrors.push({ row, column, errors });
                }
                if (item) {
                    values.push(item);
                }
            });
            items.push({ values });
        });

        if (validationErrors?.length) {
            // Validation errors found in parsed excel data
            setValidationTableProps({
                ...validationTableProps,
                columnDefinitions: [
                    {
                        id: "row",
                        header: "Row",
                        cell: item => item.row + 1 // Match excel index
                    },
                    {
                        id: "column",
                        header: "Column",
                        cell: item => item.column
                    },
                    {
                        id: "errors",
                        header: "Errors",
                        cell: item => <span style={{ color: "red" }}>{item.errors.join(", ")}</span>
                    }
                ],
                items: validationErrors
            });
            return;
        }

        setTableProps({
            ...tableProps,
            allItems: items,
            items: items.slice(0, PREVIEW_ROW_LIMIT),
            columnDefinitions: advancedList.fieldDefinitions.map(field => ({
                header: field.displayName,
                cell: item => AdvancedListPolarisFactory.Table.renderItemDisplay(
                    item.values.find(value => value?.key === field.fieldKey)?.value,
                    field,
                    props.fieldConfigurations?.[field.fieldKey]
                ),
                maxWidth: "350px"
            })),
        });
    };

    /**
     * Function to parse field value from cell
     */
    const getFieldValue = (field: FieldDefinition, value: any): {
        item?: AdvancedListItemField,
        errors?: string[],
    } => {
        let errors: string[];
        if (
            AdvancedListFactory.isReservedField(field.fieldKey) ||
            field.dataType === FieldDefinitionDataType.sequence
        ) return { errors };
        let restoredValue: any;
        const stringValue = String(value);
        if (value == null || !stringValue.trim().length) {
            if (field.required) {
                return {
                    errors: ["Field is required"]
                };
            }
            // return empty field value
            return { errors };
        }
        switch (field.dataType) {
            case FieldDefinitionDataType.sequence:
                // Skip field for creation
                break;
            case FieldDefinitionDataType.number:
                restoredValue = value as number;
                break;
            case FieldDefinitionDataType.string:
                restoredValue = stringValue;
                break;
            case FieldDefinitionDataType.date:
                const dateValue = AdvancedListFactory.getDateinYYYYMMDD(stringValue);
                if (dateValue === "-") {
                    errors = [`Invalid date format, supported: ${AdvancedListConstants.DEFAULT_DATE_FORMAT}`];
                } else {
                    restoredValue = dateValue;
                }
                break;
            case FieldDefinitionDataType.IdentityRef:
                restoredValue = SDSUtils.getAmazonPersonRef(stringValue.trim());
                break;
            case FieldDefinitionDataType.multiIdentityRef:
                restoredValue = stringValue.trim()
                    .split(AdvancedListConstants.EXPORT_MULTI_SELECT_DELIMITER)
                    .filter(x => x.trim().length)
                    .map(SDSUtils.getAmazonPersonRef);
                break;
            case FieldDefinitionDataType.boolean:
                const extractedValue = stringValue.trim().toLowerCase();
                const truthy = ["yes", "y", "t", "1", "true"];
                const falsy = ["no", "n", "f", "0", "false"];
                if (truthy.includes(extractedValue)) {
                    restoredValue = true;
                } else if (falsy.includes(extractedValue)) {
                    restoredValue = false;
                } else {
                    errors = [`Invalid boolean value "${stringValue}"`];
                }
                break;
            case FieldDefinitionDataType.hyperlink:
                restoredValue = {
                    href: stringValue,
                    title: stringValue
                };
                break;
            case FieldDefinitionDataType.choice:
                const fieldConfig = props.fieldConfigurations?.[field.fieldKey] as FieldConfiguration.Choice;
                restoredValue = [];
                if (fieldConfig.type === FieldConfiguration.ChoiceType.SINGLE) {
                    // Handle single choice
                    const foundOption = field.choiceOptions.filter(x => x.displayValue === stringValue);
                    if (!foundOption.length) {
                        // No matching option found
                        if (field.allowFreeTextOption) {
                            // Create an ad-hoc option with provided value
                            restoredValue.push({ displayValue: stringValue });
                        } else {
                            // Add error for invalid option
                            errors = [`Option "${stringValue}" not found`];
                        }
                    } else {
                        // Return found option
                        restoredValue.push(...foundOption);
                    }
                    break;
                } else {
                    // Handle multiple choice
                    const options = stringValue
                        .split(AdvancedListConstants.EXPORT_MULTI_SELECT_DELIMITER)
                        .filter(x => x.trim().length);
                    const foundOptions = field.choiceOptions.filter(x => options.includes(x.displayValue));
                    if (foundOptions.length < options.length) {
                        // Not all options were found
                        const optionsNotFound = options.filter(x => !foundOptions.some(y => y.displayValue === x));
                        if (field.allowFreeTextOption) {
                            // Store the not found options as ad-hoc options
                            restoredValue = optionsNotFound.map(x => ({ displayValue: x }));
                        } else {
                            // Add errors for the invalid options
                            errors = optionsNotFound.map(x => `Option "${x}" not found`);
                        }
                    }
                    restoredValue = [...restoredValue, ...foundOptions];
                }
                break;
            default:
                errors = [`Unsupported field type "${field.dataType}" for column "${field.displayName}"`];
                break;
        }
        return {
            item: (restoredValue != null) && {
                key: field.fieldKey,
                type: field.dataType,
                value: restoredValue
            },
            errors
        };
    };

    const handleFileUpload = async ({ detail }) => {
        if (!detail.value?.length && spinnerProps) {
            // Do not remove file if an operation is in progress
            return;
        }
        setFileUploadProps((prev) => ({
            ...prev,
            value: detail.value,
            errorText: "",
            invalid: false,
            fileErrors: [],
            fileWarnings: [],
        }));
        setFlashbarProps(undefined);
        setTableProps(undefined);
        setValidationTableProps(undefined);
        setProgressBarProps(undefined);
        try {
            setSpinnerProps({});
            const file = detail.value[0];
            if (!validFile(file)) return;
            const readExcelOutput = await ExcelFactory.readExcel({ file: file });
            if (!readExcelOutput.length) {
                setFileUploadProps((prev) => ({ ...prev, fileErrors: ["The excel file is empty"] }));
                handleError("Empty file uploaded");
                return;
            }
            const headers = handleFileHeader(Object.keys(readExcelOutput[0]));
            if (!headers) return;
            handleFileData(readExcelOutput.slice(0), headers);
        } catch (error) {
            handleError((error as Error).message);
        } finally {
            setSpinnerProps(undefined);
        }
    };

    /**
     * Function to batch create AL items
     */
    const batchCreateItems = async (items: Pick<AdvancedListItem, "values">[]) => {
        try {
            setSpinnerProps({});
            setProgressBarProps({
                value: 0,
                label: "Uploading...",
                description: "This may take a few minutes",
                additionalInfo: "Please do not close this window till upload is complete."
            });
            setFlashbarProps(undefined);
            const createdItems: AdvancedListItem[] = [];
            const failedItemsIndices: number[] = [];

            for await (const [index, item] of items.entries()) {
                const createItemOutput = await context.getAdvancedListAPI().createItem({
                    ...toCreateItemInput(item.values),
                    containerId: props.containerRef.id
                });
                const output = APIOutput.fromRaw<CreateAdvancedListItemCommandOutput>(createItemOutput.data);
                if (output.isErr()) {
                    failedItemsIndices.push(index);
                } else {
                    createdItems.push(output.data.body);
                }
                setProgressBarProps({
                    ...progressBarProps,
                    label: "Uploading...",
                    description: "This may take a few minutes",
                    additionalInfo: "Please do not close this window till upload is complete.",
                    value: calculateProgress(
                        items.length,
                        createdItems.length + failedItemsIndices.length
                    ),
                });
            }
            if (failedItemsIndices.length) {
                handleError(`Failed to create ${failedItemsIndices.length} items`);
                setProgressBarProps({
                    ...progressBarProps,
                    status: "error",
                    resultText: `Failed to create ${failedItemsIndices.length} items`,
                    label: "Result",
                    description: "Some items failed to create",
                    resultButtonText: "Download failed items",
                    onResultButtonClick: () => {
                        exportItems(
                            items.filter((_, index) => failedItemsIndices.includes(index)),
                            "Failed_Import"
                        );
                    }
                });
            } else {
                setProgressBarProps({
                    ...progressBarProps,
                    status: "success",
                    resultText: `Successfully created ${createdItems.length} items`,
                    label: "Result",
                    description: "All items created successfully",
                });
            }
            if (props.onCreated && !failedItemsIndices.length) {
                props.onCreated(createdItems);
            }
        } catch (error) {
            handleError((error as Error).message);
        } finally {
            setSpinnerProps(undefined);
        }
    };

    const toCreateItemInput = (values: AdvancedListItemField[]): CreateAdvancedListItemCommandInput => {
        return Builder<CreateAdvancedListItemCommandInput>()
            .entityId(props.advancedListRef.entityId)
            .repositoryId(props.advancedListRef.repositoryRef.repositoryId)
            .by(SDSUtils.getAmazonPersonRef(context.userId))
            .values(values)
            .build();
    };

    const calculateProgress = (total: number, uploaded: number) => {
        return Math.ceil((uploaded / total) * 100);
    };

    const exportItems = (items: Pick<AdvancedListItem, "values">[], filePrefix?: string) => {
        AdvancedListFactory.exportItemsToFile({
            fieldConfigurationRecord: props.fieldConfigurations,
            fieldDefinitions: advancedList.fieldDefinitions,
            items: items,
            filePrefix: filePrefix
        });
    };

    const fileUploadInstructions = <TextContent>
        <strong>Steps to upload a file:</strong>
        <br/><strong>&nbsp;&nbsp;Step 1 : </strong>Click <Link onClick={() => {
            exportItems([], "Template");
        }} external>here</Link> to download the file template
        <br/><strong>&nbsp;&nbsp;Step 2 : </strong>Fill out the template file from step 1 above and then drop the file here or click below to browse.
    </TextContent>;

    const component = <>
        <SpaceBetween size="m">
            <ColumnLayout columns={2} borders="vertical">
                <SpaceBetween size="s">
                    {fileUploadInstructions}
                    <FileUpload {...fileUploadProps} onChange={handleFileUpload} />
                </SpaceBetween>
                {progressBarProps && <ProgressBar
                    {...progressBarProps}
                />}
            </ColumnLayout>
            <hr />
            {!!validationTableProps?.items.length && <Table
                {...validationTableProps}
                header={<Header
                    counter={`(${validationTableProps.items.length} errors found)`}
                >Validation Errors</Header>}
            />}
            {!!tableProps?.items.length && <Table
                {...tableProps}
                header={<Header
                    variant="h2"
                    counter={`${tableProps.allItems.length} items found`}
                    actions={
                        <Button
                            variant="primary"
                            disabled={!tableProps.items.length}
                            loading={!!spinnerProps}
                            onClick={() => {
                                batchCreateItems(tableProps.allItems);
                            }}
                        >Upload All Items</Button>
                    }
                >Preview</Header>}
            />}
        </SpaceBetween>
    </>;

    return <CustomCommonContainerModal
        header="Import Items from Excel"
        child={<>
            <SpaceBetween size="m">
                {spinnerProps && <Spinner {...spinnerProps} />}
                {flashbarProps && <Flashbar {...flashbarProps} />}
                {advancedList && component}
            </SpaceBetween>
        </>}
        loading={!!spinnerProps}
        disabled={!!spinnerProps || progressBarProps?.status === "error"}
        onCancel={() => props.onCanceled()}
        size="xl"
    />;
};
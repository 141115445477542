import {
  AdvancedList,
  AdvancedListView,
  SearchAdvancedListViewsCommandOutput
} from "@amzn/altar-sds-client";
import {
  AdvancedListContent,
  ALView,
  APIOutput
} from "@amzn/ask-legal-domain";
import {
  Box,
  Button,
  ColumnLayout,
  Flashbar,
  FlashbarProps,
  Header,
  SpaceBetween,
  Spinner,
  SpinnerProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../../model/ui-model";
import {
  ViewAddEditModal,
  ViewAddEditModalProps
} from "./views/ViewAddEditModal";
import { AppContext } from "../../../../setup/context";
import { SDSUtils } from "../../../../utils/sds-utils";

export const ViewList = (props: {
  state: UIModel.State<AdvancedListContent>;
  advancedList: AdvancedList;
  onCreated?: (view: ALView.Payload) => void;
  onDeleted?: (view: ALView.Payload) => void;
}) => {
  const context = React.useContext(AppContext);
  const [spinnerProps, setSpinnerProps] = React.useState<SpinnerProps>();
  const [flashbarProps, setFlashbarProps] = React.useState<FlashbarProps>();
  const [viewAddEditModalProps, setViewAddEditModalProps] = React.useState<ViewAddEditModalProps>();
  const [loadedViews, setLoadedViews] = React.useState<AdvancedListView[]>([]);
  const [views, setViews] = React.useState<ALView.Payload[]>([]);

  const handleError = (message: string) => {
    setFlashbarProps({
      items: [
        {
          type: "error",
          content: message,
        },
      ],
    });
  };

  const init = async () => {
    try {
      setSpinnerProps({});
      const searchViewsOutput = await context.getAdvancedListAPI().searchViews({
        listEntityId: props.advancedList.entityInfo.ref.entityRef.entityId,
        listRepositoryId: props.advancedList.entityInfo.ref.entityRef.repositoryRef.repositoryId,
        by: SDSUtils.getAmazonPersonRef(context.userId),
      });
      const viewsData = APIOutput.fromRaw<SearchAdvancedListViewsCommandOutput>(searchViewsOutput.data);
      if (viewsData.isOk()) {
        setLoadedViews(viewsData.data.items);
      } else {
        handleError(`Failed to load views: ${viewsData.err.message}`);
      }
    } catch (e) {
      handleError(`Failed to load views: ${e.message}`);
    } finally {
      setSpinnerProps(undefined);
    }
  };

  const infoComp = (message: React.ReactNode) => <Box textAlign="center" color="inherit">
    <br />
    <Box padding={{ bottom: "s" }} variant="p" color="inherit">
      {message}
    </Box>
  </Box>;

  React.useEffect(() => {
    init();
  }, [props.advancedList]);

  React.useEffect(() => {
    const viewsInLive = loadedViews
      .filter(item => !props.state.value.upsertViewsList
        .find(view => view?.ref?.entityExtraRef?.extraId === item.entityExtraVersionRef.entityExtraRef.extraId)
      )
      .map((item) => {
        return ALView.Payload.init({
          id: item.entityExtraVersionRef.entityExtraRef.extraId,
          ref: item.entityExtraVersionRef,
          name: item.name,
          fieldKeys: item.fieldKeys,
          description: item.description,
          filter: item.filter,
          sort: item.sort,
          deprecated: item.deprecated,
        });
      });
    const viewsInDraft = props.state.value.upsertViewsList;
    setViews([
      ...viewsInDraft,
      ...viewsInLive,
    ]);
  }, [
    props.state.value.upsertViewsList,
    loadedViews,
  ]);

  return <>
    {flashbarProps && <Flashbar {...flashbarProps} />}
    <Header
      variant="h2"
      actions={<Button
        variant="primary"
        iconName="add-plus"
        onClick={() => setViewAddEditModalProps({
          advancedList: props.advancedList,
          onCancel: () => setViewAddEditModalProps(undefined),
          onSubmit: (view: ALView.Payload) => {
            props.onCreated?.(view);
            setViewAddEditModalProps(undefined);
          },
        })}
      >
        New View
      </Button>}
    >
      Manage Views
    </Header>
    {viewAddEditModalProps &&
      <ViewAddEditModal {...viewAddEditModalProps} />}
    <br />
    <ColumnLayout columns={3} borders="all">
      <span><b>Name</b></span>
      <span><b>Description</b></span>
      <span><b>Actions</b></span>
    </ColumnLayout>
    {spinnerProps && infoComp(<Spinner {...spinnerProps} />)}
    {!spinnerProps && !views.length && infoComp(<em>No views found</em>)}
    {!!views.length && views.map(v => {
      return <ColumnLayout columns={3} borders="all">
        <span>
          {v.name}
          {props.state.value.upsertViewsList.find(x => x.id === v.id) && " (Pending)"}
          {props.state.value.deleteViewsList?.find(x => x.id === v.id) && " (Pending delete)"}
        </span>
        <span>{v.description || "-"}</span>
        <span>
          <SpaceBetween size="xs" direction="horizontal">
            <Button
              variant="icon"
              iconName="edit"
              onClick={() => setViewAddEditModalProps({
                advancedList: props.advancedList,
                viewPayload: v,
                onCancel: () => setViewAddEditModalProps(undefined),
                onSubmit: (view: ALView.Payload) => {
                  props.onCreated?.(view);
                  setViewAddEditModalProps(undefined);
                },
              })}
            />
            <Button
              variant="icon"
              iconName="remove"
              onClick={() => {
                props.onDeleted?.(v);
              }}
            />
          </SpaceBetween>
        </span>
      </ColumnLayout>;
    })}
  </>;
};
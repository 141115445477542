import {
    Button,
    Grid,
    Input,
    Modal,
    Popover,
    SpaceBetween,
    StatusIndicator
} from "@amzn/awsui-components-react";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { UIField } from "../../common/UIField";
import { EntitySelection } from "../../common/EntitySelection";
import { Instance, InstanceFactory } from "@amzn/ask-legal-domain";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";

export const SharePageModal = (props: {
    pageId: string;
    onDismiss: () => void;
}) => {
    const context = React.useContext(AppContext);
    const [selectedInstance, setSelectedInstance] = React.useState<Instance>();

    const generateSharedPageURL = () => {
        return `${window.location.origin}#/shared-page/${selectedInstance.id}/${props.pageId}/live`;
    };

    const onClickCopy = () => {
        navigator.clipboard.writeText(generateSharedPageURL());
    };

    return <Modal
        visible={true}
        size="medium"
        header="Share Page"
        onDismiss={props.onDismiss}
    >
        <SpaceBetween size="m">
            <UIField.CustomField
                name="Select Target Instance"
                child={
                    <EntitySelection.Single<{}, Instance>
                        api={context.getInstanceAPI().loadAll}
                        input={{}}
                        selected={selectedInstance}
                        onSelectionChange={(instance) => {
                            setSelectedInstance(instance);
                        }}
                        filterOutFunction={(o: OptionDefinition) => o.value === InstanceFactory.fromEntityId(props.pageId)}
                    />
                }
            />
            {selectedInstance &&
                <UIField.CustomField
                    name="Shareable Link"
                    child={
                        <Grid gridDefinition={[
                            { colspan: 9 },
                            { colspan: 3 }
                        ]}>
                            <Input
                                value={generateSharedPageURL()}
                                readOnly
                            />
                            <Popover
                                dismissButton={false}
                                position="top"
                                size="small"
                                triggerType="custom"
                                content={<StatusIndicator type="success">
                                    Copied to clipboard
                                </StatusIndicator>}
                            >
                                <Button variant="primary" iconName="copy" onClick={onClickCopy}>Copy</Button>
                            </Popover>
                        </Grid>
                    }
                />
            }
        </SpaceBetween>
    </Modal>;
};
import { Link, Popover } from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { RuleFieldsView } from "./modal-content/RuleFieldsEditing";

export const RulePopover = (props: {
    id: string;
}) => {
    const context = React.useContext(AppContext);
    const [ruleValue, setRuleValue] = React.useState(props.id);
    const loadRuleRunner = useAPI(
        context.getRecommendationRuleAPI().load
    );

    React.useEffect(() => {
        loadRuleRunner.submitRun(props.id);
    }, [props.id]);

    React.useEffect(() => {
        if (loadRuleRunner.status === "Succeeded") {
            if (!loadRuleRunner.data.output) {
                loadRuleRunner.reload();
            } else {
                setRuleValue(loadRuleRunner.data.output.name);
            }
        }
    }, [loadRuleRunner.status]);

    return (
    <Popover
        dismissAriaLabel="Close"
        fixedWidth
        header={
            `Rule Details: ${ruleValue}`}
        size="large"
        triggerType="custom"
        content={ loadRuleRunner.status === "Succeeded" &&
            <RuleFieldsView rule={loadRuleRunner.data.output} />
        }
    >
        {ruleValue}
    </Popover>);
};
import { PageFactory, Recommendation } from "@amzn/ask-legal-domain";
import { Link, CollectionPreferencesProps, TableProps, Badge } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";

export namespace FavoritePolarisFactory {
    export namespace Table {
        export interface Item {
            id: string;
            type: string;
            title: string;
            description?: string;
            rank: number;
        }

        export const Rank = Builder<TableProps.ColumnDefinition<Item>>()
            .id("rank")
            .header("Rank")
            .cell((item: Item) => item.rank)
            .build();
        export const Title = Builder<TableProps.ColumnDefinition<Item>>()
            .id("title")
            .header("Title")
            .cell((item: Item) =>
                <React.Fragment>
                    <Link href={`#/page/${PageFactory.fromEntityId(item.id)}/live`}>{item.title}</Link>
                </React.Fragment>)
            .build();
        export const Description = Builder<TableProps.ColumnDefinition<Item>>()
            .id("description")
            .header("Description")
            .cell((item: Item) => item.description ? item.description : "No Description")
            .build();
    }
}
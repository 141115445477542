import { INavigation, Input, Navigation } from "@amzn/ask-legal-domain";
import { AppContextValue } from "../setup/context-value";
import { RestAPI } from "@aws-amplify/api-rest";
import { APIResponse, toDefaultRequestPayload } from "./common";

export interface NavigationAPI {
    create(input: INavigation.CreateNavigationInput): Promise<APIResponse<Navigation.Data>>;
    update(input: INavigation.UpdateNavigationInput): Promise<APIResponse<Navigation.Data>>;
    load(input: INavigation.LoadNavigationInput): Promise<APIResponse<Navigation.Data>>;
}

export class NavigationAPIImpl implements NavigationAPI {
    constructor (private context: AppContextValue) {}

    async create(input: INavigation.CreateNavigationInput): Promise<APIResponse<Navigation.Data>> {
        const response: APIResponse<Navigation.Data> = await RestAPI.post(
            "custom-domain",
            "/navigation/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update(input: INavigation.UpdateNavigationInput): Promise<APIResponse<Navigation.Data>> {
        const response: APIResponse<Navigation.Data> = await RestAPI.post(
            "custom-domain",
            "/navigation/update",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async load(input: INavigation.LoadNavigationInput): Promise<APIResponse<Navigation.Data>> {
        const response: APIResponse<Navigation.Data> = await RestAPI.post(
            "custom-domain",
            "/navigation/load",
            toDefaultRequestPayload(input)
        );
        return response;
    }
}
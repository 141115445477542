import * as React from "react";
import { EntityRef, InstanceFactory, Role, ServerSidePaginatedLoadingInput, wrapPermissionedContent } from "@amzn/ask-legal-domain";
import { Header, Box, Modal, Link, Button, SpaceBetween, FlashbarProps, Flashbar, SelectProps, Select } from "@amzn/awsui-components-react";
import { UIModel } from "../../../../model/ui-model";
import { UIField } from "../../UIField";
import { Builder } from "builder-pattern";
import { AppContext } from "../../../../setup/context";
import { useAPI2 } from "../../../../hooks/api-hook";
import { useComponnentProps } from "../../../../hooks/polaris-component-hooks";

export const RoleBasedPermissionedContentEditModule = (props: {
    active: UIModel.State<boolean>;
    editor?: any // TinyMCE can pass in the editor object to direct insert text and cursor position
}) => {
    const modifiedContent = UIModel.State.use<string>({initialValue: ""});
    const [items, setItems] = React.useState<FlashbarProps.MessageDefinition[]>([]);

    const message = Builder<FlashbarProps.MessageDefinition>()
        .type("info")
        .dismissible(true)
        .dismissLabel("Dismiss")
        .onDismiss(() => { setItems([]); })
        .content(
            <>
                Please choose <strong><em>"Keep"</em></strong> if prompted to paste HTML content.<br/>The previous content can be copied again.{" "}
            </>
        )
        .action(
            <Button
                iconName="copy"
                onClick={() => { navigator.clipboard.writeText(modifiedContent.value); }}
            >Copy previous</Button>
        )
        .build();


    React.useEffect(() => {
        if (!modifiedContent.value) {
            setItems([]);
            return;
        }
        setItems([message]);
    }, [modifiedContent.value]);

    return (
        <React.Fragment>
            {modifiedContent.value && <Flashbar items={items} />}
            <PermissionedContentEditModal
                onConfirm={(v) => props.editor ? props.editor.insertContent(v) : null}
                active={props.active}
                modifiedContent={modifiedContent}
            />
        </React.Fragment>
    );
};

const PermissionedContentEditModal = (props: {
    active: UIModel.State<boolean>;
    onConfirm?: (v: string) => void;
    modifiedContent: UIModel.State<string>;
}) => {
    const [role, setRole] = React.useState<Role.Data>(null);
    const content = UIModel.State.useNotNullString({ initialValue: null});
    const reset = () => {
        content.setValue(null);
        setRole(null);
        props.active.setValue(false);
    };

    return (
        props.active.value && <Modal
            visible={true}
            header={<Header>
                Create Permission Content <Link variant="info">Info</Link>
            </Header>}
            onDismiss={reset}
            footer={
                <Box float="right">
                    <SpaceBetween size="m" direction="horizontal">
                        <Button
                            variant="primary"
                            iconName="copy"
                            disabled={!role || !!content.errorText}
                            onClick={() => {
                                const result = wrapPermissionedContent(content.value, role.id);
                                navigator.clipboard.writeText(result);
                                props.modifiedContent.setValue(result);
                                props.active.setValue(false);
                                reset();
                                if (props.onConfirm) {
                                    props.onConfirm(result);
                                }
                            }
                        }>Confirm
                        </Button>
                    </SpaceBetween>
                </Box>
            }>
            <React.Fragment>
                <SpaceBetween size="m">
                    <Box variant="span">
                        <label>Select from Content Permission Roles</label>
                        <ContentPermissionRoleSelect selected={role} onSelectionChange={selected => setRole(selected)}/>
                    </Box>
                    <UIField.StateValueField
                        name="Content"
                        state={content}
                        placeholder="Enter content here..."
                        editing={true}
                        variant="TextArea"
                    />
                </SpaceBetween>
            </React.Fragment>
        </Modal>
    );
};

const ContentPermissionRoleSelect = (props: {
    selected: EntityRef;
    onSelectionChange: (selected: Role.Data) => void;
}) => {
    const context = React.useContext(AppContext);
    const [allRoles, setAllRoles] = React.useState<Role.Data[]>([]);

    const loadRolesByInstanceRunner = useAPI2(context.getRoleAPI().loadByInstance);
    const loadInstanceRunner = useAPI2(context.getInstanceAPI().load);

    const selectProps = useComponnentProps<SelectProps>({
        loadingText: "Loading...",
        errorText: "Error fetching data...",
        finishedText: "No more options",
        filteringType: "manual",
        placeholder: "Select from dropdown",
        options: [],
        filteringPlaceholder: "Type to filter",
        statusType: "loading",
        selectedOption: null,
    });

    React.useEffect(() => {
        const pageId = window.location.hash.split("/")[2];
        loadInstanceRunner.invoke(InstanceFactory.fromEntityId(pageId));
        loadRolesByInstanceRunner.invoke(ServerSidePaginatedLoadingInput.create({
            partitionKey: InstanceFactory.fromEntityId(pageId),
            pageSize: 10000, // set to 10000 to always load all roles
            currentPageIndex: 1,
            filters: []
        }));
    }, []);

    React.useEffect(() => {
        if (loadRolesByInstanceRunner.status === "Succeeded") {
            setAllRoles(loadRolesByInstanceRunner.output.result);
        }
        if (loadInstanceRunner.status === "Succeeded" && loadRolesByInstanceRunner.status === "Succeeded") {
            const contentPermissionRoles = loadInstanceRunner.output.contentPermissionRoles ?
                loadInstanceRunner.output.contentPermissionRoles : [];
            selectProps.setProps(prev => ({
                ...prev,
                statusType: "finished",
                options: contentPermissionRoles.map(r => ({
                    label: loadRolesByInstanceRunner.output.result.find(e => e.id === r.id).name,
                    value: r.id
                })),
                selectedOption: loadRolesByInstanceRunner.output.result.find(e => e.id === props.selected?.id)
            }));
        }
    }, [loadInstanceRunner.status, loadRolesByInstanceRunner.status]);

    const handleOnChange = e => {
        const selectedEntity = allRoles.find(entity =>
            e.detail.selectedOption.value === entity.id
        );
        props.onSelectionChange(selectedEntity);
        selectProps.setProps(prev => ({
            ...prev,
            selectedOption: e.detail.selectedOption
        }));
    };

    return <Select {...selectProps.props} onChange={handleOnChange}/>;
};
import { Container } from "@amzn/ask-legal-domain";
import { ExpandableSection, Grid } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../model/ui-model";
import { UIField } from "../common/UIField";
import { SmartRichTextView } from "../common/RichTextEditor/SmartRichTextView";
import { RestrictedHyperLinkOnlyRichTextEdit } from "../common/RichTextEditor/TinyMCEBased/RestrictedHyperLinksOnlyEdit";
import { StrongRichTextEdit } from "../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export namespace ContainerDescription {
    export const View = (props: {
        data?: Container.Margin,
        variant: "Header" | "Footer",
        diffMode?: boolean;
    }) => {
        if (!props.data || (props.data.header.length === 0 && props.data.body.length === 0)) return null;
        return (
            <div>
                { props.variant === "Footer" && <hr/> }
                { props.data.header.length > 0 &&
                    <SmartRichTextView content={props.data.header} diffMode={props.diffMode} /> }
                { props.data.body.length > 0 &&
                    <SmartRichTextView content={props.data.body} diffMode={props.diffMode} /> }
                { props.variant === "Header" && <hr/> }
            </div>
        );
    };

    export const Edit = (props: {
        variant: "Header" | "Footer"
        headerState: UIModel.State<string>;
        bodyState: UIModel.State<string>;
    }) => {
        return (
            <React.Fragment>
                { props.variant === "Header" && <hr/> }
                <ExpandableSection
                    header={`${props.variant} Description`}>
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <UIField.CustomField
                            name={`${props.variant} description title`}
                            child={<RestrictedHyperLinkOnlyRichTextEdit
                                content={props.headerState.value}
                                onChange={updated => props.headerState.setValue(updated)}
                            />}
                        />
                        <UIField.CustomField
                            name={`${props.variant} description body`}
                            child={<StrongRichTextEdit
                                content={props.bodyState.value}
                                onChange={updated => props.bodyState.setValue(updated)}
                                hidePermContent={true}
                            />}
                        />
                    </Grid>
                </ExpandableSection>
                { props.variant === "Footer" && <hr/> }
            </React.Fragment>
        );
    };
}
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { RecommendationAdminPortal } from "./RecommendationAdminPortal";
import useRoutedParamsWithContext from "../../setup/useRoutedParamsWithContext";

export const recommendationRoutes = [
    {
        path: "/recommendation/:instanceId/admin",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <RecommendationAdminPortal id={instanceId}/>;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Admin Portal", href: `#/instance/${instanceId}/admin` },
                { text: "Recommendation Admin Portal", href: `#/recommendation/${instanceId}/admin` }
            ]}/>;
        },
        header: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaHeader headerType="Other" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    }
];
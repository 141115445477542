import * as React from "react";
import { FilterField, PageLibrary, SortingField } from "@amzn/ask-legal-domain";
import { Link, PropertyFilterProps, TableProps } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { CommonPolarisFactory } from "./common-polaris-factory";
import { LegalOnlyBadgeComp } from "../../components/common/LegalOnlyTag";

export namespace PageLibraryPolarisFactory {
    export namespace Table {
        export namespace PropertyFilter {
            export const Name = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Name)
                .operators(["="])
                .propertyLabel("Name")
                .build();
            export const Any = Builder<PropertyFilterProps.FilteringProperty>()
                .key(FilterField.Any)
                .operators(["="])
                .propertyLabel("Any")
                .build();
        }

        export const InstanceRef = Builder<TableProps.ColumnDefinition<PageLibrary>>()
            .id("instanceRef")
            .header("Instance")
            .cell((item: PageLibrary) => item.instanceName)
            .build();
        export const Freshness = Builder<TableProps.ColumnDefinition<PageLibrary>>()
            .id("freshness")
            .header("Freshness")
            .cell((item: PageLibrary) => item.freshness)
            .build();
        export const PageLibraryName = Builder<TableProps.ColumnDefinition<PageLibrary>>()
            .id("pageLibraryName")
            .header("Name")
            .cell((item: PageLibrary) => (
                <React.Fragment>
                    <Link variant="secondary" href={`#/page-library/${item.id}/admin`}>
                        { !!item.displayName ? item.displayName : item.name }
                    </Link>
                    {!!item.restrictToLegal && LegalOnlyBadgeComp}
                </React.Fragment>
            ))
            .sortingField(SortingField.Name)
            .build();
        export function toColumnDefinitions() {
            return CommonPolarisFactory.Table.ColumnDefinition.toSortedColumnDefinition([
                PageLibraryName,
                InstanceRef,
                Freshness
            ]);
        }
    }
}
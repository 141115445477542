import { Questionnaire } from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect, useState } from "react";
import { UIModel } from "./ui-model";

export namespace QuestionnaireModel {
  export namespace UI {
    export type Actions =
      | "AddQuestion"
      | "UpdateQuestion"
      | "DeleteQuestion"
      | "MoveQuestionUp"
      | "MoveQuestionDown";
    export enum MoveAction {
      MOVE_UP = "move_up",
      MOVE_DOWN = "move_down",
    }

    export namespace Update {
      export class UpdateQuestionnaireState {
        questionnaireState: Questionnaire.Question[];
        isDirty: boolean;
        addOrUpdateQuestion: (question: Questionnaire.Question) => void;
        deleteQuestion: (question: Questionnaire.Question) => void;
        moveQuestion: (
          question: Questionnaire.Question,
          action: string
        ) => void;
        reset: () => void;

        static use(props: { template?: Questionnaire.Data }) {
          const template = UIModel.State.use<Questionnaire.Data>({
            initialValue: props.template,
          });
          const [isDirty, setDirty] = useState<boolean>(false);
          const questionnaireState = UIModel.State.use<Questionnaire.Data>({
            initialValue: props.template,
          });

          useEffect(() => {
            init(props.template);
          }, []);

          const reset = () => {
            questionnaireState.setValue(template.value);
            setDirty(false);
          };

          const init = (template?: Questionnaire.Data) => {
            if (!template) return;
            questionnaireState.setValue(template);
            setDirty(false);
          };

          const addOrUpdateQuestion = (question: Questionnaire.Question) => {
            const newQuestionnaire = Questionnaire.Data.addOrUpdateQuestion(
              questionnaireState.value,
              question
            );
            questionnaireState.setValue(newQuestionnaire);
            setDirty(true);
          };

          const deleteQuestion = (question: Questionnaire.Question) => {
            const newQuestionnaire = Questionnaire.Data.removeQuestion(
              questionnaireState.value,
              question.id
            );
            questionnaireState.setValue(newQuestionnaire);
            setDirty(true);
          };

          const moveQuestion = (
            question: Questionnaire.Question,
            action: string
          ) => {
            const existingQuestions = [...questionnaireState.value.questions];

            // find existing
            const findIndex = existingQuestions.findIndex(
              (q) => q.id === question.id
            );

            if (existingQuestions.length > 1 && findIndex !== -1) {
              if (action === QuestionnaireModel.UI.MoveAction.MOVE_UP) {
                if (findIndex > 0) {
                  let temp = existingQuestions[findIndex];
                  existingQuestions[findIndex] =
                    existingQuestions[findIndex - 1];
                  existingQuestions[findIndex - 1] = temp;
                }
              } else if (action === QuestionnaireModel.UI.MoveAction.MOVE_DOWN) {
                if (findIndex < existingQuestions.length - 1) {
                  let temp = existingQuestions[findIndex];
                  existingQuestions[findIndex] =
                    existingQuestions[findIndex + 1];
                  existingQuestions[findIndex + 1] = temp;
                }
              }
            }

            const newQuestions = Questionnaire.Data.create({
              questions: existingQuestions,
            });
            questionnaireState.setValue(newQuestions);
            setDirty(true);
          };

          return Builder<UpdateQuestionnaireState>(
            new UpdateQuestionnaireState()
          )
            .reset(reset)
            .isDirty(isDirty)
            .addOrUpdateQuestion(addOrUpdateQuestion)
            .deleteQuestion(deleteQuestion)
            .moveQuestion(moveQuestion)
            .questionnaireState(questionnaireState.value.questions)
            .build();
        }
      }

      export class EditState<T> {
        active: boolean;
        result: T;
        activate: () => void;
        deactivate: () => void;
        save: () => void;
        reset: () => void;

        constructor() {
          const [active, setActive] = useState<boolean>(false);
          const activate = () => {
            setActive(true);
          };
          const deactivate = () => {
            setActive(false);
          };

          return Builder<EditState<any>>()
            .activate(activate)
            .deactivate(deactivate)
            .active(active)
            .build();
        }
      }

      export class QuestionInputItem {
        id: string;
        question: string;
        answer: string;

        static defaultValue() {
          return Builder<QuestionInputItem>()
            .id("")
            .question("")
            .answer("")
            .build();
        }

        static fromQuestion(question: Questionnaire.Question) {
          if (!question) {
            return QuestionInputItem.defaultValue();
          }
          return Builder<QuestionInputItem>()
            .id(question.id)
            .question(question.question)
            .answer(question.answer)
            .build();
        }
      }

      export class AddQuestionState extends EditState<Questionnaire.Question> {
        readonly actionName: string = "Add Question";
        questionField: UIModel.State<string>;
        answerField: UIModel.State<string>;

        static use() {
          const [result, setResult] = useState<Questionnaire.Question>();
          const questionField = UIModel.State.useRequired<string>({
            initialValue: "",
          });
          const answerField = UIModel.State.use<string>({
            initialValue: "",
          });

          const reset = (): void => {
            setResult(null);
            questionField.setValue("");
            answerField.setValue("");
          };

          const save = () => {
            if (!questionField.value) return;
            const newResult = Questionnaire.Question.create({
              question: questionField.value,
              answer: answerField.value,
            });
            setResult(newResult);
          };

          return Builder<AddQuestionState>(new AddQuestionState())
            .result(result)
            .questionField(questionField)
            .answerField(answerField)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class EditQuestionState extends EditState<Questionnaire.Question> {
        readonly actionName: string = "Edit Question";
        editQuestionInputState: UIModel.State<QuestionInputItem>;
        activeQuestionState: UIModel.State<Questionnaire.Question>;

        static use() {
          const [result, setResult] = useState<Questionnaire.Question>();
          const editQuestionInputState = UIModel.State.use<QuestionInputItem>({
            initialValue: QuestionInputItem.defaultValue(),
          });
          const activeQuestionState = UIModel.State.use<Questionnaire.Question>(
            {
              initialValue: null,
            }
          );

          // Following EffectHook does not work as expected, need to research on why it doesn't work
          // useEffect(() => {
          //   if (!!activeQuestionState.value) {
          //     editQuestionInputState.setValue(
          //       QuestionInputItem.fromQuestion(activeQuestionState.value)
          //     );
          //   }
          // }, [activeQuestionState.value]);

          const reset = (): void => {
            setResult(null);
            editQuestionInputState.setValue(QuestionInputItem.defaultValue());
            activeQuestionState.setValue(null);
          };

          const save = () => {
            if (!editQuestionInputState.value) return;
            const newResult = Questionnaire.Question.update({
              existingQuestion: activeQuestionState.value,
              question: editQuestionInputState.value.question,
              answer: editQuestionInputState.value.answer,
            });
            setResult(newResult);
          };

          return Builder<EditQuestionState>(new EditQuestionState())
            .result(result)
            .activeQuestionState(activeQuestionState)
            .editQuestionInputState(editQuestionInputState)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class DeleteQuestionState extends EditState<Questionnaire.Question> {
        readonly actionName: string = "Delete Question";
        activeQuestionState: UIModel.State<Questionnaire.Question>;

        static use() {
          const [result, setResult] = useState<Questionnaire.Question>();
          const activeQuestionState = UIModel.State.use<Questionnaire.Question>(
            {
              initialValue: null,
            }
          );

          const reset = (): void => {
            setResult(null);
          };

          const save = () => {
            if (!activeQuestionState.value) return;
            setResult(activeQuestionState.value);
          };

          return Builder<DeleteQuestionState>(new DeleteQuestionState())
            .result(result)
            .activeQuestionState(activeQuestionState)
            .save(save)
            .reset(reset)
            .build();
        }
      }

      export class MoveQuestionState extends EditState<Questionnaire.Question> {
        readonly actionName: string = "Move Question";
        activeQuestionState: UIModel.State<Questionnaire.Question>;
        activeQuestionActionState: UIModel.State<string>;

        static use() {
          const activeQuestionState = UIModel.State.use<Questionnaire.Question>(
            {
              initialValue: null,
            }
          );
          const activeQuestionActionState = UIModel.State.use<string>({
            initialValue: null,
          });

          return Builder<MoveQuestionState>(new MoveQuestionState())
            .activeQuestionState(activeQuestionState)
            .activeQuestionActionState(activeQuestionActionState)
            .build();
        }
      }
    }
  }
}
import { QuestionnaireComponent } from "./index";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import { QuestionnaireModel } from "../../../model/questionnaire-model";
import { Questionnaire } from "@amzn/ask-legal-domain";

export enum BasicAccordionViewType {
  VIEW = "view",
  EDIT = "edit",
}

export const BasicAccordionView = (props: {
  existingQuestions: Questionnaire.Data;
  diffMode?: boolean;
  expandCollapseAllTrigger?: { value: string; };
}) => {
  return (
    <>
      <QuestionnaireComponent.View.Main
        inputState={props.existingQuestions.questions}
        diffMode={props.diffMode}
        expandCollapseAllTrigger={props.expandCollapseAllTrigger}
      />
    </>
  );
};

export const BasicAccordionEdit = (props: {
  existingQuestions: Questionnaire.Data;
  onChange?: (content: Questionnaire.Question[]) => void;
}) => {

  const updateQuestionnaireState =
    QuestionnaireModel.UI.Update.UpdateQuestionnaireState.use({
      template: props.existingQuestions,
    });

  const editingQuestionnaireState = UIModel.State.useRequired<boolean>({
    initialValue: true,
  });

  React.useEffect(() => {
    props.onChange ? props.onChange(updateQuestionnaireState.questionnaireState) : null;
  }, [updateQuestionnaireState.questionnaireState]);

  return (
    <>
      <QuestionnaireComponent.Edit.Main
        inputState={updateQuestionnaireState}
        editingQuestionnaireState={editingQuestionnaireState}
        onSubmit={() => null}
        onChange={(data) => null}
      />
    </>
  );
};

export const BasicAccordionGroupEdit = (props: {
  existingQuestions: Questionnaire.Data;
  onChange?: (content: Questionnaire.Question[]) => void;
}) => {

  const updateQuestionnaireState =
    QuestionnaireModel.UI.Update.UpdateQuestionnaireState.use({
      template: props.existingQuestions,
    });

  const editingQuestionnaireState = UIModel.State.useRequired<boolean>({
    initialValue: true,
  });

  React.useEffect(() => {
    props.onChange ? props.onChange(updateQuestionnaireState.questionnaireState) : null;
  }, [updateQuestionnaireState.questionnaireState]);

  return (
    <>
      <QuestionnaireComponent.Edit.Main
        inputState={updateQuestionnaireState}
        editingQuestionnaireState={editingQuestionnaireState}
        onSubmit={() => null}
        onChange={(data) => null}
      />
    </>
  );
};
import { KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { Checkbox, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

export const FileTypeFilter = (props: {
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
}) => {
    const defaultTypeOptions = ["Page", "Document", "Link"];

    return (
        <ExpandableSection defaultExpanded={false} header="File type">
            <SpaceBetween direction="vertical" size="s">
                {defaultTypeOptions.map(t => (
                    <Checkbox
                        checked={SearchInterface.SearchQueryInput.isFiltered(props.queryInputState.value, {
                            attribute: KendraConst.Attributes.Custom.ContentType,
                            valueMap: {
                                value: t,
                                valueType: KendraConst.IndexAttributeType.STRING
                            }
                        })}
                        onChange={e => {
                            if (e.detail.checked) {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Custom.ContentType,
                                        operation: "EqualsTo",
                                        valueMap: {
                                            value: t,
                                            valueType: KendraConst.IndexAttributeType.STRING
                                        }
                                    })
                                );
                            } else {
                                props.queryInputState.setValue(
                                    SearchInterface.SearchQueryInput.removeFilter(props.queryInputState.value, {
                                        attribute: KendraConst.Attributes.Custom.ContentType,
                                        operation: "EqualsTo",
                                        valueMap: {
                                            value: t,
                                            valueType: KendraConst.IndexAttributeType.STRING
                                        }
                                    })
                                );
                            }
                        }}>{t}
                    </Checkbox>
                ))}
            </SpaceBetween>
        </ExpandableSection>
    );
};
import * as React from "react";
import "../../styles/component/layout/AskLegalLayout.scss";

export const AskLegalLayout = (props: {
    content: JSX.Element;
    header: JSX.Element;
    footer?: JSX.Element;
}) => {
    return (
        <div className="sherpa-main-ui">
            { props.header }
            <div style={{ "zIndex" : "auto" }}>
                { props.content }
            </div>
            { !!props.footer && props.footer }
        </div>
    );
};

export namespace AskLegalLayoutUtils {
    export const scrollWithOffset = (el, offset) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = offset;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };
}
import * as React from "react";
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge, Edge as _Edge } from "reactflow";
export namespace CustomEdge {

    export const Type = "decisionTreeEdge";

    export interface Data {
        optionText: string;
    }

    export type Edge = _Edge<Data>;

    export const Comp: React.FC<EdgeProps> = ({
        id,
        sourceX,
        sourceY,
        targetX,
        targetY,
        sourcePosition,
        targetPosition,
        data,
        style = {},
        markerEnd
    }) => {
        const [edgePath, labelX, labelY] = getBezierPath({
            sourceX,
            sourceY,
            sourcePosition,
            targetX,
            targetY,
            targetPosition,
        });

        const defaultLabelStyling: React.CSSProperties = {
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: "lightgrey",
            maxWidth: "100px",
            padding: 5,
            borderRadius: 5,
            fontSize: 8,
            pointerEvents: "all",
            zIndex: 1
        };

        const restrictLabelStyling: React.CSSProperties = {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxHeight: "20px"
        };

        const focusedStyling: React.CSSProperties = {
            border: "1px solid red",
            fontWeight: "bold",
            zIndex: 10,
            maxHeight: "100%"
        };

        const [labelStyle, setLabelStyle] = React.useState<React.CSSProperties>({
            ...defaultLabelStyling,
            ...restrictLabelStyling
        });
        const [strokeColor, setStrokeColor] = React.useState<"red" | undefined>();

        React.useEffect(() => {
            setLabelStyle({
                ...labelStyle,
                transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`
            });
        }, [labelX, labelY]);

        return (
            <>
                <BaseEdge path={edgePath} markerEnd={markerEnd} style={{...style, stroke: strokeColor}} />
                <EdgeLabelRenderer>
                    <div
                        tabIndex={0}
                        onMouseEnter={() => {
                            setLabelStyle({
                                ...defaultLabelStyling,
                                ...focusedStyling
                            });
                            setStrokeColor("red");
                        }}
                        onMouseLeave={() => {
                            setLabelStyle({
                                ...defaultLabelStyling,
                                ...restrictLabelStyling
                            });
                            setStrokeColor(undefined);
                        }}
                        style={labelStyle}
                        className="nopan">
                        {data.optionText}
                    </div>
                </EdgeLabelRenderer>
            </>
        );
    };

    export const List = { [Type]: CustomEdge.Comp };
}

import { IMetrics, IUserProfile, PageFactory, PageLibraryFactory } from "@amzn/ask-legal-domain";
import { Grid, Icon, SpaceBetween, Spinner } from "@amzn/awsui-components-react";
import * as React from "react";
import { useHistory } from "react-router";
import { useAPI } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import "../../styles/util-styles.scss";
import "../../styles/home-page/instance-home-widgets.scss";
import "../../styles/home-page/responsive.scss";
import { LegalOnlyBadge } from "../common/LegalOnlyTag";

export const FavoritesWidget = (props: {
    instanceId: string;
}) => {
    const context = React.useContext(AppContext);
    const [columns, setColumns] = React.useState<number>(4); // 4 columns
    const [rows, setRows] = React.useState<number>(1); // initially 1 row
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [loadOutput, setLoadOuput] = React.useState<IUserProfile.LoadFavoritesOutput>();


    const loadFavoritesRunner = useAPI(
        context.getUserProfileAPI().loadFavorites
    );

    const fetchFavorites = () => {
        loadFavoritesRunner.submitRun(
            IUserProfile.LoadFavoritesInput.create(
                columns * rows,
                currentPage,
                props.instanceId
            )
        );
    };

    const renderEmptyFavorites = () => {
        const empty = <div className="card bg-dark text-white other-widget-card-empty">
            <div className="empty-body"></div>
        </div>;
        return (
            <React.Fragment>
                <div className="card bg-dark text-white favorites-widget-card">
                    <div className="card-body favorites-widget-card-body">
                        <h5 className="card-title text-center">How to Add Favorites</h5>
                        <p className="card-text">Click  <Icon name="heart" size="small"/>  to add favorites</p>
                    </div>
                </div>
                {empty}
                {empty}
                {empty}
            </React.Fragment>
        );
    };

    const favoritesCards = () => {
        const cardRows = [];
        let currentRow: FavoritesCardProps[] = [];
        for (let index = 0; index < columns * rows; index++) {
            if (
                loadFavoritesRunner.status === "Succeeded" &&
                !!loadOutput &&
                index < loadOutput.favorites.length
            ) {
                currentRow.push({
                    description: loadOutput.favorites[index].description,
                    cardTitle: loadOutput.favorites[index].title,
                    cardId: loadOutput.favorites[index].id,
                    cardType: loadOutput.favorites[index].type,
                    loading: false
                });
            } else if (loadFavoritesRunner.status === "Succeeded" && !!loadOutput) {
                currentRow.push({
                    description: "No more favorites to view",
                    cardTitle: "No more favorites",
                    cardId: "",
                    cardType: "",
                    loading: true,
                    isEmpty: true
                });
            } else {
                currentRow.push({
                    description: "Loading...",
                    cardTitle: "Loading...",
                    cardId: "",
                    cardType: "",
                    loading: true
                });
            }
            if (currentRow.length === columns) {
                cardRows.push(currentRow);
                currentRow = [];
            }
        }
        return (
            <div className="home-page-favorite-row">
                <SpaceBetween direction="vertical" size="l">
                    {cardRows.map(row => <div className="card-deck">
                        <Grid className="homepage-responsive" gridDefinition={[
                                { colspan: { default: 12, s: 3 } },
                                { colspan: { default: 12, s: 3 } },
                                { colspan: { default: 12, s: 3 } },
                                { colspan: { default: 12, s: 3 } },
                            ]}>
                            {row.map(props =>
                            <FavoritesCard {...props} />)}
                        </Grid></div>)}
                </SpaceBetween>
            </div>
        );
    };

    React.useEffect(() => {
        // listen to window size to change pageSize accordingly to best fit the page
        // Not yet implemented
    });

    React.useEffect(() => {
        if (rows > 0) {
            fetchFavorites();
        }
    }, [props.instanceId, columns * rows, currentPage]);

    React.useEffect(() => {
        if (loadFavoritesRunner.status === "Succeeded") {
            setLoadOuput(loadFavoritesRunner.data.output);
            if (loadFavoritesRunner.data.output.totalCount > 0) {
                setExpanded(true);
            } else {
                setExpanded(false);
            }
        }
    }, [loadFavoritesRunner.status]);

    return (<React.Fragment>
        <div className="home-page-sub-header responsive-header">
            <div onClick={() => setExpanded(!expanded)} className="responsive">
                {expanded ? <Icon name="caret-down-filled" size="big"/> : <Icon name="caret-right-filled" size="big"/>}
            </div>
            <a className="title" href={`${window.location.origin}/#/instance/${props.instanceId}/myProfile`}>
                Favorites
            </a>
        </div>
        {expanded && <div className="card-deck other-widget-main home-page-sub-fav-content">
            {loadFavoritesRunner.status === "Running" &&
                <div className="vertical-center other-widget-empty">
                    <Spinner variant="inverted" size="large"/>
                </div>
            }
            {loadFavoritesRunner.status === "Succeeded" &&
                <React.Fragment>
                    <div
                        className={"vertical-center " +
                            (currentPage > 1 ?
                                "other-widget-navigate-icon" :
                                "other-widget-navigate-icon-disabled"
                            )
                        }
                        onClick={() => {
                            if (currentPage > 1) {
                                setCurrentPage(currentPage - 1);
                            }
                        }}>
                        <Icon
                            name="angle-left-double"
                            size="big"
                        />
                    </div>
                    {loadFavoritesRunner.data.output.totalCount > 0 ? favoritesCards() : renderEmptyFavorites()}
                    <div
                        className={
                            "vertical-center " +
                            (
                                (!!loadOutput && (currentPage + 1) * columns * rows - loadOutput.totalCount < columns * rows) ?
                                "other-widget-navigate-icon" : "other-widget-navigate-icon-disabled"
                            )}
                        onClick={() => {
                            if (!!loadOutput &&
                                (currentPage + 1) * columns * rows - loadOutput.totalCount < columns * rows) {
                                setCurrentPage(currentPage + 1);
                            }
                        }}>
                        <Icon
                            name="angle-right-double"
                            size="big"
                        />
                    </div>
                </React.Fragment>}
        </div>}</React.Fragment>
    );
};

interface FavoritesCardProps {
    cardId: string;
    description: string;
    cardTitle: string;
    cardType: string;
    loading: boolean;
    isEmpty?: boolean;
}

const FavoritesCard = (
    props: FavoritesCardProps
) => {
    const history = useHistory();
    const context = React.useContext(AppContext);
    const recordViewHistoryRunner = useAPI(
        context.getCommonAPI().recordViewHistory
    );

    const recordViewHistory = (forwardingUrlPath: string) => {
        recordViewHistoryRunner.submitRun(
            IMetrics.RecordViewHistoryInput.create({
                type: "Favorites",
                entityId: props.cardId,
                url: forwardingUrlPath
            })
        );
    };

    if (!!props.isEmpty) {
        return <div className="card bg-dark text-white other-widget-card-empty">
            <div className="empty-body"></div>
        </div>;
    }

    if (!!props.loading) {
        return <div className="card bg-dark text-white other-widget-card-empty">
            <div className="empty-body vertical-center">
                <Spinner />
            </div>
        </div>;
    }

    return (
        <div onClick={() => {
            if (!props.loading) {
                let forwardingUrlPath = `/page/${props.cardId}/live`;
                if (props.cardType === "Container") {
                    forwardingUrlPath = `/page/${PageFactory.fromEntityId(props.cardId)}/live?container=${props.cardId}`;
                }
                window.open("/#" + forwardingUrlPath, "_blank");
                recordViewHistory(forwardingUrlPath);
            }
        }} className="card bg-dark text-white favorites-widget-card">
            <div className="card-body favorites-widget-card-body">
                <h5 className="card-title text-center">
                    {props.cardTitle}
                    <LegalOnlyBadge pageLibraryId={PageLibraryFactory.fromEntityId(props.cardId)}/>
                </h5>
                <p className="card-text">{props.description}</p>
            </div>
        </div>
    );
};
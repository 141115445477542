import * as React from "react";
import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Pagination,
    PropertyFilter,
    SpaceBetween,
    Table
} from "@amzn/awsui-components-react";
import { PageLibraryCreate } from "./PageLibraryCreate";
import { Builder } from "builder-pattern";
import { PageLibraryPolarisFactory } from "../../factory/polaris/page-library-polaris-factory";
import { CommonPolarisFactory } from "../../factory/polaris/common-polaris-factory";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { useLoadingWithServerSidePagination } from "../../hooks/polaris-collection-hook";

export const PageLibraryHome = (props: {
    id: string;
    viewOnly?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const columnDefinitions = PageLibraryPolarisFactory.Table.toColumnDefinitions();

    const tableDefaultPreferences = Builder<CollectionPreferencesProps.Preferences<any>>()
        .pageSize(10)
        .visibleContent([
            PageLibraryPolarisFactory.Table.PageLibraryName.id,
            CommonPolarisFactory.Table.ColumnDefinition.LastModifiedBy.id,
            CommonPolarisFactory.Table.ColumnDefinition.LastModifiedDate.id,
        ])
        .build();
    const collection = useLoadingWithServerSidePagination(
        context.getPageLibraryAPI().loadAll,
        props.id,
        {
            columnDefinitions: columnDefinitions,
            defaultTablePreferences: tableDefaultPreferences,
            pageSizeSelectionOptions: [
                CommonPolarisFactory.Table.PageSizeSelection.Option_Ten,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Twenty,
                CommonPolarisFactory.Table.PageSizeSelection.Option_Fifty
            ]
        },
        {
            filteringProperties: [
                PageLibraryPolarisFactory.Table.PropertyFilter.Any,
                PageLibraryPolarisFactory.Table.PropertyFilter.Name
            ]
        }
    );

    const counter = () => {
        if (collection.table.props.loading) return "";
        return `(${collection.table.props.selectedItems.length}/${collection.table.props.items.length})`;
    };

    const header = (<Header
        variant="h2"
        counter={counter()}
        actions={
            !props.viewOnly && <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <PageLibraryCreate id={props.id} />
                </SpaceBetween>
            </Box>
        }>
        Page Libraries
    </Header>);

    const empty = (<Box textAlign="center" color="inherit">
        <b>No Page Libraries</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No Page Libraries to display
        </Box>
        <PageLibraryCreate id={props.id} />
    </Box>);

    return (
        <React.Fragment>
            {!!collection.table.error && <ErrorFlashbar error={collection.table.error} />}
            <Table
                {...collection.table.props}
                empty={empty}
                header={header}
                preferences={
                    <CollectionPreferences {...collection.tablePreference.props} />
                }
                pagination={
                    <Pagination {...collection.tablePagination.props} />
                }
                filter={
                    <PropertyFilter {...collection.tableFiltering.props}/>
                }
            />
        </React.Fragment>
    );
};
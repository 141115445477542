import { IExternalReference, ExternalReference, EntityRef } from "@amzn/ask-legal-domain";
import { AppContextValue } from "../setup/context-value";
import { APIResponse, toDefaultRequestPayload } from "./common";
import { RestAPI } from "@aws-amplify/api-rest";

export interface ExternalReferenceAPI {
    create<T>(input: IExternalReference.CreateExternalReferenceInput<T>): Promise<APIResponse<ExternalReference.Data<T>>>;
    update<T>(input: IExternalReference.UpdateExternalReferenceInput): Promise<APIResponse<ExternalReference.Data<T>>>;
    load<T>(id: string): Promise<APIResponse<ExternalReference.Data<T>>>;
    remove(id: string): Promise<APIResponse<ExternalReference.Data<any>>>;
    loadByBaseEntity<T>(baseEntity: EntityRef): Promise<APIResponse<ExternalReference.Data<T>[]>>;
}

export class ExternalReferenceAPIImpl implements ExternalReferenceAPI {
    constructor (private context: AppContextValue) {}

    async remove(id: string): Promise<APIResponse<ExternalReference.Data<any>>> {
        const response: APIResponse<ExternalReference.Data<any>> = await RestAPI.post(
            "custom-domain",
            "/external-reference/remove",
            toDefaultRequestPayload(id)
        );
        return response;
    }

    async create<T>(input: IExternalReference.CreateExternalReferenceInput<T>): Promise<APIResponse<ExternalReference.Data<T>>> {
        const response: APIResponse<ExternalReference.Data<T>> = await RestAPI.post(
            "custom-domain",
            "/external-reference/create",
            toDefaultRequestPayload(input)
        );
        return response;
    }

    async update<T>(input: IExternalReference.UpdateExternalReferenceInput): Promise<APIResponse<ExternalReference.Data<T>>> {
        throw new Error("Method not implemented.");
    }

    async load<T>(id: string): Promise<APIResponse<ExternalReference.Data<T>>> {
        throw new Error("Method not implemented.");
    }

    async loadByBaseEntity<T>(baseEntity: EntityRef): Promise<APIResponse<ExternalReference.Data<T>[]>> {
        const response: APIResponse<ExternalReference.Data<T>[]> = await RestAPI.post(
            "custom-domain",
            "/external-reference/load-by-base-entity",
            toDefaultRequestPayload(baseEntity)
        );
        return response;
    }
}
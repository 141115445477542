import { Recommendation, RecommendationRanking, ServerSidePaginatedLoadingInput, Cache, Identity, IRecommendation } from "@amzn/ask-legal-domain";
import { Table, Box, Button, SpaceBetween, Header, TableProps, Badge, Link, Spinner, Modal, Alert } from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useAPI, useAPI2 } from "../../hooks/api-hook";
import { useComponnentProps } from "../../hooks/polaris-component-hooks";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { UIField } from "../common/UIField";
import { RulePopover } from "./RuleInfoLink";

namespace RecommendationRankTable {
    export interface ItemDefinition {
        data: Recommendation.Data;
        rank: number;
    }
    export const Title = Builder<TableProps.ColumnDefinition<ItemDefinition>>()
        .id("title")
        .header("Title")
        .cell((item: ItemDefinition) => item.data.title)
        .build();
    export const Url = Builder<TableProps.ColumnDefinition<ItemDefinition>>()
        .id("url")
        .header("Page URL")
        .cell((item: ItemDefinition) =>
            <React.Fragment>
                <Link href={item.data.url}>{item.data.url}</Link>
            </React.Fragment>)
        .build();
    export const Rules = Builder<TableProps.ColumnDefinition<ItemDefinition>>()
        .id("rules")
        .header("Rules")
        .cell((item: ItemDefinition) => item.data.rules.map(e =>
                <Badge color="blue">
                    <RulePopover id={e} />
                </Badge>
            ))
        .build();
    export const Rank = Builder<TableProps.ColumnDefinition<ItemDefinition>>()
        .id("rank")
        .header("Rank")
        .cell((item: ItemDefinition) => item.rank)
        .build();
}

export const RecommendationRankPreLoad = (props: { id: string }) => {
    const context = React.useContext(AppContext);

    const loadRecommendationRunner = useAPI(context.getRecommendationAPI().loadAll);

    React.useEffect(() => {
        loadRecommendationRunner.submitRun(ServerSidePaginatedLoadingInput.create({
            partitionKey: props.id,
            currentPageIndex: 1,
            pageSize: 1000, // 1000 will be a safe number for now, one instance won't have more than 1000 recommendations
            filters: []
        }));
    }, []);

    return (
        <React.Fragment>
            {loadRecommendationRunner.status === "Running" && <Spinner/>}
            {loadRecommendationRunner.status === "Succeeded" &&
                <RecommendationRankEdit
                    instanceId={props.id}
                    items={loadRecommendationRunner.data.output.result}
                />
            }
            {loadRecommendationRunner.status === "Error" && <ErrorFlashbar error={loadRecommendationRunner.data.err}/>}
        </React.Fragment>
    );
};

export const RecommendationRankEdit = (props: { instanceId: string, items: Recommendation.Data[] }) => {
    const context = React.useContext(AppContext);
    const [identity, setIdentity] = React.useState<Identity>(null);
    const [cache, setCache] = React.useState<Cache.Data<RecommendationRanking>>();

    const [originalRank, setOriginalRank] = React.useState<string[]>(props.items.map(i => i.id));
    const [newRank, setNewRank] = React.useState<string[]>(props.items.map(i => i.id));

    const loadCacheRunner = useAPI2(context.getRecommendationAPI().getFullRank);
    const checkoutRunner = useAPI2(context.getRecommendationAPI().updateRankCheckout);
    const saveAndCheckinRunner = useAPI2(context.getRecommendationAPI().updateRankSaveAndCheckin);
    const discardAndCheckinRunner = useAPI2(context.getRecommendationAPI().updateRankDiscardAndCheckin);

    const [overrideCheckoutWarningModalVisible, setOverrideCheckoutWarningModalVisible] = React.useState<boolean>(false);
    const [changeRankModalVisible, setChangeRankModalVisible] = React.useState<boolean>(false);
    const [dirty, setDirty] = React.useState<boolean>(false);

    const table = useComponnentProps<TableProps<RecommendationRankTable.ItemDefinition>>({
        columnDefinitions: [
            RecommendationRankTable.Rank,
            RecommendationRankTable.Title,
            RecommendationRankTable.Url,
            RecommendationRankTable.Rules
        ],
        items: props.items.map((item, index) => ({
            data: item,
            rank: index + 1
        })),
        selectedItems: [],
        selectionType: "single",
        onSelectionChange: e => table.setProps((prev) => ({
            ...prev,
            selectedItems: e.detail.selectedItems
        })),
        loadingText: "Loading...",
        loading: false,
        visibleColumns: [
            RecommendationRankTable.Rank.id,
            RecommendationRankTable.Title.id,
            RecommendationRankTable.Url.id,
            RecommendationRankTable.Rules.id,
        ]
    });

    const toIndexState = UIModel.State.use<number>({
        initialValue: 0,
        validation: (n: number) => {
            const maxBound = table.props.items.length;
            if (isNaN(n)) return "must be a number";
            if (n > maxBound) return `${n} is out of bound, maximum index is ${maxBound}`;
            if (n <= 0) return `${n} is out of bound, minimum index is ${1}`;
        }
    });
    const reorder = (fromIndex: number) => {
        const toIndex = toIndexState.value - 1;
        const maxBound = table.props.items.length;
        const _tableItems = table.props.items.slice();
        const _newRank = newRank.slice();
        const item = _tableItems[fromIndex];
        _tableItems.splice(fromIndex, 1);
        _newRank.splice(fromIndex, 1);
        if (toIndex >= maxBound - 1) {
            _tableItems.splice(maxBound - 1, 0, item);
            _newRank.splice(maxBound - 1, 0, item.data.id);
        } else {
            _tableItems.splice(toIndex, 0, item);
            _newRank.splice(toIndex, 0, item.data.id);
        }
        table.setProps(prev => ({
            ...prev,
            items: _tableItems.map((item, index) => ({
                data: item.data,
                rank: index + 1
            }))
        }));
        setNewRank(_newRank);
        toIndexState.setValue(0);
        setDirty(true);
    };

    React.useEffect(() => {
        const loadIdentity = async () => {
            const identity = await context.getIdentity();
            setIdentity(identity);
        };
        loadIdentity();
        loadCacheRunner.invoke(props.instanceId);
    }, []);

    React.useEffect(() => {
        if (loadCacheRunner.status === "Succeeded") {
            setCache(loadCacheRunner.output);
        }
    }, [loadCacheRunner.status]);

    React.useEffect(() => {
        if (checkoutRunner.status === "Succeeded") {
            setCache(checkoutRunner.output);
            setOverrideCheckoutWarningModalVisible(false);
        }
    }, [checkoutRunner.status]);

    React.useEffect(() => {
        if (saveAndCheckinRunner.status === "Succeeded") {
            setCache(saveAndCheckinRunner.output);
            setDirty(false);
        }
    }, [saveAndCheckinRunner.status]);

    React.useEffect(() => {
        if (discardAndCheckinRunner.status === "Succeeded") {
            setCache(discardAndCheckinRunner.output);
        }
    }, [discardAndCheckinRunner.status]);

    const empty = (
        <Box textAlign="center" color="inherit">
            <b>
                No Recommendations found
            </b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No Recommendations found in this Instance
            </Box>
        </Box>
    );

    const counter = () => {
        if (table.props.loading) return "";
        return `(${table.props.items.length})`;
    };

    const header = (
        <Header variant="h2" counter={counter()} actions={
            <SpaceBetween direction="horizontal" size="m">
                <Button
                    variant="primary"
                    disabled={
                        table.props.selectedItems.length === 0 ||
                        !cache ||
                        !identity ||
                        cache.payload.status !== "CheckedOut" ||
                        cache.payload.lastModifiedBy.id !== identity.id
                    }
                    onClick={() => setChangeRankModalVisible(true)}>
                    Change Rank
                </Button>
            </SpaceBetween>
        }>
            Recommendations Ranking
        </Header>
    );

    const getActionButtons = () => {
        if (!!cache && !!identity) {
            if (cache.payload.status === "Idle") {
                return (
                    <Button
                        variant="primary"
                        loading={checkoutRunner.status === "Running"}
                        onClick={() => checkoutRunner.invoke(props.instanceId)}>
                        Checkout
                    </Button>
                );
            } else if (cache.payload.status === "CheckedOut" && cache.payload.lastModifiedBy.id === identity.id) {
                return (
                    <SpaceBetween direction="horizontal" size="l">
                        <Button
                            variant="normal"
                            onClick={() => {
                                setNewRank(originalRank);
                                table.setProps(prev => ({
                                    ...prev,
                                    items: props.items.map((item, index) => ({
                                        data: item,
                                        rank: index + 1
                                    }))
                                }));
                                setDirty(false);
                            }}>Reset All
                        </Button>
                        <Button
                            variant="normal"
                            onClick={() => {
                                discardAndCheckinRunner.invoke(props.instanceId);
                                setNewRank(originalRank);
                                table.setProps(prev => ({
                                    ...prev,
                                    items: props.items.map((item, index) => ({
                                        data: item,
                                        rank: index + 1
                                    }))
                                }));
                                setDirty(false);
                            }}
                            loading={discardAndCheckinRunner.status === "Running" || saveAndCheckinRunner.status === "Running"}>
                            Discard all changes and Checkin
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => saveAndCheckinRunner.invoke(
                                IRecommendation.UpdateRecommendationRankingInput.create({
                                    instanceId: props.instanceId,
                                    order: newRank
                                }))
                            }
                            loading={discardAndCheckinRunner.status === "Running" || saveAndCheckinRunner.status === "Running"}>
                            Save all changes and Checkin
                        </Button>
                    </SpaceBetween>
                );
            } else if (cache.payload.status === "CheckedOut" && cache.payload.lastModifiedBy.id !== identity.id) {
                return (
                    <Button variant="primary" onClick={() => setOverrideCheckoutWarningModalVisible(true)}>
                        Override and Checkout
                    </Button>
                );
            }
        }
        return <Button disabled><Spinner/></Button>;
    };

    return (
        <SpaceBetween direction="vertical" size="xl">
            {dirty && (
                <Alert
                    visible={true}
                    header="Please save your changes">
                    Changes have been made to the ranking, please make sure to <strong>Save and check in</strong> before leaving the tab
                </Alert>
            )}
            {overrideCheckoutWarningModalVisible && (
                <Modal
                    header="Override Confirmation"
                    visible={true}
                    onDismiss={e => {
                        if (e.detail.reason === "closeButton") {
                            setOverrideCheckoutWarningModalVisible(false);
                        }
                    }}
                    footer={
                    <Box float="right">
                        <Button
                            variant="link"
                            loading={checkoutRunner.status === "Running"}
                            onClick={() => setOverrideCheckoutWarningModalVisible(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            loading={checkoutRunner.status === "Running"}
                            onClick={() => checkoutRunner.invoke(props.instanceId)}>
                            Confirm
                        </Button>
                    </Box>
                }>
                    <Alert type="warning">
                    <strong>{cache.payload.lastModifiedBy.id}</strong> has already checked out, if you override and checkout,
                    <strong>&nbsp;{cache.payload.lastModifiedBy.id}</strong> will lose all their changes. Please make sure you need to override and checkout.
                    </Alert>
                </Modal>
            )}
            {changeRankModalVisible && (
                <Modal
                    header="Change Rank"
                    visible={true}
                    onDismiss={e => {
                        if (e.detail.reason === "closeButton") {
                            setChangeRankModalVisible(false);
                        }
                    }}
                    footer={
                        <Box float="right">
                            <Button variant="link" onClick={() => setChangeRankModalVisible(false)}>
                                Cancel
                            </Button>
                            <Button variant="primary" disabled={!!toIndexState.errorText} onClick={() => {
                                reorder(table.props.selectedItems[0].rank - 1);
                                setChangeRankModalVisible(false);
                                table.setProps(prev => ({
                                    ...prev,
                                    selectedItems: []
                                }));
                            }}>
                                Confirm
                            </Button>
                        </Box>
                    }>
                    Current rank: {table.props.selectedItems[0].rank}
                    <br/>
                    <UIField.NumberValueField name="New rank" state={toIndexState} editing={true}/>
                </Modal>
            )}
            <Box float="right">
                {getActionButtons()}
            </Box>
            <Table
                {...table.props}
                empty={empty}
                header={header}
            />
        </SpaceBetween>
    );
};
import {
    EntityPermission,
    EntityRef,
    Identity,
    IEntityPermission,
    Team
} from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import { useEffect } from "react";
import { UIModel } from "./ui-model";

export namespace EntityPermissionModel {
    export class UpdateState {
        target: EntityRef;
        instanceRef: EntityRef;
        adminTeamsField: UIModel.State<Team[]>;
        approverTeamsField: UIModel.State<Team[]>;
        editorTeamsField: UIModel.State<Team[]>;
        viewerTeamsField: UIModel.State<Team[]>;
        adminUsersField: UIModel.State<Identity[]>;
        approverUsersField: UIModel.State<Identity[]>;
        editorUsersField: UIModel.State<Identity[]>;
        viewerUsersField: UIModel.State<Identity[]>;
        publicViewOnlyField: UIModel.State<boolean>;
        reset: () => void;

        static toInput(state: UpdateState) {
            return IEntityPermission.UpdateEntityPermissionInput.create({
                entityPermissionRef: state.target,
                instanceRef: state.instanceRef,
                newAdminTeams: state.adminTeamsField.value,
                newApproverTeams: state.approverTeamsField.value,
                newEditorTeams: state.editorTeamsField.value,
                newViewerTeams: state.viewerTeamsField.value,
                newAdminUsers: !state.adminUsersField.value ? [] : state.adminUsersField.value,
                newApproverUsers: !state.approverUsersField.value ? [] : state.approverUsersField.value,
                newEditorUsers: !state.editorUsersField.value ? [] : state.editorUsersField.value,
                newViewerUsers: !state.viewerUsersField.value ? [] : state.viewerUsersField.value,
                isPublicViewOnly: state.publicViewOnlyField.value,
            });
        }

        static use(props: { template: EntityPermission.Data, instanceRef: EntityRef }) {
            const adminTeamsField = UIModel.State.useArray<Team>({
                initialValue: []
            });
            const approverTeamsField = UIModel.State.useArray<Team>({
                initialValue: []
            });
            const editorTeamsField = UIModel.State.useArray<Team>({
                initialValue: []
            });
            const viewerTeamsField = UIModel.State.useArray<Team>({
                initialValue: []
            });
            const adminUsersField = UIModel.State.useArrayWithLimit<Identity>({
                initialValue: [],
                limit: EntityPermission.RESOURCE_ADMIN_USER_LIMIT
            });
            const approverUsersField = UIModel.State.useArrayWithLimit<Identity>({
                initialValue: [],
                limit: EntityPermission.RESOURCE_APPROVER_USER_LIMIT
            });
            const editorUsersField = UIModel.State.useArrayWithLimit<Identity>({
                initialValue: [],
                limit: EntityPermission.RESOURCE_EDITOR_USER_LIMIT
            });
            const viewerUsersField = UIModel.State.useArrayWithLimit<Identity>({
                initialValue: [],
                limit: EntityPermission.RESOURCE_VIEWER_USER_LIMIT
            });
            const publicViewOnlyField = UIModel.State.use<boolean>({
                initialValue: false
            });

            const reset = () => {
                adminTeamsField.setValue(props.template.adminTeams);
                approverTeamsField.setValue(props.template.approverTeams);
                editorTeamsField.setValue(props.template.editorTeams);
                viewerTeamsField.setValue(props.template.viewerTeams);
                adminUsersField.setValue(props.template.adminUsers);
                approverUsersField.setValue(props.template.approverUsers);
                editorUsersField.setValue(props.template.editorUsers);
                viewerUsersField.setValue(props.template.viewerUsers);
                publicViewOnlyField.setValue(props.template.isPublicViewOnly);
            };

            useEffect(() => {
                reset();
            }, []);

            return Builder<UpdateState>()
                .target(props.template.target)
                .instanceRef(props.instanceRef)
                .adminTeamsField(adminTeamsField)
                .approverTeamsField(approverTeamsField)
                .editorTeamsField(editorTeamsField)
                .viewerTeamsField(viewerTeamsField)
                .adminUsersField(adminUsersField)
                .approverUsersField(approverUsersField)
                .editorUsersField(editorUsersField)
                .viewerUsersField(viewerUsersField)
                .publicViewOnlyField(publicViewOnlyField)
                .reset(reset)
                .build();
        }
    }
}
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { registerSamePageLinkEvent } from "../../../utils/common-utils";
import { RichTextView } from "./RichTextView";
import $ = require("jquery");

export const SmartRichTextView = (props: {
    content: string;
    fontSize?: number;
    diffMode?: boolean;
}) => {
    const context = React.useContext(AppContext);
    const [content, setContent] = React.useState<string>();

    /**
     * Use this effect hook to alter links pointing to containers in same page
     */
     React.useEffect(() => {
        const currentHash = window.location.hash;
        const pageHash = currentHash.split("#")[1];
        let pageId = pageHash.split("/")[2];

        // Override param for shared pages
        if (pageHash.split("/")[1] === "shared-page") pageId = pageHash.split("/")[3];

        let modifiedContent = replaceSamePageLink(props.content, pageId);
        setContent(modifiedContent);
    }, [props.content]);

    React.useEffect(() => {
        registerSamePageLinkEvent();
        return () => {
            $(".custom-container-link").off();
        };
    });

    const replaceSamePageLink = (original: string, pageId: string): string => {
        let regexStrSamePage =
            "<a ([^>]*?)\\s?href=\"((" +
            window.location.origin +
            "\/)?#\/page\/" +
            pageId +
            "\/live\\?container=[a-z0-9A-Z-]*)\"\\s?([^>]*?)\\s?>";
        regexStrSamePage = regexStrSamePage.replace(/\//g, "\\/");
        const regExpSamePage = RegExp(regexStrSamePage, "g");
        const matches = original.match(regExpSamePage);
        let modifiedContent = original;
        if (matches) {
            // there can be multiple matches of above regex, i.e. multiple same page links
            for (const match of matches) {
                const exec = regExpSamePage.exec(match);
                const urlsearchparams = new URLSearchParams(exec[2].split("/live")[1]);
                const containerId = urlsearchparams.get("container");
                const modifiedMatch = match.replace(
                    regExpSamePage,
                    `<a class='custom-container-link' container-id='${containerId}' $1 $4>`
                );
                modifiedContent = modifiedContent.replace(match, modifiedMatch);
            }
        }
        return modifiedContent;
    };

    return <RichTextView content={content} diffMode={props.diffMode}/>;
};
import {
    DEFAULT_LEGAL_TEAM,
    EntityFactory,
    EntityRef,
    Instance,
    InstanceInterface,
    Role,
    Team
} from "@amzn/ask-legal-domain";
import {
    Container,
    Button,
    Form,
    Header,
    Box,
    ColumnLayout,
    SpaceBetween,
    Popover,
    Link,
    FormField,
    TextContent
} from "@amzn/awsui-components-react";
import * as React from "react";
import { useAPI2 } from "../../hooks/api-hook";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { EntitySelection } from "../common/EntitySelection";
import { RolePopover } from "../role/RoleInfoLink";

export const TeamDefinitionEdit = (props: {
    state: UIModel.State<Instance>;
}) => {
    const context = React.useContext(AppContext);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [legalTeam, setLegalTeam] = React.useState<EntityRef>(props.state.value.legalTeam);
    const [contentPermissionRoles, setContentPermissionRoles] = React.useState<EntityRef[]>(
        !!props.state.value.contentPermissionRoles ?
            props.state.value.contentPermissionRoles :
            []
    );

    const updateLegalTeamRunner = useAPI2(
        context.getInstanceAPI().update
    );

    const updateTeamDefinition = () => {
        updateLegalTeamRunner.invoke(InstanceInterface.UpdateInstanceInput.create(
            EntityFactory.toEntityRef(props.state.value),
            {
                legalTeam: legalTeam,
                contentPermissionRoles: contentPermissionRoles
            }
        ));
    };

    React.useEffect(() => {
        if (updateLegalTeamRunner.status === "Succeeded") {
            setEditing(false);
            props.state.setValue(updateLegalTeamRunner.output);
            setLegalTeam(updateLegalTeamRunner.output.legalTeam);
            setContentPermissionRoles(updateLegalTeamRunner.output.contentPermissionRoles);
        }
    }, [updateLegalTeamRunner.status]);

    return (
        <Container
            header={
                <Header
                    variant="h3"
                    actions={!editing ?
                        <Box float="right">
                            <Button
                                onClick={() => {
                                    setEditing(true);
                                }}
                                loading={updateLegalTeamRunner.status === "Running"}
                                variant="normal">
                                Edit
                            </Button>
                        </Box> :
                        <Box float="right"><SpaceBetween direction="horizontal" size="l">
                            <Button
                                onClick={() => {
                                    setEditing(false);
                                    setLegalTeam(props.state.value.legalTeam);
                                }}
                                loading={updateLegalTeamRunner.status === "Running"}
                                variant="link">
                                Cancel
                            </Button>
                            <Button
                                onClick={updateTeamDefinition}
                                loading={updateLegalTeamRunner.status === "Running"}
                                variant="primary">
                                Confirm
                            </Button>
                        </SpaceBetween></Box>
                    }>
                    Special Role Definitions&nbsp;
                    <Popover
                        dismissButton={false}
                        position="top"
                        size="small"
                        triggerType="custom"
                        content="These are roles defined at an instance level for specific use cases"
                    ><Link variant="info">Info</Link></Popover>
                </Header>
            }>
            <ColumnLayout columns={1}>
                <Form>
                    <SpaceBetween direction="vertical" size="xl">
                        <FormField
                            label="Legal Team Role"
                            description="The Role selected below will grant permission to page libraries marked as Legal Only as well as Legal Only Navigation Menus">
                            {editing && <EntitySelection.SingleWithPagination
                                api={context.getRoleAPI().loadByInstance}
                                partitionKey={props.state.value.id}
                                selected={legalTeam}
                                onSelectionChange={(entity: Role.Data) =>
                                    setLegalTeam(EntityFactory.toEntityRef(entity))
                                }
                            />}
                            {!editing && (!!legalTeam ?
                                <RolePopover badge id={legalTeam.id}/> :
                                <Box padding={{ left : "xxxl" }} textAlign="center">
                                    <TextContent>
                                        <em>
                                            <small>
                                                --None--
                                            </small>
                                        </em>
                                    </TextContent>
                                </Box>
                            )}
                        </FormField>
                        <FormField
                            label="Container Content Permission Roles"
                            description="You can create permissioned content in rich text container and accordion container to these roles. These roles cannot be removed from here once saved">
                            {editing && <EntitySelection.MultiWithPagination
                                api={context.getRoleAPI().loadByInstance}
                                partitionKey={props.state.value.id}
                                selected={contentPermissionRoles}
                                onSelectionChange={(entities: Role.Data[]) => {
                                    // User can't remove roles from this set
                                    if (!!props.state.value.contentPermissionRoles) {
                                        for (const oldRole of props.state.value.contentPermissionRoles) {
                                            const found = entities.find(e => e.id === oldRole.id);
                                            if (!found) {
                                                return;
                                            }
                                        }
                                    }
                                    setContentPermissionRoles(
                                        entities.map(e => EntityFactory.toEntityRef(e))
                                    );
                                }}
                            />}
                            {!editing && (!!contentPermissionRoles ?
                                <SpaceBetween direction="horizontal" size="m">
                                    {contentPermissionRoles.map(r => <RolePopover badge id={r.id}/>)}
                                </SpaceBetween> :
                                <Box padding={{ left : "xxxl" }} textAlign="center">
                                    <TextContent>
                                        <em>
                                            <small>
                                                --None--
                                            </small>
                                        </em>
                                    </TextContent>
                                </Box>
                            )}
                        </FormField>
                    </SpaceBetween>
                </Form>
            </ColumnLayout>
        </Container>
    );
};
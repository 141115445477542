import {
    EntityFactory,
    EntityPermission,
    EntityRef,
    EntityType,
    InstanceFactory,
    Stage
} from "@amzn/ask-legal-domain";
import {
    Header,
    SpaceBetween,
    FormField,
    TokenGroup,
    Spinner,
    Form,
    Button,
    Container,
    ColumnLayout,
    Box,
    TextContent,
    Toggle,
    StatusIndicator,
    Modal,
    Input
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import { useEffect, useContext, useState } from "react";
import * as React from "react";
import { EntityPermissionPolarisFactory } from "../../factory/polaris/entity-permission-polaris-factory";
import { useAPI } from "../../hooks/api-hook";
import { EntityPermissionModel } from "../../model/entity-permission-model";
import { UIModel } from "../../model/ui-model";
import { AppContext } from "../../setup/context";
import { ErrorFlashbar } from "./ErrorFlashbar";
import { TeamSearch } from "./TeamSearch";
import { UserSearch } from "./UserSearch";

export namespace EntityPermissionDetail {
    export const PreloadComp = (props: {
        id: string;
        viewOnly?: boolean;
    }) => {
        const context = useContext(AppContext);
        const entityPermissionDataState = UIModel.State.use<EntityPermission.Data>({});
        const loadEntityPermissionRunner = useAPI(
            context.getEntityPermissionAPI().load
        );
        const load = () => {
            loadEntityPermissionRunner.submitRun(props.id);
        };

        useEffect(() => {
            load();
        }, [props.id]);

        useEffect(() => {
            if (loadEntityPermissionRunner.status === "Succeeded") {
                entityPermissionDataState.setValue(
                    loadEntityPermissionRunner.data.output
                );
            }
        }, [loadEntityPermissionRunner.status]);

        return (
            <React.Fragment>
                { loadEntityPermissionRunner.status === "Error" &&
                    <ErrorFlashbar error={loadEntityPermissionRunner.data.err}/>
                }
                { !entityPermissionDataState.value &&
                    <Box textAlign="center" className="awsui-util-status-inactive"><Spinner/> Loading...</Box>
                }
                { !!entityPermissionDataState.value &&
                    <Comp state={entityPermissionDataState} viewOnly={props.viewOnly} />
                }
            </React.Fragment>
        );
    };

    export const Comp = (props: {
        state: UIModel.State<EntityPermission.Data>;
        viewOnly?: boolean;
    }) => {
        const context = useContext(AppContext);
        const smartState = SmartState.use({
            data: props.state.value
        });
        const loadInstancePermissionRunner = useAPI(
            context.getEntityPermissionAPI().load
        );
        let instanceRef: EntityRef;
        if (props.state.value.target.type === EntityType.Instance) {
            instanceRef = EntityFactory.toEntityRef(
                props.state.value
            );
        } else if (props.state.value.target.type === EntityType.PageLibrary) {
            instanceRef = Builder<EntityRef>()
                .id(InstanceFactory.fromEntityId(props.state.value.target.id))
                .type(EntityType.Instance)
                .build();
        } else if (props.state.value.target.type === EntityType.Page) {
            instanceRef = Builder<EntityRef>()
                .id(InstanceFactory.fromEntityId(props.state.value.target.id))
                .type(EntityType.Instance)
                .build();
        }
        const updateEntityPermissionState = EntityPermissionModel.UpdateState.use({
            template: props.state.value, instanceRef: instanceRef
        });

        const updateEntityPermissionRunner = useAPI(
            context.getEntityPermissionAPI().update
        );

        const [editing, setEditing] = useState<boolean>(false);
        const [showPublicAccessToggle, setShowPublicAccessToggle] = React.useState(false);
        const [showToggleAlert, setShowToggleAlert] = React.useState(false);
        const [userAcknowledge, setUserAcknowledge] = React.useState("");

        const update = () => {
            updateEntityPermissionRunner.submitRun(
                EntityPermissionModel.UpdateState.toInput(updateEntityPermissionState)
            );
        };

        const empty = (
            <Box padding={{ left : "xxxl" }} textAlign="center">
                <TextContent>
                    <em>
                        <small>
                            --None--
                        </small>
                    </em>
                </TextContent>
            </Box>
        );

        const getUserInformationText = (isEnabling: boolean) => {
            const warningText = <h2>{isEnabling ?
                <StatusIndicator type="warning">WARNING! Enabling this feature will grant access to all users in Amazon network</StatusIndicator> :
                <StatusIndicator type="warning">WARNING! Disabling this feature will restrict access to all underlying public resources</StatusIndicator>}</h2>;
            let infoText: React.JSX.Element;
            if (props.state.value.target.type === EntityType.Instance) {
                if (isEnabling) {
                    infoText = <em><b>NOTE:</b> Marking this instance public will grant all users in the Amazon network to view the Home page. This will also enable all editors in the instance to be able to mark a Page Library as public</em>;
                } else {
                    infoText = <em><b>NOTE:</b> Marking this instance private will restrict access to all underlying page libraries and will hide the access controls for all page library Editors</em>;
                }
            } else if (props.state.value.target.type === EntityType.PageLibrary) {
                if (isEnabling) {
                    infoText = <em><b>NOTE:</b> Marking this page library public will grant all users in the Amazon network View access to all pages underneath</em>;
                } else {
                    infoText = <em><b>NOTE:</b> Marking this page library private will restrict access to pages by authorization as specified in the section above</em>;
                }
            }
            return [
                warningText,
                <br/>,
                infoText
            ];
        };

        const TogglingPublicAccessAlertModal = (
            <Modal
                visible={showToggleAlert}
                size="large"
                header={"Are you sure?"}
                onDismiss={() => setShowToggleAlert(false)}
                footer={<Box float="right">
                    <Button variant="link" onClick={() => setShowToggleAlert(false)}>
                        Go Back
                    </Button>
                    <Button variant="primary" onClick={() => {
                        updateEntityPermissionState.publicViewOnlyField.setValue(!updateEntityPermissionState.publicViewOnlyField.value);
                        setShowToggleAlert(false);
                        }} disabled={userAcknowledge !== "Acknowledge"}>
                        Agree and Continue
                    </Button>
                </Box>}
            >
                {getUserInformationText(!updateEntityPermissionState.publicViewOnlyField.value)}
                <br/>
                <br/>
                <FormField
                    label={"To confirm action, please enter \"Acknowledge\" below"}
                >
                    <Input
                        placeholder="Acknowledge"
                        value={userAcknowledge}
                        onChange={(e) => setUserAcknowledge(e.detail.value)}
                    />
                </FormField>
            </Modal>
        );

        useEffect(() => {
            if (updateEntityPermissionRunner.status === "Succeeded") {
                props.state.setValue(
                    updateEntityPermissionRunner.data.output
                );
                smartState.entityPermissionDataState.setValue(updateEntityPermissionRunner.data.output);
                setEditing(false);
            }
        }, [updateEntityPermissionRunner.status]);

        useEffect(() => {
            if (props.viewOnly) return;
            const relevantStage = [Stage.Prod, Stage.Gamma, Stage.Beta].includes(context.getStage() as Stage) ? context.getStage() : Stage.Dev;
            if (!EntityPermission.ENABLED_PUBLIC_INSTANCES[relevantStage].includes(props.state.value.instanceId)) return;
            if (props.state.value.target.type === EntityType.PageLibrary) {
                loadInstancePermissionRunner.submitRun(props.state.value.instanceId);
            } else if (props.state.value.target.type === EntityType.Instance) {
                setShowPublicAccessToggle(true);
            }
        }, []);

        useEffect(() => {
            if (props.viewOnly) return;
            if (loadInstancePermissionRunner.status === "Succeeded") {
                if (!!loadInstancePermissionRunner.data.output.isPublicViewOnly) {
                    setShowPublicAccessToggle(true);
                } else {
                    setShowPublicAccessToggle(false);
                }
            }
        }, [loadInstancePermissionRunner.status]);

        return (
            <React.Fragment>
                { updateEntityPermissionRunner.status === "Error" &&
                    <ErrorFlashbar error={updateEntityPermissionRunner.data.err}/>
                }
                <Container
                    header={
                        <Header
                            variant="h3"
                            actions={
                                !props.viewOnly && (
                                    editing ? (
                                        <SpaceBetween direction="horizontal" size="m">
                                            <Button
                                                loading={updateEntityPermissionRunner.status === "Running"}
                                                onClick={() => {
                                                    setEditing(false);
                                                    updateEntityPermissionState.reset();
                                                }}
                                                variant="normal">Cancel
                                            </Button>
                                            <Button
                                                onClick={() => update()}
                                                variant="primary"
                                                disabled={
                                                    updateEntityPermissionState.adminUsersField.errorText ||
                                                    updateEntityPermissionState.approverUsersField.errorText ||
                                                    updateEntityPermissionState.editorUsersField.errorText ||
                                                    updateEntityPermissionState.viewerUsersField.errorText
                                                }
                                                loading={updateEntityPermissionRunner.status === "Running"}>Save
                                            </Button>
                                        </SpaceBetween>
                                    ) : (
                                        <Button onClick={() => setEditing(true)}>Edit</Button>
                                    )
                                )
                            }>
                            Permissions
                        </Header>
                    }>
                    <Form>
                        <SpaceBetween direction="vertical" size="l">
                            { smartState.adminDisplay &&
                                <ColumnLayout columns={2}>
                                    <FormField label="Admin Team(s)">
                                        {editing && <TeamSearch.Multiple
                                            initialSelected={updateEntityPermissionState.adminTeamsField.value}
                                            onTeamSelectChange={(teams) =>
                                                updateEntityPermissionState.adminTeamsField.setValue(teams)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.adminTeams || smartState.entityPermissionDataState.value.adminTeams.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.adminTeams) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toTokens(
                                                    smartState.entityPermissionDataState.value.adminTeams
                                                )}
                                            />
                                        }
                                    </FormField>
                                    <FormField
                                        label="Admin User(s)"
                                        errorText={updateEntityPermissionState.adminUsersField.errorText}>
                                        {editing && <UserSearch.Multiple
                                            initialSelected={updateEntityPermissionState.adminUsersField.value}
                                            onUserSelectChange={(users) =>
                                                updateEntityPermissionState.adminUsersField.setValue(users)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.adminUsers || smartState.entityPermissionDataState.value.adminUsers.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.adminUsers) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toUserTokens(
                                                    smartState.entityPermissionDataState.value.adminUsers
                                                )}
                                            />
                                        }
                                    </FormField>
                                </ColumnLayout>
                            }
                            { smartState.approverDisplay &&
                                <ColumnLayout columns={2}>
                                    <FormField label="Approver Team(s)">
                                        {editing && <TeamSearch.Multiple
                                            initialSelected={updateEntityPermissionState.approverTeamsField.value}
                                            onTeamSelectChange={(teams) =>
                                                updateEntityPermissionState.approverTeamsField.setValue(teams)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.approverTeams || smartState.entityPermissionDataState.value.approverTeams.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.approverTeams) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toTokens(
                                                    smartState.entityPermissionDataState.value.approverTeams
                                                )}
                                            />
                                        }
                                    </FormField>
                                    <FormField
                                        label="Approver User(s)"
                                        errorText={updateEntityPermissionState.approverUsersField.errorText}
                                        >
                                        {editing && <UserSearch.Multiple
                                            initialSelected={updateEntityPermissionState.approverUsersField.value}
                                            onUserSelectChange={(users) =>
                                                updateEntityPermissionState.approverUsersField.setValue(users)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.approverUsers || smartState.entityPermissionDataState.value.approverUsers.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.approverUsers) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toUserTokens(
                                                    smartState.entityPermissionDataState.value.approverUsers
                                                )}
                                            />
                                        }
                                    </FormField>
                                </ColumnLayout>
                            }
                            { smartState.editorDisplay &&
                                <ColumnLayout columns={2}>
                                    <FormField label="Editor Team(s)">
                                        {editing && <TeamSearch.Multiple
                                            initialSelected={updateEntityPermissionState.editorTeamsField.value}
                                            onTeamSelectChange={(teams) =>
                                                updateEntityPermissionState.editorTeamsField.setValue(teams)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.editorTeams || smartState.entityPermissionDataState.value.editorTeams.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.editorTeams) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toTokens(
                                                    smartState.entityPermissionDataState.value.editorTeams
                                                )}
                                            />
                                        }
                                    </FormField>
                                    <FormField
                                        label="Editor User(s)"
                                        errorText={updateEntityPermissionState.editorUsersField.errorText}>
                                        {editing && <UserSearch.Multiple
                                            initialSelected={updateEntityPermissionState.editorUsersField.value}
                                            onUserSelectChange={(users) =>
                                                updateEntityPermissionState.editorUsersField.setValue(users)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.editorUsers || smartState.entityPermissionDataState.value.editorUsers.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.editorUsers) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toUserTokens(
                                                    smartState.entityPermissionDataState.value.editorUsers
                                                )}
                                            />
                                        }
                                    </FormField>
                                </ColumnLayout>
                            }
                            { smartState.viewerDisplay &&
                                <ColumnLayout columns={2}>
                                    <FormField label="Viewer Team(s)">
                                        {editing && <TeamSearch.Multiple
                                                initialSelected={updateEntityPermissionState.viewerTeamsField.value}
                                                onTeamSelectChange={(teams) =>
                                                    updateEntityPermissionState.viewerTeamsField.setValue(teams)
                                                }
                                            />}
                                        {!editing && (!smartState.entityPermissionDataState.value.viewerTeams || smartState.entityPermissionDataState.value.viewerTeams.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.viewerTeams) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toTokens(
                                                    smartState.entityPermissionDataState.value.viewerTeams
                                                )}
                                            />
                                        }
                                    </FormField>
                                    <FormField
                                        label="Viewer User(s)"
                                        errorText={updateEntityPermissionState.viewerUsersField.errorText}>
                                        {editing && <UserSearch.Multiple
                                            initialSelected={updateEntityPermissionState.viewerUsersField.value}
                                            onUserSelectChange={(users) =>
                                                updateEntityPermissionState.viewerUsersField.setValue(users)
                                            }
                                        />}
                                        {!editing && (!smartState.entityPermissionDataState.value.viewerUsers || smartState.entityPermissionDataState.value.viewerUsers.length === 0) &&
                                            empty
                                        }
                                        {!editing && (!!smartState.entityPermissionDataState.value.viewerUsers) &&
                                            <TokenGroup
                                                items={EntityPermissionPolarisFactory.TokenGroup.toUserTokens(
                                                    smartState.entityPermissionDataState.value.viewerUsers
                                                )}
                                            />
                                        }
                                    </FormField>
                                </ColumnLayout>
                            }
                        {TogglingPublicAccessAlertModal}
                        {showPublicAccessToggle &&
                            <>
                                <hr />
                                <FormField
                                    label="Public View Access"
                                    description="Marking this instance as publicly viewable will allow all midway authenticated users to access it"
                                >
                                    <Toggle
                                        checked={updateEntityPermissionState.publicViewOnlyField.value}
                                        onChange={(e: { detail: { checked: boolean; }; }) => setShowToggleAlert(true)}
                                        disabled={!editing}
                                    >
                                        {!!updateEntityPermissionState.publicViewOnlyField.value ?
                                        <StatusIndicator type="warning" >Public View Access Granted</StatusIndicator> :
                                        <StatusIndicator type="stopped" >Public View Access Blocked</StatusIndicator>}
                                    </Toggle>
                                </FormField>
                            </>
                        }
                        </SpaceBetween>
                    </Form>
                </Container>
            </React.Fragment>
        );
    };

    export class SmartState {
        adminDisplay: boolean;
        approverDisplay: boolean;
        editorDisplay: boolean;
        viewerDisplay: boolean;
        publicViewDisplay: boolean;
        entityPermissionDataState: UIModel.State<EntityPermission.Data>;

        static use(props: { data: EntityPermission.Data }) {
            const entityPermissionDataState = UIModel.State.use<EntityPermission.Data>({
                initialValue: props.data
            });
            const [adminDisplay, setAdminDisplay] = useState<boolean>(false);
            const [approverDisplay, setApproverDisplay] = useState<boolean>(false);
            const [editorDisplay, setEditorDisplay] = useState<boolean>(false);
            const [viewerDisplay, setViewerDisplay] = useState<boolean>(false);
            const [publicViewDisplay, setPublicViewDisplay] = useState<boolean>(false);

            useEffect(() => {
                const targetType = entityPermissionDataState.value.target.type;
                if (targetType === EntityType.Instance) {
                    setAdminDisplay(true);
                    setApproverDisplay(false);
                    setEditorDisplay(false);
                    setViewerDisplay(true);
                    setPublicViewDisplay(true);
                }
                if (targetType === EntityType.PageLibrary) {
                    setAdminDisplay(false);
                    setApproverDisplay(false);
                    setEditorDisplay(true);
                    setViewerDisplay(true);
                    setPublicViewDisplay(true);
                }
                if (targetType === EntityType.Page) {
                    setAdminDisplay(false);
                    setApproverDisplay(false);
                    setEditorDisplay(false);
                    setViewerDisplay(true);
                    setPublicViewDisplay(false);
                }
            }, [entityPermissionDataState.value.target.type]);

            return Builder<SmartState>()
                .adminDisplay(adminDisplay)
                .approverDisplay(approverDisplay)
                .editorDisplay(editorDisplay)
                .viewerDisplay(viewerDisplay)
                .publicViewDisplay(publicViewDisplay)
                .entityPermissionDataState(entityPermissionDataState)
                .build();
        }
    }
}
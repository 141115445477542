import { ItemHyperlink } from "@amzn/altar-sds-client";
import { ColumnLayout, Input } from "@amzn/awsui-components-react";
import * as React from "react";

export interface HyperLinkEditProps {
    value: ItemHyperlink;
    onChange: (value: ItemHyperlink) => void;
}

export const HyperlinkFieldEdit = (props: HyperLinkEditProps) => {
    return (
        <>
            <ColumnLayout columns={2}>
                <Input
                    value={props.value?.title}
                    onChange={(event) => props.onChange({ ...props.value, title: event.detail.value })}
                    placeholder="Enter a title for the link"
                />
                <Input
                    value={props.value?.href}
                    onChange={(event) => props.onChange({ ...props.value, href: event.detail.value })}
                    placeholder="Enter a valid URL (incl. https://)"
                    inputMode="url"
                />
            </ColumnLayout>
        </>
    );
};
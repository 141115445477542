import { KendraConst, SearchInterface } from "@amzn/ask-legal-domain";
import { Checkbox, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

const MS_IN_DAY = 24 * 60 * 60 * 1000;

export const LastUpdatedDateFilter = (props: {
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
}) => {
    const [selected, setSelected] = React.useState<"week" | "twoweeks"|  "month" | "year">();

    React.useEffect(() => {
        const now = new Date();
        let filterDate = now;
        switch (selected) {
            case "week":
                filterDate = new Date(now.getTime() - (7 * MS_IN_DAY));
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        operation: "GreaterThanOrEquals",
                        valueMap: {
                            value: filterDate.toISOString(),
                            valueType: KendraConst.IndexAttributeType.DATE
                        }
                    })
                );
                break;
            case "twoweeks":
                filterDate = new Date(now.getTime() - 14 * MS_IN_DAY);
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        operation: "GreaterThanOrEquals",
                        valueMap: {
                            value: filterDate.toISOString(),
                            valueType: KendraConst.IndexAttributeType.DATE
                        }
                    })
                );
                break;
            case "month":
                filterDate = new Date(now.getTime() - 30 * MS_IN_DAY);
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        operation: "GreaterThanOrEquals",
                        valueMap: {
                            value: filterDate.toISOString(),
                            valueType: KendraConst.IndexAttributeType.DATE
                        }
                    })
                );
                break;
            case "year":
                filterDate = new Date(now.getTime() - 365 * MS_IN_DAY);
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.applyFilter(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        operation: "GreaterThanOrEquals",
                        valueMap: {
                            value: filterDate.toISOString(),
                            valueType: KendraConst.IndexAttributeType.DATE
                        }
                    })
                );
                break;
            default:
                props.queryInputState.setValue(
                    SearchInterface.SearchQueryInput.removeFilter(props.queryInputState.value, {
                        attribute: KendraConst.Attributes.Default.LastUpdatedDate,
                        operation: "GreaterThanOrEquals",
                        valueMap: {
                            value: now,
                            valueType: KendraConst.IndexAttributeType.DATE
                        }
                    })
                );
        }
    }, [selected]);

    return (
        <ExpandableSection defaultExpanded={false} header="Last Updated">
            <SpaceBetween direction="vertical" size="s">
                <Checkbox
                    checked={selected === "week"}
                    onChange={e => setSelected(e.detail.checked ? "week" : null)}>
                    in the last week
                </Checkbox>
                <Checkbox
                    checked={selected === "twoweeks"}
                    onChange={e => setSelected(e.detail.checked ? "twoweeks" : null)}>
                    in the last two weeks
                </Checkbox>
                <Checkbox
                    checked={selected === "month"}
                    onChange={e => setSelected(e.detail.checked ? "month" : null)}>
                    in the last month
                </Checkbox>
                <Checkbox
                    checked={selected === "year"}
                    onChange={e => setSelected(e.detail.checked ? "year" : null)}>
                    in the last year
                </Checkbox>
            </SpaceBetween>
        </ExpandableSection>
    );
};
import { ContainerRef, LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import * as React from "react";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { LegalContactModel } from "../../../model/legal-contact-model";
import { LegalContactTable } from "../../legal-contact/LegalContactTable";
import { DeleteLegalContact } from "../../legal-contact/modal-content/DeleteLegalContact";
import { CreateLegalContactModal } from "../../legal-contact/modal-content/CreateLegalContact";
import { UpdateLegalContactModal } from "../../legal-contact/modal-content/UpdateLegalContact";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { SegmentedControl } from "@amzn/awsui-components-react-v3";
import { Alert, SpaceBetween } from "@amzn/awsui-components-react";
import { ViewByLegalTeams } from "../../legal-contact/view-components/ViewByLegalTeams";
import { ViewRecommendationsComp } from "../../legal-contact/view-components/ViewRecommendationsComp";
import { getQueryParameterMap, setURLByQueryParameters } from "../../../utils/common-utils";

enum ViewMode {
    ALL = "ALL",
    BY_LEGAL_TEAM = "BY_LEGAL_TEAM",
    RECOMMENDED = "RECOMMENDED"
}
const FETCH_LEGAL_CONTACTS_PAGE_SIZE = 25;

export const WhosMylawyerContentView = (props: {
    content: WhosMyLawyerContent;
    containerRef: ContainerRef;
    showEditControls?: boolean;
    liveContainerRef?: ContainerRef;
}) => {
    const context = React.useContext(AppContext);
    const loadLegalContactRunner = useAPI2(
        context.getLegalContactAPI().loadAllByContainer
    );
    const legalContactState = LegalContactModel.UpdateState.use({
        containerRef: props.containerRef
    });
    const [costCenterId, setCostCenterId] = React.useState<string>(null);
    const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.ALL);
    const [editingMode, setEditingMode] = React.useState<"create" | "update" | "delete" | undefined>(undefined);
    const [items, setItems] = React.useState<LegalContact.Record[]>([]);
    const [dirtyOptions, setDirtyOptions] = React.useState<boolean>(false);

    const loadLegalContacts = () => {
        setItems([]);
        loadLegalContactsByPart(1);
    };

    const loadLegalContactsByPart = (part: number) => {
        loadLegalContactRunner.invoke({
            containerRef: props.containerRef,
            pagination: {
                currentPageIndex: part,
                pageSize: FETCH_LEGAL_CONTACTS_PAGE_SIZE
            },
            sorting: {
                field: props.content.defaultConfiguration.sorting.field,
                order: props.content.defaultConfiguration.sorting.order
            }
        });
    };

    const dismiss = () => {
        legalContactState.reset();
        setEditingMode(undefined);
    };

    const buttonActionHandler = (action: string, selectedItem?: LegalContact.Record) => {
        switch (action) {
            case "create":
                setEditingMode("create");
                legalContactState.init();
                break;
            case "update":
                setEditingMode("update");
                legalContactState.init(selectedItem);
                break;
            case "delete":
                setEditingMode("delete");
                legalContactState.init(selectedItem);
                break;
        }
    };

    const findFilterTextFromQueryParams = () => {
        const urlParams = getQueryParameterMap();
        const container = urlParams.get("container");
        if (!container || container !== props.containerRef.id) return;
        const filter = urlParams.get("filter");
        if (filter) {
            return filter;
        }
        const filterByMe = urlParams.get("filterByMe");
        if (filterByMe === "true") {
            return `contact: ${context.userId}`;
        }
        return;
    };

    const performActionsBasedOnQueryParams = (legalContacts: LegalContact.Record[]) => {
        const urlParams = getQueryParameterMap();
        const container = urlParams.get("container");
        if (!container || container !== props.containerRef.id) return;
        const editById = urlParams.get("editId");
        if (editById) {
            const item = legalContacts.find((item) => item.ref.entityRef.entityId === editById);
            if (item) {
                // set update item modal
                buttonActionHandler("update", item);
                // remove edit param
                urlParams.delete("editId");
                setURLByQueryParameters(urlParams);
            }
        }
    };

    React.useEffect(() => {
        loadLegalContacts();
    }, []);

    React.useEffect(() => {
        if (loadLegalContactRunner.status === "Succeeded") {
            const totalParts = Math.ceil(loadLegalContactRunner.output.totalCount / FETCH_LEGAL_CONTACTS_PAGE_SIZE);
            setItems([
                ...items,
                ...loadLegalContactRunner.output.result
            ]);
            performActionsBasedOnQueryParams(loadLegalContactRunner.output.result);
            if (totalParts > 1 && loadLegalContactRunner.input.pagination.currentPageIndex < totalParts) {
                loadLegalContactsByPart(loadLegalContactRunner.input.pagination.currentPageIndex + 1);
            }
        }
    }, [loadLegalContactRunner.status]);

    React.useEffect(() => {
        if (!props.liveContainerRef) return;
        setDirtyOptions(
            props.containerRef.version !== props.liveContainerRef.version
        );
    }, [items, props.content]);

    const renderView = () => {
        switch (viewMode) {
            case ViewMode.ALL:
                return (
                    <LegalContactTable
                        records={items}
                        reloadData={loadLegalContacts}
                        loading={loadLegalContactRunner.status === "Running"}
                        onHeaderAction={buttonActionHandler}
                        defaultSortState={WhosMyLawyerFactory.getSortingStateFromContent(props.content)}
                        defaultPreference={WhosMyLawyerFactory.toPreferencesFromDisplayConfiguration(
                            props.content.defaultConfiguration
                        )}
                        isEditor={props.showEditControls}
                        isLegalContactOrManager={
                            items.some((item) =>
                                item.legalContactUser?.id === context.userId ||
                                item.legalContactManager?.id === context.userId
                            ) && context.userId
                        }
                        headerContent="All Contacts"
                        whosMyLawyerContent={props.content}
                        defaultFilteringText={findFilterTextFromQueryParams()}
                    />
                );
            case ViewMode.RECOMMENDED:
                return <ViewRecommendationsComp
                    items={items}
                    content={props.content}
                    costCenterId={costCenterId}
                    loading={loadLegalContactRunner.status === "Running"}
                    reloadAction={loadLegalContacts}
                />;
            case ViewMode.BY_LEGAL_TEAM:
                return <ViewByLegalTeams
                    items={items}
                    loading={loadLegalContactRunner.status === "Running"}
                    content={props.content}
                />;
            default:
                return <div>Invalid view mode</div>;
        }
    };

    return (
        <>
            {editingMode === "create" &&
                <CreateLegalContactModal
                    state={legalContactState}
                    content={props.content}
                    liveContainerRef={props.liveContainerRef}
                    onDismiss={dismiss}
                    onCreate={(createdItem: LegalContact.Record) => {
                        setItems([
                            ...items,
                            createdItem
                        ]);
                    }}
                />
            }
            {editingMode === "update" &&
                <UpdateLegalContactModal
                    state={legalContactState}
                    content={props.content}
                    liveContainerRef={props.liveContainerRef}
                    onDismiss={dismiss}
                    onUpdate={(updatedItem: LegalContact.Record) => {
                        const updatedItemIndex = items.findIndex(x =>
                            x.ref.entityRef.entityId === updatedItem.ref.entityRef.entityId
                        );
                        setItems([
                            ...items.slice(0, updatedItemIndex),
                            updatedItem,
                            ...items.slice(updatedItemIndex + 1)
                        ]);
                    }}
                />
            }
            {editingMode === "delete" &&
                <DeleteLegalContact
                    state={legalContactState}
                    onDismiss={dismiss}
                    onDelete={(deletedItem: any) => {
                        const deletedItemIndex = items.findIndex(x =>
                            x.ref.entityRef.entityId === deletedItem.ref.entityRef.entityId
                        );
                        setItems([
                            ...items.slice(0, deletedItemIndex),
                            ...items.slice(deletedItemIndex + 1)
                        ]);
                    }}
                />
            }
            <SpaceBetween size="l">
                {dirtyOptions && <Alert
                    statusIconAriaLabel="Warning"
                    type="warning"
                >
                    Any updates to <em>Team, Sub-Team and Scope</em> options require publishing the page to be reflected on live
                </Alert>}
                <SpaceBetween size="m" direction="horizontal">
                    <h5>Choose a view:</h5>
                    <SegmentedControl
                        selectedId={viewMode}
                        options={[
                            { text: "All Legal Contacts", id: ViewMode.ALL },
                            { text: "By Legal Team", id: ViewMode.BY_LEGAL_TEAM, disabled: loadLegalContactRunner.status !== "Succeeded" },
                            { text: "Recommended For You", id: ViewMode.RECOMMENDED, disabled: loadLegalContactRunner.status !== "Succeeded" }
                        ]}
                        onChange={e => setViewMode(e.detail.selectedId as ViewMode)}
                    />
                </SpaceBetween>
                {renderView()}
            </SpaceBetween>
        </>
    );
};
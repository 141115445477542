import * as React from "react";
import { Header, Tabs } from "@amzn/awsui-components-react";
import { RecommendationRuleTable } from "../../components/recommendation/RecommendationRuleTable";
import { RecommendationTable } from "../../components/recommendation/RecommendationTable";
import { RoleTable } from "../../components/role/RoleTable";
import { RecommendationSimulator } from "../../components/recommendation/RecommendationSimulator";
import { RecommendationRankPreLoad } from "../../components/recommendation/RecommendationRankEdit";

export const RecommendationAdminPortal = (props: { id: string }) => {
    const [activeTabId, setActiveTabId] = React.useState("recommendations");
    return (
        <React.Fragment>
            <Header id="pageHeader" variant="h1">Manage Recommendations</Header>
            <Tabs
                tabs={[
                    {
                        label: "Recommendations",
                        id: "recommendations",
                        content: <RecommendationTable id={props.id} viewOnly={false}/>
                    },
                    {
                        label: "Rank Editing",
                        id: "rank-editing",
                        content: <RecommendationRankPreLoad id={props.id}/>
                    },
                    {
                        label: "Rules",
                        id: "rules",
                        content: <RecommendationRuleTable id={props.id}/>
                    },
                    {
                        label: "Recommendation Simulator",
                        id: "test",
                        content: <RecommendationSimulator id={props.id} />
                    }
                ]}
                activeTabId={activeTabId}
                onChange={(e) => setActiveTabId(e.detail.activeTabId)}
            />
        </React.Fragment>
    );
};
import * as React from "react";
import { AdminPreferences, SearchInterface } from "@amzn/ask-legal-domain";
import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import { AuthorFilter } from "../search-configs/AuthorFilter";
import { FileTypeFilter } from "../search-configs/FileTypeFilter";
import { LegalOnlyFilter } from "../search-configs/LegalOnlyFilter";
import { Sorting } from "../search-configs/Sorting";
import { LastUpdatedDateFilter } from "../search-configs/LastUpdatedDateFilter";
import { PaginationSetting } from "../search-configs/PaginationSetting";
import { PageLibraryFilter } from "../search-configs/PageLibraryFilter";
import { UIModel } from "../../../model/ui-model";

export const SmartSearchFilterView = (props: {
    preferences: AdminPreferences.SearchPreferences;
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
    authors: string[];
    pageLibraries: string[];
    hasLegalOnlyAccess: boolean;
}) => {
    return (
        <React.Fragment>
            <SpaceBetween size="l" direction="vertical">
                <Header variant="awsui-h1-sticky">
                    Refine Search
                </Header>
                <Sorting
                    queryInputState={props.queryInputState}
                    preferences={props.preferences.sortPrefs}
                />
                {props.preferences.filterPrefs.showAuthors &&
                    <AuthorFilter
                        authors={props.authors}
                        queryInputState={props.queryInputState}
                    />
                }
                {props.preferences.filterPrefs.showFileType.length > 0 &&
                    <FileTypeFilter
                        queryInputState={props.queryInputState}
                    />
                }
                {props.preferences.paginationPrefs.length > 0 &&
                    <PaginationSetting
                        queryInputState={props.queryInputState}
                        prefs={props.preferences.paginationPrefs}
                    />
                }
                {props.preferences.filterPrefs.showPageLibrary &&
                    <PageLibraryFilter
                        pageLibraryIds={props.pageLibraries}
                        queryInputState={props.queryInputState}
                    />
                }
                {props.preferences.filterPrefs.showLastUpdated &&
                    <LastUpdatedDateFilter
                        queryInputState={props.queryInputState}
                    />
                }
                {props.preferences.filterPrefs.showPermissionGroups &&
                    <LegalOnlyFilter
                        queryInputState={props.queryInputState}
                        hasLegalOnlyAccess={props.hasLegalOnlyAccess}
                    />
                }
            </SpaceBetween>
        </React.Fragment>
    );
};
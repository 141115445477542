import { UIModel } from "../../../../../../model/ui-model";
import { AdvancedListContent } from "@amzn/ask-legal-domain";
import { AdvancedList } from "@amzn/altar-sds-client";
import * as React from "react";
import {
  Box,
  Button,
  Icon,
  Modal,
  SpaceBetween
} from "@amzn/awsui-components-react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import "../../../../../../styles/custom-token.scss";

export function SortableItem(props) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="polaris-token-clone"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <SpaceBetween size="xs" direction="horizontal">
        <div className="hover-grab">
          <Icon name="drag-indicator"/>
        </div>
        {props.displayName}
      </SpaceBetween>
    </div>
  );
}

export const ColumnReorderModal = (props: {
    state: UIModel.State<AdvancedListContent>;
    advancedList: AdvancedList;
    onSubmit: (sequence: string[]) => void;
    onCancel: () => void;
  }) => {


  const [items, setItems] = React.useState<string[]>(
    props.advancedList?.fieldDefinitions
      ?.filter(i => !i.deprecated)
      .map(i => i.fieldKey)
    || []
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <>
      <Modal
        visible={true}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button onClick={() => props.onCancel()} variant="link">Cancel</Button>
              <Button onClick={() => props.onSubmit(items)}variant="primary">Submit</Button>
            </SpaceBetween>
          </Box>
        }
        header="Reorder Columns"
      >
        <p>Drag and drop Columns to reorder them</p>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={items}
            strategy={verticalListSortingStrategy}
          >
            <SpaceBetween size="xs">
              {items.map(id =>
                <SortableItem
                  key={id}
                  id={id}
                  displayName={props.advancedList.fieldDefinitions
                    .find(f => f.fieldKey === id).displayName
                  }
                />
              )}
            </SpaceBetween>
          </SortableContext>
        </DndContext>
      </Modal>
    </>
  );

  function handleDragEnd(event) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};
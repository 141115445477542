import { Container } from "@amzn/ask-legal-domain";
import {
    Flashbar,
    Grid,
    SpaceBetween,
    TextContent
} from "@amzn/awsui-components-react";
import * as React from "react";
import { PageDraftModel } from "../../../model/page-draft-model";
import { UIField } from "../../common/UIField";
import { ContainerModel } from "../../../model/container-model";
import { ContainerDescription } from "../ContainerDescription";
import { SmartContainerContentEdit } from "../edit-content/SmartContainerContentEdit";
import { ConfirmLeavePageModal } from "../../common/ConfirmLeavePageModal";
import { getObjectSizeinBytes, UIConstants } from "../../../utils/common-utils";

export const EditContainerContent = (props: {
    state: PageDraftModel.EditContainerState;
}) => {
    const enableMargins = () => {
        switch (props.state.activeContainerTypeField.value) {
            case Container.Type.ACCORDION:
            case Container.Type.ACCORDION_GROUP:
            case Container.Type.DOCUMENT:
                return true;
            case Container.Type.RICH_TEXT:
            case Container.Type.LIST:
            case Container.Type.DECISION_TREE:
            case Container.Type.WHOS_MY_LAWYER:
            case Container.Type.ADVANCED_LIST:
            default:
                return false;
        }
    };

    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);
    const [containerMetaDataSizeValid, setContainerMetaDataSizeValid] = React.useState<boolean>(true);

    React.useEffect(() => {
        const newDescriptionLength = props.state.descriptionField.value ? props.state.descriptionField.value.length : 0;
        const charLimitCheck = ContainerModel.DESCRIPTION_CHAR_LIMIT - newDescriptionLength;
        if (charLimitCheck === ContainerModel.DESCRIPTION_CHAR_LIMIT) {
            setDescriptionConstraint(
                `Maximum ${ContainerModel.DESCRIPTION_CHAR_LIMIT} characters`
            );
        } else if (charLimitCheck >= 0) {
            setDescriptionConstraint(
                `Maximum ${ContainerModel.DESCRIPTION_CHAR_LIMIT} characters ( ${ContainerModel.DESCRIPTION_CHAR_LIMIT - props.state.descriptionField.value.length} remaining )`
            );
        } else if (charLimitCheck < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                    Maximum {ContainerModel.DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color : "#d13212" }}>{props.state.descriptionField.value.length - ContainerModel.DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
            );
        }
    }, [props.state.descriptionField.value]);

    React.useEffect(() => {
        const size = getObjectSizeinBytes(`
            ${props.state.headerDescriptionHeaderField.value}
            ${props.state.headerDescriptionBodyField.value}
            ${props.state.footerDescriptionHeaderField.value}
            ${props.state.footerDescriptionBodyField.value}
        `);
        if (size > UIConstants.MAX_CONTAINER_METADATA_SIZE) {
            setContainerMetaDataSizeValid(false);
        } else {
            setContainerMetaDataSizeValid(true);
        }
    }, [
        props.state.headerDescriptionHeaderField.value,
        props.state.headerDescriptionBodyField.value,
        props.state.footerDescriptionHeaderField.value,
        props.state.footerDescriptionBodyField.value
    ]);

    return (
        <React.Fragment>
            <ConfirmLeavePageModal showModal={true} />
            <SpaceBetween direction="vertical" size="xl">
                <Grid
                    gridDefinition={[
                        { colspan: 5 },
                        { colspan: 7 }
                    ]}>
                    <UIField.StateValueField
                        state={props.state.titleField}
                        name="Container Title"
                        constraintText={`Maximum ${ContainerModel.TITLE_CHAR_LIMIT} characters`}
                        editing={true}
                    />
                    <UIField.StateValueField
                        state={props.state.descriptionField}
                        name="Container Description"
                        editing={true}
                        variant="TextArea"
                        constraintText={descriptionConstraint}
                        placeholder="Enter a brief description..."
                    />
                </Grid>
                {enableMargins() &&
                    <>
                        <ContainerDescription.Edit
                            variant="Header"
                            headerState={props.state.headerDescriptionHeaderField}
                            bodyState={props.state.headerDescriptionBodyField}
                        />
                        <ContainerDescription.Edit
                            variant="Footer"
                            headerState={props.state.footerDescriptionHeaderField}
                            bodyState={props.state.footerDescriptionBodyField}
                        />
                    </>
                }
                <SmartContainerContentEdit
                    containerType={props.state.activeContainerTypeField.value}
                    state={props.state.contentField}
                />
                {!containerMetaDataSizeValid && (
                    <Flashbar
                        items={[{
                            type: "error",
                            content: <p>Header and Footer content is too large. See <a href={UIConstants.MAX_EDIT_CONTAINER_LIMIT_DOCUMENTATION} target="_blank">guidance</a> for reducing the size.</p>
                        }]}
                    />
                )}
            </SpaceBetween>
        </React.Fragment>
    );
};
import { DocumentContent } from "@amzn/ask-legal-domain";
import { Flashbar } from "@amzn/awsui-components-react";
import * as React from "react";

export const DocumentContentDiff = (props: {
    oldContent: DocumentContent;
    newContent: DocumentContent;
}) => {
    return <React.Fragment>
        <Flashbar
            items={[{
                header: "Not Yet Implemented",
                type: "error",
                content: "This feature is in development"
            }]}
        />
    </React.Fragment>;
};
import {
    AccordionContent,
    AccordionGroupContent,
    Container,
    CustomListContent,
    DocumentContent,
    IContainer,
    RichTextContent
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { AccordionContentDiff } from "./diff-content/AccordionContentDiff";
import { AccordionGroupContentDiff } from "./diff-content/AccordionGroupContentDiff";
import { CustomListContentDiff } from "./diff-content/CustomListContentDiff";
import { DocumentContentDiff } from "./diff-content/DocumentContentDiff";
import { RichTextContentDiff } from "./diff-content/RichTextContentDiff";

export const ContainerDiffView = (props: {
    newContainer: IContainer.LoadContainerOutput;
    oldContainer: IContainer.LoadContainerOutput;
}) => {
    let renderedDiffView: JSX.Element;
    switch (props.newContainer.containerMetadata.containerType) {
        case Container.Type.RICH_TEXT:
            renderedDiffView = <RichTextContentDiff
                oldContent={props.oldContainer?.loadedContent as RichTextContent}
                newContent={props.newContainer.loadedContent as RichTextContent}
            />;
            break;
        case Container.Type.ACCORDION:
            renderedDiffView = <AccordionContentDiff
                oldContent={props.oldContainer?.loadedContent as AccordionContent}
                newContent={props.newContainer.loadedContent as AccordionContent}
                oldMetadata={props.oldContainer?.containerMetadata}
                newMetadata={props.newContainer.containerMetadata}
            />;
            break;
        case Container.Type.ACCORDION_GROUP:
            renderedDiffView = <AccordionGroupContentDiff
                oldContent={props.oldContainer?.loadedContent as AccordionGroupContent}
                newContent={props.newContainer.loadedContent as AccordionGroupContent}
                oldMetadata={props.oldContainer?.containerMetadata}
                newMetadata={props.newContainer.containerMetadata}
            />;
            break;
        case Container.Type.DOCUMENT:
            renderedDiffView = <DocumentContentDiff
                oldContent={props.oldContainer?.loadedContent as DocumentContent}
                newContent={props.newContainer.loadedContent as DocumentContent}
            />;
            break;
        case Container.Type.LIST:
            renderedDiffView = <CustomListContentDiff
                oldContent={props.oldContainer?.loadedContent as CustomListContent}
                newContent={props.newContainer.loadedContent as CustomListContent}
            />;
            break;
        default:
            break;
    }
    return renderedDiffView;
};
import { AppError } from "@amzn/ask-legal-domain";
import { useState } from "react";

export interface ComponnentProps<P> {
    props: P;
    setProps: React.Dispatch<React.SetStateAction<P>>;
    error: AppError;
    setError: React.Dispatch<React.SetStateAction<AppError>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useComponnentProps<P>(p?: P): ComponnentProps<P> {
    const [props, setProps] = useState<P>(p);
    const [error, setError] = useState<AppError>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    return {
        props,
        setProps,
        error,
        setError,
        loading,
        setLoading
    };
}
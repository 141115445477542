import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import { SherpaHeader } from "../../components/layout/SherpaHeader";
import { SherpaFooter } from "../../components/layout/SherpaFooter";
import { MyLegalContactsTable } from "../../components/legal-contact/MyLegalContactsTable";
import { AllLegalContactsTable } from "../../components/legal-contact/AllLegalContactsTable";
import useRoutedParamsWithContext from "../../setup/useRoutedParamsWithContext";

export const legalContactRoutes = [
    {
        path: "/legal-contact/:instanceId/home",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <AllLegalContactsTable instanceId={instanceId} />;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Home", href: `#/instance/${instanceId}/home` },
                { text: "Legal Contacts Home", href: `#/legal-contact/${instanceId}/home` }
            ]}/>;
        },
        header: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaHeader headerType="InstanceHome" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    },
    {
        path: "/legal-contact/:instanceId/myInfo",
        content: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <MyLegalContactsTable instanceId={instanceId} />;
        },
        breadcrumbs: (props: RouteComponentProps) => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <BreadcrumbGroup items={[
                { text: "Instance Home", href: `#/instance/${instanceId}/home` },
                { text: "My Legal Contacts", href: `#/legal-contact/${instanceId}/myInfo` }
            ]}/>;
        },
        header: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaHeader headerType="InstanceHome" instanceId={instanceId}/>;
        },
        footer: () => {
            let { instanceId } = useRoutedParamsWithContext<{ instanceId: string }>();
            return <SherpaFooter variant="AdminPortal" entityId={instanceId}/>;
        }
    }
];
import { SearchInterface } from "@amzn/ask-legal-domain";
import { ExpandableSection, RadioGroup } from "@amzn/awsui-components-react";
import * as React from "react";
import { UIModel } from "../../../model/ui-model";

export const LegalOnlyFilter = (props: {
    queryInputState: UIModel.State<SearchInterface.SearchQueryInput>;
    hasLegalOnlyAccess: boolean;
}) => {
    const [selected, setSelected] = React.useState<string>("all");

    const options = [
        { label: "Show only legal results", value: "true" },
        { label: "Show everything", value: "all" },
        { label: "Exclude legal results", value: "false" }
    ];

    React.useEffect(() => {
        let queryInput = Object.assign({}, props.queryInputState.value);
        switch (selected) {
            case "true":
                queryInput.showStrictlyLegalOnly = true;
                break;
            case "false":
                queryInput.showStrictlyLegalOnly = false;
                break;
            default:
                delete queryInput.showStrictlyLegalOnly;
        }
        props.queryInputState.setValue(queryInput);
    }, [selected]);

    React.useEffect(() => {
        switch (props.queryInputState.value.showStrictlyLegalOnly) {
            case true:
                setSelected("true");
                break;
            case false:
                setSelected("false");
                break;
            default:
                setSelected("all");
        }
    }, [props.queryInputState.value.showStrictlyLegalOnly]);

    return (
        <React.Fragment>
            {props.hasLegalOnlyAccess &&
                <ExpandableSection
                    defaultExpanded={false}
                    header="Legal Only"
                >
                    <RadioGroup
                        value={selected}
                        onChange={({ detail }) => setSelected(detail.value)}
                        items={options}
                    />
                </ExpandableSection>
            }
        </React.Fragment>
    );
};
import * as React from "react";
import { Box, Button, ColumnLayout, Form, Link, Modal, Popover, SpaceBetween, TextContent } from "@amzn/awsui-components-react";
import { useHistory } from "react-router";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { PageModel } from "../../model/page-model";
import { ErrorFlashbar } from "../common/ErrorFlashbar";
import { UIField } from "../common/UIField";
import { EntityFactory, IPageDraft } from "@amzn/ask-legal-domain";
import { UserSearch } from "../common/UserSearch";

export const PageCreateModal = (
    props: {
        id: string;
    }
) => {
    const [openWindow, setOpenWindow] = React.useState(false);

    const history = useHistory();
    const context = React.useContext(AppContext);
    const createPageRunner = useAPI(
        context.getPageAPI().create
    );
    const createPageDraftRunner = useAPI(
        context.getPageDraftAPI().create
    );
    const createPageState = PageModel.CreateState.use({ pageLibraryid: props.id });

    const create = () => {
        createPageRunner.submitRun(PageModel.CreateState.toInput(createPageState));
    };

    const handleWindow = (flag: boolean) => {
        if (!flag) {
            setOpenWindow(false);
            createPageState.reset();
        } else {
            setOpenWindow(true);
        }
    };

    React.useEffect(() => {
        setOpenWindow(openWindow);
    });

    React.useEffect(() => {
        if (createPageRunner.status === "Succeeded") {
            createPageDraftRunner.submitRun(
                IPageDraft.CreatePageDraftInput.create(
                    EntityFactory.toEntityRef(createPageRunner.data.output)
                )
            );
        }
    }, [createPageRunner.status]);

    React.useEffect(() => {
        if (createPageDraftRunner.status === "Succeeded") {
            history.push(`/page/${createPageRunner.data.output.id}/admin`);
        }
    }, [createPageDraftRunner.status]);

    return (
        <React.Fragment>
            <Button variant="primary" onClick={() => handleWindow(true)}>Create New Page</Button>
            <Modal
                visible={openWindow}
                header="Create New Page"
                closeAriaLabel="Close New Page Form"
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button variant="link" onClick={() => handleWindow(false)}>
                                Cancel
                            </Button>
                            <Button
                                variant="primary"
                                onClick={create}
                                disabled={
                                    createPageState.name.errorText ||
                                    createPageState.title.errorText ||
                                    createPageState.description.errorText ||
                                    createPageState.pageOwner.errorText ||
                                    createPageState.name.value.length > PageModel.TITLE_CHAR_LIMIT ||
                                    createPageState.title.value.length > PageModel.TITLE_CHAR_LIMIT ||
                                    createPageState.description.value.length > PageModel.DESCRIPTION_CHAR_LIMIT
                                }
                                loading={createPageRunner.status === "Running" || createPageDraftRunner.status === "Running"}>
                                Submit
                            </Button>
                        </SpaceBetween>
                    </Box>}
                size="max"
                onDismiss={() => setOpenWindow(false)}
            >
                {createPageRunner.status === "Error" && <ErrorFlashbar error={createPageRunner.data.err} />}
                <PageCreateForm id={props.id} state={createPageState}/>
            </Modal>
        </React.Fragment>
    );
};

export const PageCreateForm = (
    props: {
        id: string;
        state: PageModel.CreateState
    }
) => {

    const [descriptionConstraint, setDescriptionConstraint] = React.useState<React.ReactNode>(null);

    React.useEffect(() => {
        if (!props.state.description.value || (PageModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length === PageModel.DESCRIPTION_CHAR_LIMIT)) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length >= 0) {
            setDescriptionConstraint(`Maximum ${PageModel.DESCRIPTION_CHAR_LIMIT} characters ( ${PageModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length} remaining )`);
        } else if (PageModel.DESCRIPTION_CHAR_LIMIT - props.state.description.value.length < 0) {
            setDescriptionConstraint(
                <TextContent>
                    <small>
                    Maximum {PageModel.DESCRIPTION_CHAR_LIMIT} characters ( <span style={{ color : "#d13212" }}>{props.state.description.value.length - PageModel.DESCRIPTION_CHAR_LIMIT}</span> too many )
                    </small>
                </TextContent>
                );
        }
    }, [props.state.description.value]);

    return (
        <React.Fragment>
            <Form>
                <ColumnLayout columns={2}>
                    <Box>
                        <SpaceBetween direction="vertical" size="l">
                            <UIField.StateValueField
                                state={props.state.name}
                                name="Page Name"
                                constraintText={
                                    <React.Fragment>
                                        Maximum {PageModel.TITLE_CHAR_LIMIT} characters <Popover
                                        dismissAriaLabel="Close"
                                        header="Page Naming Guidelines"
                                        triggerType="custom"
                                        content={<React.Fragment>
                                                <small>
                                                    <ul>
                                                        <li>Cannot contain spaces</li>
                                                        <li>Cannot contain any special characters</li>
                                                        <li>Can contain alphabets <b>A...Z</b> <b>a...z</b></li>
                                                        <li>Can contain numbers <b>0-9</b></li>
                                                        <li><b><em>Examples : </em></b> SamplePageName, 1New2Document3, ... etc</li>
                                                    </ul>
                                                </small>
                                            </React.Fragment>}
                                        >
                                            <Link variant="info">Format</Link>
                                        </Popover></React.Fragment>}
                                editing={true}
                            />
                            <UIField.StateValueField
                                state={props.state.description}
                                name="Page Description"
                                variant="TextArea"
                                editing={true}
                                placeholder="Enter a brief description of the page..."
                                constraintText={descriptionConstraint}
                            />
                        </SpaceBetween>
                    </Box>
                    <Box>
                        <SpaceBetween direction="vertical" size="l">
                            <UIField.StateValueField
                                state={props.state.title}
                                name="Page Title"
                                constraintText={`Maximum ${PageModel.TITLE_CHAR_LIMIT} characters`}
                                editing={true}
                            />
                            <UIField.LabelField
                                label="Page Owner"
                                children={
                                    <UserSearch.Single
                                        selected={props.state.pageOwner.value}
                                        onUserSelectChange={(selected) => props.state.pageOwner.setValue(selected)}
                                    />
                                }
                            />
                        </SpaceBetween>
                    </Box>
                </ColumnLayout>
            </Form>
        </React.Fragment>);

};
import { AdminPreferences } from "@amzn/ask-legal-domain";
import * as React from "react";
import { Badge } from "react-bootstrap";
import { useAPI2 } from "../../hooks/api-hook";
import { AppContext } from "../../setup/context";
import { PreferenceSelectionModal } from "../instance/PreferenceSelectionModal";
import { HeaderType } from "./SherpaHeader";
const AWS_LEGAL_LOGO = require("../../assets/aws-legal-logo-wb.png").default;
const AMAZON_LEGAL_LOGO = require("../../assets/amazon-legal-logo-wb.png").default;

export const InstanceBadge = (props: { instanceId: string, headerType: HeaderType }) => {
    const context = React.useContext(AppContext);
    const loadInstanceRunner = useAPI2(context.getInstanceAPI().load);
    const [instanceSelectionVisible, setInstanceSelectionVisible] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!props.instanceId) return;
        loadInstanceRunner.invoke(props.instanceId);
    }, [props.instanceId]);

    return (
        <div className="right-corner-brand">
            {loadInstanceRunner.status === "Succeeded" &&
                <React.Fragment>
                {loadInstanceRunner.output.preferences?.organization ===
                    AdminPreferences.Organization.AMAZON_LEGAL &&
                    <img className={"non-select " + (props.headerType === "Home" ? "float-right" : "right-corner-brand-logo")}
                        src={String(AMAZON_LEGAL_LOGO)}
                        draggable={false}
                    />
                }
                {loadInstanceRunner.output.preferences?.organization ===
                    AdminPreferences.Organization.AWS_LEGAL &&
                    <img className={"non-select " + (props.headerType === "Home" ? "float-right" : "right-corner-brand-logo")}
                        src={String(AWS_LEGAL_LOGO)}
                        draggable={false}
                    />
                }
                </React.Fragment>
            }
            <div className="right-corner-brand-badge">
                <Badge className="bg-orange" onClick={() => setInstanceSelectionVisible(true)}>
                    {loadInstanceRunner.status === "Succeeded" &&
                        loadInstanceRunner.output.name
                    }
                </Badge>
            </div>
            {instanceSelectionVisible &&
                <PreferenceSelectionModal onDismiss={() => setInstanceSelectionVisible(false)}/>
            }
        </div>
    );
};
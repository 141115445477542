import { SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { SimpleModal } from "../../common/SimpleModal";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { ILegalContact, LegalContact } from "@amzn/ask-legal-domain";
import { LegalContactModel } from "../../../model/legal-contact-model";
import { ErrorFlashbar } from "../../common/ErrorFlashbar";

export const DeleteLegalContact = (props: {
    state: LegalContactModel.UpdateState;
    onDismiss: () => void;
    onDelete: (deleteOutput: LegalContact.Record) => void;
}) => {
    const context = React.useContext(AppContext);
    const deleteLegalContactRunner = useAPI2(
        context.getLegalContactAPI().delete
    );

    const onSave = () => {
        deleteLegalContactRunner.invoke(
            ILegalContact.DeleteLegalContactInput.create({
                entityVersionRef: props.state.entityVersionRef!.value,
                containerId: props.state.containerRef.id
            })
        );
    };

    React.useEffect(() => {
        if (deleteLegalContactRunner.status === "Succeeded") {
            props.onDelete(deleteLegalContactRunner.output);
            props.onDismiss();
        }
    }, [deleteLegalContactRunner.status]);

    return (
        <SimpleModal
            child={<SpaceBetween size="m">
                {deleteLegalContactRunner.status === "Error" && <ErrorFlashbar error={deleteLegalContactRunner.err} />}
                <div>Are you sure you want to delete this legal contact?</div>
                <div><strong>Note: Deleting will result in this legal contact being removed from the Live version of this page</strong></div>
            </SpaceBetween>}
            header={`Delete Legal Contact - ${props.state.legalContactUser.value.name}`}
            loading={deleteLegalContactRunner.status === "Running"}
            onCancel={props.onDismiss}
            onSave={onSave}
            size="large"
            disableLeavingAlert={true}
        />
    );
};